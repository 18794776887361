// import displayHelper from "@/util/display_helper"
import model from "@/model"
import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.conversions) {
      object.conversions = model.conversionInfo(object.conversions);
    }
  } else {
    object = {
      event: '',
      stroke: '',
      entranceAge: '',
      meet: '',
      course: 0,
      result: '',
      date_epoch: 0,
      finaPoints: '',
      distance: '',
      conversions: model.conversionInfo(),

      // results attributes
      gender: '',
      percent: 0,

    };
  }
  object.formattedKey = function formattedKey(squadId, memberId) {
    return squadId.concat('.', memberId.toString(), '.', object.getDistance().toString(), '.', object.getCourse().toString());
  }
  object.getEvent = function getEvent() {
    return object.event ? object.event : '';
  }
  object.getStroke = function getStroke() {
    return object.stroke ? object.stroke : '';
  }
  object.getEntranceAge = function getEntranceAge() {
    return object.entranceAge ? object.entranceAge : '';
  }
  object.formattedAge = function formattedAge() {
    return 'Age ' + this.getEntranceAge();
  }
  object.getMeet = function getMeet() {
    return object.meet ? object.meet : '';
  }
  object.getCourse = function getCourse() {
    return object.course ? object.course : 0;
  }
  object.formattedCourse = function formattedCourse() {
    return object.getCourse() === 25 ? 'Short Course' : 'Long Course';
  }

  object.getResult = function getResult() {
    return object.result ? object.result : '';
  }
  object.hasResult = function hasResult() {
    return object.getResult().length > 0;
  }
  object.getDate = function getDate() {
    return object.date_epoch ? object.date_epoch : 0;
  }
  object.formattedDate = function formattedDate() {
    return datetimeHelper.formattedDateTime(this.getDate(), true);
  }
  object.getFina = function getFina() {
    return object.finaPoints ? object.finaPoints : '';
  }
  object.getDistance = function getDistance() {
    return object.distance ? object.distance : '';
  }
  object.getConversions = function getConversions() {
    return object.conversions ? object.conversions : model.conversionInfo();
  }
  object.getGender = function getGender() {
    return object.gender ? object.gender : '';
  }
  object.getPercent = function getPercent() {
    return object.percent ? object.percent.toFixed(0) : 0;
  }
  object.formattedConversion = function formattedConversion() {
    return '* ' + (this.getCourse() == 25 ? this.getConversions().getLcTime() : this.getConversions().getScTime());
  }
  object.isValid = function isValid() {
    return this.getDate() > 0;
  }
  object.formattedResultAge = function formattedResultAge() {
    return 'Result: ' + object.getResult() + ', Age: ' + object.getEntranceAge();
  }
  return object;
}

