import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      filename: '',
      createdAt: 0,
      createdBy: ''
    };
  }
  object.getFileName = function getFileName() {
    return object.filename ? object.filename : '';
  }
  object.getCreatedBy = function getCreatedBy() {
    return object.createdBy ? object.createdBy : '';
  }
  object.getCreatedAt = function getCreatedAt() {
    return object.createdAt ? object.createdAt : 0;
  }
  object.formattedCreatedAt = function formattedCreatedAt() {
    return datetimeHelper.formattedDateTime(this.getCreatedAt());
  }
  return object;
}

