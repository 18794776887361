<template>
  <div class="page_layout d-flex flex-row gradient_background">
    <!-- side bar left -->
    <div class="side_bar desktop">
      <slot name="page_side_bar"></slot>
    </div>
    <!-- content right -->
    <div class="content flex-grow-1">
      <slot name="page_content"></slot>
    </div>
  </div>
</template>
<script>

const LOG_TAG = 'page_layout'

export default {
  name: LOG_TAG,
}
</script>

<style scoped>
.page_layout {
  height: calc(100vh - 6rem);
}
.gradient_background {
  background-image: linear-gradient(var(--background-blue), var(--background-blue-light))
}
.side_bar {
  width: 12rem;
  height: 100%;
}
.side_bar.desktop {
  display: block;
}
.content {
  height: 100%;
  overflow-y: auto; /* Hide scrollbars */
}
@media screen and (max-width: 767px) {
  .page_layout {
    height: calc(100vh - 4rem);
  }
  .side_bar.desktop {
    display: none;
  }
}
</style>
