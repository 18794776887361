<template>
  <div v-if="mSquadExt.isValid()" class="mt-1">

    <b-modal centered title="Oops!" id="no-access-modal">
      <p class="my-4">
        You do not have access to view {{ mSquadMembers.selected.getValidatedName() }}'s profile.
      </p>

      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm" v-on:click.stop="ok()">
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal centered
             title="Warning"
             id="pending-approval-modal">
      <p class="my-4">
        {{ mSquadMembers.selected.getValidatedName() }} is currently pending approval.
      </p>

      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm" v-on:click.stop="ok()">
          Close
        </b-button>
      </template>
    </b-modal>

    <!-- broadcast-modal -->
    <b-modal centered title="Send message?" id="message-modal">
      <h6>{{mMemberOptions.messageData.title}}</h6>
      <p class="my-4">{{ mMemberOptions.messageData.body }}</p>


      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmSendMessage()">
          Send
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- approve-swimmer-modal -->
    <b-modal centered title="Confirm" id="approve-swimmer-modal">
      <p class="my-4">
        Approve {{ mSquadMembers.selected.getValidatedName() }} to the squad?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm" v-on:click.stop="onConfirmApproveSwimmer()">
          Approve
        </b-button>
        <b-button class="my-button-cancel" v-on:click.stop="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <!-- reject-swimmer-modal -->
    <b-modal centered title="Confirm" id="reject-swimmer-modal">
      <p class="my-4">
        Reject {{ mSquadMembers.selected.getValidatedName() }} to the squad?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmRejectSwimmer()">
          Reject
        </b-button>
        <b-button class="my-button-cancel" v-on:click.stop="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- delete-coach-modal -->
    <b-modal centered title="Remove coach?" id="delete-coach-modal">
      <p class="my-4">
        Remove coach {{ mSquadMembers.selected.getValidatedName() }} from the squad?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmDeleteCoach()">
          Remove
        </b-button>
        <b-button class="my-button-cancel" v-on:click.stop="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- delete-swimmer-modal -->
    <b-modal centered title="Remove swimmer?" id="delete-swimmer-modal">
      <p class="my-4">
        Remove {{ mSquadMembers.selected.getValidatedName() }} from the squad?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmDeleteSwimmer()">
          Remove
        </b-button>
        <b-button class="my-button-cancel" v-on:click.stop="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <!-- search-member-modal -->
    <b-modal centered
             :title="mSearchMember.isInviteCoach() ? 'Add Coach' : 'Add Swimmer'"
             id="search-member-modal">
      <p v-if="mSearchMember.isInviteCoach()">
        An email invitation is sent to join squad as coach
      </p>
      <search-user-by-name-card
          v-if="mSearchMember.isInviteSwimmer()"
          :dark="true"
          :club-id="mSquadExt.getClubId()"
          :squad-id="mSquadExt.getSquadId()"
          :swimmer="mSearchMember.swimmer"
          :squad-members="mSquadMembers.options"
          :on-search-state="onSearchSwimmerState">
      </search-user-by-name-card>
      <search-user-by-email-card
          v-if="mSearchMember.isInviteCoach()"
          :dark="true"
          :support-sign-up="true"
          :user="mSearchMember.selectedMember"
          :on-search-state="onSearchUserState">

      </search-user-by-email-card>
      <label
          class="mt-2 primary"
          :class="{
                'my-box-decoration-secondary': !mSearchMember.selectedMember.isValid(),
              }"
          style="font-size: 0.85em;">Display name:</label>
      <input
          type="text"
          class="form-control"
          placeholder="Enter name"
          v-model="mSearchMember.customName"
          :disabled="mSearchMember.searching || !mSearchMember.selectedMember.isValid()">

      <!-- swimmer mobile -->
      <label
          class="mt-2 primary"
          :class="{
                'my-box-decoration-secondary': !mSearchMember.selectedMember.isValid(),
              }"
          style="font-size: 0.85em;">Mobile number:</label>
      <input
          type="text"
          class="form-control"
          placeholder="Enter mobile number"
          v-model="mSearchMember.mobile"
          :disabled="mSearchMember.searching || !mSearchMember.selectedMember.isValid()">

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm"
                  :disabled="!mSearchMember.selectedMember.isValid()"
                  v-on:click.stop="onConfirmMember()">
          {{mSearchMember.isInviteCoach() ? 'Invite' : 'Add'}}
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- link-user-modal -->
    <b-modal centered
             :title="'Link Mytogs'"
             id="link-user-modal">
      <div style="font-size: 0.85em">
        Stay connected with your swimmer, send an email invitation to sign-up MySquad and MyTogs
      </div>

      <input
          type="email"
          class="form-control mt-3"
          placeholder="Enter email"
          v-model="mLinkAccount.email">

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm"
                  :disabled="!isValidLinkEmail"
                  v-on:click.stop="onConfirmLinkAccount(mLinkAccount.email)">
          Invite
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- profile-member-modal -->
    <b-modal centered
             :title="mEditMember.customName"
             id="profile-swimmer-modal">

      <!-- tabs -->
      <div class="d-flex flex-row flex-wrap col-12 mb-2 pl-0 pr-0">
        <div v-for="(option, index) in mMemberOptions.options" v-bind:key="index"
             class="clickable my-box my-box-decoration-dark ml-1 mb-1 pl-2 pr-2 my-button-tab-decoration-flex-1"
             :class="{'selected': option.value === mMemberOptions.selected.value}"
             style="width: 7rem" v-on:click.stop="onSelectMemberOption(option)">
          <span>{{ option.text }}</span>
        </div>
      </div>




      <div v-if="mEditMember.loading">
        <loader :embedded="true" :dark="true" style="margin-top: 1rem; text-align: center"></loader>
      </div>
      <div v-else>

        <!-- charts tab -->
        <div v-if="mMemberOptions.isPerformance()">
          <div class="d-flex flex-row justify-content-between mt-2">
            <div
                v-for="(option, index) in mPerformanceOptions.options" :key="index"
                class="clickable my-button-tab-decoration-flex-2 my-tab-primary-dark"
                :class="{
        'selected': mPerformanceOptions.selected == option.value
        }"
                v-on:click.stop="onClickPerformanceOption(option.value)">
              {{ option.text }}
            </div>

          </div>
          <separator-line></separator-line>

          <performance-radar-card
              v-if="mPerformanceOptions.isCharts()"
              :squad-ext="mSquadExt"
              :swimmer="mEditMember.swimmer">
          </performance-radar-card>
          <profile-pb
              v-else-if="mPerformanceOptions.isPb()"
              :swimmer="mEditMember.swimmer">
          </profile-pb>
        </div>
        <div v-else-if="mMemberOptions.isCharts()">
          <performance-radar-card
            :squad-ext="mSquadExt"
            :swimmer="mEditMember.swimmer">
          </performance-radar-card>
        </div>
        <div v-else-if="mMemberOptions.isPb()">
          <profile-pb
              :swimmer="mEditMember.swimmer">
          </profile-pb>
        </div>
        <!-- profile tab -->
        <div v-else-if="mMemberOptions.isProfile()">
          <profile-profile
            :squad-ext="mSquadExt"
            :member="mEditMember.selected"
            :swimmer="mEditMember.swimmer"
            :restricted-access="restrictedAccess"
          >
          </profile-profile>
        </div>
        <!-- messages tab -->
        <div v-else-if="mMemberOptions.isMessages()">
          <message-card
              :dark="true"
              :default-body="''"
              :confirm-text="'Send'"
              :disable-date-picker="true"
              :disable-region-picker="true"
              :on-click-cb="onSendMessage"
              :timestamp="mMemberOptions.messageDataTimestamp">
          </message-card>
          <section style="min-height: 4rem;">
            <div v-if="mMemberOptions.messageOptions.options.length > 0">
              <div class="d-flex flex-row justify-content-end">
                <div style="font-size: 0.9em; font-weight: bold">History({{mMemberOptions.messageOptions.options.length}})</div>
              </div>
              <div class="pl-1 pr-1" style="max-height: 12rem; overflow-y: auto">
                <div v-for="(message, index) in mMemberOptions.messageOptions.options" v-bind:key="index"
                     class="my-box my-box-decoration-dark mt-1 pt-1 pl-2 pr-2 pb-1
                    d-flex flex-column justify-content-sm-around"
                     style="padding-top: 3px">

                  <div class="d-flex flex-row justify-content-between" style="font-size: 0.8em">
                    <div>
                      {{message.formattedCreateAtDate()}}
                    </div>
                    <div>
                      {{message.formattedCreateAtTime()}}
                    </div>

                  </div>
                  <div style="font-size: 0.8em">
                    <span style="font-weight: bold;">{{message.title}}</span>
                  </div>
                  <div class="mt-1" style="font-size: 0.8em">
                    <span>{{message.body}}</span>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
        <div v-else-if="mMemberOptions.isNotes()">
          <textarea
              rows="8"
              class="form-control mt-2"
              :placeholder="'Coach notes'"
              v-model="mMemberOptions.coachNotes">
          </textarea>
          <div class="d-flex flex-row justify-content-end mt-2">
            <b-button class="my-button-confirm"
                      :disabled="!mMemberOptions.isUpdateNotes"
                      v-on:click.stop="onSaveCoachNotes(mMemberOptions.coachNotes)"
            >
              Save
            </b-button>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok }">
        <b-button class="my-button-confirm" @click="ok()">
          Close
        </b-button>
      </template>
    </b-modal>


    <!-- exist-member-modal -->
    <b-modal centered title="Add Swimmer" id="exist-member-modal" ok-only>
      <p class="my-4">
        {{ mSearchMember.selectedMember.getName() }} is already in the squad.
      </p>

    </b-modal>

    <!-- model end -->



    <!-- squad coach -->
    <div class="my-text-primary d-flex flex-row justify-content-end pl-1 pr-1 pt-2" style="font-size: 0.8em">
      <div class="mb-2" style="height: 2rem;">
        <div v-if="mSquadExt.hasCoach()" >
          <div class="text-right">
            <span class="font-weight-normal">{{mSquadExt.getCoach().getValidatedName()}}</span>
            <span class="font-weight-bold"> - Coach
            </span>
            <span
                v-if="(!restrictedAccess && isAdmin)"
                class="ml-1"
                v-on:click.stop="onDeleteCoach(mSquadExt.getCoach())">
              <font-awesome-icon
                  icon="times-circle"
                  size="sm"
                  class="clickable my-btn-warning"
              />
            </span>
          </div>
          <div class="text-right">
            <span style="font-size: 0.75em">
              <font-awesome-icon
                  v-if="mSquadExt.getCoach().isPending()"
                  icon="clock"
                  size="sm"
                  class="label clickable mr-1"/>
              <span>{{mSquadExt.getCoach().getEmail()}}</span>

            </span>
          </div>
        </div>
        <div v-else-if="(!restrictedAccess && isAdmin)"
             class="clickable" style="height: 2rem;" v-on:click.stop="onInviteMember('invite_coach')">
          <div style="text-align: right">
            <span class="font-weight-bold"></span>
          </div>
          <font-awesome-icon
              icon="plus-circle"
              size="sm"
              class="label mr-2"/>
          <span style="font-weight: bold; text-transform: capitalize;">Add Coach</span>
        </div>
        <div v-else>
          <div class="text-right">
            <span class="font-weight-bold">Coach
            </span>
          </div>
          <div class="text-right">
            <span style="font-size: 0.75em">
              <span> ---- </span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- add swimmer -->
    <div v-if="!disableEdit && (isAdmin || mIsCoach)"
         class="my-text-primary d-flex flex-column pl-1 pr-1 pt-2 mt-2" style="font-size: 0.85em">
      <!-- by email -->
      <div class="clickable d-flex flex-row justify-content-start ml-2"
           :class="{
              '': mSquadExt.getSquadSize() <= mSquadExt.getMembers().length
           }"
           v-on:click.stop="onInviteMember('invite_swimmer')">
        <font-awesome-icon
            icon="plus-circle"
            size="sm"
            class="label mr-2 align-self-center"/>
        <span>Add Swimmer</span>
      </div>

      <!-- by shared link -->
      <div class="clickable mt-1 ml-2"
           :class="{
              '': mSquadExt.getSquadSize() <= mSquadExt.getMembers().length
           }"
           v-on:click.stop="onCopyLink()">
        <div class="d-flex flex-row justify-content-start">
          <font-awesome-icon
              icon="copy"
              size="sm"
              class="label mr-2 align-self-center"/>
          <span>Member sign-up <span style="text-decoration: underline;">link</span> <span style="font-size: 0.7em">(Click to copy)</span></span>
        </div>
<!--        <span class="ml-4" style="font-size: 0.75em">{{mSquadExt.formattedInviteLink()}}</span>-->
      </div>
    </div>

    <div class="d-flex flex-row justify-content-end mb-1">
      <div class="mt-2" style="width: 3.6rem;">
        <div style="font-weight: bold; font-size: 0.85em; text-align: right; color: white; text-transform: capitalize;">{{  mSquadExt.getMembers().length }} / {{ mSquadExt.getSquadSize()}}</div>
      </div>
    </div>


    <!-- squad members -->
    <div class="my-box my-box-decoration ml-1 mb-1 pl-2 pr-2 pb-2" style="min-height: 8rem">
      <div>
        <div v-if="windowWidth > 768"
             class="d-flex flex-row justify-content-between pl-1 pr-1"
             style="height: 3rem; font-size: 0.85em">
          <div class="col-4 pl-1 align-self-center" style="text-align: left">NAME</div>
          <div class="col-4 align-self-center" style="text-align: center">MYTOGS ACCOUNT</div>
          <div class="col-4 pr-1 align-self-center" style="text-align: right"></div>
        </div>

        <div class="pl-2 pr-2">
          <separator-line></separator-line>
          <loader v-if="mLoading" :embedded="true" style="margin-top: 1rem; text-align: center"></loader>
          <div v-if="!mLoading && mSquadExt.getMembers().length == 0" style="margin-top: 1rem; text-align: center">
            No members
          </div>
        </div>

        <!-- swimmers -->
        <section v-if="windowWidth > 768">
          <div v-for="(swimmer, index) in mSquadMembers.options" :key="index"
               class="my_text_md my-highlight-item d-flex flex-row justify-content-between pl-1 pr-1 pt-2"
               style="height: 3.2rem"
               v-on:click.stop="onOpenSwimmerProfile(swimmer)">
            <div class="col-4 pl-2 mt-2">
              <div>
                <div class="font-weight-bold">{{ swimmer.getValidatedName() }}</div>
              </div>
            </div>
            <div class="col-4 text-center mt-2">
              <div class="d-flex flex-row justify-content-center">
                <span v-if="disableEdit || swimmer.getEmail()">{{swimmer.getEmail('---')}}</span>
                <div  v-else class="clickable my-box my-box-decoration-dark ml-1 mb-1 pl-2 pr-2"
                     style="width: 7rem" v-on:click.stop="onLinkAccount(swimmer)">
                  <span>Link Account</span>
                </div>
               <font-awesome-icon
                    v-if="swimmer.isPending()"
                    icon="clock"
                    size="sm"
                    class="label clickable align-self-center ml-1"
                />
              </div>
            </div>
            <div class="col-4 text-right">
              <div v-if="!disableEdit">
                <div v-if="!swimmer.hasApproved()" class="d-flex flex-row justify-content-end">
                  <div class="mt-2 ml-2" v-on:click.stop="onApproveSwimmer(swimmer)">
                    <font-awesome-icon
                        icon="check-circle"
                        size="lg"
                        class="clickable my-btn-warning"
                    />
                  </div>
                  <div class="mt-2 ml-2" v-on:click.stop="onRejectSwimmer(swimmer)">
                    <font-awesome-icon
                        icon="times-circle"
                        size="lg"
                        class="clickable my-btn-warning"
                    />
                  </div>
                </div>
                <div v-else>
                  <div class="my-hover-option mt-2 ml-2" v-on:click.stop="onDeleteSwimmer(swimmer)">
                    <font-awesome-icon
                        icon="times-circle"
                        size="lg"
                        class="clickable my-btn-warning"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section v-else>
          <div v-for="(swimmer, index) in mSquadMembers.options" :key="index"
               class="my_text_md my-highlight-item pl-1 pr-1"
               style="height: 3.6rem"
               v-on:click.stop="onOpenSwimmerProfile(swimmer)">
            <div class="d-flex flex-row justify-content-between pt-1 pb-1">
              <div class="col-8">
                <div class="mt-1">
                  <div class="font-weight-bold">{{ swimmer.getValidatedName() }}</div>
                </div>
                <div>
                  <span v-if="disableEdit || swimmer.getEmail()">{{swimmer.getEmail('---')}}</span>
                  <div  v-else class="clickable my-box my-box-decoration-dark mt-1 mb-1"
                        style="width: 7rem; text-align: center" v-on:click.stop="onLinkAccount(swimmer)">
                    <span style="text-align: center">Link Account</span>
                  </div>
                  <font-awesome-icon
                      v-if="swimmer.isPending()"
                      icon="clock"
                      size="sm"
                      class="label clickable align-self-center ml-1"
                  />
                </div>
              </div>

              <div class="col-4 text-right">
                <div v-if="!disableEdit">
                  <div v-if="!swimmer.hasApproved()" class="d-flex flex-row justify-content-end pt-3">
                    <div v-on:click.stop="onApproveSwimmer(swimmer)">
                      <font-awesome-icon
                          icon="check-circle"
                          size="lg"
                          class="clickable my-btn-warning"
                      />
                    </div>
                    <div class="ml-3" v-on:click.stop="onRejectSwimmer(swimmer)">
                      <font-awesome-icon
                          icon="times-circle"
                          size="lg"
                          class="clickable my-btn-warning"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="my-hover-option mt-2 ml-2" v-on:click.stop="onDeleteSwimmer(swimmer)">
                      <font-awesome-icon
                          icon="times-circle"
                          size="lg"
                          class="clickable my-btn-warning"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <separator-line></separator-line>

          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/loader"
import SearchUserByEmailCard from "@/views/content/components/search_user_by_email_card"
import SearchUserByNameCard from "@/views/content/components/search_user_by_name_card"
import SeparatorLine from "@/components/separator_line"
import MessageCard from "@/views/content/components/message_card"
import ProfileProfile from "@/views/content/components/profile_profile"
import ProfilePb from "@/views/content/components/profile_pb"
import PerformanceRadarCard from "@/views/content/components/performace_radar_card"

import MySquadBloc from "@/bloc/mysquad_bloc";

import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger"
import DateTimeHelper from "@/util/datetime_helper";
import DisplayHelper from "@/util/display_helper";

const LOG_TAG = 'squad_members';
export default {
  name: LOG_TAG,
  components: {
    Loader,
    SearchUserByEmailCard,
    SearchUserByNameCard,
    SeparatorLine,
    MessageCard,
    ProfileProfile,
    ProfilePb,
    PerformanceRadarCard
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    disableShareLink: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    restrictedAccess: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data() {
    return {
      mLoading: false,
      //mClub: model.clubInfo(),
      //mSquad: model.squadResponse(),
      mIsCoach: false,
      mSquadExt: model.squadResponseExt(),
      mMemberOptions: {
        selected: {text: '', value: ''},
        options: [
          //{text: 'PB', value: 'pb'},
          //{text: 'Profile', value: 'profile'},
          //{text: 'Messages', value: 'messages'},
          //{text: 'Charts', value: 'charts'},
          //{text: 'Notes', value: 'notes'},
        ],
        messageOptions: {
          options: [],
          seeAll: false,
        },
        isPerformance() {
          return this.selected.value === 'performance';
        },
        isCharts() {
          return this.selected.value === 'charts';
        },
        isPb() {
          return this.selected.value === 'pb';
        },
        isProfile() {
          return this.selected.value === 'profile';
        },
        isMessages() {
          return this.selected.value === 'messages';
        },
        isNotes() {
          return this.selected.value === 'notes';
        },
        reset(disableEdit, coachAccess, messages, hasMyTogsUuid, hasMemberId) {
          const options = [];

          // only user with my club access has edit access
          if (disableEdit) {
            if (hasMemberId) {
              options.push({text: 'Charts', value: 'charts'});
              options.push({text: 'PB', value: 'pb'});
            }
            options.push({text: 'Profile', value: 'profile'});
          } else {
            if (hasMemberId) {
              options.push({text: 'Performance', value: 'performance'});
            }
            options.push({text: 'Profile', value: 'profile'});
            options.push({text: 'notes', value: 'notes'});
            if (hasMyTogsUuid) {
              options.push({text: 'Messages', value: 'messages'});
            }
          }

          this.options = options;
          this.selected = this.options[0];
          this.messageOptions.options = messages;
          this.messageOptions.seeAll = false;

          this.coachNotes = '';
          this.coachNotesOrig = '';
          this.isUpdateNotes = false;
        },
        messageData: model.notificationInfo(),
        messageDataTimestamp: DateTimeHelper.currentTimeInSecond(),
        // coach notes

        coachNotes: '',
        coachNotesOrig: '',
        isUpdateNotes: false,
        resetNotes(notes) {
          this.coachNotes = this.coachNotesOrig = notes;
          this.isUpdateNotes = false;
        }
      },
      mPerformanceOptions: {
        selected: {text: '', value: ''},
        options: [
          {text: 'Charts', value: 'charts'},
          {text: 'PB', value: 'pb'},
        ],
        isCharts() {
          return this.selected === this.options[0].value;
        },
        isPb() {
          return this.selected === this.options[1].value;
        },
        reset() {
          this.selected = this.options[0].value;
        }
      },
      mSquadMembers: {
        selected: model.memberResponse(),
        options: [],
        clear() {
          this.options = [];
          this.selected = model.memberResponse();
        },
      },
      // for coach and swimmer search
      mSearchMember: {
        key: '',
        disabled: true,
        selectedMember: model.memberResponse(),
        swimmer: model.memberResponse(),
        searching: false,
        customName: '',
        mobile: '',
        resetEmail() {
          this.selectedMember = model.memberResponse();
          this.swimmer = model.memberResponse();
          this.searching = false;
          this.customName = '';
          this.mobile = '';
        },
        init() {
          this.resetEmail();
        },
        validate() {
          const isValid = this.selectedMember.isValid();
          logger.d(LOG_TAG, 'validate', isValid);
          return isValid;
        },
        isInviteCoach() {
          return this.key === 'invite_coach';
        },
        isInviteSwimmer() {
          return this.key === 'invite_swimmer';
        }
      },
      mLinkAccount: {
        email: '',
        linkedUser: model.memberResponse(),
        reset() {
          this.email = '';
          this.linkedUser = model.memberResponse();
        }
      },
      mEditMember: {
        selected: model.memberResponse(),
        swimmer: model.memberResponse(),
        loading: false,
        searching: false,
        customName: '',
        mobile: '',
        reset() {
          this.selected = model.memberResponse();
          this.swimmer = model.memberResponse();
          this.searching = false;
          this.customName = '';
          this.mobile = '';
        },
      },
      mProfileOptions: {
        options: [
          {text: 'Profile', value: 'profile'},
          {text: 'Goal', value: 'goal'},
          {text: 'Notes', value: 'notes'},
        ],
        selected: 'profile'
      }
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    isInternalUser() {
      return this.storeAuth.isInternalUser();
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeClubSet() {
      return this.$store.state.clubsSet.set;
    },
    storeSquadSet() {
      return this.$store.state.squadsSet.set;
    },
    storeSquadTimestamp() {
      return this.$store.state.squadsSet.timestamp;
    },
    isValidLinkEmail() {
      return DisplayHelper.isValidEmail(this.mLinkAccount.email);
    }
  },
  watch: {
    squadExt: function onChange(value) {
      const vm = this;
      //logger.d(LOG_TAG, 'watch:squadExt', value);
      vm.refresh(value);
    },
    storeSquadTimestamp: function onChange() {
      const vm = this;
      //logger.d(LOG_TAG, 'watch:storeSquadTimestamp', value);
      vm.onNotifyUpdateSquad(vm.squadExt);
    },
    'mMemberOptions.coachNotes': function onChange(value) {
      const vm = this;
      vm.mMemberOptions.isUpdateNotes = value !== vm.mMemberOptions.coachNotesOrig;
    }
  },
  mounted() {
    logger.d(LOG_TAG, 'mounted');
    const vm = this;
    vm.refresh(vm.squadExt);
  },
  methods: {
    onCopyLink() {
      const vm = this;
      const link = vm.mSquadExt.formattedInviteLink();
      const cb = navigator.clipboard;

      cb.writeText(link).then(() => {
        vm.$notify({ type: 'success', text: 'Squad share link copied, you can now share this link.' });
      });
    },
    onNotifyUpdateSquad(selectedSquad) {
      const vm = this;
      let squadInfo = vm.storeSquadSet[selectedSquad.getSquadId()];
      if (squadInfo) {
        //logger.d(LOG_TAG, 'onNotifyUpdateSquad', squadInfo.getSquadId(), squadInfo.getMembers().length);
        vm.mSquadExt = model.squadResponseExt(squadInfo)
            .setClubId(vm.squadExt.getClubId())
            .setClubName(vm.squadExt.getClubName());
        vm.mSquadMembers.clear();
        vm.mSquadMembers.options = squadInfo.getMembers();

        // do we have profile open?
        const selectedSquadMember = squadInfo.getMembers().find((member) => member.getSquadMemberId() === vm.mEditMember.selected.getSquadMemberId());
        if (selectedSquadMember && selectedSquadMember.isValid()) {
          vm.onOpenSwimmerProfile(selectedSquadMember, true);
        }
      }
    },
    refresh(selectedSquad, force) {
      const vm = this;
      const selectedSquadId = selectedSquad.getSquadId();
      if (vm.mSquadExt.getSquadId() !== selectedSquadId || force) {
        vm.mLoading = vm.loading;
        vm.mIsCoach = vm.storeAuth.isSquadCoach(selectedSquadId);
        logger.d(LOG_TAG, 'refresh', 'coach', vm.mIsCoach);

        vm.mSquadMembers.clear();
        vm.mSquadExt = selectedSquad;

        MySquadBloc.syncSquad(
            vm.mSquadExt.getClubId(),
            vm.mSquadExt.getSquadId(),
            false,
            () => {
              vm.mLoading = false;
              vm.$store.commit('setLoading', false);
            },
            (error) => {
              logger.w(LOG_TAG, 'syncSquad', error);

              vm.mLoading = false;
              vm.$store.commit('setLoading', false);
            }
        )
      }
    },
    onSendMessage(value) {
      const vm = this;
      vm.mMemberOptions.messageData = value
        .setTo(vm.mEditMember.selected.getId())
        .setCustomName(vm.mEditMember.customName);

      logger.d(LOG_TAG, 'onSendSquadNotification', vm.mMemberOptions.messageData);
      vm.$bvModal.show('message-modal');
    },
    onConfirmSendMessage() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmSendMessage');
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('message-modal');
      webapi.apiService.broadcastMessage(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMemberOptions.messageData,
          (response) => {
            logger.d(LOG_TAG, 'broadcastMessage', response);
            MySquadBloc.syncMemberMessages(
                vm.mSquadExt.getClubId(),
                vm.mSquadExt.getSquadId(),
                vm.mEditMember.selected.getId(),
                (messages) => {
                  vm.mMemberOptions.messageOptions.options = messages;
                  vm.$store.commit('setLoading', false);
                  vm.mMemberOptions.messageDataTimestamp = DateTimeHelper.currentTimeInSecond();
                },
                () => {
                  vm.$store.commit('setLoading', false);
                  vm.mMemberOptions.messageDataTimestamp = DateTimeHelper.currentTimeInSecond();
                }
            )
            vm.$notify({ type: 'success', text: 'Message was sent successfully.' });
          },
          (error) => {
            logger.w(LOG_TAG, 'broadcastMessage', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'There was a problem sending your message, please try again later.' });
          }
      )
    },
    onSaveCoachNotes(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSaveCoachNotes', value);
      MySquadBloc.addCoachNotes(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mEditMember.selected.getSquadMemberId(),
          value,
          () => {
            vm.mMemberOptions.coachNotesOrig = vm.mMemberOptions.coachNotes;
            vm.mMemberOptions.isUpdateNotes = false;
            vm.$notify({ type: 'success', text: 'notes updated successfully.' });
          },
          () => {
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          }
      )
    },
    onSelectMemberOption(value) {
      const vm = this;
      vm.mMemberOptions.selected = value;
    },
    onClickPerformanceOption(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickPerformanceOption', value);
      vm.mPerformanceOptions.selected = value;
    },
    onDeleteSwimmer(value) {
      const vm = this;
      vm.mSquadMembers.selected = value;
      logger.d(LOG_TAG, 'onDeleteSwimmer', vm.mSquadMembers.selected);
      vm.$bvModal.show('delete-swimmer-modal');
    },
    onConfirmDeleteSwimmer() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmDeleteSwimmer', vm.mSquadMembers.selected);
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('delete-swimmer-modal');
      MySquadBloc.removeMember(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mSquadMembers.selected.getSquadMemberId(),
          () => {
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'There was a problem sending your message, please try again later.' });
          }
      )
    },
    onApproveSwimmer(value) {
      const vm = this;
      vm.mSquadMembers.selected = value;
      logger.d(LOG_TAG, 'onApproveSwimmer', vm.mSquadMembers.selected);
      vm.$bvModal.show('approve-swimmer-modal');
    },
    onConfirmApproveSwimmer() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmApproveSwimmer', vm.mSquadMembers.selected);
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('approve-swimmer-modal');
      webapi.apiService.approveSquadJoinRequest(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mSquadMembers.selected.getSquadMemberId(),
          (response) => {
            logger.d(LOG_TAG, 'approveSquadJoinRequest', response);
            vm.refresh(vm.mSquadExt, true);
          },
          (error) => {
            logger.w(LOG_TAG, 'approveSquadJoinRequest', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onRejectSwimmer(value) {
      const vm = this;
      vm.mSquadMembers.selected = value;
      logger.d(LOG_TAG, 'onRejectSwimmer', vm.mSquadMembers.selected);
      vm.$bvModal.show('reject-swimmer-modal');
    },
    onConfirmRejectSwimmer() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmRejectSwimmer', vm.mSquadMembers.selected);
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('reject-swimmer-modal');
      webapi.apiService.rejectSquadJoinRequest(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mSquadMembers.selected.getSquadMemberId(),
          (response) => {
            logger.d(LOG_TAG, 'rejectSquadJoinRequest', response);
            vm.refresh(vm.mSquadExt, true);
          },
          (error) => {
            logger.w(LOG_TAG, 'rejectSquadJoinRequest', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onInviteMember(key) {
      const vm = this;
      logger.d(LOG_TAG, 'onInviteMember', key);
      vm.mSearchMember.key = key;
      vm.mSearchMember.resetEmail();
      vm.$bvModal.show('search-member-modal');
    },
    onOpenSwimmerProfile(value, dataOnly) {
      const vm = this;
      if (vm.restrictedAccess) {
        if (!vm.mIsCoach) {
          if (!value.hasApproved()) {
            vm.mSquadMembers.selected = value;
            vm.$bvModal.show('pending-approval-modal');
            return;
          } else if (!vm.storeAuth.isSquadMember(value.getSquadMemberId())) {
            vm.mSquadMembers.selected = value;
            vm.$bvModal.show('no-access-modal');
            return;
          }
        }
      }
      vm.mEditMember.reset();
      if (!dataOnly) {
        vm.mMemberOptions.options = [];
      }

      vm.mEditMember.selected = value;
      vm.mEditMember.customName = value.getValidatedName();
      vm.mEditMember.mobile = value.getMobile();
      vm.mEditMember.swimmer = model.memberResponse();

      logger.d(LOG_TAG, 'onEditSwimmer', value, value.isValid());
      if (!dataOnly) {
        vm.$bvModal.show('profile-swimmer-modal');
      }

      vm.mEditMember.loading = true;
      MySquadBloc.syncSquadMemberProfile(
          vm.mIsCoach,
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          value.getMemberId(),
          vm.mEditMember.selected.getId(),
          vm.mEditMember.selected.getSquadMemberId(),
          (swimmer) => {
            const coachAccess = (vm.isAdmin || vm.mIsCoach);
            if (!dataOnly) {
              vm.mMemberOptions.reset(vm.disableEdit, coachAccess, [],
                  vm.mEditMember.selected.getId(),
                  value.getMemberId());
              if (!vm.disableEdit && coachAccess) {
                vm.mPerformanceOptions.reset();
              }
            }
            vm.mEditMember.swimmer = swimmer;
            vm.mEditMember.loading = false;
          },
          (messages) => {
            vm.mMemberOptions.messageOptions.options = messages;
            //vm.mEditMember.loading = false;
          },
          (notes) => {
            vm.mMemberOptions.resetNotes(notes);
            //vm.mEditMember.loading = false;
          },
          () => {
            vm.mEditMember.loading = false;
          }
      )
      /*if (value.hasMemberId()) {

      } else {
        if (!dataOnly) {
          const coachAccess = (vm.isAdmin || vm.mIsCoach);
          vm.mMemberOptions.reset(vm.disableEdit, coachAccess, [], null, null);
          if (!vm.disableEdit && coachAccess) {
            vm.mPerformanceOptions.reset();
          }
        }
      }*/

    },
    onSearchUserState(isSearching, isSuccess, user) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchUserState', isSearching, isSuccess, user);
      vm.mSearchMember.searching = isSearching;
      if (!isSearching && isSuccess) {
        vm.mSearchMember.selectedMember = user;
      }

      vm.mSearchMember.customName = null!=user ? user.getName() : '';
      vm.mSearchMember.mobile = null!=user ? user.getMobile() : '';
      if (isSearching) {
        vm.mSearchMember.disabled = true;
      } else {
        vm.mSearchMember.disabled = !vm.mSearchMember.validate();
      }
    },
    onSearchSwimmerState(isSearching, isSuccess, swimmer) {
      const vm = this;
      vm.mSearchMember.customName = '';
      vm.mSearchMember.searching = isSearching;
      logger.d(LOG_TAG, 'onSearchSwimmerState', isSearching, isSuccess);
      if (isSuccess && swimmer) {
        vm.mSearchMember.selectedMember = swimmer;
        vm.mSearchMember.swimmer = swimmer;
        if (swimmer.getValidatedName().length > 0) {
          vm.mSearchMember.customName = swimmer.getValidatedName();
        } else if (vm.mSearchMember.selectedMember.getValidatedName().length > 0) {
          vm.mSearchMember.customName = vm.mSearchMember.selectedMember.getValidatedName();
        } else {
          vm.mSearchMember.customName = '';
        }
        //logger.d(LOG_TAG, 'onSearchSwimmerState', isSearching, isSuccess, swimmer.getMemberId());
      } else {
        vm.mSearchMember.selectedMember = model.memberResponse();
        vm.mSearchMember.customName = '';
        vm.mSearchMember.mobile = '';
      }/* else if (swimmer) {
        logger.d(LOG_TAG, 'onSearchSwimmerState', swimmer.getValidatedName());
        vm.mSearchMember.selectedMember = swimmer;
        vm.mSearchMember.swimmer = swimmer;
        vm.mSearchMember.customName = swimmer.getValidatedName();
      } else {
        vm.mSearchMember.selectedMember.setCustomName(null);
      }*/

    },
    onConfirmMember() {
      const vm = this;
      if (vm.mSearchMember.isInviteCoach()) {
        const coach = vm.mSearchMember.selectedMember;
        coach.setClub(vm.mSquadExt.getClubName());
        coach.setCustomName(vm.mSearchMember.customName);
        coach.setMobile(vm.mSearchMember.mobile);

        logger.d(LOG_TAG, 'onConfirmMember', coach, coach.isNewSignUp());

        vm.$bvModal.hide('search-member-modal');
        vm.$store.commit('setLoading', true);
        MySquadBloc.inviteCoach(
          vm.mSquadExt.getClubId(), vm.mSquadExt.getSquadId(), coach,
            () => {
              vm.$store.commit('setLoading', false);
            },
            () => {
              vm.$store.commit('setLoading', false);
            }
        )
      } else if (vm.mSearchMember.isInviteSwimmer()) {
        const swimmer = vm.mSearchMember.selectedMember;

        swimmer.setClub(vm.mSquadExt.getClubName());
        swimmer.setCustomName(vm.mSearchMember.customName);
        swimmer.setMobile(vm.mSearchMember.mobile);

        vm.$bvModal.hide('search-member-modal');
        logger.d(LOG_TAG, 'onConfirmMember', swimmer, swimmer.getId());

        vm.$store.commit('setLoading', true);
        MySquadBloc.inviteSwimmer(
            vm.mSquadExt.getClubId(), vm.mSquadExt.getSquadId(), swimmer.setUserUuid('signup'),
            () => {
              vm.$store.commit('setLoading', false);
            },
            () => {
              vm.$store.commit('setLoading', false);
            }
        )
      }
    },
    onDeleteCoach(value) {
      const vm = this;
      vm.mSquadMembers.selected = value;
      logger.d(LOG_TAG, 'onDeleteCoach', vm.mSquadMembers.selected);
      vm.$bvModal.show('delete-coach-modal');
    },
    onConfirmDeleteCoach() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmDeleteCoach', vm.mSquadMembers.selected);
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('delete-coach-modal');
      MySquadBloc.removeCoach(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mSquadMembers.selected.getId(),
          () => {
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          }
      )
    },
    onToggleAllMessages() {
      const vm = this;
      vm.mMemberOptions.messageOptions.seeAll = !vm.mMemberOptions.messageOptions.seeAll;
    },
    onLinkAccount(value) {
      const vm = this;
      vm.mSquadMembers.selected = value;
      logger.d(LOG_TAG, 'onLinkAccount', vm.mSquadMembers.selected);

      // reset search
      vm.mSearchMember.resetEmail();
      vm.mLinkAccount.reset();
      vm.$bvModal.show('link-user-modal');
    },
    onConfirmLinkAccount(email) {
      const vm = this;
      vm.$bvModal.hide('link-user-modal');
      logger.d(LOG_TAG, 'onConfirmLinkAccount',
        vm.mSquadMembers.selected.getValidatedName(),
        vm.mSquadMembers.selected.getReconcileId(),
          email,
      );
      vm.$store.commit('setLoading', true);
      MySquadBloc.inviteSwimmerToMyTogs(
          vm.mSquadExt.getClubId(), vm.mSquadExt.getSquadId(),
          vm.mSquadMembers.selected.getValidatedName(),
          vm.mSquadMembers.selected.getReconcileId(),
          email,
          () => {
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'success', text: 'Request success.' });
          },
          () => {
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          }
      )
    },
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>