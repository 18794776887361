// import displayHelper from "@/util/display_helper"
//import model from "@/model"
// import logger from "@/util/logger";

// const LOG_TAG = 'off_days_info';

import dateTimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));

  } else {
    object = {
      start: 0,
      end: 0,
      desc: '',
      repeat: false,
    };
  }
  object.setStart = function setStart(value) {
    object.start = value;
    return this;
  }
  object.setEnd = function setEnd(value) {
    object.end = value;
    return this;
  }
  object.setDescription = function setDescription(value) {
    object.desc = value;
    return this;
  }
  object.setRepeat = function setRepeat(value) {
    object.repeat = value;
    return this;
  }
  object.getStart = function getStart() {
    return object.start ? object.start : 0;
  }
  object.getEnd = function getEnd() {
    return object.end ? object.end : 0;
  }
  object.getDescription = function getDescription() {
    return object.desc ? object.desc : '';
  }
  object.isSame = function isSame(other) {
    return object.getStart() == other.getStart() &&
      object.getEnd() == other.getEnd() &&
      object.getDescription() === other.getDescription();
  }
  object.formattedDates = function formattedDates() {
    return (object.getStart() > 0 && object.getEnd() > 0) ?
      '' + dateTimeHelper.formattedDateTime(object.getStart(), true) +
      ' ~ ' +
      '' + dateTimeHelper.formattedDateTime(object.getEnd(), true) :
      'Select date(s)';
  }
  object.formattedStartDate = function formattedStartDate() {
    return (object.getStart() > 0) ?
      dateTimeHelper.formattedDateTime(object.getStart(), true) :
      'Select date';
  }
  object.formattedEndDate = function formattedEndDate() {
    return (object.getEnd() > 0) ?
      dateTimeHelper.formattedDateTime(object.getEnd(), true) :
      'Select date';
  }
  object.translateFromDates = function translateFromDates(value) {
    if (null!=value) {
      const startMS = dateTimeHelper.convertDateTimeToMilliseconds(value.start);
      const endMS = dateTimeHelper.convertDateTimeToMilliseconds(value.end);
      object.start = dateTimeHelper.epocStartOfDay(startMS);
      object.end =  dateTimeHelper.epocEndOfDay(endMS);
    }
    return this;
  }
  object.setStartDate = function setStartDate(value) {
    const ms = dateTimeHelper.convertDateTimeToMilliseconds(value);
    object.start = dateTimeHelper.epocStartOfDay(ms);
    if (object.start > object.end) {
      object.end = dateTimeHelper.epocEndOfDay(ms);
    }
    return this;
  }
  object.setEndDate = function setEndDate(value) {
    const ms = dateTimeHelper.convertDateTimeToMilliseconds(value);
    object.end = dateTimeHelper.epocEndOfDay(ms);
    return this;
  }
  object.formattedCalendarStartDate = function formattedCalendarStartDate() {
    return dateTimeHelper.convertDateFromMilliseconds(object.getStart());
  }
  object.formattedCalendarEndDate = function formattedCalendarEndDate() {
    return dateTimeHelper.convertDateFromMilliseconds(object.getEnd());
  }
  return object;
}

