<template>
  <content-layout>

    <div slot="content_container">
      <div v-if="mPageState.isEnabled()" class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">

          <!-- premium users-->
          <label class="my-text-primary my-input-label">Premium Users: {{mPremiumUsers.options.length}}</label>
          <loader :embedded="true" v-if="mPageState.isInitializing()" style="margin-top: 4rem; text-align: center"></loader>
          <div v-if="mPremiumUsers.options.length > 0" class="pl-2 pr-2" style="max-height: 680px; overflow-y: auto">
            <div v-for="(option, index) in mPremiumUsers.options" v-bind:key="option.key"
                 class="my-text-primary my-box my-box-decoration mt-2 pl-2 pr-2
                    d-flex flex-column justify-content-sm-around clickable"
                 style="padding-top: 3px"
                 v-on:click.stop="onSearchUser(option, index)">

              <div class="d-flex flex-row justify-content-between">
                <span class="font-weight-bold" style="font-size: 0.8em">
                 {{option.getName('---')}}
                </span>
                <span class="font-weight-bold" style="font-size: 0.8em">
                {{option.getId()}}
                </span>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <span style="font-size: 0.8em">
                 {{option.getEmail('---')}}
                </span>
                <span class="font-weight-bold" style="font-size: 0.8em">
                {{option.formattedPremiumState()}}
                </span>
              </div>
              <div class="d-flex flex-row justify-content-end">
                <span style="font-size: 0.8em">
                 {{option.platform}}
                </span>
              </div>
            </div>
          </div>

        </section>

        <div class="col-1 desktop"></div>
      </div>
      <div v-else-if="mPageState.isDisabled()">
        <label class="my-text-primary my-input-label" style="margin-top: 4rem">You do not have permission to this page.</label>
      </div>
      <div v-else>
        <loader :embedded="true" style="width: 100%; margin-top: 4rem"></loader>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import ProfileAddons from "@/model/app_user_profile_addons"
import firebaseRdb from "@/firebase/realtime_database"
import mytogsBloc from "@/bloc/mytogs_bloc"
import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'premium_users';

const PAGE_STATE_INITIALIZING = 'initializing';
const PAGE_STATE_ENABLED = 'enabled';
const PAGE_STATE_DISABLED = 'disabled';

export default {
  name: LOG_TAG,
  display: 'Premium Users',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader
  },
  data() {
    return {

      mPageState: {
        options: [
          PAGE_STATE_INITIALIZING,
          PAGE_STATE_ENABLED,
          PAGE_STATE_DISABLED
        ],
        selected: PAGE_STATE_INITIALIZING,
        setInitializing() {
          this.selected = this.options[0];
        },
        setEnabled() {
          this.selected = this.options[1];
        },
        setDisabled() {
          this.selected = this.options[2];
        },
        isInitializing() {
          return this.selected === this.options[0];
        },
        isEnabled() {
          return this.selected === this.options[1];
        },
        isDisabled() {
          return this.selected === this.options[2];
        }
      },
      mPremiumUsers: {
        options: [],
        update: Date.now()
      },
      mSearchUser: {
        email: '',
        user: model.memberResponse(),
        profile: model.appUserProfile(),
        actions: [],
        reset() {
          this.email = '';
          this.user = model.memberResponse();
          this.profile = model.appUserProfile();
          this.actions = [];
        }
      },
      mCommand: {
        selected: 'select',
        options: [
          {text: 'Select action', value: 'select'},
          {text: 'Logout', value: 'logout'}
        ],
        isLogout() {
          return this.selected === 'logout';
        },
        reset() {
          this.selected = 'select';
        }
      },
      mPremiumOption: {
        selected: false,
        options: [
          {text: 'Yes', value: true},
          {text: 'No', value: false}
        ],
      },
      mAddonOption: {
        current: ProfileAddons(),
        options: [],
        updated: false,
        reset(value) {
          this.current = value;
          this.options = [
            {text: '10 Managed Swimmers', key: 'swimmer_10', value: value.isSupportSwimmer10()},
            {text: 'Swimmer Performance', key: 'swimmer_performance', value: value.isSupportSwimmerPerformance()},
            {text: 'Link Fastlane', key: 'link_fastlane', value: value.isSupportLinkFastlane()},
            {text: 'Meet Result', key: 'meet_result', value: value.isSupportMeetResult()},
            {text: 'XLR8 Points', key: 'xlr8_points', value: value.isSupportXlr8Points()},
          ];
          this.updated = false;
          //logger.d(LOG_TAG, 'mAddonOption', value);
        },
        defaultOption(value) {
          if (value) {
            this.options[0].value = value.isSupportSwimmer10();
            this.options[1].value = value.isSupportSwimmerPerformance();
            this.options[2].value = value.isSupportLinkFastlane();
            this.options[3].value = value.isSupportMeetResult();
            this.options[4].value = value.isSupportXlr8Points();
          } else {
            this.options[0].value = true;
            this.options[1].value = true;
            this.options[2].value = false;
            this.options[3].value = false;
            this.options[4].value = false;
          }

          this.updated = false;
        },
        emptyOption() {
          this.options[0].value = false;
          this.options[1].value = false;
          this.options[2].value = false;
          this.options[3].value = false;
          this.options[4].value = false;
          this.updated = false;
        },
        validate() {
          this.updated =
              this.options[0].value !== this.current.isSupportSwimmer10() ||
              this.options[1].value !== this.current.isSupportSwimmerPerformance() ||
              this.options[2].value !== this.current.isSupportLinkFastlane() ||
              this.options[3].value !== this.current.isSupportMeetResult() ||
              this.options[4].value !== this.current.isSupportXlr8Points();
        },
        toJson() {
          let json = {};
          this.options.forEach((option) => {
            json[option.key] = option.value;
          });
          return json;
        }
      }
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeState() {
      return this.$store.state;
    },
    storeFirebaseUid() {
      return this.storeState.firebaseUser.uid;
    },
    isPremiumStateChanged() {
      const vm = this;
      let hasStateChange = vm.mPremiumOption.selected !== vm.mSearchUser.profile.getPremium();
      let hasAddonChange = vm.mAddonOption.updated;
      logger.d(LOG_TAG, 'isPremiumStateChanged', hasStateChange, hasAddonChange);
      return hasStateChange || hasAddonChange;
    }
  },
  watch: {
    'mPremiumOption.selected': function (value) {
      const vm = this;
      let hasStateChange = vm.mPremiumOption.selected !== vm.mSearchUser.profile.getPremium();
      if (hasStateChange) {
        if (value) {
          vm.mAddonOption.defaultOption();
        } else {
          vm.mAddonOption.emptyOption();
        }
      } else {
        vm.mAddonOption.defaultOption(vm.mAddonOption.current);
      }
    },
  },
  mounted() {
    const vm = this;
    vm.initialize();
  },
  methods: {
    initialize() {
      const vm = this;
      if (vm.storeFirebaseUid) {
        vm.mPageState.setInitializing();
        firebaseRdb.syncPremiumUsers(
            (dataList) => {
              vm.mPremiumUsers.options = dataList;
              vm.mPageState.setEnabled();
            }, (error) => {
              logger.d(LOG_TAG, 'initialize', 'syncPremiumUsers', error);
              vm.mPageState.setDisabled();
            }
        )
      } else {
        vm.mPageState.setDisabled();
      }

    },
    onSearchUser(option, index) {
      const vm = this;
      if (option.getEmail()) {
//
      } else {
        mytogsBloc.searchUserByUuid(option,
            (member) => {
              logger.d(LOG_TAG, 'onSearchUser', member);
              vm.mPremiumUsers.options.splice(index, 1, member);
              //this.set(vm.mPremiumUsers.options, index, member);
            },
            () => {
              vm.$notify({ type: 'error', text: 'Oops! User not found.' });
            });
      }

    },
    onApplyCommand() {
      const vm = this;
      if (vm.mCommand.isLogout()) {
        firebaseRdb.requestActionLogout(vm.mSearchUser.user.getId(),
        (actions) => {
          vm.mSearchUser.actions = actions;
          vm.$notify({ type: 'success', text: 'updated successfully.' });
        },
        () => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
        });
      }
    },
    onApplyPremiumStateChange() {
      const vm = this;
      firebaseRdb.requestUserProfilePremium(vm.mSearchUser.user.getId(), vm.mPremiumOption.selected,
      () => {
        firebaseRdb.updateUserProfileAddon(vm.mSearchUser.user.getId(), vm.mAddonOption.toJson(),
        () => {
          vm.onSearchUser();
          vm.$notify({ type: 'success', text: 'updated successfully.' });
        },
        () => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
        });
      },
      () => {
        vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
      });
    },
    onNotifyAddonChanged(addon) {
      logger.d(LOG_TAG, 'onNotifyAddonChanged', addon);
      const vm = this;
      vm.mAddonOption.validate();
    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
