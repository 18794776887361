<template>
  <div>
    <!-- search by inputs forms -->
    <form class="form-group" v-on:submit.prevent="onClickUpload()">
      <div class="input-group">
        <div class="custom-file">
          <input
              ref="referenceUpload"
              type="file"
              class="form-control my-box-decoration"
              id="inputGroupFile01"
              :accept="accept"
              aria-describedby="inputGroupFileAddon01"
              v-on:change="onSelectedFile">
          <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
        </div>
      </div>
      <label class="mt-3 my-text-primary" style="font-size: 0.85em"
             :class="{
            'my-text-primary-dark': dark
          }">
        <span class="font-weight-bold">Selected file: </span> {{ mData.file ? mData.file.name : '' }}
      </label>

      <!-- date picker-->
      <div v-if="enableStartEndDates">
        <label class="mt-3 my-text-primary" style="font-size: 0.75em"
               :class="{'my-text-primary-dark': dark}">
          <span class="font-weight-bold">Qualify period: </span>
        </label>
        <div class="d-flex flex-row justify-content-between mt-2">
          <button
              class="btn btn-outline-primary my-box-decoration flex-grow-1"
              style="text-align: left"
              v-on:click.prevent="onOpenDateSelector('start')">
            <font-awesome-icon
                icon="calendar-alt"
                size="sm"
                class="label clickable"
            />
            <span class="ml-3" style="font-size: 0.85em">{{ mDatePeriod.formattedStartDate() }}</span>
          </button>
          <button
              class="btn btn-outline-primary my-box-decoration  flex-grow-1 ml-1"
              style="text-align: left"
              v-on:click.prevent="onOpenDateSelector('end')">
            <font-awesome-icon
                icon="calendar-alt"
                size="sm"
                class="label clickable"
            />
            <span class="ml-3" style="font-size: 0.85em">{{ mDatePeriod.formattedEndDate() }}</span>
          </button>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-end mt-2">
        <button
            v-if="!dark"
            type="submit"
            class="btn btn-outline-primary my-box-decoration ml-2"
            style="width: 8rem"
            v-bind:disabled="!hasValidPickerData">
          Upload
        </button>
        <b-button
            v-else
            type="submit"
            class="my-button-confirm"
            style="width: 8rem;"
            v-bind:disabled="!hasValidPickerData">
          Upload
        </b-button>
      </div>

    </form>
  </div>
</template>
<script>

import logger from "@/util/logger";
import dateTimeHelper from "@/util/datetime_helper";

const LOG_TAG = 'file_picker_card';

export default {
  name: LOG_TAG,
  props: {
    dark: {
     type: Boolean,
     default: function() {
       return false;
     }
    },
    enableStartEndDates: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    _onClickCb: {
      type: Function,
    },
    accept: {
       type: String,
        default: function() {
          return '.hy3';
        }
    }
  },
  data() {
    return {
      mData: {
        file: null,
        //
      },
      mDatePeriod: {
        selectedType: null, // start/end
        selectedStart: null,
        selectedEnd: null,
        reset() {
          this.selectedEnd = null;
          this.selectedStart = null;
          this.selectedEnd = null;
        },
        setSelected(date) {
          if (this.selectedType === 'start') {
            this.selectedStart = date;
            this.selectedEnd = null;
            logger.d(LOG_TAG, 'setSelected', this.getStartDateInMilliseconds());
          } else if (this.selectedType === 'end') {
            this.selectedEnd = date;
            logger.d(LOG_TAG, 'setSelected', this.getEndDateInMilliseconds());
          }
        },
        formattedStartDate() {
          return (this.selectedStart) ?
              dateTimeHelper.formattedDateTime(this.selectedStart, true) :
              'Start date';
        },
        formattedEndDate() {
          return (this.selectedEnd) ?
              dateTimeHelper.formattedDateTime(this.selectedEnd, true) :
              'End date';
        },
        getStartDateInMilliseconds() {
          const ms = dateTimeHelper.convertDateTimeToMilliseconds(this.selectedStart);
          return dateTimeHelper.epocStartOfDay(ms);
        },
        getEndDateInMilliseconds() {
          const ms = dateTimeHelper.convertDateTimeToMilliseconds(this.selectedEnd);
          return dateTimeHelper.epocEndOfDay(ms);
        },
      }
    };
  },
  computed: {
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
    hasValidPickerData() {
      if (this.enableStartEndDates) {
        return this.mData.file != null && this.mDatePeriod.selectedStart > 0 && this.mDatePeriod.selectedEnd > 0;
      } else {
        return this.mData.file != null;
      }
    }
  },
  watch: {
    storeDatePicker: function onChange(value) {
      const vm = this;
      if (value.date) {
        vm.mDatePeriod.setSelected(value.date);
      }
    },
  },
  mounted() {
    //
    // const vm = this;

  },
  methods: {
    onSelectedFile(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectedFile', value.target.files[0]);
      vm.mData.file = value.target.files[0];
    },
    onOpenDateSelector(type /*start, end*/) {
      logger.d(LOG_TAG, 'onOpenDateSelector', type);
      const vm = this;
      vm.mDatePeriod.selectedType = type;
      if (type === 'end' && vm.mDatePeriod.selectedStart) {
        vm.$store.commit('setDatePicker', {
          enabled: true,
          byDayNoLimit: true,
          minDate: vm.mDatePeriod.selectedStart
        });
      } else {
        vm.$store.commit('setDatePicker', {
          enabled: true,
          byDayNoLimit: true,
        });
      }
    },
    onClickUpload() {
      const vm = this;
      // logger.d(LOG_TAG, 'onClickUpload', vm.mData.file);
      if (vm.enableStartEndDates) {
        vm._onClickCb(
            vm.mData.file,
            vm.mDatePeriod.getStartDateInMilliseconds(),
            vm.mDatePeriod.getEndDateInMilliseconds());
      } else {
        vm._onClickCb(vm.mData.file);
      }
      vm.$refs.referenceUpload.value = null;
      vm.mData.file = null;
      vm.mDatePeriod.reset();
    }
  }
}
</script>

<style scoped>
.label {
  color: var(--primary-text);
  font-size: 1em;
}
</style>
