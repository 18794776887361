<template>
  <content-layout>

    <div slot="content_container">

      <div class="row mt-2 mb-5">
        <!-- delete--modal -->
        <b-modal centered title="Delete training set?" id="delete-set-modal">
          <p class="my-4">
            {{ mToDelete.getDescription() }}
          </p>

          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-warning" @click="onConfirmDeleteTrainingSet()">
              Delete
            </b-button>
            <b-button class="my-button-cancel" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>


        <div class="col-1 desktop"></div>
        <!-- create section-->
        <section
            class="col-10"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search by -->
          <label class="my-text-primary" style="font-size: 0.85em;">
            Add new training set
          </label>
          <training-set-card
            :_on-click-cb="onCreateTrainingSet">
          </training-set-card>

          <!-- history section-->
          <div class="my-text-primary mt-2"
               style="font-weight: bold;">Total Training Sets: {{mTrainingSets.length}}</div>
          <loader :embedded="true" v-if="mInit" style="margin-top: 4rem; text-align: center"></loader>
          <section v-if="mTrainingSets.length > 0">
            <div v-for="(trainingSet, index) in mTrainingSets" v-bind:key="index"
              class="my-text-primary my-box my-box-decoration mt-2 pt-1 pl-2 pr-2 pb-1
                    d-flex flex-column justify-content-sm-around"
                 style="padding-top: 3px">

              <div class="d-flex flex-row justify-content-between">
                <span style="font-size: 0.8em">
                {{trainingSet.formattedCreateAt()}}
                </span>

                <div>
                  <font-awesome-icon
                      icon="trash-alt"
                      size="sm"
                      class="label clickable warning"
                      v-on:click.prevent="onOpenConfirmDeleteTrainingSet(trainingSet)"
                  />
                </div>
              </div>
              <div style="font-size: 0.8em">
                <span style="font-weight: bold;">{{trainingSet.getDescription()}}</span>
              </div>
              <div class="mt-1" style="font-size: 0.8em">
                <p style="white-space: pre-line" v-html="trainingSet.getDetailsLinkify()">
                </p>
              </div>
              <div class="d-flex flex-row justify-content-end mt-1" style="font-size: 0.7em">
                Created by: <span class="ml-1" style="font-weight: bold;">{{trainingSet.getCreatedBy()}}</span>
              </div>
            </div>
          </section>
          <section v-else-if="!mInit && mTrainingSets.length == 0">
            <div class="my-text-primary mt-3" style="font-size: 0.75em">No training sets available.</div>
          </section>
        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>

import Loader from "@/components/loader"
import ContentLayout from "@/components/content_layout"
import TrainingSetCard from "@/views/content/components/training_set_card"
import MySquadBloc from "@/bloc/mysquad_bloc";
import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger"

const LOG_TAG = 'trainingSets';

export default {
  name: LOG_TAG,
  display: 'Training Sets',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    TrainingSetCard,
    Loader
  },
  data() {
    return {
      mInit: false,
      mTrainingSets: [],
      mToDelete: model.trainingSetInfo()
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      MySquadBloc.syncTrainingSets(
        vm.storeAuth.getClubId(),
        (dataList) => {
          vm.mTrainingSets = dataList;
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        },
        () => {
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        }
      )
    },
    onCreateTrainingSet(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onCreateTrainingSet', data);
      vm.$store.commit('setLoading', true);
      MySquadBloc.addNewTrainingSet(
        vm.storeAuth.getClubId(), data,
        (dataList) => {
          vm.mTrainingSets = dataList;
          vm.$store.commit('setLoading', false);
        },
        () => {
          vm.$store.commit('setLoading', false);
        }
      )
    },
    onOpenConfirmDeleteTrainingSet(value) {
      const vm = this;
      vm.mToDelete = value;
      vm.$bvModal.show('delete-set-modal');
    },
    onConfirmDeleteTrainingSet() {
      const vm = this;
      vm.$bvModal.hide('delete-set-modal');
      vm.$store.commit('setLoading', true);
      webapi.apiService.removeTrainingSet(
          vm.storeAuth.getClubId(),
          vm.mToDelete.getTrainingSetId(),
          (response) => {
            logger.d(LOG_TAG, 'refresh', response.length);
            vm.refresh();
          },
          (error) => {
            logger.w(LOG_TAG, 'refresh', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
