import moment from "moment"
// import logger from "@/util/logger"

// const LOG_TAG = 'datetime_helper';

export default {
  convertDateTimeToMilliseconds(value) {
    return new Date(value).getTime();
  },
  formattedFullDateTime(value, ignoreTime) {
    if (value) {
      if (ignoreTime) {
        return moment(value).format('ddd, Do MMM YY');
      } else {
        return moment(value).format('ddd, Do MMM YY, HH:mm');
      }
    }
    return '';
  },
  formattedDateTime(value, ignoreTime) {
    if (value) {
      if (ignoreTime) {
        return moment(value).format('Do.MMM.YY');
      } else {
        return moment(value).format('Do.MMM.YY, HH:mm');
      }
    }
    return '';
  },
  formattedDateTime2(value) {
    if (value) {
      return moment(value).format('DD.M.YY HH:mm');
    }
    return '';
  },
  formattedTimeDate(value, ignoreTime) {
    if (ignoreTime) {
      return moment(value).format('DD.MMM.YY');
    } else {
      return moment(value).format('HH:mm DD.MMM.YY');
    }
  },
  formattedDateTimeShort(value) {
    return moment(value).format('DD MMM');
  },
  formattedTime(value) {
    return moment(value).format('HH:mm');
  },
  formattedDayDifference(from, to) {
    return moment(to).diff(moment(from), 'days');
  },
  currentTimeInSecond() {
    return new Date().getTime() / 1000;
  },
  formattedSecondsToDateTime(value) {
    return this.formattedDateTime(new Date( value * 1000))
  },
  epocTimeNow() {
    return moment().valueOf();
  },
  epocYearNow(date) {
    if (date) {
      return parseInt(moment(date).format('YYYY'));
    } else {
      return parseInt(moment().format('YYYY'));
    }
  },
  epocMonthNow(date) {
    if (date) {
      return parseInt(moment(date).format('M'));
    } else {
      return parseInt(moment().format('M'));
    }
  },
  currentDate() {
    return new Date();
  },
  convertDateFromMilliseconds(value) {
    return new Date(value);
  },
  epocStartOfWeek(date) {
    if (date) {
      return moment(date).startOf("isoWeek").valueOf();
    } else {
      return moment().startOf("isoWeek").valueOf();
    }
  },
  epocEndOfWeek(date) {
    if (date) {
      return moment(date).endOf("isoWeek").valueOf();
    } else {
      return moment().endOf("isoWeek").valueOf();
    }
  },
  epocStartOfMonth(date) {
    if (date) {
      return moment(date).startOf("month").valueOf();
    } else {
      return moment().startOf("month").valueOf();
    }
  },
  epocEndOfMonth(date) {
    if (date) {
      return moment(date).endOf("month").valueOf();
    } else {
      return moment().endOf("month").valueOf();
    }
  },
  epocStartOfDay(fromDate, addHours) {
    if (fromDate) {
      return moment(fromDate).startOf("day").add(addHours ? addHours : 0, 'hours').valueOf();
    } else {
      return moment().startOf("day").valueOf();
    }
  },
  epocEndOfDay(fromDate) {
    if (fromDate) {
      return moment(fromDate).endOf("day").valueOf();
    } else {
      return moment().endOf("day").valueOf();
    }
  },
  epoc(value) {
    if (value) {
      return moment(value).valueOf();
    } else {
      return moment().valueOf();
    }
  },
  translateTimeToMilliseconds(time) {
    let timeString = time.toString();
    if (!timeString.includes(':')) {
      timeString = '0:' + timeString;
    }
    if (!timeString.includes('.')) {
      timeString = timeString + '.00';
    }
    const minSplits = timeString.split(':');
    const secSplits = minSplits[1].split('.');
    let secSplit = secSplits[0];
    secSplit =
      secSplit ?
        secSplit.length < 2 ? secSplit.padEnd(2, '0') : secSplit
      : '00';

    let milliSplit = secSplits[1];
    if (milliSplit) {
      if (milliSplit.length < 2) {
        milliSplit = milliSplit.padEnd(2, '0');
      } else {
        milliSplit = milliSplit.substring(0, 2);
      }
    } else {
      milliSplit = '00';
    }
    /*milliSplit =
      milliSplit ?
        milliSplit.length < 2 ? milliSplit.padEnd(2, '0') : milliSplit.length > 2 ? minSplits.substring(0, 2) :
      : '00';*/

    const minutes = parseInt(minSplits[0]) * 60 * 1000;
    const seconds = parseInt(secSplit) * 1000;
    const milliseconds = parseInt(milliSplit) * 10;
    const total = minutes + seconds + milliseconds;
    //const minutesSplits = minutes[1].split('.');
    //const seconds = minutesSplits[0];
    //logger.d(LOG_TAG, 'translateTimeToMilliseconds', time, minutes, seconds, minutesSplits.length > 1 ? minutesSplits[1] : 0);
    //logger.d(LOG_TAG, 'validate', 'translateTimeToMilliseconds', timeString, minSplits[0], secSplit, milliSplit, total);
    return total;
  },
  translateMillisecondsToTime(toConvert) {
    let formatted = '';
    let ms = ((toConvert % 1000));
    toConvert = (toConvert - ms) / 1000;
    const secs = toConvert % 60;
    toConvert = (toConvert - secs) / 60;
    const mins = toConvert % 60;

  /*  logger.d(LOG_TAG,
      'formatNewTarget',
      'translateMillisecondsToTime',
      toConvert,
      ms);*/


    let formattedSec = secs.toString().padStart(2, '0');
    let formattedMs = ms.toString().padStart(2, '0');
    if (mins > 0) {
      formatted = mins + ':' + formattedSec + '.' + formattedMs;
    } else {
      formatted = formattedSec + '.' + formattedMs;
    }
    return formatted; // The function returns the product of p1
  }
};
