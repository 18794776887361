<template>
  <content-layout>
    <div slot="content_container">
      <div class="row pl-1 pr-1 pb-5">
        <!--add iframe -->
        <iframe
            width="100%"
            height="960px"
            src="https://www.knowi.com/d/ch1ipccNgM6Kk24FUtpVtwVM2FSpXThFqsaMa8D37W1gie" frameborder="0" allowfullscreen>

        </iframe>
        <!-- Modal -->
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"

const LOG_TAG = 'report';

export default {
  name: LOG_TAG,
  display: 'Report',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
  },
  data() {
    return {
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },

  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
</style>
