import apiHelper from "@/webapi/api_helper"
import logger from "@/util/logger"

// apis


const LOG_TAG = 'slack_service';
const CHANNEL_URL = 'https://hooks.slack.com/services/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';
/*

var newmsg={};
newmsg.url="https://hooks.slack.com/services/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";
newmsg.payload =
{
	"channel":"mysquads-logging",
	"icon_emoji":":pushstack:",
	"username":"pushbot",
	"text":"```"+JSON.stringify(msg.payload,null, 2)+"```"
}
if(msg.channel){
    newmsg.payload.channel = msg.channel
}
//newmsg.method = "POST";

return newmsg;
 */
export default {
  channelAppVersionUpdate(version, updatedBy, onResponseCb, onErrorCb) {
    let url = CHANNEL_URL;

    logger.d(LOG_TAG, 'channelAppVersionUpdate', url);
    const payload = {
      'channel': 'mytogs-app-updates',
      'username': 'Announcement',
      "blocks": [
        {
          "type": "header",
          "text": {
            "type": "plain_text",
            "text": "App version update:",
            "emoji": true
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*Version:*\n" + version
            },
            {
              "type": "mrkdwn",
              "text": "*Updated by:*\n" + updatedBy
            }
          ]
        },
      ]
    }
    apiHelper.apiPost2(url, payload, onResponseCb, onErrorCb);
  },
  channelAppAddonUpdate(options, updatedBy, onResponseCb, onErrorCb) {
    let url = CHANNEL_URL;

    logger.d(LOG_TAG, 'channelAppAddonUpdate', url);
    let addons = '';
    let status = '';
    options.forEach((option) => {
      addons += '\n' + option.text;
      status += '\n' + option.value;
    });
    const payload = {
      'channel': 'mytogs-app-updates',
      'username': 'Announcement',
      "blocks": [
        {
          "type": "header",
          "text": {
            "type": "plain_text",
            "text": "App addon update:",
            "emoji": true
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*Updated by:*\n" + updatedBy
            }
          ]
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*Addon:*" + addons
            },
            {
              "type": "mrkdwn",
              "text": "*Status:*" + status
            }
          ]
        },
      ]
    }
    apiHelper.apiPost2(url, payload, onResponseCb, onErrorCb);
  },
}