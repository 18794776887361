<template>
  <content-layout>

    <div slot="content_container">
      <div v-if="mPageState.isEnabled()" class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search swimmer -->
          <form class="form-group" v-on:submit.prevent="onSearchSwimmer(mSearchSwimmer.name)">
            <label class="my-text-primary my-input-label">Search Swimmer:</label>
            <input type="text"
                   class="form-control my-box-decoration"
                   placeholder="Enter swimmer name"
                   v-model="mSearchSwimmer.name">
            <div class="d-flex flex-row justify-content-end mt-4">
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!mSearchSwimmer.name"
                      v-on:click.stop="onSearchSwimmer(mSearchSwimmer.name)">
                {{ 'Search' }}
              </button>
            </div>

          </form>
          <!-- premium users-->
          <label class="my-text-primary my-input-label">Swimmer results: {{mSearchSwimmer.options.length}}</label>
          <loader :embedded="true" v-if="mPageState.isInitializing()" style="margin-top: 4rem; text-align: center"></loader>
          <div v-if="mSearchSwimmer.options.length > 0" class="pl-2 pr-2" style="max-height: 680px; overflow-y: auto">
            <div v-for="(option, index) in mSearchSwimmer.options" v-bind:key="option.key"
                 class="my-text-primary my-box my-box-decoration mt-2 pl-2 pr-2
                    d-flex flex-column justify-content-sm-around clickable"
                 style="padding-top: 3px"
                 v-on:click.stop="onSyncSwimmerRecords(option, index)">

              <div class="d-flex flex-row justify-content-between">
                <span class="font-weight-bold" style="font-size: 0.8em">
                 {{option.getName('---')}}
                </span>
                <span style="font-size: 0.8em">
                {{option.getMemberId()}}
                </span>
              </div>
              <div class="d-flex flex-row justify-content-end mt-1 text-capitalize">
                <span style="font-size: 0.8em">
                {{option.getBranch()}}
                </span>
              </div>
            </div>
          </div>

        </section>

        <div class="col-1 desktop"></div>
      </div>
      <div v-else-if="mPageState.isDisabled()">
        <label class="my-text-primary my-input-label" style="margin-top: 4rem">You do not have permission to this page.</label>
      </div>
      <div v-else>
        <loader :embedded="true" style="width: 100%; margin-top: 4rem"></loader>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import firebaseRdb from "@/firebase/realtime_database"
import mytogsBloc from "@/bloc/mytogs_bloc"
import logger from "@/util/logger"

const LOG_TAG = 'sync_user_records';

const PAGE_STATE_INITIALIZING = 'initializing';
const PAGE_STATE_ENABLED = 'enabled';
const PAGE_STATE_DISABLED = 'disabled';

export default {
  name: LOG_TAG,
  display: 'Sync User Records',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader
  },
  data() {
    return {

      mPageState: {
        options: [
          PAGE_STATE_INITIALIZING,
          PAGE_STATE_ENABLED,
          PAGE_STATE_DISABLED
        ],
        selected: PAGE_STATE_INITIALIZING,
        setInitializing() {
          this.selected = this.options[0];
        },
        setEnabled() {
          this.selected = this.options[1];
        },
        setDisabled() {
          this.selected = this.options[2];
        },
        isInitializing() {
          return this.selected === this.options[0];
        },
        isEnabled() {
          return this.selected === this.options[1];
        },
        isDisabled() {
          return this.selected === this.options[2];
        }
      },
      mPremiumUsers: {
        options: [],
        update: Date.now()
      },
      mSearchSwimmer: {
        name: '',
        options: [],
        selected: null,
        reset() {
          this.options = [];
        }
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeState() {
      return this.$store.state;
    },
    storeFirebaseUid() {
      return this.storeState.firebaseUser.uid;
    },
  },
  watch: {
  },
  mounted() {
    const vm = this;
    vm.initialize();
  },
  methods: {
    initialize() {
      const vm = this;
      if (vm.storeFirebaseUid) {
        vm.mPageState.setInitializing();
        firebaseRdb.syncPremiumUsers(
            (dataList) => {
              vm.mPremiumUsers.options = dataList;
              vm.mPageState.setEnabled();
            }, (error) => {
              logger.d(LOG_TAG, 'initialize', 'syncPremiumUsers', error);
              vm.mPageState.setDisabled();
            }
        )
      } else {
        vm.mPageState.setDisabled();
      }

    },
    onSearchSwimmer(name) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchSwimmer', name);
      mytogsBloc.searchSwimmer(
        name,
        (dataList) => {
          //
          logger.d(LOG_TAG, 'onSearchSwimmer', dataList);
          vm.mSearchSwimmer.options = dataList;
          if (dataList.length === 0) {
            vm.$notify({ type: 'error', text: 'Oops! No results found.' });
          }
        },
        (error) => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          logger.d(LOG_TAG, 'onSearchSwimmer', error);
        }
      )
    },
    onSyncSwimmerRecords(swimmer, index) {
      const vm = this;
      logger.d(LOG_TAG, 'onSyncSwimmerRecords', index, swimmer.getMemberId());
      if (confirm([
          'Are you sure you want to refresh ',
          swimmer.getName(),
          '\'s database records?',
      ].join('')) == true) {
        vm.$store.commit('setLoading', true);
        mytogsBloc.forceDatabaseReSync(
          swimmer.getMemberId(),
          (response) => {
            //
            logger.d(LOG_TAG, 'onSyncSwimmerRecords', response);
            setTimeout(function(){
              vm.$notify({ type: 'success', text: 'Settings updated successfully.' });
              vm.$store.commit('setLoading', false);
            }, 2500);
          },
          (error) => {
            logger.d(LOG_TAG, 'onSyncSwimmerRecords', error);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
            vm.$store.commit('setLoading', false);
          }
        )
      } else {
        //
      }

    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
