<template>
  <div>
    <form class="form-group" v-on:submit.prevent="onClickCreate()">
      <button
          v-if="!disableDatePicker"
          class="btn btn-outline-primary my-box-decoration"
          style="width: 12rem; text-align: left"
          v-on:click.prevent="onOpenDateSelector">
        <font-awesome-icon
            icon="calendar-alt"
            size="sm"
            class="label clickable"
        />
        <span class="ml-3">{{ mScheduledDate.length == 0 ? 'Select date' : mScheduledDate }}</span>
      </button>
      <!-- title -->
      <div v-if="!disableTitle">
        <input type="text"
               class="form-control mt-3"
               :class="{'my-box-decoration': !dark}"
               placeholder="Enter message title"
               maxlength="25"
               v-model="mTitle">
        <div class="d-flex flex-row justify-content-between">
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.6em">* max 25 characters</label>
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.5em">{{mTitle.length}} / 25</label>
        </div>
      </div>

      <!-- message -->
      <textarea
          v-if="limitBody > 0"
          rows="2"
          class="form-control mt-2"
          :class="{'my-box-decoration': !dark}"
          :placeholder="defaultPlaceholder"
          :maxlength="limitBody"
          :disabled="disableEdit"
          v-model="mBody">
      </textarea>
      <textarea
          v-else
          rows="8"
          class="form-control mt-2"
          :class="{'my-box-decoration': !dark}"
          :placeholder="defaultPlaceholder"
          :disabled="disableEdit"
          v-model="mBody">
      </textarea>
      <div v-if="limitBody > 0" class="d-flex flex-row justify-content-between">
        <label :class="{'my-text-primary': !dark}" style="font-size: 0.6em">* max {{ limitBody }} characters</label>
        <label :class="{'my-text-primary': !dark}" style="font-size: 0.5em">{{mBody.length}} / {{ limitBody }}</label>
      </div>
      <!-- select region-->
      <label v-if="!disableRegionPicker" class="my-text-primary my-input-label">Notification Region:</label>
      <div v-if="!disableRegionPicker" class="my-text-primary my-box my-box-decoration pl-2 pr-2
                    d-flex flex-column justify-content-sm-around">

        <form class="mt-2 mb-2 ml-1">

          <div v-if="mRegionData.allOption.isActive()">
            <input type="radio" id="region_all" name="region_option" value="region_all" v-model="mRegionData.selected">
            <label class="ml-2" style="font-size: 0.85em" for="region_all">{{mRegionData.allOption.getName()}}</label><br>
          </div>

          <input type="radio" id="region_select" name="region_option" value="region_select" v-model="mRegionData.selected">
          <label class="ml-2" style="font-size: 0.85em" for="region_select">Select Region(s):</label><br>

          <!-- special test options -->
          <div v-if="storeAuth.isInternalUser()">
            <input type="radio" id="region_test" name="test_option" value="region_test" v-model="mRegionData.selected">
            <label class="ml-2" style="font-size: 0.85em" for="region_test">Test Region(s):</label><br>
          </div>



          <div
              v-if="mRegionData.isRegionTest()"
              class="d-flex flex-row flex-wrap">
            <button
                v-for="(region, index) in mRegionData.testRegionOptions" :key="index"
                v-on:click.prevent="onSelectRegion(region)"
                class="btn btn-outline-primary my-box-decoration m-1"
                :class="{'selected': mRegionData.regionsSelected.findIndex((selected) => selected.getTopic() === region.getTopic()) >= 0}"
                style="width: 6.6rem; height: 3rem; font-size: 0.65em"
                :disabled="!mRegionData.isRegionTest()">
              {{ region.getName() }}
            </button>
          </div>
          <div v-else
               class="d-flex flex-row flex-wrap">
            <button
                v-for="(region, index) in mRegionData.regionOptions" :key="index"
                v-on:click.prevent="onSelectRegion(region)"
                class="btn btn-outline-primary my-box-decoration m-1"
                :class="{'selected': mRegionData.regionsSelected.findIndex((selected) => selected.getTopic() === region.getTopic()) >= 0}"
                style="width: 6.6rem; height: 3rem; font-size: 0.65em"
                :disabled="!mRegionData.isRegionSelect()">
              {{ region.getName() }}
            </button>
          </div>
        </form>

      </div>

      <!-- action link -->
      <div v-if="!disableRegionPicker" class="my-text-primary mt-2">
        <input type="checkbox" id="enable_action" v-model="mActionOptions.enabled">
        <label class="ml-2" style="font-size: 0.85em" for="enable_action" >Action button:</label><br>

        <div v-if="mActionOptions.enabled" class="mt-1">
          <!-- text -->
          <input type="text"
                 class="form-control"
                 :class="{'my-box-decoration': !dark}"
                 placeholder="Enter action text"
                 maxlength="8"
                 v-model="mActionOptions.linkText">
          <div class="d-flex flex-row justify-content-between">
            <label :class="{'my-text-primary': !dark}" style="font-size: 0.6em">* max 8 characters</label>
            <label :class="{'my-text-primary': !dark}" style="font-size: 0.5em">{{mActionOptions.linkText.length}} / 8</label>
          </div>
          <!-- link -->
          <input type="text"
                 class="form-control mt-1"
                 :class="{'my-box-decoration': !dark}"
                 placeholder="Enter action link"
                 v-model="mActionOptions.linkUrl">
        </div>
      </div>

      <div v-if="!disableEdit" class="d-flex flex-row justify-content-end mt-3">
        <button v-if="!dark"
                type="submit"
                class="btn btn-outline-primary my-box-decoration ml-2"
                style="width: 10rem"
                :disabled="!isValid">
          {{ confirmText }}
        </button>
        <b-button v-else
                  type="submit"
                  class="my-button-confirm"
                  :disabled="!isValid">
          {{ confirmText }}
        </b-button>
      </div>

    </form>

  </div>
</template>
<script>

import model from "@/model";
import datetimeHelper from "@/util/datetime_helper";
import logger from "@/util/logger";

const LOG_TAG = 'message_card';

export default {
  name: LOG_TAG,
  props: {
    limitBody: {
      type: Number,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return 100;
      }
    },
    defaultBody: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return '';
      }
    },
    defaultPlaceholder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return 'Enter message body';
      }
    },
    disableDatePicker: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    disableRegionPicker: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    disableTitle: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    confirmText: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return 'Create';
      }
    },
    onClickCb: {
      type: Function,
    },
    timestamp: {
      type: Number,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return 0;
      }
    },
    dark: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
  },
  data() {
    return {
      mTitle: '',
      mBody: '',
      mScheduledDate: '',
      mRegionData: {
        selected: '', //'region_all', ''region_select', 'region_test'
        allOption: model.regionResponse(),
        regionOptions: [],
        regionsSelected: [],
        testRegionOptions: [
          //model.regionResponse({active: true, name: 'Region Test 1', topic: 'region_test_1'}),
          //model.regionResponse({active: true, name: 'Region Test 2', topic: 'region_test_2'}),
          //model.regionResponse({active: true, name: 'Region Test 3', topic: 'region_test_3'}),
        ],
        init(options) {
          logger.d(LOG_TAG, 'init', options.length);
          this.regionOptions = [];
          this.regionsSelected = [];
          options.forEach((region) => {
            const regionInfo = model.regionResponse(region);
            if (regionInfo.isActive()) {
              if (regionInfo.isTopicNews()) {
                this.allOption = regionInfo;
              } else {
                this.regionOptions.push(regionInfo);
              }
            }
            if (this.allOption.isActive()) {
              this.selected = 'region_all';
            } else {
              this.selected = 'region_select';
            }
          })
        },
        isRegionAll() {
          return this.selected === 'region_all';
        },
        isRegionSelect() {
          return this.selected === 'region_select';
        },
        isRegionTest() {
          return this.selected === 'region_test';
        }
      },
      mActionOptions: {
        enabled: false,
        linkText: '',
        linkUrl: '',
        reset() {
          this.enabled = false;
          this.linkText = '';
          this.linkUrl = '';
        }
      },
      mTimestamp: 0
    };
  },
  watch: {
    storeDatePicker: function onChange(value) {
      const vm = this;
      const pickerInfo = model.datepickerInfo(value);
      vm.mScheduledDate = pickerInfo.formattedDateTime();
      logger.d(LOG_TAG, 'watch', 'storeDatePicker', pickerInfo, vm.mScheduledDate);
    },
    timestamp: function onChange(value) {
      const vm = this;
      if (vm.mTimestamp != value) {
        vm.mTitle = '';
        vm.mBody = vm.defaultBody ? vm.defaultBody : '';
        vm.mActionOptions.reset();
        vm.mTimestamp = value;
      }
    },
    'mRegionData.selected': function onChange() {
      const vm = this;
      // clear upon select change
      vm.mRegionData.regionsSelected = [];
    }
  },
  computed: {
    isValid() {
      const vm = this;
      const validTitle = vm.disableTitle || (
          vm.mTitle.length > 0
      );
      const validDate = vm.disableDatePicker || (
          vm.mScheduledDate.length > 0
      );
      const validRegion = vm.disableRegionPicker || (
          vm.mRegionData.isRegionAll() ||
          (vm.mRegionData.isRegionSelect() || vm.mRegionData.isRegionTest()) &&
          vm.mRegionData.regionsSelected.length  > 0
      );
      const validBody = vm.defaultBody ? (vm.defaultBody !== vm.mBody) : vm.mBody.length > 0;
      const result = validDate && validTitle && validBody && validRegion;
      return result;
    },
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    //
    const vm = this;
    vm.mBody = vm.defaultBody;
    vm.mRegionData.init(vm.storeAuth.getRegion());
  },
  methods: {
    onOpenDateSelector() {
      const vm = this;
      vm.$store.commit('setDatePicker', {
        enabled: true,
      });
    },
    onClickCreate() {
      const vm = this;
      if (vm.isValid && vm.onClickCb) {
        const payload = model.notificationInfo({
          title: vm.mTitle,
          body: vm.mBody,
          scheduledDate: datetimeHelper.convertDateTimeToMilliseconds(vm.storeDatePicker.date)
        });
        if (vm.mActionOptions.enabled) {
          if (vm.mActionOptions.linkText && vm.mActionOptions.linkUrl) {
            payload.linkText = vm.mActionOptions.linkText;
            payload.link = vm.mActionOptions.linkUrl;
          }
        }
        if (vm.mRegionData.isRegionAll()) {
          payload.setSubRegion([vm.mRegionData.allOption]);
        } else {
          payload.setSubRegion(vm.mRegionData.regionsSelected);
        }
        logger.d(LOG_TAG, 'onClickCreate', payload);
        vm.onClickCb(payload);
      }
    },
    onSelectRegion(region) {
      const vm = this;
      const itemIndex = vm.mRegionData.regionsSelected.findIndex((selected) => selected.getTopic() === region.getTopic());
      if (itemIndex >= 0) {
        vm.mRegionData.regionsSelected.splice(itemIndex, 1);
      } else {
        vm.mRegionData.regionsSelected.push(region);
      }
    }
  }
}
</script>

<style scoped>
.label {
  color: var(--primary-text);
  font-size: 1em;
}
.b-form-btn-label-control.form-control:hover {
  color: yellow;
}
</style>
