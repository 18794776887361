<template>
  <form class="form-group" v-on:submit.prevent="onSearchUser()">
    <div v-if="isSearchByEmail">
      <div
          v-if="linkMyTogs"
          class="font-weight-bold"
          :class="{
        'my-text-primary': !dark
      }"
          style="font-size: 0.85em;">
          <font-awesome-icon
              :icon="'link'"
              size="sm"
              class="label"/>
        {{(mData.user.isValid()) ? 'Linked MyTogs account' : 'Link swimmer to MyTogs'}}:
      </div>
      <div
          v-else
          class="font-weight-bold"
          :class="{
        'my-text-primary': !dark
      }"
          style="font-size: 0.85em;">
        Search user by Email:
      </div>
      <separator-line></separator-line>
      <div class="d-flex flex-row justify-content-between mt-2">
        <div v-if="mData.user.isValid()"
             :class="{'my-text-primary': !dark}"
             style="padding: 0.35rem 0; font-size: 0.75em;">
          <div class="font-weight-bold text-capitalize"
               :class="{'my-text-warning': mData.user.isNewSignUp()}">{{
              mData.user.isNewSignUp() ? '( *New user )' : mData.user.getName()
            }}</div>
          <div>{{ mData.user.getEmail() }}</div>
        </div>
        <input
            v-else
            type="email"
            class="form-control"
            :class="{'my-box-decoration': !dark}"
            placeholder="Enter email"
            v-model="mData.email"
            :disabled="mData.isSearching">
        <button type="submit"
                class="btn btn-outline-primary ml-2"
                :class="{'my-box-decoration': !dark}"
                style="width: 3rem; max-height: 2.4rem"
                v-bind:disabled="mData.isSearching || mData.user.isValid() ? false : !isValidEmail">

          <div v-if="mData.isSearching"
               class="spinner-border"
               :class="{'text-light': !dark}"
               style="width: 1rem; height: 1rem; border-width: 1px" role="status"></div>
          <font-awesome-icon
              v-else
              :icon="mData.user.isValid() ? 'times' : 'search'"
              size="sm"
              class="label clickable warning"/>
        </button>
      </div>
    </div>
    <div v-else>
      <div
          class="font-weight-bold"
          :class="{
        'my-text-primary': !dark
      }"
          style="font-size: 0.85em;">
<!--        <font-awesome-icon
            v-if="!mData.swimmer.isValid()"
            :icon="'link'"
            size="sm"
            class="label"/>-->
        {{(mData.swimmer.isValid()) ? 'Swimmer details' : 'Search swimmer by name'}}:
      </div>
      <separator-line></separator-line>
      <div class="d-flex flex-row justify-content-between mt-2">
        <div v-if="mData.swimmer.isValid()" class="text-capitalize" style="padding: 0.35rem 0; font-size: 0.75em;">
          <div :class="{'my-text-primary': !dark}" class="font-weight-bold">{{mData.swimmer.getName()}}</div>
          <div :class="{'my-text-primary': !dark}">{{ mData.swimmer.getBranch() }}</div>
        </div>
        <input
            v-else
            type="text"
            class="form-control"
            :class="{'my-box-decoration': !dark}"
            placeholder="Search by swimmer name"
            v-model="mData.name"
            :disabled="
              mData.isSearching ||
             (!swimmerFirst && !mData.user.isValid())">
        <button type="submit"
                class="btn btn-outline-primary ml-2"
                :class="{'my-box-decoration': !dark}"
                style="width: 3rem; max-height: 2.4rem"
                v-bind:disabled="
                  (modifiable && swimmerFirst ? false : (!mData.user.isValid() || !isValidName)) ||
                 (!modifiable && mData.swimmer.isValid())">

          <div v-if="mData.isSearching"
               class="spinner-border"
               :class="{'text-light': !dark}"
               style="width: 1rem; height: 1rem; border-width: 1px" role="status"></div>
          <font-awesome-icon
              v-else
              :icon="mData.swimmer.isValid() ? 'times' : 'search'"
              size="sm"
              class="label clickable warning"

          />
        </button>
      </div>
      <div v-if="mData.swimmerOptions.length > 0" class="mt-3">
        <div style="font-size: 0.85em; font-weight: bold">Select swimmer({{ mData.swimmerOptions.length }})</div>
        <div class="d-flex flex-row flex-nowrap mt-2" style="width: 100%; overflow-x: auto;">
          <div v-for="(swimmer, index) in mData.swimmerOptions" :key="index"
               v-on:click.stop="onSelectSwimmer(swimmer)"
               class="my-box my-box-decoration-dark clickable text-capitalize p-1 ml-1 mr-1 mb-2"
               :class="{'selected': mData.swimmer.getMemberId() === swimmer.getMemberId()}"
              style="min-width: 8rem; font-size: 0.75em">
            <div style="font-weight: bold"> {{swimmer.getName() }}</div>
            <div> {{swimmer.getBranch() }}</div>
          </div>
        </div>
      </div>
      <div class="my-text-warning" style="font-size: 0.75em;" v-html="mData.error">
      </div>
      <div>

      </div>
    </div>
  </form>
</template>
<script>

import SeparatorLine from "@/components/separator_line"
import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger";

const LOG_TAG = 'search_user_card';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
  },
  props: {
    mode: {
      type: String,
      default: 'email' // email, name (freetext),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    supportSignUp: {
      type: Boolean,
      default: false,
    },
    invite: {
      type: Boolean,
      default: false,
    },
    swimmerFirst: {
      type: Boolean,
      default: false,
    },
    linkMyTogs: {
      type: Boolean,
      default: false,
    },
    clubId: null,
    squadId: null,
    user: {
      type: Object,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return model.memberResponse();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return model.memberResponse();
      }
    },
    squadMembers: {
      type: Array,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return [];
      }
    },
    // (searching, success, result)
    onSearchState: {
      type: Function,
    },
    modifiable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mData: {
        email: '',
        isSearching: false,
        user: model.memberResponse(),
        reset() {
          this.email = '';
          this.isSearching = false;
          this.user = model.memberResponse();

          this.name = '';
          this.swimmer = model.memberResponse();
          this.swimmerOptions = [];
        },
        name: '',
        swimmer: model.memberResponse(),
        swimmerOptions: [],
        error: '',
        resetSwimmerOptions() {
          this.name = '';
          this.swimmer = model.memberResponse();
          this.swimmerOptions = [];
          this.error = '';
        },
      },
      mErrorText: {
        noSwimmerFound: 'No matching swimmer found.',
        formattedNoSwimmerFound() {
          return this.noSwimmerFound;
        }
      }
    };
  },
  computed: {
    isSearchByEmail() {
      const vm = this;
      return vm.mode === 'email';
    },
    isValidEmail() {
      const vm = this;
      return vm.mData.email.length > 0;
    },
    isValidName() {
      const vm = this;
      return vm.mData.name.length > 0;
    }
  },
  watch: {
    user: function(value) {
      logger.d(LOG_TAG, 'watch.user', 'reset', value);
      const vm = this;
      if (!value.isValid()) {
        vm.mData.reset();
      } else {
        vm.mData.user = value;
      }
    },
    swimmer: function(value) {
      logger.d(LOG_TAG, 'watch.swimmer', 'reset', value);
      const vm = this;
      vm.mData.swimmer = value;
    }
  },
  mounted() {
    //
    const vm = this;
    logger.d(LOG_TAG, 'mounted', vm.isSearchByEmail, vm.user);
    vm.mData.user = vm.user;
    vm.mData.swimmer = vm.swimmer;
  },
  methods: {
    onSearchUser() {
      const vm = this;
      if (vm.isSearchByEmail) {
        vm.onSearchUserByEmail(vm.mData.email);
      } else {
        vm.onSearchUserByName(vm.mData.name);
      }
    },
    onSearchUserByEmail(email) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchUserByEmail', email);
      if (vm.mData.isSearching) {
        return;
      }
      if (vm.mData.user.isValid()) {
        logger.d(LOG_TAG, 'onSearchUserByEmail', 'reset');
        vm.mData.reset();
        vm.onSearchState(false, true, model.memberResponse());
      } else {
        vm.onSearchState(true, false);
        vm.mData.isSearching = true;
        webapi.apiService.searchMember(
            email,
            (response) => {
              const user = model.memberResponse(response);
              vm.mData.user = user;
              vm.mData.isSearching = false;
              vm.onSearchState(false, true, user);
              logger.d(LOG_TAG, 'onSearchUserByEmail', vm.mData.user.getName());
            },
            (error) => {
              if (vm.supportSignUp) {
                const user = model.memberResponse().setUserUuid('signup').setEmail(email);
                vm.mData.user = user;
                vm.mData.isSearching = false;
                vm.onSearchState(false, true, user);
              } else {
                vm.mData.isSearching = false;
                vm.onSearchState(false, false);
                vm.$bvModal.msgBoxOk('email provided does not match an existing mytogs user', {
                  title: 'Error',
                  size: 'sm',
                  okVariant: 'danger',
                  okTitle: 'Close',
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                });
              }

              logger.w(LOG_TAG, 'onSearchUser', error);
            }
        )
      }
    },
    isSwimmerInSquad(swimmer) {
      const vm = this;
      return vm.squadMembers.find((member) => member.getMemberId() === swimmer.getMemberId());
    },
    onSearchUserByName(name) {
      const vm = this;

      logger.d(LOG_TAG, 'onSearchUserByName', vm.clubId, vm.squadId, name);
      if (vm.mData.isSearching) {
        return;
      }
      if (vm.mData.swimmer.isValid()) {
        logger.d(LOG_TAG, 'onSearchUserByName', 'reset');
        vm.mData.resetSwimmerOptions();
        vm.onSearchState(false, true, model.memberResponse());
      } else {
        vm.mData.error = '';
        vm.onSearchState(true, false);
        vm.mData.isSearching = true;
        webapi.apiService.searchSwimmer(
            vm.clubId,
            vm.squadId,
            name,
            (response) => {
              const swimmerOptions = [];
              response.forEach((user) => {
                swimmerOptions.push(model.memberResponse(user));
              });
              vm.mData.isSearching = false;
              vm.mData.swimmerOptions = swimmerOptions;
              if (swimmerOptions.length == 0) {
                vm.mData.error = vm.mErrorText.formattedNoSwimmerFound();
                vm.onSearchState(false, true, model.memberResponse().setName(name));
              }
              logger.d(LOG_TAG, 'searchSwimmer', swimmerOptions.length);
            },
            (error) => {
              vm.mData.isSearching = false;
              vm.mData.error = vm.mErrorText.formattedNoSwimmerFound();
              vm.onSearchState(false, true, model.memberResponse().setName(name));
              logger.w(LOG_TAG, 'searchSwimmer', error);
            }
        )
      }
    },
    onSelectSwimmer(swimmer) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectSwimmer', swimmer);
      if (vm.isSwimmerInSquad(swimmer)) {
        vm.$bvModal.msgBoxOk(swimmer.getName() + ' is already in squad', {
          title: 'Error',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Close',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        });
      } else {
        vm.mData.swimmer = swimmer;
        vm.onSearchState(false, true, swimmer);
      }

    }
  }
}
</script>

<style scoped>
</style>
