<template>
  <div>
    <!-- search by inputs forms -->
    <div>
      <form class="form-group" v-on:submit.prevent="onClickCreate()">

        <!-- title -->
        <input type="text"
               class="form-control mt-3"
               :class="{'my-box-decoration': !dark}"
               placeholder="Enter training title"
               maxlength="25"
              v-model="mTitle">
        <div class="d-flex flex-row justify-content-between">
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.6em">* max 25 characters</label>
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.5em">{{mTitle.length}} / 25</label>
        </div>
        <!-- description -->
        <textarea
            rows="4"
           class="form-control mt-2"
          :class="{'my-box-decoration': !dark}"
           placeholder="Enter training details"
           maxlength="1024"
           v-model="mBody">
        </textarea>
        <div class="d-flex flex-row justify-content-between">
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.6em">* max 1024 characters</label>
          <label :class="{'my-text-primary': !dark}" style="font-size: 0.5em">{{mBody.length}} / 1024</label>
        </div>

        <div class="d-flex flex-row justify-content-end mt-2">
          <button v-if="!dark"
                  type="submit"
                  class="btn btn-outline-primary my-box-decoration ml-2"
                  style="width: 10rem"
                  :disabled="!isValid">
            Create
          </button>
          <b-button v-else
                    type="submit"
                    class="my-button-confirm"
                    style="width: 10rem"
                    :disabled="!isValid">
            Create
          </b-button>
        </div>

      </form>

    </div>
  </div>
</template>
<script>

import model from "@/model";
import logger from "@/util/logger";

const LOG_TAG = 'training_set_card';

export default {
  name: LOG_TAG,
  props: {
    _disabled: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    _onClickCb: {
      type: Function,
    },
    dark: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
  },
  data() {
    return {
      mTitle: '',
      mBody: '',
    };
  },
  watch: {
    _disabled: function onChange(value) {
      logger.d(LOG_TAG, 'watch', '_disabled', value);
      // const vm = this;
    },
  },
  computed: {
    isValid() {
      const vm = this;
      return (vm.mTitle.length > 0 && vm.mBody.length > 0);
    },
    storeAuth() {
      return this.$store.state.auth;
    },
  },
  mounted() {
  },
  methods: {
    onClickCreate() {
      const vm = this;
      logger.d(LOG_TAG, 'onClickCreate');
      vm._onClickCb(model.trainingSetInfo()
        .setDescription(vm.mTitle)
        .setDetails(vm.mBody));
    },
  }
}
</script>

<style scoped>

</style>
