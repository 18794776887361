<template>
  <content-layout>

    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search by -->
          <label class="my-text-primary" style="font-size: 0.85em;">
            {{ mDescriptions[0]}}
            <span style="font-weight: bold">{{ mDescriptions[1]}}</span>
            {{mDescriptions[2]}}
          </label>
          <file-picker-card
            :accept="'.hy3,.zip'"
            :_on-click-cb="onUploadFile">
          </file-picker-card>
          <!-- search result -->

          <!-- history section-->
<!--          <div class="my-text-primary mt-2"
               style="font-weight: bold;">Recent Events: {{mFileHistory.length}}</div>-->
          <!-- content section-->
          <loader :embedded="true" v-if="mInit" style="margin-top: 4rem; text-align: center"></loader>
<!--          <section v-if="mFileHistory.length > 0">
            <div v-for="(file) in mFileHistory" v-bind:key="file.eventId"
                 class="my-text-primary my-box my-box-decoration mt-2 pl-2 pr-2
                    d-flex flex-column justify-content-sm-around"
                 style="padding-top: 3px">

              <div class="d-flex flex-row justify-content-between">
                <span style="font-size: 0.8em">
                {{file.formattedEventPeriod()}}
                </span>

              </div>
              <div style="font-size: 0.8em">
                <span style="font-weight: bold;">{{file.getEventName()}}</span>
              </div>
              <div class="d-flex flex-row justify-content-end mt-1" style="font-size: 0.7em">
                Ages: <span class="ml-1" style="font-weight: bold;">{{file.formattedAgeRange()}}</span>
              </div>
              <div v-if="file.hasQualifyPeriod()" class="d-flex flex-row justify-content-end mt-1" style="font-size: 0.7em">
                Qualify period: <span class="ml-1" style="font-weight: bold;">{{file.formattedQualifyPeriod()}}</span>
              </div>
            </div>
          </section>-->

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import FilePickerCard from "@/views/content/components/file_picker_card"
import Loader from "@/components/loader"
import webapi from "@/webapi"
import logger from "@/util/logger"

const LOG_TAG = 'updatePyschSheet';

export default {
  name: LOG_TAG,
  display: 'Update Pysch Sheet',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    FilePickerCard,
    Loader
  },
  data() {
    return {
      mInit: false,
      mFilePath: '',
      mState: '',
      mStateDescription: '',
       mDescriptions: [
        'Upload Pysch Sheet',
        'HY3, ZIP ',
        'Files',
      ],
      mFileHistory: []
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },

  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      /*
      webapi.apiService.getEventHYVs(
        (response) => {
          logger.d(LOG_TAG, 'getHYVFiles', response.length);
          const list = [];
          response.forEach((file) => {
            list.push(model.hyvFileResponse(file))
          })
          list.sort((a, b) => a.getEventStartAt() - b.getEventStartAt())
          vm.mFileHistory = list;
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        },
        (error) => {
          logger.d(LOG_TAG, 'getHYVFiles', error);
          logger.w(LOG_TAG, 'refresh', error);
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        }
      );*/
      vm.$store.commit('setLoading', false);
      vm.mInit = false;
    },
    onUploadFile(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onUploadFile', data.name, vm.mFilePath);
      vm.$store.commit('setLoading', true);
      webapi.apiService.uploadPyschSheet(
          data,
          (response) => {
            logger.d(LOG_TAG, 'onUploadFile', response);
            vm.refresh();
            vm.onPostResult(true, 'updated');
          },
          (error) => {
            logger.w(LOG_TAG, 'onUploadFile', error.data);
            vm.$store.commit('setLoading', false);
            vm.onPostResult(false, error.data);
          }
      )
    },
    onPostResult(success, message) {
      this.$bvModal.msgBoxOk(message, {
        title: success ? 'Success' : 'Error',
        size: 'sm',
        okVariant: success ? 'success' : 'danger',
        okTitle: 'Close',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      });
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
