<template>
  <content-layout>
    <div slot="content_container">
      <div class="row pl-1 pr-1 pb-5">
        <!--add banner -->
        <b-modal centered title="Create Banner" id="add-banner-modal">
          <form ref="form" @submit.stop.prevent="onConfirmAddBanner()">
            <b-form-group label="Title" label-for="title-input" class="my-input-label">
              <b-form-input id="title-input" v-model="mBannerNew.title" maxlength="25"></b-form-input>
              <label class="my-input-subscript">* max 25 characters</label>
            </b-form-group>
            <b-form-group label="Description" label-for="desc-input" class="my-input-label">
              <b-form-input id="desc-input" v-model="mBannerNew.description" maxlength="100"></b-form-input>
              <label class="my-input-subscript">* max 100 characters</label>
            </b-form-group>

            <section>
              <label class="ml-1" style="font-size: .9em">Banner image from:</label>
              <input type="radio"
                     class="ml-2"
                     :id="'link'"
                     :value="'link'"
                     v-model="mBannerNew.img_from">
              <label class="ml-1" style="font-size: .9em" :for="'link'">Link</label>

              <input class="ml-2" type="radio"
                     :id="'upload'"
                     :value='"upload"'
                     v-model="mBannerNew.img_from">
              <label class="ml-1" style="font-size: .9em" :for="'upload'">Upload</label>

              <b-form-group v-if="mBannerNew.img_from === 'link'">
                <b-form-input id="img-input" v-model="mBannerNew.img_url"></b-form-input>
              </b-form-group>
              <b-form-group v-if="mBannerNew.img_from === 'upload'">
                <file-picker-card
                    :dark="true"
                    :accept="'image/*'"
                    :_on-click-cb="onUploadBanner">
                </file-picker-card>
                <span class="font-weight-bold" style="font-size: 0.85em;">Download link:
                  <span v-if="mBannerNew.img_url" class="font-weight-normal">{{mBannerNew.img_url}}</span>
                  <span v-else class="warning font-weight-normal"> *Please upload a banner photo</span>
                </span>
                <div>
                  <label class="warning my-input-subscript">* required</label>
                  <label class="warning my-input-subscript">(For best result, image width less than 1024px and 16:8 aspect ratio)</label>
                </div>
              </b-form-group>

            </section>

            <b-form-group label="Link URL" label-for="link-input" class="my-input-label">
              <b-form-input id="link-input" v-model="mBannerNew.link_url"></b-form-input>
              <label v-if="mBannerNew.invalid_link_url" class="warning my-input-subscript">** Invalid link Url ***</label>
            </b-form-group>
            <b-form-group label="Redirect URL" label-for="redirect-input" class="my-input-label">
              <b-form-input id="redirect-input" v-model="mBannerNew.redirect_url"></b-form-input>
            </b-form-group>
            <!-- date  picker -->
<!--            <div class="d-flex flex-row">
              <b-form-group label="Start Date" label-for="start-datepicker" class="my-input-label">
                <b-form-datepicker class="my-calendar-picker" id="start-datepicker" v-model="mBannerNew.start"></b-form-datepicker>
                <label class="warning my-input-subscript">* required</label>
              </b-form-group>
              <div class="ml-2"></div>
              <b-form-group label="End Date" label-for="end-datepicker" class="my-input-label">
                <b-form-datepicker class="my-calendar-picker" id="end-datepicker" v-model="mBannerNew.end"></b-form-datepicker>
              </b-form-group>
            </div>-->

            <div>
              <div class="d-flex flex-row">
                <div class="flex-grow-1">
                  <label class="my-input-label">Start date time:</label>
                  <div>
                    <button
                        class="btn btn-outline-primary my-box-decoration-dark"
                        style="width: 100%; text-align: left"
                        v-on:click.prevent="onOpenDateSelector('start')">
                      <font-awesome-icon
                          icon="calendar-alt"
                          size="sm"
                          class="clickable"
                      />
                      <span class="ml-3 my-input-label">{{ mBannerNew.formattedStartDate() }}</span>
                    </button>
                  </div>

                </div>
                <div class="ml-1 flex-grow-1">
                  <label class="my-input-label">End date time:</label>
                  <div>
                    <button
                        class="btn btn-outline-primary my-box-decoration-dark"
                        style="width: 100%; text-align: left"
                        v-on:click.prevent="onOpenDateSelector('end')">
                      <font-awesome-icon
                          icon="calendar-alt"
                          size="sm"
                          class="clickable"
                      />
                      <span class="ml-3 my-input-label">{{ mBannerNew.formattedEndDate()  }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <label class="warning my-input-subscript">* required</label>
            </div>


          </form>
          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-confirm" @click="onConfirmAddBanner()" :disabled="!mBannerNew.isValid()">
              Create
            </b-button>
            <b-button class="my-button-cancel" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>

        <!--delete warning -->
        <b-modal centered title="Delete?" id="delete-banner-modal">
          <p>Are you sure you want to delete banner: {{mIndex}}?</p>
          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-warning" @click="onConfirmDeleteBanner()">
              Delete
            </b-button>
            <b-button class="my-button-cancel" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>

        <div class="mt-1 ml-4 clickable" v-on:click.prevent="onClickAddBanner()">
          <font-awesome-icon
              icon="plus-circle"
              size="lg"
              class="label clickable "
          />
          <span class="label ml-1">Create Banner</span>
        </div>
        <div class="mt-4"></div>
        <!-- content section-->
        <loader :embedded="true" v-if="mInit" style="width: 100%; margin-top: 4rem"></loader>

        <section class="d-flex flex-row flex-wrap col-11"
                 :class="{
            'col-12': windowWidth <= 768
            }">
          <div class="banner-container m-1 mb-4"
               :class="{
            'clickable': banner.hasLink()
            }"
               v-for="(banner, index) in mBanners" v-bind:key="index"
               v-on:click.prevent="onClickBanner(index + 1, banner)">
            <div class="index">
              {{ index+1 }}. {{banner.isExpired() ? '(Expired)': banner.formattedStart()}} ~ {{banner.formattedEnd()}}
            </div>

            <div class="banner-image-container"
                 :class="{
            'banner-image-container-with-link': banner.hasLink()
            }">
              <img :src="banner.getImgUrl()" alt="banner img">
            </div>

            <!-- delete button -->
            <div v-on:click.prevent="onClickDeleteBanner($event, index + 1, banner)">
              <font-awesome-icon
                  icon="times-circle"
                  size="lg"
                  class="my-btn-warning clickable"
                  style="position: absolute; right: 0.6rem; top: 3rem;"
              />
            </div>
            <!-- title / description -->
            <div class="title" style="margin-left: 0.6rem ;margin-right: 2.3rem">
              {{banner.getTitle()}}
            </div>
            <div class="description" style="margin-left: 0.6rem ;margin-right: 2.3rem">
              {{banner.getDescription()}}
            </div>
          </div>
        </section>
        <!-- Modal -->
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import FilePickerCard from "@/views/content/components/file_picker_card"
import firebaseStorage from "@/firebase/mytogs_storage";
import Loader from "@/components/loader"
import model from "@/model"
import webapi from "@/webapi"
import displayHelper from "@/util/display_helper"
import logger from "@/util/logger"
import dateTimeHelper from "@/util/datetime_helper";

const LOG_TAG = 'updateBanner';

export default {
  name: LOG_TAG,
  display: 'Update Banner',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    FilePickerCard,
    Loader
  },
  data() {
    return {
      mInit: false,
      mBanners: [],
      mIndex: 0,
      mBannerSelected: model.bannerResponse(),

      mBannerNew: {
        img_url: '',
        img_from: 'link', // link, upload
        link_url: '',
        redirect_url: '',
        title: '',
        description: '',

        dateType: null, // start, end
        start: null,
        end: null,
        setSelectedDate(date) {
          if (this.dateType === 'start') {
            this.start = date;
          } else if (this.dateType === 'end') {
            this.end = date;
          }
        },
        formattedStartDate() {
          if (this.start) {
            return dateTimeHelper.formattedFullDateTime(this.start);
          } else {
            return 'Select date';
          }
        },
        formattedEndDate() {
          if (this.end) {
            return dateTimeHelper.formattedFullDateTime(this.end)
          } else {
            return 'Select date';
          }
        },
        invalid_link_url: false,
        clear() {
          this.img_url = '';
          this.img_from = 'link';
          this.link_url = '';
          this.redirect_url = '';
          this.title = '';
          this.description = '';


          //
          this.dateType = null; // start, end
          this.start = null;
          this.end = null

          this.invalid_link_url = false;
        },
        isValid() {
          let validLinkUrl = true;
          if (this.link_url.length > 0) {
            validLinkUrl = displayHelper.isValidUrlLink(this.link_url);
            this.invalid_link_url = !validLinkUrl;
          } else {
            this.invalid_link_url = false;
          }
          return this.img_url.length > 0 && this.start && validLinkUrl;
        },
        toJson() {
          const json = {
            img_url: this.img_url,
            link_url: this.link_url,
            redirect_url: this.redirect_url,
            title: this.title,
            description: this.description,
            start: Math.floor(dateTimeHelper.epoc(this.start) / 1000)
          };
          if (this.end) {
            json['end'] = Math.floor(dateTimeHelper.epoc(this.end) / 1000);
          }
          return json;
        }
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
  },
  watch: {
    storeDatePicker: function onChange(value) {
      const vm = this;
      if (value.date) {
        vm.mBannerNew.setSelectedDate(value.date);

      }
    },
  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      const newBanners = [];
      webapi.apiService.getAppBanners(
        (response) => {
          logger.d(LOG_TAG, 'refresh', response);
          for (const key in response){
            logger.d(LOG_TAG, 'refresh', key, response[key]);
            newBanners.push(model.bannerResponse(response[key]));
          }
          newBanners.sort((a, b) => b.getStart() - a.getStart());
          vm.mBanners = newBanners;
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        },
        (error) => {
          logger.w(LOG_TAG, 'refresh', error);
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        }
      )
    },
    formattedBannerTitle(banner) {
      // const vm = this;
      if (banner.isExpired()) {
        return '(Expired)';
      } else {
        return banner.formattedStart();
      }
    },
    onOpenDateSelector(type /*start, end*/) {
      const vm = this;
      vm.mBannerNew.dateType = type;
      const payload = {};
      if (type === 'end' && vm.mBannerNew.start) {
        payload.enabled = true;
        payload.byDateTime = true;
        payload.minDate = vm.mBannerNew.start
      } else {
        payload.enabled = true;
        payload.byDateTime = true;
      }
      logger.d(LOG_TAG, 'onOpenDateSelector', payload);
      vm.$store.commit('setDatePicker', payload);
    },
    onClickBanner(index, banner) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickBanner', index, banner.hasLink());
      if (banner.hasLink()) {
        vm.mIndex = index;
        vm.mBannerSelected = banner;
        window.open(banner.getLinkUrl());

      }
    },
    onClickDeleteBanner(event, index, banner) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickDeleteBanner', index);
      // stop propagation to banner click
      event.stopPropagation();

      vm.mIndex = index;
      vm.mBannerSelected = banner;
      vm.$bvModal.show('delete-banner-modal');
    },
    onConfirmDeleteBanner() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmDeleteBanner');
      vm.$bvModal.hide('delete-banner-modal');
      vm.$store.commit('setLoading', true);
      webapi.apiService.deleteBanner(
        vm.mBannerSelected.getStart(),
          (response) => {
            logger.d(LOG_TAG, 'onConfirmDeleteBanner', response);
            vm.$notify({ type: 'success', text: 'Banner successfully deleted' });
            vm.refresh();
          },
          (error) => {
            logger.w(LOG_TAG, 'onConfirmDeleteBanner', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: error.data });
          }
      )
    },
    onClickAddBanner() {
      const vm = this;
      logger.d(LOG_TAG, 'onClickAddBanner');
      vm.mBannerNew.clear();
      vm.$bvModal.show('add-banner-modal');
    },
    onConfirmAddBanner() {
      const vm = this;
      const payload = vm.mBannerNew.toJson();
      logger.d(LOG_TAG, 'onConfirmAddBanner', payload);
      // reset
      vm.mBannerNew.clear();
      vm.$bvModal.hide('add-banner-modal');
      logger.d(LOG_TAG, 'onConfirmAddBanner', payload);
      //
      vm.$store.commit('setLoading', true);
      webapi.apiService.createBanner(
          payload,
          (response) => {
            logger.d(LOG_TAG, 'onConfirmAddBanner', response);
            vm.$notify({ type: 'success', text: 'Banner successfully created' });
            vm.refresh();
          },
          (error) => {
            logger.w(LOG_TAG, 'onConfirmAddBanner', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: error.data });
          }
      )
    },
    onUploadBanner(file) {
      const vm = this;
      logger.d(LOG_TAG, 'onUploadBanner', file);
      vm.$store.commit('setLoading', true);
      firebaseStorage.uploadBanner(
          file,
          (attachmentInfo) => {
            logger.d(LOG_TAG, 'onUploadBanner', attachmentInfo);
            vm.mBannerNew.img_url = attachmentInfo.getDownloadUrl();
            vm.$store.commit('setLoading', false);
          }, (error) => {
            logger.d(LOG_TAG, 'onUploadBanner', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Failed uploading image' });
          }
      )
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  color: var(--primary-text);
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.desktop {
  display: block;
}
.banner-container {
  position: relative;
  width: 26rem;
  height: 15rem;
  background-color: transparent;
}
.banner-image-container {
  position: absolute;
  width: 100%;
  margin-top: 2.3rem;
  background-color: black;
  border-radius: 10px;
}
.banner-image-container img {
  width: 100%;
  height: 12.6rem;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.6;
}
.banner-image-container-with-link img:hover {
  border-style: solid;
  border-width: 2px;
  border-color: white;
  opacity: 0.75;
}
.banner-container .index {
  position: absolute;
  top: 0.6rem;
  left: 0.3rem;
  color: white;
  font-size: 0.75em;
  font-weight: bold;
}
.banner-container .title {
  position: absolute;
  left: 0.3rem;
  top: 3rem;
  font-size: 0.9em;
  font-weight: bold;
  color: white;
  text-align: left;
}
.banner-container .description {
  position: absolute;
  left: 0.3rem;
  bottom: 0.6rem;
  font-size: 0.9em;
  color: white;
  text-align: left
}
.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.my-btn-warning {
  color: darkgrey;
  background-color: transparent;
}
.my-btn-warning:hover {
  color: var(--indicator-red);
  background-color: transparent;
}
.my-calendar-picker:hover {
  color: white;
  background-color: var(--background-blue);
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .banner-container {
    width: 100%;
    height: 10rem;
  }
  .banner-container img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-radius: 6px;
  }
}
</style>
