<template>
  <div v-if="mMember.isValid()">

    <div class="d-flex flex-row justify-content-between mt-2">
      <div
          v-for="(option, index) in mProfileOptions.options" :key="index"
          class="clickable my-button-tab-decoration-flex-2 my-tab-primary-dark"
        :class="{
        'selected': mProfileOptions.selected == option.value
        }"
        v-on:click.stop="onClickProfileOption(option.value)">
        {{ option.text }}
      </div>

    </div>
    <separator-line></separator-line>

    <div
        v-if="mProfileOptions.isGoalSettings()"
        class="d-flex flex-row justify-content-start mt-3 mb-1 font-weight-bold" style="font-size: 0.85em">
      <div v-for="(option, index) in mGoalSettingsOptions.options" :key="index">
        <input
            type="radio"
            class="clickable ml-2"
            :id="option.text"
            :name="option.text"
            :value="option.value"
            v-model="mGoalSettingsOptions.selected">
        <label class="clickable ml-2 text-center" :for="option.text"
            :class="{
              'font-weight-normal': index > 0
            }">
          {{option.text}}
        </label>
      </div>
    </div>


    <div class="mt-3">
      <profile-update-profile
          v-if="mProfileOptions.isProfile()"
          :squad-ext="mSquadExt"
          :member="mMember"
          :swimmer="mSwimmer">
      </profile-update-profile>
      <div v-if="mProfileOptions.isGoalSettings()">
        <profile-update-goal-target-time
            v-if="mGoalSettingsOptions.isTargetTime()"
            :squad-ext="mSquadExt"
            :member="mMember"
            :swimmer="mSwimmer"
            :restricted-access="restrictedAccess"
        >

        </profile-update-goal-target-time>
        <profile-update-goal-memo
            v-if="mGoalSettingsOptions.isMemo()"
            :squad-ext="mSquadExt"
            :member="mMember"
            :swimmer="mSwimmer"
            :restricted-access="restrictedAccess"
        >

        </profile-update-goal-memo>
      </div>
    </div>
  </div>
</template>
<script>

import ProfileUpdateProfile from "@/views/content/components/profile_update_profile";
import ProfileUpdateGoalTargetTime from "@/views/content/components/profile_update_goal_target_time";
import ProfileUpdateGoalMemo from "@/views/content/components/profile_update_goal_memo";
import SeparatorLine from "@/components/separator_line";
import model from "@/model";
import logger from "@/util/logger"

const LOG_TAG = 'profile_profile';

export default {
  name: LOG_TAG,
  components: {
    ProfileUpdateProfile,
    ProfileUpdateGoalTargetTime,
    ProfileUpdateGoalMemo,
    SeparatorLine
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    member: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    restrictedAccess: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data () {
    return {
      mSquadExt: model.squadResponseExt(),
      mMember: model.memberResponse(),
      mSwimmer: model.memberResponse(),
      mProfileOptions: {
        options: [
          {text: 'Profile', value: 'profile'},
          {text: 'Goal Settings', value: 'goal_settings'},
        ],
        selected: 'profile',
        isProfile() {
          return this.selected === this.options[0].value;
        },
        isGoalSettings() {
          return this.selected === this.options[1].value;
        },

      },
      mGoalSettingsOptions: {
        options: [
          {text: 'Target time', value: 'target_time'},
          {text: 'Memo', value: 'memo'},
        ],
        selected: 'target_time',
        isTargetTime() {
          return this.selected === this.options[0].value;
        },
        isMemo() {
          return this.selected === this.options[1].value;
        }
      }
    }
  },
  computed: {
  },
  watch: {
    squadExt: function onChange() {
      const vm = this;
      vm.refresh();
    },
    member: function onChange() {
      const vm = this;
      vm.refresh();
    },
    swimmer: function onChange() {
      const vm = this;
      vm.refresh();
    },
  },
  mounted() {
    const vm = this;
    vm.refresh();
  },
  methods: {
    refresh() {
      logger.d(LOG_TAG, 'refresh');
      const vm = this;
      vm.mSquadExt = vm.squadExt;
      vm.mMember = vm.member;
      vm.mSwimmer = vm.swimmer;
    },
    onClickProfileOption(option) {
      const vm = this;
      vm.mProfileOptions.selected = option;
    }
  }
}
</script>

<style scoped>
</style>
