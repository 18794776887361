<template>
  <div v-if="mSquadExt.isValid()" class="mt-1">

    <!-- delete schedule -->
    <b-modal centered title="Delete schedule?" id="delete-schedule-modal">
      <p class="my-4">
        Remove {{ mRemoveSchedule.schedule.getTrainingSet().getDescription() }} from {{ mRemoveSchedule.schedule.formattedDayOfWeek() }}({{mRemoveSchedule.schedule.getStartTime()}}) ?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" @click="onConfirmDeleteSchedule()">
          Remove
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!--add schedule -->
    <b-modal centered title="Add Schedule" id="add-schedule-modal">
      <form ref="form" @submit.stop.prevent="onConfirmAddSchedule()">
        <b-form-group label="Day of week:" label-for="dayofweek-select" class="my-input-label">
          <b-form-select id="dayofweek-select"
                         v-model="mNewSchedule.dayOfWeekOptions.selected"
                         :options="mNewSchedule.dayOfWeekOptions.options">

          </b-form-select>
        </b-form-group>
        <!-- start time -->
        <b-form-group label="Start time:" label-for="datetime-start-select" class="my-input-label">
          <b-form-timepicker id="datetime-start-select" minutes-step="5" v-model="mNewSchedule.selectedStartTime"></b-form-timepicker>
          <label v-if="mNewSchedule.selectedStartTimeError" style="font-size: 0.75em; color: var(--indicator-red)">* Time slot not available</label>
        </b-form-group>
        <!-- duration -->
        <b-form-group label="Duration (Minutes):" label-for="duration-select" class="my-input-label">
          <b-form-select id="duration-select" v-model="mNewSchedule.duration.selected" :options="mNewSchedule.duration.options"></b-form-select>
        </b-form-group>
        <!-- select details -->
        <b-form-group label="Training:" label-for="training-select" class="my-input-label">
          <b-form-select id="training-select" v-model="mNewSchedule.trainingSets.selected" :options="mNewSchedule.trainingSets.options">
          </b-form-select>

          <div v-if="mNewSchedule.trainingSets.selected === 'create'">
            <training-set-card
              :dark="true"
              :_on-click-cb="onCreateTrainingSet">
            </training-set-card>
          </div>

        </b-form-group>

        <!-- enter location -->
        <b-form-group label="Location:" label-for="location-input" class="my-input-label">
          <b-form-input id="location-input" placeholder="Enter location" v-model="mNewSchedule.trainingLocation"></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm" @click="onConfirmAddSchedule()"
                  :disabled="!mNewSchedule.isValid">
          Create
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>


    <!-- show schedule -->
    <b-modal centered id="show-schedule-modal">
      <template #modal-title>
        {{ mSelectedSchedule.selected.formattedDayOfWeek()}} ({{mSelectedSchedule.selected.getStartTime()}})
      </template>
      <h5 class="font-weight-bold">{{mSelectedSchedule.selected.getTrainingSet().getDescription()}}</h5>
      <div class="mt-3"></div>
      <div v-if="disableEdit">
        <div style="font-size: 0.9em; text-align: right">{{mSelectedSchedule.selected.getLocation()}}
          <span style="font-weight: bold;">({{mSelectedSchedule.selected.formattedDuration()}})</span>
        </div>
      </div>
      <div v-else>
        <!-- enter training title -->
        <b-form-group label="Training title:" label-for="training-input" class="my-input-label">
          <b-form-input id="training-input" placeholder="Enter title" v-model="mSelectedSchedule.editTitle"></b-form-input>
        </b-form-group>
        <!-- start time -->
        <b-form-group label="Start time:" label-for="datetime-start-select" class="my-input-label">
          <b-form-timepicker id="datetime-start-select" minutes-step="5" v-model="mSelectedSchedule.editStartTime"></b-form-timepicker>
        </b-form-group>
        <!-- duration -->
        <b-form-group label="Duration (Minutes):" label-for="duration-select" class="my-input-label">
          <b-form-select id="duration-select" v-model="mSelectedSchedule.editDuration" :options="mNewSchedule.duration.options"></b-form-select>
        </b-form-group>
        <!-- enter location -->
        <b-form-group label="Location:" label-for="location-input" class="my-input-label">
          <b-form-input id="location-input" placeholder="Enter location" v-model="mSelectedSchedule.editLocation"></b-form-input>
        </b-form-group>
      </div>
      <!-- schedule details -->
      <div>
        <div v-if="disableEdit">
          <separator-line></separator-line>
          <p class="mt-3" style="white-space: pre-line" v-html="mSelectedSchedule.selected.getTrainingSet().getDetailsLinkify('black')">
          </p>
        </div>
        <div v-else>
          <label class="my-input-label">Training details:</label>
          <textarea
              rows="8"
              class="form-control"
              placeholder="Enter message body"
              v-model="mSelectedSchedule.editDetails">
          </textarea>
        </div>
      </div>
      <template v-if="!disableEdit" #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm"
                  :disabled="!mSelectedSchedule.isUpdated"
                  @click="onUpdateSchedule()">
          Update
        </b-button>
        <b-button class="my-button-cancel"
                  @click="cancel()">
          Cancel
        </b-button>
      </template>
      <template v-else #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-cancel"
                  @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal centered id="show-off-days-modal">
      <template #modal-title>
        Add off-days
      </template>
      <div class="d-flex flex-row">
        <div class="flex-grow-1">
          <label class="my-input-label">Start date:</label>
          <div>
            <button
                class="btn btn-outline-primary my-box-decoration-dark"
                style="width: 100%; text-align: left"
                v-on:click.prevent="onOpenDateSelector('start')">
              <font-awesome-icon
                  icon="calendar-alt"
                  size="sm"
                  class="label clickable"
              />
              <span class="ml-3" style="font-size: 0.85em">{{ mOffDays.selectedInfo.formattedStartDate() }}</span>
            </button>
          </div>

        </div>
        <div class="ml-1 flex-grow-1">
          <label class="my-input-label">End date:</label>
          <div>
            <button
                class="btn btn-outline-primary my-box-decoration-dark"
                style="width: 100%; text-align: left"
                v-on:click.prevent="onOpenDateSelector('end')">
              <font-awesome-icon
                  icon="calendar-alt"
                  size="sm"
                  class="label clickable"
              />
              <span class="ml-3" style="font-size: 0.85em">{{ mOffDays.selectedInfo.formattedEndDate() }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <label class="my-input-label">Details:</label>
        <textarea
            rows="1"
            class="form-control"
            placeholder="Enter description"
            v-model="mOffDays.description">
          </textarea>
      </div>
      <div class="mt-3 d-flex flex-row justify-content-between">
        <div class="d-flex flex-row justify-content-start">
          <input class="ml-2" type="checkbox" id="off_day_repeat" v-model="mOffDays.repeat">
          <label class="ml-1" style="font-size: 0.65em" for="off_day_repeat" >*Repeat yearly</label><br>
        </div>

        <b-button class="my-button-confirm"
                  style="min-width: 4.3rem"
                  :disabled="!mOffDays.isValid"
                  v-on:click.stop="onConfirmAddOffDays()">
          Add
        </b-button>
      </div>

      <!-- off days history -->
      <div v-if="mOffDays.offDays.length > 0"
           class="mt-3" style="width: 100%; overflow-y: auto;">
        <separator-line class="mt-1 mb-2"></separator-line>

        <div v-for="(offDays, index) in mOffDays.offDays" :key="index"
             class="my-box my-box-decoration-dark p-1 ml-1 mr-1 mb-1"
             style="min-width: 8rem; font-size: 0.75em">

          <div class="d-flex flex-row justify-content-between mr-1">
            <div class="ml-1">
              <div class="mt-1 ml-1" style="font-size: 0.85em;">
                <font-awesome-icon
                    v-if="offDays.repeat"
                    icon="redo"
                    size="sm"
                    class="label font-weight-bold mr-1"
                />
                {{ offDays.formattedDates() }}
              </div>
              <div class="mt-1 ml-1" style="font-size: 0.85em; font-weight: bold;"> {{ offDays.getDescription() }}</div>
            </div>
            <div class="text-right">
              <font-awesome-icon
                  icon="times-circle"
                  size="sm"
                  class="clickable my-btn-warning"
                  v-on:click.stop="onConfirmRemoveOffDays(offDays)"
              />
            </div>
          </div>

        </div>
      </div>

      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-confirm"
                  @click="ok()">
          Close
        </b-button>

      </template>

    </b-modal>

    <div v-if="!disableEdit" class="my-text-primary d-flex flex-row justify-content-start mt-3 pl-1 pr-1" style="font-size: 0.85em">
      <!-- add schedule -->
      <div v-if="isAdmin || isCoach" class="clickable d-flex flex-row" v-on:click.prevent="onAddSchedule()">
        <font-awesome-icon
            icon="plus-circle"
            size="sm"
            class="label align-self-center"/>
        <span class="ml-1" style="text-transform: capitalize;">Add schedule</span>
      </div>
    </div>
    <div>
      <div v-if="disableEdit">
        <div v-if="mOffDays.offDays.length > 0" class="my-text-primary d-flex flex-row justify-content-start mt-3 pl-1 pr-1" style="font-size: 0.85em">
          <div class="clickable d-flex flex-row" v-on:click.prevent="onShowOffDays()">
            <font-awesome-icon
                icon="calendar-alt"
                size="sm"
                class="label align-self-center"/>
            <span class="ml-1" style="text-transform: capitalize;">Scheduled off-days</span>
          </div>
        </div>
      </div>
      <div v-else class="my-text-primary d-flex flex-row justify-content-start mt-1 pl-1 pr-1" style="font-size: 0.85em">
        <!-- add holiday -->
        <div v-if="isAdmin || isCoach" class="clickable d-flex flex-row" v-on:click.prevent="onAddOffDays()">
          <font-awesome-icon
              icon="plus-circle"
              size="sm"
              class="label align-self-center"/>
          <span class="ml-1" style="text-transform: capitalize;">Schedule off-days</span>
        </div>
        <div v-if="mOffDays.offDays.length > 0" class="ml-2" v-on:click.prevent="onShowOffDays()">
          <font-awesome-icon
              icon="calendar-alt"
              size="sm"
              class="clickable label align-self-center"
          />
        </div>
      </div>
    </div>

    <!-- weekly schedule -->
    <separator-line class="mt-1"></separator-line>
    <div class="d-flex flex-row justify-content-start mt-2 pb-4" style="min-height: 3rem; overflow-x: auto;">
      <day-training-set-card v-for="(dof, index) in mNewSchedule.dayOfWeekOptions.options"
                             :disable-edit="disableEdit"
                             :day-of-week="dof.value"
                             :schedules="mSchedules"
                             :_on-click-cb="onClickSchedule"
                             :_on-delete-cb="onDeleteSchedule"
                             :key="index">
      </day-training-set-card>
    </div>


  </div>
</template>

<script>

import SeparatorLine from "@/components/separator_line"
import DayTrainingSetCard from "@/views/content/components/day_training_set_card"
import TrainingSetCard from "@/views/content/components/training_set_card"
import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger"
import MySquadBloc from "@/bloc/mysquad_bloc";

const LOG_TAG = 'squad_schedules';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
    DayTrainingSetCard,
    TrainingSetCard
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      mLoading: false,
      mSquadExt: model.squadResponseExt(),
      mSchedules: [],

      mSelectedSchedule: {
        selected: model.trainingScheduleInfo(),
        editTitle: '',
        editDetails: '',
        editLocation: '',
        editDuration: 0,
        editStartTime: '05:00:00',
        isUpdated: false,
        setSelected(value) {
          this.selected = value;
          this.editTitle = value.getTrainingSet().getDescription();
          this.editDetails = value.getTrainingSet().getDetails();
          this.editDuration = value.getDurationInMinutes();
          this.editLocation = value.getLocation();
          this.editStartTime = value.getStartTimeInFull();
          this.isUpdated = false;
        },
        validate() {
          const isChangeTitle = this.editTitle !== this.selected.getTrainingSet().getDescription();
          const isChangeDetails = this.editDetails !== this.selected.getTrainingSet().getDetails();
          const isChangeDuration = this.editDuration !== this.selected.getDurationInMinutes();
          const isChangeLocation = this.editLocation !== this.selected.getLocation();
          const isChangeStartTime = this.editStartTime !== this.selected.getStartTimeInFull();
          this.isUpdated = isChangeTitle || isChangeDetails || isChangeDuration || isChangeLocation || isChangeStartTime;
        },
        applyChange() {
          const trainingSet = this.selected.getTrainingSet();
          trainingSet
              .setDescription(this.editTitle)
              .setDetails(this.editDetails);
          this.selected
              .setStartTime(this.editStartTime.substring(0, 5))
              .setDuration(this.editDuration * 60 * 1000)
              .setLocation(this.editLocation);
        },
      },
      mNewSchedule: {
        dayOfWeekOptions: {
          options: [
            {text: 'Select day of week', value: -1},
            {text: 'Monday', value: 1},
            {text: 'Tuesday', value: 2},
            {text: 'Wednesday', value: 3},
            {text: 'Thursday', value: 4},
            {text: 'Friday', value: 5},
            {text: 'Saturday', value: 6},
            {text: 'Sunday', value: 7},
          ],
          selected: -1,
        },
        duration: {
          options: [
            {text: '15', value: 15},
            {text: '30', value: 30},
            {text: '45', value: 45},
            {text: '55', value: 55},
            {text: '60', value: 60},
            {text: '75', value: 75},
            {text: '90 (1.5hr)', value: 90},
            {text: '105', value: 105},
            {text: '120 (2hr)', value: 120},
            {text: '150 (2.5hr)', value: 150},
          ],
          selected: 60,
        },
        trainingSets: {
          options: [
            {text: 'Select training', value: 'select'},
            {text: 'Create new training', value: 'create'}
          ],
          selected: 'select',
        },
        selectedStartTime: '05:00:00',
        selectedStartTimeError: false,
        trainingLocation: '',

        isValid: false,

        setTrainingSets(newSets) {
          const newOptions = [
            {text: 'Select training', value: 'select'},
          ];
          newSets.forEach((set) => {
            newOptions.push({
              text: set.getDescription(),
              value: set.getTrainingSetId(),
              data: set
            })
          });
          newOptions.push({text: 'Create new training', value: 'create'});
          logger.d(LOG_TAG, 'setTrainingSets', newSets.length, newOptions.length);
          this.trainingSets.options = newOptions;
          this.trainingSets.selected = 'select';
        },
        isSameTimeSlot(other) {
          return this.dayOfWeekOptions.selected === other.getDayOfWeek() &&
              this.selectedStartTime.includes(other.getStartTime());
        },
        toJson() {
          return {
            dayOfWeek: this.dayOfWeekOptions.selected,
            startTime: this.selectedStartTime.substring(0, 5),
            duration: this.duration.selected * 60 * 1000,
            trainingSetId: this.trainingSets.selected,
            location: this.trainingLocation
          };
        },
        clear() {
          this.dayOfWeekOptions.selected = -1;
          this.selectedStartTime = '05:00:00';
          this.duration.selected = 60;
          this.trainingSets.selected = 'select';
          this.trainingLocation = '';

          this.isValid = false;
          this.selectedStartTimeError = false;
        }
      },
      mRemoveSchedule: {
        schedule: model.trainingScheduleInfo(),
        setSchedule(value) {
          this.schedule = value;
        },
        isSameSchedule(other) {
          return this.schedule.getDayOfWeek() === other.getDayOfWeek() &&
              this.schedule.getStartTime() === other.getStartTime() &&
              this.schedule.getTrainingSetId() === other.getTrainingSetId();
        }
      },
      mOffDays: {
        selectedType: null,
        selectedStart: null,
        selectedEnd: null,
        description: '',
        repeat: false,
        selectedInfo: model.offDaysInfo(),
        offDays: [],
        isValid: false,
        setOffDays(value) {
          this.offDays = value;
        },
        setSelected(date) {
          if (this.selectedType === 'start') {
            this.selectedStart = date;
            this.selectedInfo.setStartDate(date);
          } else if (this.selectedType === 'end') {
            this.selectedEnd = date;
            this.selectedInfo.setEndDate(date);
          }
        },
        setDescription(value) {
          this.description = value;
        },
        setRepeat(value) {
          this.repeat = value;
        },
        addOffDays() {
          const offDaysInfo = this.selectedInfo
            .setDescription(this.description)
            .setRepeat(this.repeat);
          this.offDays.push(offDaysInfo);
          return this.offDays;
        },
        removeOffDays(value) {
          this.offDays.splice(this.offDays.findIndex((offDay) => offDay.isSame(value)), 1);
          return this.offDays;
        },
        validate() {
          this.isValid = this.selectedInfo.getStart() > 0 && this.selectedInfo.getEnd() > 0 && this.description.length > 0;
        },
        clear() {
          this.selectedType = null;
          this.selectedStart = null;
          this.selectedEnd = null;
          this.description = '';
          this.repeat = false;
          this.selectedInfo = model.offDaysInfo();
          this.isValid = false;
        }
      }
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    storeTrainingSets() {
      return this.$store.state.trainingSets;
    },
    storeSquadSet() {
      return this.$store.state.squadsSet.set;
    },
    storeSquadTimestamp() {
      return this.$store.state.squadsSet.timestamp;
    },
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    /*isValidNewSchedule() {
      const valid = this.mNewSchedule.dayOfWeekOptions.selected &&
          this.mNewSchedule.selectedStartTime.length > 0 &&
          this.mNewSchedule.duration.selected &&
          this.mNewSchedule.trainingSets.selected &&
          this.mNewSchedule.trainingLocation.length > 0;

      logger.d(LOG_TAG, 'computed:isValidNewSchedule', valid);
      return valid;
    }*/
  },
  watch: {
    squadExt: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:squadExt', value);
      vm.refresh(vm.squadExt);
    },
    storeSquadTimestamp: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:storeSquadTimestamp', value);
      vm.onNotifyUpdateSquad(vm.squadExt);
    },
    storeTrainingSets: function onChange(value) {
      const vm = this;
      vm.mNewSchedule.setTrainingSets(value);
    },
    storeDatePicker: function onChange(value) {
      const vm = this;
      if (value.date) {
        logger.d(LOG_TAG, 'watch:storeDatePicker', value.date);
        vm.mOffDays.setSelected(value.date);

      }
      //vm.mCalendar.setDate(value.date);
      //vm.refresh(vm.squadExt, false);
    },
    'mNewSchedule.dayOfWeekOptions.selected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:dayOfWeekOptions', value);
      vm.validateNewSchedule();
    },
    'mNewSchedule.selectedStartTime': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:selectedStartTime', value);
      vm.validateNewSchedule();
    },
    'mNewSchedule.duration.selected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:duration', value);
      vm.validateNewSchedule();
    },
    'mNewSchedule.trainingSets.selected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:trainingSets', value);
      vm.validateNewSchedule();
    },
    'mNewSchedule.trainingLocation': function onChange() {
      const vm = this;
      vm.validateNewSchedule();
    },
    'mSelectedSchedule.editDetails': function onChange() {
      const vm = this;
      vm.mSelectedSchedule.validate();
    },
    'mSelectedSchedule.editDuration': function onChange() {
      const vm = this;
      vm.mSelectedSchedule.validate();
    },
    'mSelectedSchedule.editLocation': function onChange() {
      const vm = this;
      vm.mSelectedSchedule.validate();
    },
    'mSelectedSchedule.editStartTime': function onChange() {
      const vm = this;
      vm.mSelectedSchedule.validate();
    },
    'mSelectedSchedule.editTitle': function onChange() {
      const vm = this;
      vm.mSelectedSchedule.validate();
    },
    'mOffDays.selectedStart': function onChnage() {
      const vm = this;
      vm.mOffDays.validate();
    },
    'mOffDays.selectedEnd': function onChnage() {
      const vm = this;
      vm.mOffDays.validate();
    },
    'mOffDays.description': function onChnage() {
      const vm = this;
      vm.mOffDays.validate();
    }
  },
  mounted() {
    logger.d(LOG_TAG, 'mounted');
    const vm = this;
    vm.refresh(vm.squadExt);
  },
  methods: {
    validateNewSchedule() {
      const vm = this;
      const valid = vm.mNewSchedule.dayOfWeekOptions.selected !== -1 &&
          vm.mNewSchedule.selectedStartTime.length > 0 &&
          vm.mNewSchedule.duration.selected !== -1 &&
          (vm.mNewSchedule.trainingSets.selected !== 'select' && vm.mNewSchedule.trainingSets.selected !== 'create') &&
          vm.mNewSchedule.trainingLocation.length > 0;

      logger.d(LOG_TAG, 'validateNewSchedule', valid);
      vm.mNewSchedule.isValid = valid;
    },
    onNotifyUpdateSquad(selectedSquad) {
      const vm = this;
      let squadInfo = vm.storeSquadSet[selectedSquad.getSquadId()];
      if (squadInfo) {
        logger.d(LOG_TAG, 'onNotifyUpdateSquad', squadInfo.getSquadId());
        vm.mNewSchedule.setTrainingSets(vm.storeTrainingSets);
        vm.mSquadExt = model.squadResponseExt(squadInfo);
        vm.mSchedules = vm.mSquadExt.getSchedule();
        vm.mOffDays.setOffDays(vm.mSquadExt.getExclusion());
      }
    },
    refresh(selectedSquad, force) {
      const vm = this;
      if (vm.mSquadExt.getSquadId() !== selectedSquad.getSquadId() || force) {
        vm.mLoading = vm.loading;
        vm.mSquadExt = selectedSquad;
        vm.mSchedules = vm.mSquadExt.getSchedule();

        MySquadBloc.syncSquad(
            vm.mSquadExt.getClubId(),
            vm.mSquadExt.getSquadId(),
            false,
            () => {
              vm.mLoading = false;
              vm.$store.commit('setLoading', false);
            },
            (error) => {
              logger.w(LOG_TAG, 'syncSquad', error);

              vm.mLoading = false;
              vm.$store.commit('setLoading', false);
            }
        )
      }
    },
    onCreateTrainingSet(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onCreateTrainingSet', data);
      vm.$store.commit('setLoading', true);
      MySquadBloc.addNewTrainingSet(
          vm.mSquadExt.getClubId(),
          data,
          (dataList) => {
            // success, select the 1st by default
            setTimeout(function(){
              vm.mNewSchedule.trainingSets.selected = dataList[0].getTrainingSetId()
              vm.$store.commit('setLoading', false);
            }, 330);

          },
          () => {
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onAddSchedule() {
      const vm = this;
      logger.d(LOG_TAG, 'onAddSchedule');
      vm.mNewSchedule.clear();
      vm.mNewSchedule.setTrainingSets(vm.storeTrainingSets);
      vm.$bvModal.show('add-schedule-modal');
    },
    onConfirmAddSchedule() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmAddSchedule', vm.mNewSchedule.isValid);
      if (vm.mNewSchedule.isValid) {
        // is start time taken?
        const matchingSchedule = vm.mSquadExt.getSchedule().filter((schedule) => {
          let match = vm.mNewSchedule.isSameTimeSlot(schedule);
          return match;
        })
        if (matchingSchedule.length > 0) {
          vm.mNewSchedule.selectedStartTimeError = true;
        } else {
          logger.d(LOG_TAG, 'onConfirmAddSchedule', vm.mNewSchedule.toJson());
          vm.$store.commit('setLoading', true);
          vm.$bvModal.hide('add-schedule-modal');
          const payload = vm.mSquadExt.getSchedule().concat(vm.mNewSchedule.toJson());
          webapi.apiService.updateTrainingSchedule(
              vm.mSquadExt.getClubId(),
              vm.mSquadExt.getSquadId(),
              payload,
              () => {
                vm.refresh(vm.mSquadExt, true);
              },
              () => {
                vm.$store.commit('setLoading', false);
              }
          )
        }
      }
    },
    onAddOffDays() {
      const vm = this;
      logger.d(LOG_TAG, 'onAddOffDays');
      vm.mOffDays.clear();
      vm.$bvModal.show('show-off-days-modal');
    },
    onConfirmAddOffDays() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateSquadOffDays(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mOffDays.addOffDays(),
          () => {
            vm.mOffDays.clear();
            vm.mLoading = false;
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            logger.w(LOG_TAG, 'syncSquad', error);

            vm.mLoading = false;
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onConfirmRemoveOffDays(value) {
      const vm = this;
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateSquadOffDays(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mOffDays.removeOffDays(value),
          () => {
            vm.mOffDays.clear();
            vm.mLoading = false;
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            logger.w(LOG_TAG, 'syncSquad', error);

            vm.mLoading = false;
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onShowOffDays() {
      const vm = this;
      vm.$store.commit('setDatePicker', {
        enabled: true,
        viewOnly: true,
        selectedDates: vm.mOffDays.offDays
      });
    },
    onOpenDateSelector(type /*start, end*/) {
      const vm = this;
      vm.mOffDays.selectedType = type;
      if (type === 'end' && vm.mOffDays.selectedStart) {
        vm.$store.commit('setDatePicker', {
          enabled: true,
          byDay: true,
          minDate: vm.mOffDays.selectedStart
        });
      } else {
        vm.$store.commit('setDatePicker', {
          enabled: true,
          byDay: true,
        });
      }

    },
    onClickSchedule(selected) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickSchedule', selected, selected.formattedDayOfWeek());
      vm.mSelectedSchedule.setSelected(selected);
      vm.$bvModal.show('show-schedule-modal');
    },
    onDeleteSchedule(selected) {
      const vm = this;
      logger.d(LOG_TAG, 'onDeleteSchedule', selected);
      vm.mRemoveSchedule.setSchedule(selected);

      vm.$bvModal.show('delete-schedule-modal');
    },
    onConfirmDeleteSchedule() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      vm.$bvModal.hide('delete-schedule-modal');

      logger.d(LOG_TAG, 'onConfirmDeleteSchedule', 'remove',
          vm.mRemoveSchedule.schedule.getDayOfWeek(),
          vm.mRemoveSchedule.schedule.getStartTime(),
          vm.mRemoveSchedule.schedule.getTrainingSetId());
      const newSchedules = vm.mSquadExt.getSchedule().filter((schedule) => {
        let match = vm.mRemoveSchedule.isSameSchedule(schedule);
        return !match;
      })
      logger.d(LOG_TAG, 'onConfirmDeleteSchedule', newSchedules.length);
      webapi.apiService.updateTrainingSchedule(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          newSchedules,
          () => {
            vm.refresh(vm.mSquadExt, true);
          },
          () => {
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onUpdateSchedule() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      vm.$bvModal.hide('show-schedule-modal');

      const index = vm.mSquadExt.getSchedule().indexOf(vm.mSelectedSchedule.selected);
      logger.d(LOG_TAG, 'onUpdateSchedule', index);
      vm.mSelectedSchedule.applyChange();

      webapi.apiService.updateTrainingSchedule(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mSquadExt.getSchedule(),
          () => {
            vm.refresh(vm.mSquadExt, true);
          },
          () => {
            vm.$store.commit('setLoading', false);
          }
      )
    },
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
