//import firebase from 'firebase/app';
import 'firebase/storage';

import model from "@/model"
import logger from "@/util/logger"
import dateTimeHelper from "@/util/datetime_helper"

const LOG_TAG = 'mysquad_storage';
const ROOT = 'mysquad/';
const PATH_ATTACHMENTS = 'attachments/'

export default {
  getStorage(path) {
    const fullPath = ROOT + path;
    logger.d(LOG_TAG, 'getStorage', fullPath);
    return window.firebaseStorageRef.child(fullPath);
  },
  uploadAttachment(clubId, squadId, sessionId, file, onSuccess, onError) {
    const fileName = file.name;
    const timeNow = dateTimeHelper.epocTimeNow();
    let path =
      PATH_ATTACHMENTS +
      clubId.toString() + '/' + squadId.toString() + '/' +
      timeNow + '.' + fileName;

    this.getStorage(path).put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadUrl) => {
        logger.d(LOG_TAG, 'uploadAttachment', downloadUrl);
        onSuccess(model.attachmentInfo()
          .setFileName(fileName)
          .setDownloadUrl(downloadUrl)
          .setTimestamp(timeNow)
        );
      }).catch((error) => {
        onError(error);
      })
    });
  }
};
