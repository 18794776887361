import model from "@/model"
import webapi from "@/webapi"
// import dateTimeHelper from "@/util/datetime_helper"
import storageHelper from "@/util/local_storage_helper"
import logger from "@/util/logger"

const LOG_TAG = 'mysquad_bloc';
export default {
  logout() {
    if (storageHelper.isRememberMe()) {
      storageHelper.setAuth(
        storageHelper.getAuth().logout()
      )
    }
    window.store.commit('logout', true);

  },
  validateInvitation(code, reconcileId, isCoach, onSuccess, onError) {
    logger.d(LOG_TAG, 'code', reconcileId);
    webapi.apiService.validateInvitation(code,
      (response) => {
        const codeData = model.inviteCodeResponse(response);
        logger.d(LOG_TAG, 'validateInvitation', 'validateInvitation', codeData);
        if (reconcileId) {
          webapi.apiService.validateReconcileId(reconcileId, isCoach,
            (response) => {
              const reconcileData = model.inviteCodeResponse(response);
              logger.d(LOG_TAG, 'validateInvitation', 'validateReconcileId', reconcileData);
              onSuccess(codeData, reconcileData);
            },
            (error) => {
              onError(error);
            });
        } else {
          onSuccess(codeData, null);
        }
      },
      (error) => {
        onError(error);
      });
  },
  createClub(clubId, squadLimit, squadSize, onSuccess, onError) {
    webapi.apiService.createClub(
      {
        clubId: clubId,
        clubAdmin: [],
        squadLimit: squadLimit, // max 5
        squadSize: squadSize // max 30
      },
      () => {
        this.syncAllClubs(
          true,
          (unclaimed, claimed) => {
            onSuccess(unclaimed, claimed);
          },
          (error) => {
            onError(error);
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'createClub', error);
        onError(error);
      }
    )
  },
  syncAllClubs(force, onSuccess, onError) {

    if (!force) {
      const cacheSet = window.store.state.allClubs;
      const hasCache = cacheSet.unclaimed.length > 0 || cacheSet.claimed.length > 0;
      if (hasCache) {
        onSuccess(cacheSet.unclaimed, cacheSet.claimed);
        return;
      }
    }
    webapi.apiService.getClubs(
      (response) => {
        logger.d(LOG_TAG, 'syncAllClubs', response);
        const unclaimedClubs = [];
        const claimedClubs = [];
        response.forEach((data) => {
          const clubInfo = model.clubResponse(data);
          if (clubInfo.isClaimed()) {
            claimedClubs.push(clubInfo)
          } else {
            unclaimedClubs.push(clubInfo)
          }
        })
        unclaimedClubs.sort((a, b) => a.getName().localeCompare(b.getName()));
        claimedClubs.sort((a, b) => a.getName().localeCompare(b.getName()));

        window.store.commit('setAllClubs', {
          unclaimed: unclaimedClubs,
          claimed: claimedClubs
        });
        onSuccess(unclaimedClubs, claimedClubs);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncAllClubs', error);
        onError(error);

      }
    )
  },
  addClubAdmin(clubId, clubName, adminInfo, onSuccess, onError) {
    adminInfo.clubName = clubName;
    webapi.apiService.addClubAdmin(
      clubId,
      adminInfo,
      () => {
        this.syncClubAdmins(
          clubId,
          (clubAdmins) => {
            onSuccess(clubAdmins);
          },
          (error) => {
            onError(error);
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'addClubAdmin', error);
        onError(error);

      }
    )
  },
  deleteClubAdmin(clubId, userId, onSuccess, onError) {
    webapi.apiService.removeClubAdmin(
      clubId,
      userId,
      () => {
        this.syncClubAdmins(
          clubId,
          (clubAdmins) => {
            onSuccess(clubAdmins);
          },
          (error) => {
            onError(error);
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'deleteClubAdmin', error);
        onError(error);

      }
    )
  },
  syncClubAdmins(clubId, onSuccess, onError) {
    webapi.apiService.getClubAdmin(
      clubId,
      (response) => {
        let clubAdmins = [];
        if (response) {
          clubAdmins = response.map((data) => model.memberResponse(data));
        }
        logger.d(LOG_TAG, 'syncClubAdmins', clubAdmins.length);
        onSuccess(clubAdmins);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncClubAdmins', error);
        onError(error);

      }
    )
  },
  syncClub(clubId, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncClub');

    // trigger cache first
    window.store.commit('notifyClubChange', true);
    webapi.apiService.getClub(
      clubId,
      (response) => {
        const clubResponse = model.clubInfo(response[0]);
        logger.d(LOG_TAG, 'getClub', clubResponse.getClubId(), clubResponse.getClubName(),
          'squads', clubResponse.getSquads().length);

        window.store.commit('updateClub', clubResponse);
        onSuccess();
      },
      (error) => {
        logger.w(LOG_TAG, 'getClub', error);

        onError();
      }
    )
  },
  syncSquad(clubId, squadId, force, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncSquad');

    // trigger cache first
    if (!force) {
      window.store.commit('notifySquadChange', true);
    }
    webapi.apiService.getSquad(
      clubId,
      squadId,
      (response) => {
        const squadResponse = model.squadResponse(response);
        logger.d(LOG_TAG, 'getSquad', squadResponse.getSquadId(), squadResponse.getSquadName(),
          'members', squadResponse.getMembers().length);

        window.store.commit('updateSquad', squadResponse);
        onSuccess();
      },
      (error) => {
        logger.w(LOG_TAG, 'getSquad', error);

        onError();
      }
    )
  },
  syncTrainingSets(clubId, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncTrainingSets');
    webapi.apiService.getTrainingSets(
      clubId,
      (response) => {
        logger.d(LOG_TAG, 'refresh', response.length);
        const dataList = response.map((data) => model.trainingSetInfo(data));
        dataList.sort((a, b) => b.getCreatedAt() - a.getCreatedAt());
        window.store.commit('setTrainingSets', dataList);
        onSuccess(dataList);
      },
      (error) => {
        logger.w(LOG_TAG, 'refresh', error);
        onError();
      }
    )
  },
  addNewTrainingSet(clubId, trainingSetInfo, onSuccess, onError) {
    logger.d(LOG_TAG, 'addTrainingSets');
    webapi.apiService.createTrainingSet(
      clubId,
      trainingSetInfo,
      () => {
        this.syncTrainingSets(
          clubId,
          (dataList) => {
            onSuccess(dataList);
          },
          (error) => {
            logger.w(LOG_TAG, 'refresh', error);
            onError();
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'refresh', error);
        onError();
      }
    )

  },
  syncSquadAttendance(clubId, squadId, timeFrom, timeTo, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncSquadAttendance');

    webapi.apiService.querySquadAttendance(
      clubId,
      squadId,
      timeFrom, timeTo,
      (response) => {
        const attendanceReport = response.map((attendance) => model.attendanceResponse(attendance));
        attendanceReport.sort((a, b) => a.getValidatedName().localeCompare(b.getValidatedName()));
        logger.d(LOG_TAG, 'syncSquadAttendance', attendanceReport.length);
        onSuccess(attendanceReport);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncSquadAttendance', error);
        onError();
      }
    )
  },
  syncMessages(clubId, squadId, force, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncMessages', squadId);

    const cacheSet = window.store.state.squadMessageSet.set[squadId];
    // always trigger cache first if exist
    if (cacheSet) {
      window.store.commit('notifySquadMessageChange', true);
    }

    if (!cacheSet || force) {
      webapi.apiService.getMessages(
        clubId,
        squadId,
        (response) => {
          logger.d(LOG_TAG, 'syncMessages', response);
          const messages = response.map((message) => model.notificationInfo(message));
          messages.sort((a, b) => b.getCreatedAt() - a.getCreatedAt());

          logger.d(LOG_TAG, 'syncMessages', squadId, messages.length);
          window.store.commit('updateSquadMessage', {
            squadId: squadId,
            data: messages,
          });

          onSuccess(messages);
        },
        (error) => {
          onError(error);
        }
      )
    }
  },
  syncMemberMessages(clubId, squadId, userId, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncMemberMessages', squadId);

    webapi.apiService.getMemberMessages(
      clubId,
      squadId,
      userId,
      (response) => {
        logger.d(LOG_TAG, 'syncMemberMessages', response);
        const messages = response.map((message) => model.notificationInfo(message));
        messages.sort((a, b) => b.getCreatedAt() - a.getCreatedAt());
        onSuccess(messages);
      },
      (error) => {
        onError(error);
      }
    )
  },
  syncSwimmerPerformance(clubId, squadId, memberId, distance, course, onSuccess, onError) {
    const cacheKey = model.resultInfo({
      distance: distance,
      course: course
    }).formattedKey(squadId, memberId);
    logger.d(LOG_TAG, 'syncSwimmerPerformance', cacheKey);

    // always use cache if exists
    const cacheSet = window.store.state.swimmerPerfSet.set[cacheKey];
    if (cacheSet) {
      logger.d(LOG_TAG, 'syncSwimmerPerformance', 'has cache', cacheSet.length);
      onSuccess(cacheSet);
    } else {
      webapi.apiService.querySwimmerPerformance(
        clubId,
        squadId,
        memberId,
        distance,
        course,
        (response) => {
          const resultList = response.map((result) => model.resultInfo(result));
          logger.d(LOG_TAG, 'syncSwimmerPerformance', resultList.length);
          window.store.commit('updateSwimmerPerf', {
            key: cacheKey,
            data: resultList,
          });
          onSuccess(resultList);
        },
        (error) => {
          onError(error);
        }
      )
    }


  },
  syncSquadTrainingLogs(clubId, squadId, startOfWeek, endOfWeek, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncSquadTrainingLogs', startOfWeek, endOfWeek);

    webapi.apiService.queryTrainingSessions(
      clubId,
      squadId,
      startOfWeek,
      endOfWeek,
      (response) => {
        logger.d(LOG_TAG, 'syncSquadTrainingLogs', response);
        const sessions = response.map((result) => model.trainingSessionInfo(result));
        sessions.sort((a, b) => b.getSessionTime() - a.getSessionTime());
        onSuccess(sessions);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncSquadTrainingLogs', error);
        onError();
      }
    )
  },
  updateMemberProfile(clubId, squadId, squadMemberId, name, mobile, memberId, onSuccess, onError) {
    webapi.apiService.updateMember(
      clubId,
      squadId,
      squadMemberId,
      {
        'customName': name,
        'mobile': mobile,
        'memberId': memberId ? memberId : null
      },
      (response) => {
        logger.d(LOG_TAG, 'updateMemberProfile', response);

        // refresh squad
        this.syncSquad(clubId, squadId, true, onSuccess, onError)
      },
      (error) => {
        logger.w(LOG_TAG, 'updateMemberProfile', error);
        onError(error);
      }
    )
  },
  syncMemberGoal(clubId, squadId, squadMemberId, onSuccess, onError) {
    webapi.apiService.getMemberGoal(
      clubId,
      squadId,
      squadMemberId,
      (response) => {
        logger.d(LOG_TAG, 'syncMemberGoal', response);
        const goal = model.goalInfo(response);
        onSuccess(goal);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncMemberGoal', error);
        onError(error);
      }
    )
  },
  addMemberGoalTargetTime(clubId, squadId, squadMemberId, targets, onSuccess, onError) {
    webapi.apiService.addMemberGoalTargetTime(
      clubId,
      squadId,
      squadMemberId,
      {
        'targets': targets,
      },
      (response) => {
        logger.d(LOG_TAG, 'addMemberGoalTargetTime', response);
        this.syncMemberGoal(
          clubId,
          squadId,
          squadMemberId,
          (response) => {
            onSuccess(response);
          },
          () => {
            onError();
          }
        )

      },
      (error) => {
        logger.w(LOG_TAG, 'addMemberGoalTargetTime', error);
        onError(error);
      }
    )
  },
  removeMemberGoalTargetTime(clubId, squadId, squadMemberId, distance, stroke, course, onSuccess, onError) {
    webapi.apiService.removeMemberGoalTargetTime(
      clubId,
      squadId,
      squadMemberId,
      distance, stroke, course,
      (response) => {
        logger.d(LOG_TAG, 'removeMemberTargetTime', response);
        this.syncMemberGoal(
          clubId,
          squadId,
          squadMemberId,
          (response) => {
            onSuccess(response);
          },
          () => {
            onError();
          }
        )

      },
      (error) => {
        logger.w(LOG_TAG, 'removeMemberTargetTime', error);
        onError(error);
      }
    )
  },
  updateMemberGoalText(clubId, squadId, squadMemberId, text, onSuccess, onError) {
    webapi.apiService.updateMemberGoal(
      clubId,
      squadId,
      squadMemberId,
      {
        'text': text,
      },
      (response) => {
        logger.d(LOG_TAG, 'updateMemberGoalText', response);
        this.syncMemberGoal(
          clubId,
          squadId,
          squadMemberId,
          (response) => {
            onSuccess(response);
          },
          () => {
            onError();
          }
        )

      },
      (error) => {
        logger.w(LOG_TAG, 'updateMemberGoalText', error);
        onError(error);
      }
    )
  },
  updateMemberGoalTargets(clubId, squadId, squadMemberId, targets, onSuccess, onError) {
    webapi.apiService.updateMemberGoal(
      clubId,
      squadId,
      squadMemberId,
      {
        'targets': targets,
      },
      (response) => {
        logger.d(LOG_TAG, 'updateMemberGoalTargets', response);
        this.syncMemberGoal(
          clubId,
          squadId,
          squadMemberId,
          (response) => {
            onSuccess(response);
          },
          () => {
            onError();
          }
        )

      },
      (error) => {
        logger.w(LOG_TAG, 'updateMemberGoalText', error);
        onError(error);
      }
    )
  },
  updateSessionAttendance(clubId, squadId, sessionId, absentIds, absentNames, presentIds, presentNames, onSuccess, onError) {
    logger.d(LOG_TAG, 'updateSessionAttendance');

    webapi.apiService.updateTrainingSessionNote(
      clubId,
      squadId,
      sessionId,
      {
        attendance: {
          absence: absentIds,
          absenceName: absentNames,
          presence: presentIds,
          presenceName: presentNames,
        }
      },
      (response) => {
        logger.d(LOG_TAG, 'updateSessionAttendance', response);
        const updated = model.trainingSessionInfo(response);
        onSuccess(updated);
      },
      (error) => {
        logger.w(LOG_TAG, 'updateSessionAttendance', error);
        onError(error);
      }
    )
  },
  updateSessionNotes(clubId, squadId, sessionId, notes, onSuccess, onError) {
    logger.d(LOG_TAG, 'updateSessionNotes');

    webapi.apiService.updateTrainingSessionNote(
      clubId,
      squadId,
      sessionId,
      {
        notes: notes
      },
      (response) => {
        logger.d(LOG_TAG, 'updateTrainingSessionNote', response);
        const updated = model.trainingSessionInfo(response);
        onSuccess(updated);
      },
      (error) => {
        logger.w(LOG_TAG, 'updateTrainingSessionNote', error);
        onError(error);
      }
    )
  },
  updateSessionAttachments(clubId, squadId, sessionId, attachments, onSuccess, onError) {
    logger.d(LOG_TAG, 'updateSessionAttachments');

    webapi.apiService.updateTrainingSessionAttachments(
      clubId,
      squadId,
      sessionId,
      {
        attachments: attachments
      },
      (response) => {
        logger.d(LOG_TAG, 'updateSessionAttachments', response);
        const updated = model.trainingSessionInfo(response);
        onSuccess(updated);
      },
      (error) => {
        logger.w(LOG_TAG, 'updateSessionAttachments', error);
        onError(error);
      }
    )
  },
  inviteCoach(clubId, squadId, coachInfo, onSuccess, onError) {
    logger.d(LOG_TAG, 'inviteCoach', coachInfo.isNewSignUp());

    if (coachInfo.isNewSignUp()) {
      webapi.apiService.inviteCoachNewSignUp(
        clubId, squadId, coachInfo.setUserUuid(null),
        () => {
          this.syncSquad(
            clubId, squadId, true,
            () => {
              onSuccess();
            },
            () => {
              onError();
            }
          )
        },
        () => {
          onError();
        }
      )
    } else {
      webapi.apiService.inviteCoach(
        clubId, squadId, coachInfo,
        () => {
          this.syncSquad(
            clubId, squadId, true,
            () => {
              onSuccess();
            },
            () => {
              onError();
            }
          )
        },
        () => {
          onError();
        }
      )
    }
  },
  inviteSwimmer(clubId, squadId, swimmerInfo, onSuccess, onError) {
    logger.d(LOG_TAG, 'inviteSwimmer', swimmerInfo.isNewSignUp());

    if (swimmerInfo.isNewSignUp()) {
      webapi.apiService.inviteMemberNewSignUp(
        clubId, squadId, [swimmerInfo.setUserUuid(null)],
        () => {
          this.syncSquad(
            clubId, squadId, true,
            () => {
              onSuccess();
            },
            () => {
              onError();
            }
          )
        },
        (error) => {
          logger.w(LOG_TAG, 'inviteSwimmer', error);
          onError(error);
        }
      )
    } else {
      webapi.apiService.inviteMember(
        clubId, squadId, [swimmerInfo],
        () => {
          this.syncSquad(
            clubId, squadId, true,
            () => {
              onSuccess();
            },
            () => {
              onError();
            }
          )
        },
        (error) => {
          logger.w(LOG_TAG, 'inviteSwimmer', error);
          onError(error);
        }
      )
    }
  },
  inviteSwimmerToMyTogs(clubId, squadId, name, reconcileId, email, onSuccess, onError) {
    logger.d(LOG_TAG, 'inviteSwimmerToMyTogs', email);

    webapi.apiService.requestLinkSwimmerToMyTogsAccount(
      clubId, squadId,
      [{
        'name': name,
        'email': email,
        'reconcileId': reconcileId,
      }],
      () => {
        this.syncSquad(
          clubId, squadId, true,
          () => {
            onSuccess();
          },
          () => {
            onError();
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'inviteSwimmerToMyTogs', error);
        onError(error);
      }
    )
  },
  removeCoach(clubId, squadId, uId, onSuccess, onError) {
    logger.d(LOG_TAG, 'removeCoach');

    webapi.apiService.removeCoach(
      clubId, squadId, uId,
      () => {
        this.syncSquad(
          clubId, squadId, true,
          () => {
            onSuccess();
          },
          () => {
            onError();
          }
        )
      },
      () => {
        onError();
      }
    )
  },
  removeMember(clubId, squadId, squadMemberId, onSuccess, onError) {
    logger.d(LOG_TAG, 'removeCoach');

    webapi.apiService.removeMember(
      clubId, squadId, squadMemberId,
      () => {
        this.syncSquad(
          clubId, squadId, true,
          () => {
            onSuccess();
          },
          () => {
            onError();
          }
        )
      },
      () => {
        onError();
      }
    )
  },
  addCoachNotes(clubId, squadId, squadMemberId, notes, onSuccess, onError) {
    logger.d(LOG_TAG, 'addCoachNotes');

    webapi.apiService.addCoachNotes(
      clubId, squadId, squadMemberId, {
        notes: notes
      },
      () => {
        webapi.apiService.getCoachNotes(
          clubId, squadId, squadMemberId,
          () => {
            onSuccess();
          },
          () => {
            onError();
          }
        )
      },
      () => {
        onError();
      }
    )
  },
  syncSquadMemberProfile(
    isCoach,
    clubId, squadId, memberId, userId, squadMemberId,
    onMemberResponse, onMessageResponse, onNotesResponse, onError) {
    logger.d(LOG_TAG, 'syncSquadMemberProfile');

    if (memberId) {
      webapi.apiService.getSwimmer(
        clubId, squadId, memberId,
        (memberResponse) => {
          const swimmer = model.memberResponse(memberResponse);
          if (swimmer.hasPersonalBest()) {
            swimmer.personalBest = swimmer.getPersonalBest().map((data) => model.resultInfo(data));
            swimmer.personalBest.sort((a, b) => b.getDate() - a.getDate())
          }
          onMemberResponse(swimmer);
        },
        () => {
          onError();
        });
    } else {
      onMemberResponse(model.memberResponse());
    }
    if (userId) {
      this.syncMemberMessages(
        clubId, squadId, userId,
        (messages) => {
          onMessageResponse(messages);
        },
        () => {
          onError();
        }
      )
    }
    if (isCoach && squadMemberId) {
      webapi.apiService.getCoachNotes(
        clubId, squadId, squadMemberId,
        (notesResponse) => {
          if (notesResponse && notesResponse.length > 0) {
            onNotesResponse(notesResponse[0].notes);
          } else {
            onNotesResponse('');
          }

        },
        () => {
          onError();
        }
      )
    }
  },
  updateSquadOffDays(clubId, squadId, offDays, onSuccess, onError) {
    logger.d(LOG_TAG, 'updateSquadOffDays');

    webapi.apiService.updateTrainingOffDays(
      clubId, squadId, offDays,
      () => {
        this.syncSquad(
          clubId,
          squadId,
          true,
          () => {
            onSuccess();
          },
          (error) => {
            onError(error);
          }
        )
      },
      () => {
        onError();
      }
    )
  },
};
