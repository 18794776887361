import model from "@/model";
const KEY_AUTH = 'Auth';

export default {
  setAuth(value) {
    if (value) {
      window.localStorage.setItem(KEY_AUTH, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(KEY_AUTH);
    }
  },
  getAuth() {
    let auth = model.authResponse();
    const cache = window.localStorage.getItem(KEY_AUTH);
    if (cache) {
      auth = model.authResponse(JSON.parse(cache));
    }
    return auth;
  },
  isRememberMe() {
    const cache = window.localStorage.getItem(KEY_AUTH);
    return cache != null;
  }
};
