<template>
  <div>
    <div v-if="mLoading" class="align-items-center align-content-center" style="height: 12rem">
      <loader :embedded="true" :dark="true" style="margin-top: 8rem; text-align: center"></loader>
    </div>
    <div v-else-if="mSwimmer.hasPersonalBest()">
      <b-form-group label="Select distance" label-for="distance-select" style="font-size: 0.85em">
        <b-form-select id="distance-select" v-model="mPerfOption.selectedDistance" :options="mPerfOption.distanceOptions"></b-form-select>
      </b-form-group>
      <div class="d-flex flex-row justify-content-between">
        <div class="ml-2">
          <input class="mt-auto mb-auto" type="radio" id="sc" name="sc" :value="25" v-model="mPerfOption.selectedCourse">
          <label class="ml-2 mt-auto mb-auto" style="font-size: 0.85em" for="sc">SC</label>
          <input class="ml-3 mt-auto mb-auto" type="radio" id="lc" name="lc" :value="50" v-model="mPerfOption.selectedCourse">
          <label class="ml-2 mt-auto mb-auto" style="font-size: 0.85em" for="lc">LC</label>
        </div>
        <b-button class="my-button-confirm"
                  :disabled="!mPerfOption.search.updated"
                  v-on:click.stop="onQueryPerformance()">
          Update
        </b-button>

      </div>
      <stroke-radar :datasets="mPerfs">

      </stroke-radar>
    </div>
    <div v-else>
      No Records available.
    </div>
  </div>
</template>
<script>

// import logger from "@/util/logger";
import StrokeRadar from "@/views/content/components/stroke_radar";
import Loader from "@/components/loader"
import model from "@/model";
import MySquadBloc from "@/bloc/mysquad_bloc";
import logger from "@/util/logger"

const LOG_TAG = 'performance_radar_card';

export default {
  name: LOG_TAG,
  components: {
    Loader,
    StrokeRadar
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
  },
  data () {
    return {
      mLoading: true,
      mSwimmer: model.memberResponse(),
      mPerfs: [
        {text: 'Freestyle', value: model.resultInfo()},
        {text: 'Backstroke', value: model.resultInfo()},
        {text: 'Breaststroke', value: model.resultInfo()},
        {text: 'Butterfly', value: model.resultInfo()},
        {text: 'Individual Medley', value: model.resultInfo()},
      ],
      mPerfOption: {
        //50, 100, 200, 400, 800, 1500
        distanceOptions: [
          {text: '50', value: '50'},
          {text: '100', value: '100'},
          {text: '200', value: '200'},
          {text: '400', value: '400'},
          {text: '800', value: '800'},
          {text: '1500', value: '1500'},
        ],
        selectedDistance: '100',
        courseOptions: [
          {text: 'SC', value: 25},
          {text: 'LC', value: 50},
        ],
        selectedCourse: 25,
        search: {
          distance: '100',
          course: 25,
          updated: false
        }
      }
    }
  },
  computed: {
    storeSwimmerPerfSet() {
      return this.$store.state.swimmerPerfSet.set;
    },
    storeSwimmerPerfTimestamp() {
      return this.$store.state.swimmerPerfSet.timestamp;
    },
  },
  watch: {
    storeSwimmerPerfTimestamp: function onChange() {
      const vm = this;
      vm.onNotifyUpdatePerf();
    },
    'mPerfOption.selectedDistance': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'selectedDistance', value);
      vm.validateSearch(value, vm.mPerfOption.selectedCourse);
    },
    'mPerfOption.selectedCourse': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'selectedCourse', value);
      vm.validateSearch(vm.mPerfOption.selectedDistance, value);
    }
  },
  mounted() {
    //
    const vm = this;
    vm.mSwimmer = vm.swimmer;
    if (vm.squadExt.isValid() && vm.mSwimmer.isValid()) {
      vm.refresh(vm.mPerfOption.search);
    }
  },
  methods: {
    refresh(searchOption, force) {
      const vm = this;
      vm.mLoading = true;
      setTimeout(function(){
        MySquadBloc.syncSwimmerPerformance(
            vm.squadExt.getClubId(),
            vm.squadExt.getSquadId(),
            vm.mSwimmer.getMemberId(),
            searchOption.distance,
            searchOption.course,
            (dataList) => {
              vm.mPerfs.forEach((perf) => {
                const data = dataList.find((data) => perf.text === data.getStroke());
                perf.value = data ? data : model.resultInfo();
              });
              vm.mLoading = false;
            },
            (error) => {
              logger.w(LOG_TAG, 'syncSwimmerPerformance', error);
            }
        )
      }, force ? 330 : 0);

    },
    onNotifyUpdatePerf() {
      /*const vm = this;
      let newDataSet = vm.storeSwimmerPerfSet[vm.swimmer.getMemberId()];
      if (newDataSet) {
        vm.mPerfs.forEach((perf) => {
          const dataset = newDataSet.find((dataset) => perf.text === dataset.getStroke());
          perf.value = dataset ? dataset : model.resultInfo();
        });
        vm.mLoading = false;
      }*/
    },
    validateSearch(selectedDistance, selectedCourse) {
      const vm = this;
      const update =
          selectedDistance !== vm.mPerfOption.search.distance ||
          selectedCourse !== vm.mPerfOption.search.course;
      vm.mPerfOption.search.updated = update;
      logger.d(LOG_TAG, 'validateSearch', update);
    },
    onQueryPerformance() {
      const vm = this;
      vm.mPerfOption.search.distance = vm.mPerfOption.selectedDistance;
      vm.mPerfOption.search.course = vm.mPerfOption.selectedCourse;
      vm.mPerfOption.search.updated = false;
      vm.refresh(vm.mPerfOption.search, true);
    }
  }
}
</script>

<style scoped>
</style>
