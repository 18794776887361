import datetimeHelper from "@/util/datetime_helper"
import displayHelper from "@/util/display_helper"
// import logger from "@/util/logger"

// const LOG_TAG = 'BannerResponse';

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      active: false,
      img_url: '',
      link_url: '',
      title: '',
      description: '',
      start: 0,
      end: null,
    };
  }
  object.getImgUrl = function getImgUrl() {
    return object.img_url ? object.img_url : '';
  }
  object.getLinkUrl = function getLinkUrl() {
    return object.link_url ? object.link_url : '';
  }
  object.getTitle = function getTitle() {
    return object.title ? object.title : '';
  }
  object.getDescription = function getDescription() {
    return object.description ? object.description : '';
  }
  object.getStart = function getStart() {
    return object.start ? object.start : 0;
  }
  object.getEnd = function getEnd() {
    return object.end ? object.end : 0;
  }
  object.hasLink = function hasLink() {
    const validLink = displayHelper.isValidUrlLink(this.getLinkUrl());
    return validLink;
  }
  object.isExpired = function isExpired() {
    let expired = false;
    let currentTimeInSecond = datetimeHelper.currentTimeInSecond();
    if (this.getEnd() > 0) {
      expired = currentTimeInSecond > this.getEnd();
    }
    // logger.d(LOG_TAG, 'isExpired', expired, currentTimeInSecond, this.getEnd());
    return expired;
  }
  object.formattedStart = function formattedStart() {
    if (this.getStart() > 0) {
      return datetimeHelper.formattedSecondsToDateTime(this.getStart());
    }
    return '';
  }
  object.formattedEnd = function formattedEnd() {
    if (this.getEnd() > 0) {
      return datetimeHelper.formattedSecondsToDateTime(this.getEnd());
    }
    return '';
  }
  return object;
}

