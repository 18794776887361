<template>
  <div>
    <message-card
        :dark="true"
        :confirm-text="'Save'"
        :disable-title="true"
        :disable-date-picker="true"
        :disable-region-picker="true"
        :on-click-cb="onSaveNote"
        :default-body="mEditNotes.body"
        :limit-body="0"
        :timestamp="mEditNotes.timestamp"
        :disable-edit="restrictedAccess"
    >
    </message-card>
  </div>
</template>
<script>

import model from "@/model";
import MessageCard from "@/views/content/components/message_card"
import MySquadBloc from "@/bloc/mysquad_bloc";
import logger from "@/util/logger"
import dateTimeHelper from "@/util/datetime_helper";

const LOG_TAG = 'profile_update_goal_memo';

export default {
  name: LOG_TAG,
  components: {
    MessageCard,
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    member: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    restrictedAccess: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data () {
    return {
      mSquadExt: model.squadResponseExt(),
      mMember: model.memberResponse(),
      mSwimmer: model.memberResponse(),
      mGoal: model.goalInfo(),

      mEditNotes: {
        body: '',
        timestamp: dateTimeHelper.currentTimeInSecond(),
        updated: false,
        setBody(value) {
          this.body = value;
          this.timestamp = dateTimeHelper.currentTimeInSecond();
          this.updated = false;
        }
      },
    }
  },
  computed: {
  },
  watch: {
    squadExt: function onChange() {
      const vm = this;
      vm.refresh();
    },
    member: function onChange() {
      const vm = this;
      vm.refresh();
    },
    swimmer: function onChange() {
      const vm = this;
      vm.refresh();
    },

  },
  mounted() {
    const vm = this;
    vm.refresh();
  },
  methods: {
    refresh() {
      logger.d(LOG_TAG, 'refresh');
      const vm = this;
      vm.mSquadExt = vm.squadExt;
      vm.mMember = vm.member;
      vm.mSwimmer = vm.swimmer;
      MySquadBloc.syncMemberGoal(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          (goal) => {
            vm.mGoal = goal;
            vm.mEditNotes.setBody(goal.getText());
          },
          () => {
          }
      )
    },
    onSaveNote(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSaveNote', value);
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateMemberGoalText(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          value.body,
          (goal) => {
            vm.mGoal = goal;
            vm.mEditNotes.setBody(goal.getText());
            vm.$notify({ type: 'success', text: 'Settings updated successfully.' });
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
            vm.$store.commit('setLoading', false);
          }
      )
    },
  }
}
</script>

<style scoped>
</style>
