<template>
  <div class="side_bar">
    <!-- desktop mode -->
    <div class="d-flex flex-column p-0">
      <div class="side_button clickable mt-2 pt-2 pb-2 pl-4"
           :class="{'side_button-selected': mSideOption.selected === option.value}"
           v-on:click.prevent="onClickOption(option)"
           v-for="option in mSideOption.options" v-bind:key="option.value">
        {{option.text}}
      </div>
    </div>
  </div>
</template>

<script>
import logger from "@/util/logger"

const LOG_TAG = 'side_bar';

export default {
  name: LOG_TAG,
  props: {
    sideOptions: {
      type: Array,
      required: true,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return [];
      }
    },
  },
  data() {
    return {
      mSideOption: {
        options: [],
        selected: '',
      }
    };
  },
  watch: {
    windowWidth: function (value) {
      const vm = this;
      if (value <= 800) {
        const defaultOption = vm.mSideOption.options[0];
        if (defaultOption && vm.mSideOption.selected !== defaultOption.value) {
          // vm.onClickOption(defaultOption.value);
        }
      }
    },
    sideOptions: function (change) {
      const vm = this;
      // logger.d(LOG_TAG, 'watch', 'selectedPage', change);
      vm.refresh(change);
    },
    selectedPage: function (change) {
      const vm = this;
      // logger.d(LOG_TAG, 'watch', 'selectedPage', change.value);
      vm.mSideOption.selected = change.value;
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    selectedPage() {
      return this.$store.state.selectedPage;
    },
  },
  mounted() {
    const vm = this;
    vm.refresh(vm.sideOptions);
  },
  methods: {
    onClickOption(option) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickOption', option.value);
      vm.mSideOption.selected = option.value;
      vm.$store.commit('setSelectedPage', option);
      vm.$emit('click', option.value);
    },
    refresh(options) {
      const vm = this;
      vm.mSideOption.options = [];
      if (options.length > 0) {
        options.forEach((option) => {
          vm.mSideOption.options.push(option);
        });
        let defaultPage = vm.mSideOption.options.find((option) => option.value == vm.$route.name);
        if (!defaultPage) {
          defaultPage = vm.mSideOption.options[0];
        }
        logger.d(LOG_TAG, 'mounted', vm.$route.name, defaultPage.value);
        vm.mSideOption.selected = defaultPage.value;
        vm.$store.commit('setSelectedPage', defaultPage);
      }
    }
  }
}
</script>
<style scoped>
.side_bar {
  display: block;
  position: relative;
  height: 100%;
}
.side_button {
  display: block;
  text-align: left;
  color: lightgrey;
  text-decoration: none;
  font-size: 0.95em;
  font-weight: bold;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.side_button:hover {
  color: black;
  background-color: var(--primary-highlight-background);
}
.side_button-selected {
  color: black;
  background-color: var(--primary-highlight-background);
}
@media screen and (max-width: 800px) {

}
</style>
