<template>
  <div id="app">
    <notifications position="bottom center" width="100%"/>
    <loader v-if="isLoading" style="z-index: 9999;"></loader>
    <date-picker v-if="openDatePicker"></date-picker>
    <div v-if="!storeAuth.isJoin() && storeAuth.isAuthenticated()">
      <nav-bar
        :nav-options="mNavigationOption.options"
        v-on:click="onClickNav">
      </nav-bar>
    </div>
    <div>
      <router-view/>
    </div>
    <div v-if="!storeAuth.isJoin() && storeAuth.isAuthenticated()">
      <footer-bar></footer-bar>
    </div>
  </div>
</template>
<script>

import Loader from "@/components/loader"
import DatePicker from "@/components/date_picker"
import NavBar from "@/components/nav_bar"
import FooterBar from "@/components/footer_bar"
import logger from "@/util/logger"

const LOG_TAG = 'app';

export default {
  name: LOG_TAG,
  components: {
    Loader,
    DatePicker,
    NavBar,
    FooterBar
  },
  props: {
  },
  computed: {
    storeAuth() {
      return this.$store.state.auth;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    openDatePicker() {
      return this.$store.state.datePicker.enabled === true;
    },
  },
  watch: {
    storeAuth: function (auth) {
      const vm = this;
      if (auth.isAuthenticated()) {
        if (!auth.isJoin()) {
          vm.$router.replace({name: 'dashboard'}, () => {});
        }
      } else if (vm.$router.currentRoute.path === '/join') {
        vm.$router.replace({name: 'join'}, () => {});
      } else {
        vm.$router.replace({name: '/'}, () => {});
      }
    },
  },
  data() {
    return {
      mNavigationOption: {
        options: [
          {text: 'MYTOGS ADMIN PORTAL', value: 'dashboard'},
          //{text: 'Place Holder', value: 'place_holder_tab'},
        ],
      }
    };
  },
  created() {
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowWidth');
    });
  },
  mounted() {
  },
  methods: {
    onClickNav(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickNav', value);
      switch (value) {
        case 'dashboard':
          vm.$router.replace({name: value}, () => {});
          break;
        case 'place_holder_tab':
          vm.$router.replace({name: value}, () => {});
          break;
        case 'help':
          vm.$router.replace({name: value,}, () => {});
          break;
        case 'account':
          vm.$router.replace({name: 'account',}, () => {});
          break;
        default:
          logger.w(LOG_TAG, 'onClickNav', 'not supported', value);
      }
    },
  }
}
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

</style>

