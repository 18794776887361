<template>
  <div v-if="mSquadExt.isValid()" class="mt-2">
    <b-modal centered
             :title="mEditSchedule.selected.getTrainingSet().getDescription()"
             id="schedule-modal" :hide-footer="true">
      <!-- tabs -->
      <div class="d-flex flex-row flex-wrap col-12 mb-2 pl-0 pr-0">
        <div v-for="(option, index) in mLogOptions.options" v-bind:key="index"
             class="clickable my-box my-box-decoration-dark ml-1 mb-1 pl-2 pr-2"
             :class="{'selected': option.value === mLogOptions.selected.value}"
             style="width: 7rem; padding-top: 2px;" v-on:click.stop="onSelectOption(option)">
          <div class="text-capitalize text-center" style="height: 1.8rem; padding-top: 5px; font-size: 0.8em">
            <span>{{ option.text }}</span>
          </div>
        </div>
      </div>

      <section v-if="mLogOptions.isAttendance()">
        <div class="mt-3" style="width: 100%;">
          <div v-for="(attendance, index) in mEditSchedule.attendance" :key="index"
               class="
               d-flex flex-column justify-content-center clickable
               mt-1 mb-1 pl-2 pr-2 pt-1 pb-1 my-box-no-border my-box-decoration-dark"
               style="min-height: 2.8rem; font-size: 0.75em">

            <div class="d-flex flex-row justify-content-between align-items-center">
              <div class="font-weight-bold"> {{attendance.getName() }}</div>
              <div v-if="!disableEdit" class="pt-2">
                <input type="radio"
                       :name="attendance.getAbsentKey()" :id="attendance.getAbsentKey()" value="absent"
                       v-model="attendance.state"
                       v-on:change="onNotifyAttendanceChanged(attendance)">
                <label class="ml-1" style="font-size: .9em" :for="attendance.getAbsentKey()">Absent</label>

                <input class="ml-3" type="radio"
                       :name="attendance.getPresentKey()" :id="attendance.getPresentKey()" value="present"
                       v-model="attendance.state"
                       v-on:change="onNotifyAttendanceChanged(attendance)">
                <label class="ml-1" style="font-size: .9em" :for="attendance.getPresentKey()">Present</label>
              </div>
              <div v-else class="pt-2"
                   :class="{
                'warning font-weight-bold': (!attendance.isUnknown() && attendance.isAbsent()),
                }">
                {{attendance.formattedState()}}
              </div>
            </div>


          </div>
        </div>
        <div v-if="!disableEdit" class="d-flex flex-row justify-content-end mt-2">
          <b-button class="my-button-confirm" style="width: 6rem;"
                    :disabled="!mEditSchedule.updated"
            v-on:click.stop="onSaveAttendance">
            Save
          </b-button>
        </div>
      </section>
      <section v-else-if="mLogOptions.isNotes()">
        <message-card
            :dark="true"
            :confirm-text="'Save'"
            :disable-title="true"
            :disable-date-picker="true"
            :disable-region-picker="true"
            :on-click-cb="onSaveNote"
            :default-body="mEditNotes.body"
            :limit-body="0"
            :timestamp="mEditNotes.timestamp">
        </message-card>
      </section>
      <section v-else-if="mLogOptions.isAttachments()">
        <div style="min-height: 8rem">
          <div class="mt-2 mb-2 pl-1 pr-1" style="width: 100%;">
            <div v-if="!disableEdit">
              <div
                  v-if="mEditAttachments.attachments.length < 5"
                  style="font-size: 0.85em">Select an image or PDF:
              </div>
              <file-picker-card
                  v-if="mEditAttachments.attachments.length < 5"
                  :dark="true"
                  :accept="'image/*,.pdf'"
                  :_on-click-cb="onUploadAttachment">
              </file-picker-card>
            </div>
            <div class="font-weight-bold text-right" style="font-size: 0.85em">({{mEditAttachments.attachments.length}} / 5)</div>

            <div v-if="mEditAttachments.attachments.length > 0" style="min-height: 2rem">
              <div v-for="(attachment, index) in mEditAttachments.attachments" :key="index"
                   class="
                 d-flex flex-column justify-content-between clickable
                 mt-1 mb-1 pl-2 pr-2 pt-1 pb-1 my-box-no-border my-box-decoration-dark"
                   style="min-height: 2.2rem; font-size: 0.75em"
                   v-on:click.stop="onDownload(attachment)">
                <div class="d-flex flex-row justify-content-between mt-1">
                  <div class="font-weight-bold">{{ attachment.getFileName() }}</div>
                  <font-awesome-icon
                      icon="external-link-alt"
                      size="sm"
                      class="label clickable"
                  />
                </div>
                <div class="text-right">{{attachment.formattedTimestamp()}}</div>
              </div>
            </div>
            <div v-else-if="disableEdit"
            class="text-center mt-4" style="font-size: 0.85em">
              No Attachments found.
            </div>

          </div>
        </div>
      </section>
    </b-modal>


    <!-- calendar picker -->
    <section>
      <div class="my-highlight-item clickable my-text-primary mt-1 mb-1 pl-3 pr-3"
           style="padding-top: 2px;" v-on:click.stop="onOpenDatePicker()">
        <div class="text-capitalize text-center d-flex flex-row justify-content-end" style="height: 2rem">
          <font-awesome-icon
              icon="calendar-alt"
              size="sm"
              class="label clickable mr-2 mt-auto mb-auto"
          />
          <div class="mt-auto mb-auto" style="margin-top: 3px; font-size: 0.9em; font-weight: bold">
            Week: {{mCalendar.getStartOfWeek()}}  -  {{mCalendar.getEndOfWeek()}}
          </div>
          <font-awesome-icon
              icon="chevron-down"
              size="sm"
              class="label clickable ml-3 mt-auto mb-auto"
          />
        </div>
      </div>
    </section>

    <!-- options -->
<!--    <section class="d-flex flex-row justify-content-between">
      <div v-for="(option, index) in mLogOptions.options" v-bind:key="index"
           class="clickable my-tab-primary text-center tab-border-left"
           :class="{
              'tab-border-right': index === (mLogOptions.options.length - 1),
              'selected': mLogOptions.selected == option
            }"
           style="width: 34%; font-size: 0.8em;"
            v-on:click.stop="onSelectOption(option)">
        {{option.text}}
      </div>
    </section>-->
    <separator-line></separator-line>
    <!-- options end -->
    <!-- option contents -->
    <section>
      <div class="my-text-primary mt-2" style="font-size: 0.9em; font-weight: bold">Logs ({{mCalendar.schedules.length}})</div>
      <div v-if="mCalendar.schedules.length > 0" class="pl-1 pr-1" style="max-height: 24rem; overflow-y: auto">
        <div v-for="(schedule, index) in mCalendar.schedules" v-bind:key="index"
             class="clickable my-text-primary my-highlight-item my-box my-box-decoration mt-2 pt-1 pl-2 pr-2 pb-1
                        d-flex flex-column justify-content-sm-around"
             style="padding-top: 3px"
             v-on:click.stop="onOpenSchedule(schedule)"
        >

          <div class="d-flex flex-row justify-content-between" style="font-size: 0.8em">
            <div>
              {{schedule.formattedSessionTime()}} <span class="font-weight-bold">({{schedule.formattedDayOfWeek(true)}}) </span>
            </div>
            <div class="font-weight-bold">
              {{schedule.getStartTime()}}
            </div>
          </div>
          <div style="font-size: 0.8em">
            <span style="font-weight: bold;">{{schedule.getTrainingSet().getDescription()}}</span>
          </div>
          <div class="d-flex flex-row justify-content-end" style="font-size: 0.85em">
            <div v-if="schedule.hasNotes()">
              <font-awesome-icon
                  icon="sticky-note"
                  size="sm"
                  class="label clickable mt-auto mb-auto"
              />
            </div>
            <div class="mr-2"></div>
            <div v-if="schedule.hasAttachments()">
              <font-awesome-icon
                  icon="paperclip"
                  size="sm"
                  class="label clickable mt-auto mb-auto"
              /> <span>{{schedule.getAttachments().length}}</span>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-end" style="font-size: 0.75em">
            <span style="font-size: 0.65em; padding-top: 5px">PRESENT{{': '}}</span>
              <span style="margin-left: 2px">{{ schedule.getPresence().length}}</span>
            <span style="font-size: 0.65em; padding-top: 5px; margin-left: 0.3rem">ABSENT{{': '}}</span>
              <span style="margin-left: 2px">{{ schedule.getAbsence().length}}</span>

            <span style="margin-left: 0.4rem">({{ schedule.formattedAttendanceRating()}})</span>
          </div>
          <div v-if="schedule.hasLastUpdated()"
          class="text-right" style="font-size: 0.65em">
            <separator-line></separator-line>

            <div class="mt-2"><span class="font-weight-bold">Last: </span>{{schedule.formattedLastUpdated()}}</div>
            <div><span class="font-weight-bold">By: </span>{{schedule.getSubmittedBy()}}</div>
          </div>
        </div>
      </div>
      <div v-else class="my-box my-box-decoration text-center mt-2 pt-1 pl-2 pr-2 pb-1" style="height: 12rem; line-height: 12rem">
        No logs found.
      </div>
    </section>
  </div>
</template>

<script>

import SeparatorLine from "@/components/separator_line"
import MessageCard from "@/views/content/components/message_card"
import FilePickerCard from "@/views/content/components/file_picker_card"
import MySquadBloc from "@/bloc/mysquad_bloc";
import firebaseStorage from "@/firebase/mysquad_storage";
import model from "@/model"
import logger from "@/util/logger"
import dateTimeHelper from "@/util/datetime_helper";

const LOG_TAG = 'squad_training_logs';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
    FilePickerCard,
    MessageCard
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    restrictedAccess: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data() {
    return {
      mLoading: true,
      mSquadExt: model.squadResponseExt(),
      mLogOptions: {
        selected: {text: '', value: ''},
        options: [
          {text: 'Attendance', value: 'attendance'},
          {text: 'Notes', value: 'notes'},
          {text: 'Attachments', value: 'attachments'},
        ],
        isAttendance() {
          return this.selected.value === 'attendance';
        },
        isNotes() {
          return this.selected.value === 'notes';
        },
        isAttachments() {
          return this.selected.value === 'attachments';
        },
        init(restrictedAccess) {
          if (restrictedAccess) {
            this.options = [
              {text: 'Attendance', value: 'attendance'},
              {text: 'Attachments', value: 'attachments'},
            ];
          } else {
            this.options = [
              {text: 'Attendance', value: 'attendance'},
              {text: 'Notes', value: 'notes'},
              {text: 'Attachments', value: 'attachments'},
            ];
          }
          this.selected = this.options[0];
        }
      },
      mCalendar: {
        loading: false,
        dayOfWeekOptions: {
          options: [
            {text: 'Monday', value: 1},
            {text: 'Tuesday', value: 2},
            {text: 'Wednesday', value: 3},
            {text: 'Thursday', value: 4},
            {text: 'Friday', value: 5},
            {text: 'Saturday', value: 6},
            {text: 'Sunday', value: 7},
          ],
        },
        selectedDate: null,
        startOfWeek: 0,
        endOfWeek: 0,
        schedules: [],
        init() {
          this.setDate(dateTimeHelper.currentDate());
        },
        setDate(value) {
          this.selectedDate = value;

          const dateInMilliseconds = dateTimeHelper.convertDateTimeToMilliseconds(value);
          this.startOfWeek = dateTimeHelper.epocStartOfWeek(dateInMilliseconds);
          this.endOfWeek = dateTimeHelper.epocEndOfWeek(dateInMilliseconds);

          logger.d(LOG_TAG, 'setDate',
              this.selectedDate,
              dateTimeHelper.convertDateFromMilliseconds(this.startOfWeek),
              dateTimeHelper.convertDateFromMilliseconds(this.endOfWeek));
        },
        setSchedules(value) {
          this.schedules = value;
        },
        getStartOfWeek() {
          return dateTimeHelper.formattedDateTime(this.startOfWeek, true);
        },
        getEndOfWeek() {
          return dateTimeHelper.formattedDateTime(this.endOfWeek, true);
        }
      },
      mEditSchedule: {
        selected: model.trainingSessionInfo(),
        attendance: [],
        attendanceOrig: [],
        updated: false,
        initAttendance(approvedMembers) {
          this.attendance = approvedMembers.map((member) => {
            const info = model.attendanceInfo()
                .setName(member.getValidatedName())
                .setSquadMemberId(member.getSquadMemberId());
            if (this.selected.getPresence().includes(member.getSquadMemberId())) {
              info.setPresent();
            } else if (this.selected.getAbsence().includes(member.getSquadMemberId())) {
              info.setAbsent();
            }
            return info;
          });
          this.attendanceOrig = this.attendance.map((attendance) => model.attendanceInfo(attendance));
          this.updated = false;
        },
        resumeAttendance() {
          // add absent
          this.attendance = this.selected.getAbsence().map((squadMemberId, index) => {
            const info = model.attendanceInfo()
                .setName(this.selected.getAbsenceName()[index])
                .setSquadMemberId(squadMemberId).setAbsent();
            return info;
          });
          // add present
          this.attendance.push(...this.selected.getPresence().map((squadMemberId, index) => {
            const info = model.attendanceInfo()
                .setName(this.selected.getPresenceName()[index])
                .setSquadMemberId(squadMemberId).setPresent();
            return info;
          }));
          // sort name
          this.attendance.sort((a, b) => a.getNameKey().localeCompare(b.getNameKey()));
          this.attendanceOrig = this.attendance.map((attendance) => model.attendanceInfo(attendance));
          this.updated = false;
        },
        checkAttendance() {
          this.updated = false;
          for (let index = 0; this.attendanceOrig.length > index; index ++) {
            const orig = this.attendanceOrig[index];
            const editable = this.attendance[index];
            logger.d(LOG_TAG, 'checkAttendance', orig.getState(), editable.getState());
            if (orig.getState() !== editable.getState()) {
              this.updated = true;
              break;
            }
          }
        },
        getAbsentIds() {
          return this.attendance
              .filter((attendance) => attendance.isAbsent())
              .map((attendance) => attendance.getSquadMemberId());
        },
        getAbsentNames() {
          return this.attendance
              .filter((attendance) => attendance.isAbsent())
              .map((attendance) => attendance.getName());
        },
        getPresentIds() {
          return this.attendance
              .filter((attendance) => attendance.isPresent())
              .map((attendance) => attendance.getSquadMemberId());
        },
        getPresentNames() {
          return this.attendance
              .filter((attendance) => attendance.isPresent())
              .map((attendance) => attendance.getName());
        }
      },
      mEditNotes: {
        body: '',
        timestamp: dateTimeHelper.currentTimeInSecond(),
        updated: false,
      },
      mEditAttachments: {
        attachments: [],
        updated: false,
        sort() {
          this.attachments.sort((a, b) => b.getTimestamp() - a.getTimestamp());
        }
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    store() {
      return this.$store;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
    storeSquadSet() {
      return this.$store.state.squadsSet.set;
    },
    storeSquadTimestamp() {
      return this.$store.state.squadsSet.timestamp;
    },
    isScheduleUpdated() {
      return true;
    }
  },
  watch: {
    squadExt: function onChange(value) {
      const vm = this;
      vm.refresh(value, true);
    },
    storeDatePicker: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:storeDatePicker', value);
      vm.mCalendar.setDate(value.date);
      vm.refresh(vm.squadExt, false);
    }
  },
  mounted() {
    logger.d(LOG_TAG, 'mounted');
    const vm = this;
    vm.refresh(vm.squadExt, true);
  },
  methods: {
    refresh(squadExt, init, onSuccess) {
      const vm = this;
      logger.d(LOG_TAG, 'refresh', squadExt);
      if (init) {
        vm.mSquadExt = squadExt;
        vm.mLogOptions.init(vm.restrictedAccess);
        vm.mCalendar.init();
      }
      MySquadBloc.syncSquadTrainingLogs(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mCalendar.startOfWeek,
          vm.mCalendar.endOfWeek,
          (value) => {
            logger.d(LOG_TAG, 'syncSquadTrainingLogs', value);
            vm.mCalendar.setSchedules(value);
            vm.$store.commit('setLoading', false);
            if (onSuccess) {
              onSuccess();
            }
          },
          (error) => {
            logger.w(LOG_TAG, 'syncSquadTrainingLogs', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onSelectOption(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectOption', value);
      vm.mLogOptions.selected = value;
    },
    onOpenDatePicker() {
      const vm = this;
      vm.$store.commit('setDatePicker',
          model.datepickerInfo()
            .setDate(vm.mCalendar.selectedDate)
            .setEnabled(true)
            .setByWeek(true));
    },
    onOpenSchedule(schedule) {
      const vm = this;
      vm.mLogOptions.init(vm.restrictedAccess);
      vm.mEditSchedule.selected = schedule;
      vm.mEditSchedule.attendance = [];

      vm.mEditNotes.body = schedule.getNotes();
      vm.mEditAttachments.attachments = schedule.getAttachments();
      vm.mEditAttachments.sort();
      const squadInfo = vm.storeSquadSet[vm.mSquadExt.getSquadId()];
      if (squadInfo) {
        if (schedule.hasAttendance()) {
          vm.mEditSchedule.resumeAttendance();
        } else {
          vm.mEditSchedule.initAttendance(squadInfo.getApprovedMembers());
        }
      }
      vm.$bvModal.show('schedule-modal');
    },
    onRefreshTrainingLogs() {

    },
    onNotifyAttendanceChanged(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onNotifyAttendanceChanged', value.getState(), value.getName());
      vm.mEditSchedule.checkAttendance();
    },
    onSaveAttendance() {
      const vm = this;
      logger.d(LOG_TAG, 'onSaveAttendance');
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateSessionAttendance(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mEditSchedule.selected.getSessionTime(),
          vm.mEditSchedule.getAbsentIds(), vm.mEditSchedule.getAbsentNames(),
          vm.mEditSchedule.getPresentIds(), vm.mEditSchedule.getPresentNames(),
          (updated) => {
            logger.d(LOG_TAG, 'onSaveAttendance', updated);
            vm.refresh(vm.mSquadExt, false,
                () => {
              vm.mEditSchedule.updated = false;
              vm.$notify({ type: 'success', text: 'Attendance Updated successfully.' });
            });

          },
          (error) => {
            logger.w(LOG_TAG, 'onSaveAttendance', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          }
      )
    },
    onSaveNote(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSaveNote', value);
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateSessionNotes(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mEditSchedule.selected.getSessionTime(),
          value.body,
          (updated) => {
            logger.d(LOG_TAG, 'onSaveNote', updated);
            vm.refresh(vm.mSquadExt, false,
                () => {
                  vm.mEditNotes.body = updated.getNotes();
                  vm.$notify({ type: 'success', text: 'Updated successfully.' });
                });

          },
          (error) => {
            logger.w(LOG_TAG, 'onSaveNote', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          }
      )
    },
    onUploadAttachment(file) {
      const vm = this;
      logger.d(LOG_TAG, 'onUploadAttachment', file);
      vm.$store.commit('setLoading', true);
      firebaseStorage.uploadAttachment(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mEditSchedule.selected.getSessionTime(),
          file,
          (attachmentInfo) => {
            logger.d(LOG_TAG, 'onUploadAttachment', attachmentInfo);
            vm.mEditAttachments.attachments.push(attachmentInfo);
            vm.mEditAttachments.sort();
            MySquadBloc.updateSessionAttachments(
                vm.mSquadExt.getClubId(),
                vm.mSquadExt.getSquadId(),
                vm.mEditSchedule.selected.getSessionTime(),
                vm.mEditAttachments.attachments.map((attachment) => JSON.stringify(attachment)),
                (value) => {
                  logger.d(LOG_TAG, 'onUploadAttachment', value);
                  vm.refresh(vm.mSquadExt, false,
               () => {
                   vm.$notify({ type: 'success', text: 'File uploaded successfully.' });
                 });
                },
                (error) => {
                  logger.w(LOG_TAG, 'onUploadAttachment', error);
                }
            )

          },
          (error) => {
            logger.w(LOG_TAG, 'onUploadAttachment', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'Oops! Unable to upload file, please try again later.' });
          }
      )
    },
    onDownload(attachment) {
      window.open(attachment.getDownloadUrl());
      /*
      // This can be downloaded directly:
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        let fileURL = window.URL.createObjectURL(new Blob([xhr.content]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', attachment.getFileName());
        document.body.appendChild(fileLink);

        fileLink.click();
      };
      xhr.open('GET', attachment.getDownloadUrl());
      xhr.send();
*/
      // Or inserted into an <img> element
      //let img = document.getElementById('myimg');
      //img.setAttribute('src', attachment.getDownloadUrl());
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
