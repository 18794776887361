// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.schedule) {
      object.schedule = object.schedule.map((schedule) => model.trainingScheduleInfo(schedule));
    }
    if (object.members) {
      object.members = object.members.map((member) => model.memberResponse(member));
      object.members.sort((a, b) => a.getValidatedNameKey().localeCompare(b.getValidatedNameKey()));
    }
    if (object.exclusion) {
      object.exclusion = object.exclusion.map((offDays) => model.offDaysInfo(offDays));
      object.exclusion.sort((a, b) => a.getStart() - b.getStart());
    }
  } else {
    object = {
      squadId: '',
      squadName: '',
      club: '',
      clubId: '',
      coach: model.memberResponse(),
      members: [],
      createAt: 0,
      schedule: [],
      squadMemberId: '',
      inviteCode: '',
      squadLimit: 0, // max 5
      squadSize: 0, // max 30
      exclusion: [],
    };
  }
  object.setClubId = function setClubId(value) {
    object.clubId = value;
    return this;
  }
  object.setSquadId = function setSquadId(value) {
    object.squadId = value;
    return this;
  }
  object.getSquadId = function getSquadId() {
    return object.squadId ? object.squadId : '';
  }
  object.getSquadName = function getSquadName() {
    return object.squadName ? object.squadName : 'Add new squad';
  }
  object.isValid = function isValid() {
    return this.getSquadId().length > 0;
  }
  object.getCoach = function getCoach() {
    return object.coach ? model.memberResponse(object.coach) : model.memberResponse();
  }
  object.getSchedule = function getSchedule() {
    return object.schedule ? object.schedule : [];
  }
  object.getSquadMemberId = function getSquadMemberId() {
    return object.squadMemberId ? object.squadMemberId : '';
  }
  object.hasCoach = function hasCoach() {
    return object.getCoach().isValid();
  }
  object.getMembers = function getMembers() {
    return object.members ? object.members : [];
  }
  object.getApprovedMembers = function getApprovedMembers() {
    return object.getMembers().filter((member) => member.hasApproved());
  }
  object.hasSchedule = function hasSchedule() {
    return object.getSchedule().length > 0;
  }
  object.getInviteCode = function getInviteCode() {
    return object.inviteCode ? object.inviteCode : '';
  }
  object.getClubId = function getClubId() {
    return object.clubId ? object.clubId : '';
  }
  object.formattedInviteLink = function formattedInviteLink() {
    return 'https://squads.mytogs.co.nz/join?code=' + object.getInviteCode();
  }
  object.getSquadLimit = function getSquadLimit() {
    return object.squadLimit ? object.squadLimit : 0;
  }
  object.getSquadSize = function getSquadSize() {
    return object.squadSize ? object.squadSize : 0;
  }
  object.getExclusion = function getExclusion() {
    return object.exclusion ? object.exclusion : [];
  }
  return object;
}

