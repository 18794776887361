<template>
  <content-layout>

    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search by -->
          <label class="my-text-primary" style="font-size: 0.85em;">
            {{ mDescriptions[0]}}
            <span style="font-weight: bold">{{ mDescriptions[1]}}</span>
            {{mDescriptions[2]}}
            <span style="font-weight: bold">{{ mDescriptions[3]}}</span>
            {{mDescriptions[4]}}
            <span>
              <a style="color: white; font-weight: bold; text-decoration: underline;"
                  href="https://mytogs-public.s3.ap-southeast-2.amazonaws.com/mytogs-meet-manager-upload-instruction.pdf" target=”_blank”>
                {{mDescriptions[5]}}
              </a>
            </span>
          </label>
          <file-picker-card
            :_on-click-cb="onUploadFile">
          </file-picker-card>
          <!-- search result -->

          <!-- history section-->
          <div class="my-text-primary mt-2"
               style="font-weight: bold;">Recent Uploaded HY3 files: {{mHy3FileHistory.length}}</div>
          <!-- content section-->
          <loader :embedded="true" v-if="mInit" style="margin-top: 4rem; text-align: center"></loader>
          <section v-if="mHy3FileHistory.length > 0">
            <div v-for="(file) in mHy3FileHistory" v-bind:key="file.createdAt"
                 class="my-text-primary my-box my-box-decoration mt-2 pl-2 pr-2
                    d-flex flex-column justify-content-sm-around"
                 style="padding-top: 3px">

              <div class="d-flex flex-row justify-content-between">
                <span style="font-size: 0.8em">
                {{file.formattedCreatedAt()}}
                </span>

                <div>
                  <font-awesome-icon
                      icon="download"
                      size="sm"
                      class="label clickable warning"
                      v-on:click.prevent="onDownloadFile(file)"
                  />
                </div>
              </div>
              <div style="font-size: 0.8em">
                <span style="font-weight: bold;">{{file.getFileName()}}</span>
              </div>
              <div class="d-flex flex-row justify-content-end mt-1" style="font-size: 0.7em">
                Uploaded by: <span class="ml-1" style="font-weight: bold;">{{file.getCreatedBy()}}</span>
              </div>


            </div>
          </section>

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import FilePickerCard from "@/views/content/components/file_picker_card"
import Loader from "@/components/loader"
import webapi from "@/webapi"
import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'updateResult';

export default {
  name: LOG_TAG,
  display: 'Update Result',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    FilePickerCard,
    Loader
  },
  data() {
    return {
      mInit: false,
      mFilePath: '',
      mState: '',
      mStateDescription: '',
      mDescriptions: [
        'Upload Meet Manager ',
        'HY3 ',
        'Files for instant ',
        'unofficial ',
        'result notifications. For more information ',
        'Click here'
      ],
      mHy3FileHistory: []
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },

  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      webapi.apiService.getHY3Files(
        (response) => {
          logger.d(LOG_TAG, 'getHY3Files', response.length);
          const list = [];
          response.forEach((file) => {
            list.push(model.hy3FileResponse(file))
          })
          list.sort((a, b) => b.getCreatedAt() - a.getCreatedAt)
          vm.mHy3FileHistory = list;
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        },
        (error) => {
          logger.w(LOG_TAG, 'refresh', error);
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        }
      );
    },
    onUploadFile(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onUploadFile', data.name, vm.mFilePath);
      vm.$store.commit('setLoading', true);
      webapi.apiService.uploadUnofficialResult(
          data,
          (response) => {
            logger.d(LOG_TAG, 'onUploadFile', response);
            vm.refresh();
            vm.onPostResult(true, response);
          },
          (error) => {
            logger.w(LOG_TAG, 'onUploadFile', error.data);
            vm.$store.commit('setLoading', false);
            vm.onPostResult(false, error.data);
          }
      )
    },
    onDownloadFile(data) {
      logger.d(LOG_TAG, 'onDownloadFile', data.getFileName());
      webapi.apiService.getHY3FileContent(
          data.getFileName(),
          (response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.content]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', data.getFileName());
            document.body.appendChild(fileLink);

            fileLink.click();
          },
          (error) => {
            logger.w(LOG_TAG, 'onDownloadFile', error);
          }
      );
    },
    onPostResult(success, message) {
      this.$bvModal.msgBoxOk(message, {
        title: success ? 'Success' : 'Error',
        size: 'sm',
        okVariant: success ? 'success' : 'danger',
        okTitle: 'Close',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      });
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
