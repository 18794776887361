<template>
  <div>
    <radar-chart :chartData="mChartData" :options="mOptions"/>
  </div>
</template>

<script>
// import logger from "@/util/logger";
import {RadarChart } from 'vue-chart-3';
import model from "@/model"
//import logger from "@/util/logger"

const LOG_TAG = 'stroke_radar';

export default {
  name: LOG_TAG,
  components: {RadarChart},
  props: {
    datasets: {
      type: Array,
      default: function () {
        return [];
      }
    },
  },
  data () {
    return {
      mChartData: {
        labels: [
            'Freestyle',
            'Backstroke',
            'Breaststroke',
            'Butterfly',
            'Individual Medley'
        ],
        datasets: [
          {
            label: 'Rating',
            data: [0, 0, 0, 0, 0],

            borderColor: 'rgba(54, 162, 235, 0.5)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',

            rawData: [
              model.resultInfo(),
              model.resultInfo(),
              model.resultInfo(),
              model.resultInfo(),
              model.resultInfo(),
            ],
          }
        ]
      },
      mOptions: {
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 100
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Performance Rating'
          },
          tooltip: {
            callbacks: {
              footer: function(context) {
                const index = context[0].dataIndex;
                const data = context[0].dataset.rawData[index];
                //logger.d(LOG_TAG, 'tooltip', index, context[0]);
                return data.hasResult() ? data.formattedResultAge() : '---';
              }
            }
          }
        }
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {
    //
    const vm = this;
    //logger.d(LOG_TAG, 'mounted', vm.datasets);
    vm.mChartData.datasets[0].data = vm.datasets.map((data) => data.value.getPercent());
    vm.mChartData.datasets[0].rawData = vm.datasets.map((data) => data.value);
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
