//import displayHelper from "@/util/display_helper"
import profile_addons from "@/model/app_user_profile_addons";
import datetimeHelper from "@/util/datetime_helper";


export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      uuid: '',
      create_at: 0,
      update_at: 0, // present, absent, unknown
      sign_in_at: 0,
      command: '',
      command_timestamp: 0,
      premium: false,
      subscription_premium: false,
      subscription: '',
      add_ons: profile_addons()
    };
  }
  object.setUuid = function setUuid(value) {
    object.uuid = value;
    return object;
  },
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : '';
  },
  object.getCreateAt = function getCreateAt() {
    return object.create_at ? object.create_at : 0;
  },
  object.getUpdatedAt = function getUpdatedAt() {
    return object.update_at ? object.update_at : 0;
  },
  object.getSignInAt = function getSignInAt() {
    return object.sign_in_at ? object.sign_in_at : 0;
  },
  object.getCommandTimestamp = function getCommandTimestamp() {
    return object.command_timestamp ? object.command_timestamp : 0;
  }
  object.getCommand = function getCommand() {
    return object.command ? object.command : '';
  }
  object.formattedCreateAt = function formattedCreateAt() {
    return datetimeHelper.formattedDateTime(this.getCreateAt() * 1000);
  }
  object.formattedUpdatedAt = function formattedUpdatedAt() {
    return datetimeHelper.formattedDateTime(this.getUpdatedAt() * 1000);
  }
  object.formattedSignInAt = function formattedSignInAt() {
    return datetimeHelper.formattedDateTime(this.getSignInAt() * 1000);
  }
  object.formattedCommandTimestamp = function formattedCommandTimestamp() {
    if (object.getCommandTimestamp() > 0) {
      return datetimeHelper.formattedDateTime(this.getCommandTimestamp() * 1000);
    } else {
      return ''
    }
  }
  object.getPremium = function getPremium() {
    return object.premium ? object.premium : false;
  }
  object.formattedPremiumState = function formattedPremiumState() {
    return object.getPremium() ? 'Yes' : 'No';
  }
  object.getPremiumSubscription = function getPremiumSubscription() {
    return object.subscription_premium ? object.subscription_premium : false;
  }
  object.formattedPremiumSubscriptionState = function formattedPremiumSubscriptionState() {
    return object.getPremiumSubscription() ? 'Yes' : 'No';
  }
  object.getAddons = function getAddons() {
    return profile_addons(object.add_ons);
  }
  return object;
}

