<template>
  <div class="page_layout gradient_background">
    <!-- confirm modal-->
    <b-modal centered title="Submit Request?" id="submit-modal">
      <p class="my-4">{{ mJoinRequest.memberToSubmit.getName() }}</p>


      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmJoinRequest()">
          Submit
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <div class="d-flex flex-row justify-content-center" style="height: 100%;">
      <div class="d-flex flex-column justify-content-between" style="min-width: 260px; max-width: 420px">
        <div></div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-self-center">
            <div class="brand p-2">
              <img alt="mytogs logo" src="../../assets/images/logo-mytogs-white.png">
            </div>
            <div class="brand p-2">
              <img alt="swimming new zealand logo" src="../../assets/images/logo-swimming-nz-white.png">
            </div>
          </div>
          <div v-if="(isMySquad) && (!mData.isValidatingCode() && mData.hasInvitation())">
            <div class="label align-self-center mt-5" style="font-size: 1.5em">
              Squad Sign-Up
            </div>
            <div class="label align-self-center">
              {{ mData.getInvitationData().getSquadName() }} - {{ mData.getInvitationData().getClubName() }}
            </div>

            <div v-if="!mJoinRequest.completed">
              <div v-if="storeAuth.isValid()">
                <!-- skipped by sign up with reconcile data-->
                <div v-if="!mData.hasReconcileData()">
                  <div class="label align-self-center mt-5">
                    Add swimmer by:
                  </div>
                  <div class="align-self-center ml-auto mr-auto" style="max-width: 280px">
                    <div
                        v-if="storeAuth.hasManagedSwimmers()"
                        class="d-flex flex-row justify-content-start label mt-1 pt-2" style="font-size: 0.75em; font-weight: normal; padding-left: 4rem">
                      <input type="radio" id="by_members" name="swimmer_option" value="by_members" v-model="mJoinRequest.by">
                      <label class="ml-2 " for="by_members">MyTogs Swimmers</label>
                    </div>
                    <div class="d-flex flex-row justify-content-start label mt-1 pt-2" style="font-size: 0.75em; font-weight: normal; padding-left: 4rem">
                      <input type="radio" id="by_fastlane" name="swimmer_option" value="by_fastlane" v-model="mJoinRequest.by">
                      <label class="ml-2" for="by_fastlane">Fastlane ID</label>
                    </div>
                    <!-- search swimming nz -->
                    <div class="d-flex flex-row justify-content-start label mt-1 pt-2" style="font-size: 0.75em; font-weight: normal; padding-left: 4rem">
                      <input type="radio" id="by_name" name="swimmer_option" value="by_name" v-model="mJoinRequest.by">
                      <label class="ml-2" for="by_name">Search swimmer Name</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="label align-self-center mt-5">
                  Are you an exising <a style="text-decoration: underline; color: #FFFFFF" href="https://mytogs.co.nz/">MyTogs</a> user?
                </div>
                <div class="d-flex flex-row justify-content-center label mt-2 pt-1">
                  <input class="mt-1" type="radio" id="user_yes" name="user_option" value="user_yes" :disabled="storeAuth.isValid()" v-model="mData.selectMyTogsUser">
                  <label class="ml-2 " style="font-weight: normal" for="user_yes">YES</label>
                  <input class="mt-1 ml-5" type="radio" id="user_no" name="user_option" value="user_no" :disabled="storeAuth.isValid()" v-model="mData.selectMyTogsUser">
                  <label class="ml-2" style="font-weight: normal" for="user_no">NO</label>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="mJoinRequest.completed">
          <div class="label align-self-center mt-3">
            {{ mData.reconcileId ? 'Welcome to the squad!' : 'Request submitted, you will be notified by email when you request is approved.' }}
          </div>
          <button
                  type="button"
                  class="btn btn-outline-primary my-box-decoration mt-3"
                  style="min-width: 260px; max-width: 280px"
                  v-on:click.stop="onGotoMySquad()">
            Go to MySquad
          </button>
          <div class="mt-3" style="font-size: 0.85em">
            <div class="label align-self-center" style="font-weight: normal">
              Your squad schedules will also be available in your <a style="text-decoration: underline; font-weight: bold; color: #FFFFFF" href="https://mytogs.co.nz/">MyTogs</a> App shortly,
              or if you have not downloaded the App
            </div>
            <div class="label align-self-center" style="font-weight: normal">
              <a style="text-decoration: underline; font-weight: bold; color: #FFFFFF" href="https://mytogs.co.nz/">Click here</a>.
            </div>
          </div>
        </div>
        <div v-else-if="(!isMySquad) || (!mData.isValidatingCode() && !mData.hasInvitation())">
          <label class="label align-self-center mt-3">
            {{ (!isMySquad) ? 'Oops! Page not found.' : 'Oops! No invitation code were found.'}}
          </label>
        </div>
        <div></div>
        <!-- member input if signed in-->
        <div v-if="!mJoinRequest.completed">
          <div v-if="storeAuth.isValid()" class="align-self-center">
            <form v-if="mData.hasReconcileData()" class="form-group align-items-center" style="min-width: 260px; max-width: 480px;" v-on:submit.prevent="onSubmitJoinRequest()">
              <h3 class="label align-self-center mt-3">
                Welcome! {{storeAuth.getDisplayName()}}
              </h3>
              <label class="label align-self-center mt-3" style="font-weight: normal">
                Click <span class="font-weight-bold">'Submit'</span> to complete the process
              </label>
              <button type="submit"
                      class="btn btn-outline-primary my-box-decoration mt-4"
                      style="min-width: 260px; max-width: 280px">
                Submit
              </button>
            </form>
            <form
                v-else
                class="form-group align-items-center" style="min-width: 260px; max-width: 480px;" v-on:submit.prevent="onSubmitJoinRequest()">
              <b-form-group v-if="mJoinRequest.isByMembers()" label="Select member" label-for="member-select" class="ml-auto mr-auto" style="color: white; max-width: 260px">
                <b-form-select id="member-select" v-model="mJoinRequest.selectedMember" :options="mJoinRequest.memberOptions"></b-form-select>
              </b-form-group>
              <input
                  v-else-if="mJoinRequest.isByFastlane()"
                  type="text"
                  class="form-control my-box-decoration ml-auto mr-auto"
                  style="max-width: 260px"
                  :placeholder="'Enter fastlane ID'"
                  v-model="mJoinRequest.fastlaneId">
              <div v-else-if="mJoinRequest.isByName()"
                   class="d-flex flex-row justify-content-between ml-auto mr-auto"
                   style="max-width: 260px">
                <input
                    type="text"
                    class="form-control my-box-decoration"
                    :placeholder="'Enter swimmer name'"
                    v-model="mJoinRequest.name">
                <div>
                  <button
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 3rem"
                      :disabled="mJoinRequest.nameSearching || mJoinRequest.name === 0"
                      v-on:click.stop="onSearchName()">
                    <div v-if="mJoinRequest.nameSearching"
                         class="spinner-border"
                         style="width: 1rem; height: 1rem; border-width: 1px" role="status"></div>
                    <font-awesome-icon
                        v-else
                        :icon="mJoinRequest.nameOptions.length > 0 ? 'times' : 'search'"
                        size="sm"
                        class="label warning"/>
                  </button>
                </div>
              </div>
              <div v-if="mJoinRequest.nameOptions.length > 0" class="mt-3">
                <div class="my-text-primary" style="font-size: 0.85em; font-weight: bold;">Select swimmer ({{ mJoinRequest.nameOptions.length }})</div>
                <div class="d-flex flex-row flex-nowrap mt-2" style="width: 100%; overflow-x: auto;">
                  <div v-for="(swimmer, index) in mJoinRequest.nameOptions" :key="index"
                       v-on:click.stop="onSelectSwimmer(swimmer)"
                       class="my-box my-box-decoration clickable p-1 ml-1 mr-1 mb-2"
                       :class="{'selected': mJoinRequest.nameSelected.getMemberId() === swimmer.getMemberId()}"
                       style="min-width: 8rem; font-size: 0.75em">
                    <div style="font-weight: bold"> {{swimmer.getName() }}</div>
                    <div> {{swimmer.getBranch() }}</div>
                  </div>
                </div>
              </div>
              <button type="submit"
                      class="btn btn-outline-primary my-box-decoration mt-4"
                      style="min-width: 260px; max-width: 280px"
                      v-bind:disabled="!isValidJoinRequest">
                Submit
              </button>

            </form>


          </div>
          <div v-else-if="mData.hasInvitation()">

            <form class="form-group ml-auto mr-auto" style="min-width: 260px; max-width: 280px;" v-on:submit.prevent="onSignIn()">
              <label
                  v-if="mData.isMyTogsUser()"
                  style="font-size: 0.75em; color: white">Sign in to with your MyTogs credentials
              </label>
              <label v-else
                     style="font-size: 0.75em; color: white">Create MyTogs account

              </label>
              <input
                  v-if="!mData.isMyTogsUser()"
                  type="text"
                  class="form-control my-box-decoration mb-2"
                  placeholder="Enter name"
                  v-model="mUserName">
              <input type="email"
                     class="form-control my-box-decoration"
                     :placeholder="mData.isMyTogsUser() ? 'Email' : 'Enter email'"
                     v-model="mEmail">
              <input type="password"
                     class="form-control my-box-decoration mt-2"
                     :placeholder="mData.isMyTogsUser() ? 'Password' : 'Enter password (8 chars)'"
                     v-model="mPassword">
              <div class="mt-1 mb-3" style="font-size: 0.55em; color: white; text-align: start" v-if="!mData.isMyTogsUser()">* Minimum 8 characters</div>
              <input
                  v-if="!mData.isMyTogsUser()"
                  type="password"
                  class="form-control my-box-decoration mt-1"
                  placeholder="Confirm Password"
                  v-model="mPasswordConfirm">
              <button type="submit"
                      class="btn btn-outline-primary my-box-decoration mt-4"
                      style="width: 280px"
                      v-bind:disabled="!isValid">
                {{ mData.isMyTogsUser() ? 'Sign In' : 'Create'}}
              </button>

            </form>
          </div>
        </div>

        <div class="my-text-primary mt-4 mb-2" style="font-size: 0.6em">
          Copyright © 2021 myTogs - All Rights Reserved.
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import webapi from "@/webapi"
import model from "@/model"
import logger from "@/util/logger"
import member_response from "@/model/member_response";
import MySquadBloc from "@/bloc/mysquad_bloc";

const LOG_TAG = 'join';

export default {
  name: LOG_TAG,
  components: {
  },
  props: {

  },
  computed: {
    isMySquad() {
      return process.env.VUE_APP_TARGET === 'mysquad';
    },
    isValid() {
      const vm = this;
      if (vm.mData.isMyTogsUser()) {
        return vm.mEmail.length > 0 && vm.mPassword.length > 0;
      } else {
        return vm.mUserName.length > 0 &&
            vm.mEmail.length > 0 &&
            vm.mPassword.length >= 8 && vm.mPassword === vm.mPasswordConfirm;
      }
    },
    isValidJoinRequest() {
      const vm = this;
      let result = false;
      if (vm.mJoinRequest.isByMembers()) {
        result = vm.mJoinRequest.selectedMember.length > 0;
      } else if (vm.mJoinRequest.isByFastlane()) {
        result = vm.mJoinRequest.fastlaneId.length > 0;
      } else if (vm.mJoinRequest.isByName()) {
        result = vm.mJoinRequest.nameSelected.isValid();
      }
      logger.d(LOG_TAG, 'computed:isValidJoinRequest', result);
      return result;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
  },
  data() {
    return {
      mData: {
        invitationCode: '',
        reconcileId: '',
        validatingCode: true,
        invitationData: model.inviteCodeResponse(),
        reconcileData: null,
        coach: false,
        selectMyTogsUser: 'user_yes', //user_no
        setInvitationCode(code) {
          this.invitationCode = code;
          return this;
        },
        setReconcileId(code) {
          this.reconcileId = code;
          return this;
        },
        setIsCoach(value) {
          this.coach = value;
          return this;
        },
        setCodeValidating(value) {
          this.validatingCode = value;
          return this;
        },
        setInvitationData(value) {
          this.invitationData = value;
          return this;
        },
        setReconcileData(value) {
          this.reconcileData = value;
          return this;
        },
        isValidatingCode() {
          return this.validatingCode;
        },
        hasInvitation() {
          return this.invitationCode.length > 0 && this.invitationData.isValid();
        },
        hasReconcileData() {
          return this.reconcileId.length > 0 && this.reconcileData.isValid();
        },
        getInvitationData() {
          return this.invitationData;
        },
        isMyTogsUser() {
          return this.selectMyTogsUser === 'user_yes';
        }
      },
      mJoinRequest: {
        by: 'by_members', //by_members, by_fastlane, by_name
        memberOptions: [],
        selectedMember: '',
        fastlaneId: '',
        name: '',
        nameSearching: false,
        nameOptions: [],
        nameSelected: model.memberResponse(),

        memberToSubmit: model.memberResponse(),
        completed: false,
        setMemberOptions(options) {
          this.memberOptions = [];
          this.nameOptions = [];

          this.selectedMember = model.memberResponse();
          this.nameSelected = model.memberResponse();
          options.forEach((member) => {
            this.memberOptions.push({
              text: member.getName(),
              value: member.getMemberId(),
            })
          });
          if (this.memberOptions.length > 0) {
            this.selectedMember = this.memberOptions[0].value;
          }
        },
        toggleByMembers() {
          this.by = 'by_members';
        },
        toggleByFastlane() {
          this.by = 'by_fastlane';
        },
        toggleByName() {
          this.by = 'by_name';
        },
        isByMembers() {
          return this.by === 'by_members';
        },
        isByFastlane() {
          return this.by === 'by_fastlane';
        },
        isByName() {
          return this.by === 'by_name';
        },
      },
      mUserName: '',
      mEmail: '',
      mPassword: '',
      mPasswordConfirm: '',
      mAuthenticating: false,
    };
  },
  watch: {
    'mData.selectMyTogsUser': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:selectMyTogsUser', value);
      vm.mUserName = '';
      vm.mEmail = '';
      vm.mPassword = '';
      vm.mPasswordConfirm = '';
    },
    'mJoinRequest.by': function onChange() {
      const vm = this;
      if (vm.mJoinRequest.isByMembers()) {
        vm.mJoinRequest.selectedMember = vm.mJoinRequest.memberOptions[0].value;
      }
      vm.mJoinRequest.fastlaneId = '';
      vm.mJoinRequest.name = '';
      vm.mJoinRequest.nameSelected = model.memberResponse();
      vm.mJoinRequest.nameOptions = [];
    }
  },
  mounted() {
    const vm = this;
    logger.d(LOG_TAG, 'mounted', vm.isMySquad, vm.$route.query.code);
    vm.mData.setInvitationCode(vm.$route.query.code ? vm.$route.query.code : '');
    vm.mData.setReconcileId(vm.$route.query.reconcileId ? vm.$route.query.reconcileId : '');
    vm.mData.setIsCoach(vm.$route.query.coach ? vm.$route.query.coach : false);

    vm.validateInvitation(vm.mData.invitationCode, vm.mData.reconcileId, vm.mData.coach);
  },
  methods: {
    formatWelcomeRedirectLink(inviteCode, clubName, squadName) {
      return 'https://mysquads.co.nz/welcome.html' +
      '?invitecode=' + inviteCode +
      '&clubname=' + clubName +
      '&squadname=' + squadName;
    },
    validateInvitation(code, reconcileId, isCoach) {
      const vm = this;
      logger.d(LOG_TAG, 'validateInvitation');
      vm.$store.commit('setLoading', true);
      MySquadBloc.validateInvitation(code, reconcileId, isCoach,
        (codeResponse, reconcileResponse) => {
          vm.mData
            .setCodeValidating(false)
            .setInvitationData(codeResponse)
            .setReconcileData(reconcileResponse);
          vm.$store.commit('setLoading', false);
        },
        (error) => {
          logger.w(LOG_TAG, 'validateInvitation', error);
          vm.mData.setCodeValidating(false);
          vm.$store.commit('setLoading', false);
        });
    },
    onSignIn() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      if (vm.mData.isMyTogsUser()) {
        logger.d(LOG_TAG, 'onSignIn', 'signInWithEmailAndPassword');
        window.firebase.auth().signInWithEmailAndPassword(vm.mEmail, vm.mPassword)
            .then((userCredential) => {
              const user = userCredential.user;
              // logger.d(LOG_TAG, 'onSignIn', 'refreshToken', user.refreshToken);
              vm.onSignInWithBackend(user.refreshToken);
            })
            .catch((error) => {
              logger.w(LOG_TAG, 'onSignIn', error);
              vm.onSignInWithBackend(null);
            });
      } else {
        logger.d(LOG_TAG, 'onSignIn', 'createUserWithEmailAndPassword');
        window.firebase.auth().createUserWithEmailAndPassword(vm.mEmail, vm.mPassword)
            .then((userCredential) => {
              const user = userCredential.user;
              logger.d(LOG_TAG, 'onSignIn', 'refreshToken', user.uid);
              vm.onSignUpWithBackend(user.uid, user.refreshToken);
            })
            .catch((error) => {
              vm.$store.commit('setLoading', false);
              vm.$notify({ type: 'error', text: error.message });
            });
      }

    },
    onSignUpWithBackend(uId, refreshToken) {
      const vm = this;
      webapi.apiService.createNewUser(
          vm.mData.invitationCode,
          uId, vm.mEmail, vm.mUserName,
          (response) => {
            logger.d(LOG_TAG, 'createNewUser', response);
            vm.onSignInWithBackend(refreshToken);
          },
          (error) => {
            logger.w(LOG_TAG, 'createNewUser', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: error.data });
          }
      )
    },
    onSignInWithBackend(refreshToken) {
      const vm = this;
      // logger.d(LOG_TAG, 'onSignIn', 'refreshToken', refreshToken);
      webapi.apiService.signIn(
        vm.mUserName,
        (!refreshToken) ? vm.mPassword : null,
        refreshToken,
        (response) => {
          const data = model.authResponse(response);
          data.join = true;
          if (data.hasManagedSwimmers()) {
            vm.mJoinRequest.setMemberOptions(data.getManagedSwimmers());
            vm.mJoinRequest.toggleByMembers();
          } else {
            vm.mJoinRequest.toggleByFastlane();
          }

          logger.d(LOG_TAG, 'onSignIn', response, data.isValid());
          vm.$store.commit('setAuth', data);
          vm.$store.commit('setLoading', false);

        },
        (error) => {
          logger.w(LOG_TAG, 'onSignIn', error);
          vm.$store.commit('setLoading', false);
          vm.$notify({ type: 'error', text: error.data });
        }
      )
    },
    onSearchName() {
      const vm = this;
      if (vm.mJoinRequest.nameOptions.length > 0) {
        vm.mJoinRequest.name = '';
        vm.mJoinRequest.nameSelected = model.memberResponse();
        vm.mJoinRequest.nameOptions = [];
      } else if (vm.mJoinRequest.name) {
        vm.mJoinRequest.nameSearching = true;
        webapi.apiService.searchSwimmer(
            'clubId',
            'squadId',
            vm.mJoinRequest.name,
            (response) => {
              const swimmerOptions = [];
              response.forEach((user) => {
                swimmerOptions.push(model.memberResponse(user));
              });
              vm.mJoinRequest.nameSearching = false;
              vm.mJoinRequest.nameOptions = swimmerOptions;
              logger.d(LOG_TAG, 'searchSwimmer', swimmerOptions.length);
            },
            (error) => {
              vm.mJoinRequest.nameSearching = false;
              logger.w(LOG_TAG, 'searchSwimmer', error);
            }
        )
      }
    },
    onSelectSwimmer(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectSwimmer', value);
      vm.mJoinRequest.nameSelected = value;
    },
    onSubmitJoinRequest() {
      const vm = this;
      if (vm.mData.hasReconcileData()) {
        vm.onConfirmJoinRequest();
      } else {
        if (vm.mJoinRequest.isByMembers()) {
          logger.d(LOG_TAG, 'onSubmitJoinRequest:member', vm.mJoinRequest.selectedMember);

          vm.mJoinRequest.memberToSubmit = member_response({
            name: vm.storeAuth.getManagedSwimmers().find((swimmer) => swimmer.getMemberId() === vm.mJoinRequest.selectedMember).getName(),
            memberId: vm.mJoinRequest.selectedMember
          });
          vm.$bvModal.show('submit-modal');
        } else if (vm.mJoinRequest.isByFastlane()) {
          logger.d(LOG_TAG, 'onSubmitJoinRequest: fastlane', vm.mJoinRequest.fastlaneId);

          vm.$store.commit('setLoading', true);
          webapi.apiService.queryFastlaneMember(
              vm.mJoinRequest.fastlaneId,
              (response) => {
                vm.$store.commit('setLoading', false);

                logger.d(LOG_TAG, 'queryFastlaneMember', response);
                vm.mJoinRequest.memberToSubmit = model.memberResponse(response);
                vm.$bvModal.show('submit-modal');
              },
              (error) => {
                logger.d(LOG_TAG, 'queryFastlaneMember', error);
                vm.$notify({ type: 'error', text: error.data });
                vm.$store.commit('setLoading', false);
              }
          )
        } else if (vm.mJoinRequest.isByName() && vm.mJoinRequest.nameSelected.isValid()) {
          logger.d(LOG_TAG, 'onSubmitJoinRequest: name', vm.mJoinRequest.nameSelected.getName());

          vm.mJoinRequest.memberToSubmit = member_response({
            name: vm.mJoinRequest.nameSelected.getName(),
            memberId: vm.mJoinRequest.nameSelected.getMemberId()
          });
          vm.$bvModal.show('submit-modal');
        }
      }

    },
    onGotoMySquad() {
      window.location.href = 'https://squads.mytogs.co.nz/';
    },
    onConfirmJoinRequest() {
      const vm = this;
      vm.$bvModal.hide('submit-modal');

      vm.$store.commit('setLoading', true);
      webapi.apiService.requestJoinSquad(
          vm.mData.invitationCode,
          vm.mData.reconcileId,
          vm.mData.coach,
          vm.mJoinRequest.memberToSubmit.getName(),
          vm.mJoinRequest.memberToSubmit.getMemberId(),

          (response) => {
            logger.d(LOG_TAG, 'onConfirmJoinRequest', response);
            if (vm.mData.coach) {
              window.location.href = vm.formatWelcomeRedirectLink(
                  vm.mData.invitationCode,
                  vm.mData.getInvitationData().getClubName(),
                  vm.mData.getInvitationData().getSquadName()
              );
            }
            vm.mJoinRequest.completed = true;
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            logger.w(LOG_TAG, 'onConfirmJoinRequest', error);
            vm.$notify({ type: 'error', text: error.data });
            vm.$store.commit('setLoading', false);
          }
      )
    }
  }
}
</script>

<style scoped>
.page_layout {
  position: absolute;
  width: 100%;
  height: 100%;
}
.gradient_background {
  background-image: linear-gradient(var(--background-blue), var(--background-blue-light))
}
.brand {
  max-width: 6.6rem;
}
.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.btn-outline-primary {
  color: var(--primary-highlight);
}
.hidden {
  visibility: hidden;
}
.show {
  visibility: visible;
}
</style>

