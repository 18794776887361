<template>
  <content-layout>
    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <!-- modal start -->


        <!-- modal end -->

        <div class="col-1 desktop"></div>
        <!-- content section-->
        <section
            class="col-10"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left;">
          <!-- create club section -->
          <section v-if="storeAuth.hasSquads()">
            <loader v-if="!mInitialized" :embedded="true" style="margin-top: 4rem; text-align: center"></loader>
            <div v-else>
              <!-- my squads-->
              <div class="mt-2">
                <section v-if="isAdmin">
                  <label class="my-text-primary" style="font-weight: bold;">Squads</label>
                  <div class="d-flex flex-row flex-wrap col-12 pl-0 pr-0">
                    <div v-for="(squad, index) in mSquads.options" v-bind:key="index"
                         class="
                         clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-1
                         mr-1 mb-1"
                         :class="{
                          'my-highlight-item-selected': squad.isValid() && squad.getSquadId() === mSquads.selected.getSquadId()
                        }">
                      <div v-if="squad.isValid()" v-on:click.stop="onSelectSquad(squad)">
                        <span>{{ squad.getSquadName() }}</span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section v-else>
            <label class="my-text-primary">You do not currently belong to any squad,
              please contact your club administrator or check your email inbox for pending squad invitation.</label>
          </section>

          <!-- squad details -->
          <section v-if="storeAuth.hasSquads() && mInitialized && mSquads.selected.isValid()" class="mt-3">
            <!-- squad name -->
            <div class="my-text-primary d-flex flex-row justify-content-between pl-1 pr-1 pt-2">
              <span style="font-size: 1em; font-weight: bold; text-transform: capitalize;">{{ mSquads.selected.getSquadName() }}</span>
            </div>
            <!-- tabs -->
            <div class="d-flex flex-row flex-wrap col-12 mt-3 pl-0 pr-0">
              <div v-for="(option, index) in mSquadOptions.options" v-bind:key="index"
                   class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2
                    ml-1 mb-1"
                   :class="{
                          'my-highlight-item-selected': option.value === mSquadOptions.selected.value
                        }"
                   v-on:click.stop="onSelectSquadOption(option)">
                <span>{{ option.text }}</span>
              </div>
            </div>

            <!-- tab options -->
            <squad-members
                v-if="mSquadOptions.isMembers()"
                :squad-ext="mSquads.selected"
                :disable-edit="true"
                :disable-share-link="true"
                :restricted-access="true">
            </squad-members>
            <squad-schedules
                v-else-if="mSquadOptions.isSchedules()"
                :squad-ext="mSquads.selected"
                :disable-edit="true">
            </squad-schedules>
            <squad-training-logs
                v-else-if="mSquadOptions.isTrainingLogs()"
                :squad-ext="mSquads.selected"
                :disable-edit="true"
                :restricted-access="true">
            </squad-training-logs>
          </section>

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>

import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import SquadMembers from "@/views/content/components/squad_members"
import SquadSchedules from "@/views/content/components/squad_schedules"
import SquadTrainingLogs from "@/views/content/components/squad_training_logs"
import DateTimeHelper from "@/util/datetime_helper"

import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'my_squads';

export default {
  name: LOG_TAG,
  display: 'My Squads',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader,
    SquadMembers,
    SquadSchedules,
    SquadTrainingLogs
  },
  props: {
    placeHolder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return LOG_TAG;
      }
    }
  },
  data() {
    return {
      mInitialized: false,
      mSquads: {
        selected: model.squadResponse(),
        options: [],
        clear() {
          this.selected = model.squadResponse();
          this.options = [];
        },
        //
      },
      mCreateSquad: {
        name: '',
        isValid() {
          return this.name.length > 0;
        },
        clear() {
          this.name = '';
        }
      },
      mDeleteSquad: model.squadInfo(),
      mSquadOptions: {
        selected: {text: '', value: ''},
        options: [
          {text: 'Members', value: 'members'},
          {text: 'Schedules', value: 'schedules'},
          {text: 'Training logs', value: 'training_logs'},
        ],
        isMembers() {
          return this.selected.value === this.options[0].value;
        },
        isSchedules() {
          return this.selected.value === this.options[1].value;
        },
        isTrainingLogs() {
          return this.selected.value === this.options[2].value;
        },
        init() {
          if (this.selected.value.length === 0) {
            this.selected = this.options[0];
          }
        }
      },
      mMessageData: model.notificationInfo(),
      mMessageDataTimestamp: DateTimeHelper.currentTimeInSecond()
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeSquadsExcludeCoached() {
      return this.storeAuth.getSquadsExcludeCoached();
    },
  },
  mounted() {
    const vm = this;
    if (vm.storeAuth.hasSquads()) {
      vm.refresh();
    }
  },
  methods: {
    refresh() {
      const vm = this;
      if (vm.storeAuth.hasSquads()) {

        const clubSquads = vm.storeSquadsExcludeCoached.reduce(function(filtered, data) {
          const clubSquad = model.squadResponse(data);
          const existSquad = filtered.find((squad) => squad.getSquadId() === clubSquad.getSquadId());
          if (!existSquad) {
            filtered.push(clubSquad);
          }

          return filtered;
        }, []);

        vm.mSquads.clear();
        vm.mSquads.options = clubSquads;
        vm.mSquadOptions.init();
        vm.onSelectSquad(clubSquads[0]);
      }
    },
    onSelectSquad(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectSquad', value);
      // squad info also contains club info
      vm.mSquads.selected = model.squadResponseExt(value);
      vm.mInitialized = true;
    },
    onSelectSquadOption(value) {
      const vm = this;
      vm.mSquadOptions.selected = value;
    },
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
