//import displayHelper from "@/util/display_helper"
import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      action: '',
      timestamp: 0,
    };
  }
  object.getTimestamp = function getTimestamp() {
    return object.timestamp ? object.timestamp : 0;
  }
  object.getAction = function getAction() {
    return object.action ? object.action : '';
  }
  object.formattedTimestamp = function formattedTimestamp() {
    if (object.getTimestamp() > 0) {
      return datetimeHelper.formattedDateTime2(this.getTimestamp() * 1000);
    } else {
      return ''
    }
  }
  return object;
}

