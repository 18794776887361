// import displayHelper from "@/util/display_helper"
import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      sessionTime: 0,
      dayOfWeek: 0,
      startTime: '',
      description: '',
      type: '' // present, absent
    };
  }
  object.getSessionTime = function getSessionTime() {
    return object.sessionTime ? object.sessionTime : 0;
  }
  object.getDayOfWeek = function getDayOfWeek() {
    return object.dayOfWeek ? object.dayOfWeek : 0;
  }
  object.getStartTime = function getStartTime() {
    return object.startTime ? object.startTime : '';
  }
  object.getDescription = function getDescription() {
    return object.description ? object.description : '';
  }
  object.getType = function getType() {
    return object.type ? object.type : '';
  }
  object.toggleAbsent = function toggleAbsent() {
    object.type = 'Absent';
    return object;
  }
  object.togglePresent = function togglePresent() {
    object.type = 'Present';
    return object;
  }
  object.isAbsent = function isAbsent() {
    return object.getType() === 'Absent';
  }
  object.formattedSessionTime = function formattedSessionTime() {
    return datetimeHelper.formattedDateTime(this.getSessionTime(), true);
  }
  object.formattedDayOfWeek = function formattedDayOfWeek(short) {
    switch (object.getDayOfWeek()) {
      case 1:
        return short ? 'Mon' : 'Monday';
      case 2:
        return short ? 'Tue' : 'Tuesday';
      case 3:
        return short ? 'Wed' : 'Wednesday';
      case 4:
        return short ? 'Thu' : 'Thursday';
      case 5:
        return short ? 'Fri' : 'Friday';
      case 6:
        return short ? 'Sat' : 'Saturday';
      case 7:
        return short ? 'Sun' : 'Sunday';

    }
    return '';
  }
  return object;
}

