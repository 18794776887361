import datetimeHelper from "@/util/datetime_helper";
/*
{
    "title":"Sam's test message5",
    "body":"private message test",
    "to":"Pm9E03pCsYaqNYRLU355V8eAanf1",  // only if its private DM
    "toCustName":"Yoichi Chan" // only if its private DM
}
 */
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      messageId: '',
      title: '',
      body: '',
      scheduledDate: 0,
      subRegion: [],
      to: '',
      toUserUuid: '',
      toCustName: '',
      createdAt: 0,
      linkText: '',
      link: '',
    };
  }
  object.setTitle = function setTitle(value) {
    object.title = value;
    return this;
  }
  object.setTo = function setTo(value) {
    object.to = value;
    return this;
  }
  object.setToUserUuid = function setToUserUuid(value) {
    object.toUserUuid = value;
    return this;
  }
  object.setCustomName = function setCustomName(value) {
    object.toCustName = value;
    return this;
  }
  object.setBody = function setBody(value) {
    object.body = value;
    return this;
  }
  object.setSubRegion = function setSubRegion(value) {
    object.subRegion = value;
    return this;
  }
  object.getMessageId = function getMessageId() {
    return object.messageId ? object.messageId : '';
  }
  object.getScheduledDate = function getScheduledDate() {
    return object.scheduledDate ? object.scheduledDate : 0;
  }
  object.formattedScheduledDate = function formattedScheduledDate() {
    return datetimeHelper.formattedDateTime(this.getScheduledDate());
  }
  object.getCreatedAt = function getCreatedAt() {
    return object.createdAt ? object.createdAt : 0;
  }
  object.getLinkText = function getLinkText() {
    return object.linkText ? object.linkText : '';
  }
  object.getLink = function getLink() {
    return object.link ? object.link : '';
  }
  object.formattedCreateAt = function formattedCreateAt() {
    return datetimeHelper.formattedDateTime(this.getCreatedAt());
  }
  object.formattedCreateAtDate = function formattedCreateAt() {
    return datetimeHelper.formattedDateTime(this.getCreatedAt(), true);
  }
  object.formattedCreateAtTime = function formattedCreateAt() {
    return datetimeHelper.formattedTime(this.getCreatedAt());
  }
  object.getSubRegion = function getSubRegion() {
    return object.subRegion ? object.subRegion : [];
  }
  object.hasAction = function hasAction() {
    return object.getLinkText() && object.getLink();
  }
  return object;
}

