import apiHelper from "@/webapi/api_helper"
import logger from "@/util/logger"

// apis
const TARGET_API = 'api';

const API_AUTH = 'auth';
const API_MMCONVERT = 'mmconvert';
const API_EVENT_HYV = 'hyv';
const API_MEET_ENTRY = 'meetentry';
/*
curl --location --request POST 'https://togs-prod.pushstack.io:8001/api/meetentry?meetname=2022 North Island Secondary School Swimming Championships' \
--header 'Connection: keep-alive' \
--header 'sec-ch-ua: "Chromium";v="88", "Google Chrome";v="88", ";Not A Brand";v="99"' \
--header 'sec-ch-ua-mobile: ?0' \
--header 'Authorization: {{api_key}}' \
--form '=@"AyFsMmCQ_/meet-004868.hy3"'
 */

const API_APP_BANNER = 'app/banner';
const API_ADMIN_RESET_PASSWORD = 'admin/forgotpassword';
const API_ADMIN_BROADCAST = 'admin/broadcast';
const API_ADMIN_PUSH = 'admin/push';
const API_ADMIN_HY3_FILES = 'admin/hy3files';
const API_ADMIN_MYSQUAD = 'admin/mysquad';
const API_ADMIN_USER = 'admin/user';

const API_CLUBS = 'clubs';
const API_MYTOGS = 'mytogs';
const API_MYSQUAD = 'mysquad';
const API_CLUB_ADMIN = 'clubAdmin';

const API_MEMBER = 'member';
const API_CREATE_CLUB = 'createclub';
const API_CREATE_SQUAD = 'createsquad';
const API_COACH = 'coach';
const API_SWIMMER = 'swimmer';

const API_TRAINING_SET = 'trainingset';
const API_TRAINING_SCHEDULE = 'schedule';
const API_SCHEDULE_EXCLUSION = 'scheduleexclusion';
const API_MESSAGES = 'messages';
const API_INVITATION = 'invitation';
const API_SQUAD_MEMBER = 'squadmember';

const API_CREATE_USER = 'createuser';
const API_FASTLANE = 'fastlane';
const API_APPROVE = 'approve';
const API_ADD_MYTOGS = 'addmytogs';
const API_REJECT = 'reject';
const API_CHART = 'chart';
const API_RANKING = 'ranking';
const API_TRAINING_SESSIONS = 'trainingsessions';
const API_GOAL = 'goal';
const API_ATTENDANCE = 'attendance';
const API_NOTES = 'notes';

//const API_USER = 'user';
//const API_JOIN_SQUAD = 'joinsquad';

const PARAM_EMAIL = 'email';
const PARAM_NAME = 'name';
const PARAM_TIME_FROM = 'timefrom';
const PARAM_TIME_TO = 'timeto';
const PARAM_TO = 'to';
const PARAM_INVITE_CODE = 'invitecode';
const PARAM_RECONCILE_ID = 'reconcileId';
const PARAM_IS_COACH = 'coach';
const PARAM_FASTLANE_ID = 'fastlaneid';
const PARAM_SQUAD_MEMBER_ID = 'squadmemberid';
const PARAM_SIGN_UP = 'signup';
const PARAM_COACH_ID = 'coachId';

const PARAM_DISTANCE = 'distance';
const PARAM_STROKE = 'stroke';
const PARAM_COURSE = 'course';
const PARAM_MEET_NAME = 'meetname';

//mysquad/createuser?invitecode={{inviteCode}}
//invitation/fastlane?fastlaneid=318950
// /mysquad/{{clubId}}/{{squadId}}/approve?squadmemberid={{squadmemberId}}

const LOG_TAG = 'api_service';
export default {
  validateInvitation(code, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_INVITATION);

    url = url.concat('?', PARAM_INVITE_CODE, '=', code);

    logger.d(LOG_TAG, 'validateInvitation', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  validateReconcileId(reconcileId, isCoach, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_SQUAD_MEMBER);

    url = url.concat('?', PARAM_RECONCILE_ID, '=', reconcileId);
    if (isCoach) {
      url = url.concat('&', PARAM_IS_COACH, '=', isCoach);
    }

    logger.d(LOG_TAG, 'validateReconcileId', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  requestJoinSquad(code, reconcileId, isCoach, name, memberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_INVITATION);

    url = url.concat('?', PARAM_INVITE_CODE, '=', code);
    if (isCoach) {
      url = url.concat('&', PARAM_IS_COACH, '=', isCoach);
    }
    logger.d(LOG_TAG, 'requestJoinSquad', url);
    const payload = {
      'inviteCode': code,
      'customName': name,
    }
    if (memberId) {
      payload.memberId = memberId;
    }
    if (reconcileId) {
      payload.reconcileId = reconcileId;
    }
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  requestLinkSwimmerToMyTogsAccount(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_ADD_MYTOGS);


    logger.d(LOG_TAG, 'requestLinkSwimmerToMyTogsAccount', url);
    apiHelper.apiPost(
      url, payload,
      onResponseCb, onErrorCb);
  },
  approveSquadJoinRequest(clubId, squadId, squadMemberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_APPROVE);

    url = url.concat('?', PARAM_SQUAD_MEMBER_ID, '=', squadMemberId);

    logger.d(LOG_TAG, 'approveSquadJoinRequest', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  rejectSquadJoinRequest(clubId, squadId, squadMemberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_REJECT);

    url = url.concat('?', PARAM_SQUAD_MEMBER_ID, '=', squadMemberId);

    logger.d(LOG_TAG, 'rejectSquadJoinRequest', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  createNewUser(code, uId, email, name, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, API_CREATE_USER);

    url = url.concat('?', PARAM_INVITE_CODE, '=', code);

    logger.d(LOG_TAG, 'createNewUser', url);
    apiHelper.apiPost(url, {
      'userUuid': uId,
      'userEmail': email,
      'userName': name,
    }, onResponseCb, onErrorCb);
  },
  autoLogin(uId, lastMyTogsLogin, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_AUTH);

    url = url.concat('?', 'userUuid', '=', uId);
    url = url.concat('&', 'lastMyTogsLogin', '=', lastMyTogsLogin);

    logger.d(LOG_TAG, 'autoLogin', url);
    apiHelper.apiPost(url, null, onResponseCb, onErrorCb);
  },
  signIn(userName, password, refreshToken, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_AUTH);

    logger.d(LOG_TAG, 'signIn', url);
    apiHelper.apiPost(url, {
      'username': userName,
      'password': password,
      'refreshToken': refreshToken,
    }, onResponseCb, onErrorCb);
  },
  getUser(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_USER, uuid);

    logger.d(LOG_TAG, 'getUser', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  queryFastlaneMember(fastlaneId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_INVITATION, API_FASTLANE);

    url = url.concat('?', PARAM_FASTLANE_ID, '=', fastlaneId);

    logger.d(LOG_TAG, 'queryFastlaneMember', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  uploadUnofficialResult(file, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MMCONVERT);

    logger.d(LOG_TAG, 'uploadUnofficialResult', url);
    let formData = new FormData();
    formData.append('file', file);
    apiHelper.apiUpload(
      url,
      formData,
      onResponseCb, onErrorCb);
  },
  uploadEventHYV(file, startDate, endDate, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_EVENT_HYV);

    url = url.concat('?', 'qualifystart', '=', startDate);
    url = url.concat('&', 'qualifyend', '=', endDate);

    logger.d(LOG_TAG, 'uploadEventHYV', url);
    let formData = new FormData();
    formData.append('file', file);
    apiHelper.apiUpload(
      url,
      formData,
      onResponseCb, onErrorCb);
  },
  uploadPyschSheet(file, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MEET_ENTRY);

    url = url.concat('?', PARAM_MEET_NAME, '=', file.name);

    logger.d(LOG_TAG, 'uploadPyschSheet', url);
    let formData = new FormData();
    formData.append('file', file);
    apiHelper.apiUpload(
      url,
      formData,
      onResponseCb, onErrorCb);
  },
  getAppBanners(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_APP_BANNER);

    logger.d(LOG_TAG, 'getAppBanners', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  createBanner(payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_APP_BANNER);

    logger.d(LOG_TAG, 'createBanner', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  createBroadcast(payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_BROADCAST);

    logger.d(LOG_TAG, 'createBroadcast', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  deleteBanner(start, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_APP_BANNER, start);

    logger.d(LOG_TAG, 'deleteBanner', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  resetPassword(email, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(
      TARGET_API,
      API_ADMIN_RESET_PASSWORD);

    url = url.concat('?', PARAM_EMAIL, '=', email);

    logger.d(LOG_TAG, 'resetPassword', url);
    apiHelper.apiGet(
      url,
      onResponseCb, onErrorCb);
  },
  createScheduledPush(payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_PUSH);

    logger.d(LOG_TAG, 'createScheduledPush', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getScheduledPushList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_PUSH);

    logger.d(LOG_TAG, 'getScheduledPushList', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  deleteScheduledPush(messageId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_PUSH, messageId);

    logger.d(LOG_TAG, 'deleteScheduledPush', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  getHY3Files(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_HY3_FILES);

    logger.d(LOG_TAG, 'getHY3Files', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getHY3FileContent(filename, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_HY3_FILES, filename);

    logger.d(LOG_TAG, 'getHY3FileContent', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getEventHYVs(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_EVENT_HYV);

    logger.d(LOG_TAG, 'getEventHYVs', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  //
  // my squad apis
  //
  getClubs(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_CLUBS);

    logger.d(LOG_TAG, 'getClubs', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  searchMember(email, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYTOGS, API_MEMBER);

    url = url.concat('?', PARAM_EMAIL, '=', email);

    logger.d(LOG_TAG, 'searchMember', url);
    apiHelper.apiGet(
      url,
      onResponseCb, onErrorCb);
  },
  getClub(clubId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId);

    logger.d(LOG_TAG, 'getClub', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  createClub(payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, API_CREATE_CLUB);

    logger.d(LOG_TAG, 'createClub', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  addClubAdmin(clubId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_MYSQUAD, API_CLUBS, clubId, API_CLUB_ADMIN);

    logger.d(LOG_TAG, 'addClubAdmin', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getClubAdmin(clubId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_MYSQUAD, API_CLUBS, clubId, API_CLUB_ADMIN);

    logger.d(LOG_TAG, 'getClubAdmin', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  removeClubAdmin(clubId, userId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ADMIN_MYSQUAD, API_CLUBS, clubId, API_CLUB_ADMIN, userId);

    logger.d(LOG_TAG, 'removeClubAdmin', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },


  createSquad(clubId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, API_CREATE_SQUAD);

    logger.d(LOG_TAG, 'createSquad', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  deleteSquad(clubId, squadId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId);

    logger.d(LOG_TAG, 'deleteSquad', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  getSquad(clubId, squadId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId);

    logger.d(LOG_TAG, 'getSquad', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  inviteCoach(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_COACH);

    logger.d(LOG_TAG, 'inviteCoach', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  inviteCoachNewSignUp(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_COACH);
    url = url.concat('?', PARAM_SIGN_UP, '=', 'new');

    logger.d(LOG_TAG, 'inviteCoachNewSignUp', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  removeCoach(clubId, squadId, uId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_COACH);

    url = url.concat('?', PARAM_COACH_ID, '=', uId);

    logger.d(LOG_TAG, 'removeCoach', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  inviteMember(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER);

    logger.d(LOG_TAG, 'inviteMember', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  inviteMemberNewSignUp(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER);
    url = url.concat('?', PARAM_SIGN_UP, '=', 'new');

    logger.d(LOG_TAG, 'inviteMemberNewSignUp', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  updateMember(clubId, squadId, squadMemberId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId);

    logger.d(LOG_TAG, 'updateMember', url);
    apiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  addMemberGoalTargetTime(clubId, squadId, squadMemberId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_GOAL);

    logger.d(LOG_TAG, 'addMemberGoalTargetTime', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  removeMemberGoalTargetTime(clubId, squadId, squadMemberId, distance, stroke, course, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_GOAL);

    url = url.concat('?', PARAM_DISTANCE, '=', distance, '&', PARAM_STROKE, '=', stroke, '&', PARAM_COURSE, '=', course);

    logger.d(LOG_TAG, 'removeMemberGoalTargetTime', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  updateMemberGoal(clubId, squadId, squadMemberId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_GOAL);

    logger.d(LOG_TAG, 'updateMemberGoal', url);
    apiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  getMemberGoal(clubId, squadId, squadMemberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_GOAL);

    logger.d(LOG_TAG, 'getMemberGoal', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  removeMember(clubId, squadId, squadMemberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId);

    logger.d(LOG_TAG, 'removeMember', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  searchSwimmer(clubId, squadId, freeText, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_SWIMMER);

    url = url.concat('?', PARAM_NAME, '=', freeText);

    logger.d(LOG_TAG, 'searchSwimmer', url);
    apiHelper.apiGet(
      url,
      onResponseCb, onErrorCb);
  },
  getSwimmer(clubId, squadId, memberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_SWIMMER, memberId);

    logger.d(LOG_TAG, 'getSwimmer', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  // messages
  /*
  {
    "from":"FBeG6KWDrEfKUpyI2Q5etm1kMz73",
    "to":"Pm9E03pCsYaqNYRLU355V8eAanf1", // if private
    "title":"Sam'\''s test message",
    "body":"private message."
}
   */
  broadcastMessage(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MESSAGES);

    logger.d(LOG_TAG, 'broadcastMessage', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getMessages(clubId, squadId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MESSAGES);

    logger.d(LOG_TAG, 'getMessages', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getMemberMessages(clubId, squadId, userId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MESSAGES);

    url = url.concat('?', PARAM_TO, '=', userId);

    logger.d(LOG_TAG, 'getMemberMessages', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },

  // training sets
  createTrainingSet(clubId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, 'unknown', API_TRAINING_SET);

    logger.d(LOG_TAG, 'createTrainingSet', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getTrainingSets(clubId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, 'unknown', API_TRAINING_SET);

    logger.d(LOG_TAG, 'getTrainingSets', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);

  },
  removeTrainingSet(clubId, trainingSetId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, 'unknown', API_TRAINING_SET, trainingSetId);

    logger.d(LOG_TAG, 'removeTrainingSet', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);

  },
  // training schedule
  updateTrainingSchedule(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_TRAINING_SCHEDULE);
    logger.d(LOG_TAG, 'updateTrainingSchedule', url);
    apiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  // training off days
  updateTrainingOffDays(clubId, squadId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_SCHEDULE_EXCLUSION);
    logger.d(LOG_TAG, 'updateTrainingOffDays', url);
    apiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  querySwimmerPerformance(clubId, squadId, memberId, distance, course, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_SWIMMER, memberId, API_CHART);

    logger.d(LOG_TAG, 'querySwimmerPerformance', url);
    apiHelper.apiPost(url, {
      distance: distance,
      course: course,
    }, onResponseCb, onErrorCb);
  },
  /*
  {
    "stroke":"Freestyle",
    "distance":"100",
    "gender":"Male",
    "course":50,
    "yearfrom":"2010",
    "yearto":"2020",
    "juniors":false,
    "swimmerList": ["0060291", "0064086"]
}
   */
  querySquadRanking(clubId, squadId, filterSet, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_RANKING);

    logger.d(LOG_TAG, 'querySquadRanking', url);
    apiHelper.apiPost(url, filterSet, onResponseCb, onErrorCb);
  },
  queryTrainingSessions(clubId, squadId, startOfWeek, endOfWeek, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_TRAINING_SESSIONS);
    url = url.concat('?', PARAM_TIME_FROM, '=', startOfWeek);
    url = url.concat('&', PARAM_TIME_TO, '=', endOfWeek);

    logger.d(LOG_TAG, 'queryTrainingSessions', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  updateTrainingSessionAttendance(clubId, squadId, sessionId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_TRAINING_SESSIONS, sessionId);

    logger.d(LOG_TAG, 'updateTrainingSessionAttendance', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  updateTrainingSessionNote(clubId, squadId, sessionId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_TRAINING_SESSIONS, sessionId);

    logger.d(LOG_TAG, 'updateTrainingSessionNote', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  updateTrainingSessionAttachments(clubId, squadId, sessionId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_TRAINING_SESSIONS, sessionId);

    logger.d(LOG_TAG, 'updateTrainingSessionAttachments', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  querySquadAttendance(clubId, squadId, timeFrom, timeTo, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_ATTENDANCE);
    url = url.concat('?', PARAM_TIME_FROM, '=', timeFrom);
    url = url.concat('&', PARAM_TIME_TO, '=', timeTo);

    logger.d(LOG_TAG, 'querySquadAttendance', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  addCoachNotes(clubId, squadId, squadMemberId, payload, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_NOTES);

    logger.d(LOG_TAG, 'addCoachNotes', url);
    apiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getCoachNotes(clubId, squadId, squadMemberId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_MYSQUAD, clubId, squadId, API_MEMBER, squadMemberId, API_NOTES);

    logger.d(LOG_TAG, 'getCoachNotes', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  }
}