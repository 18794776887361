// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      club: '',
      claimed: false,
      clubId: ''
    };
  }
  object.getId = function getId() {
    return object.clubId ? object.clubId : '';
  }
  object.isValid = function isValid() {
    return this.getId().length > 0;
  }
  object.isClaimed = function isClaimed() {
    return object.claimed ? object.claimed : false;
  }
  object.getName = function getName() {
    return object.club ? object.club : '';
  }
  return object;
}

