<template>
  <div>
    <b-modal centered title="Edit Splits" id="edit-splits-modal" :hide-footer="true">
      <div style="min-width: 8rem; font-size: 0.75em">

        <div class="d-flex flex-row justify-content-between">
          <div class="ml-1">
            <div style="font-size: 1em; font-weight: bold"> {{mEditTargetSplit.selected.formattedEvent() }} ({{mEditTargetSplit.selected.formattedCourse() }})</div>
            <div class="mt-1 ml-1" style="font-size: 0.85em; font-weight: bold;"> Splits:</div>
          </div>
          <div class="text-right">
            <div class="mt-1" style="font-weight: bold"> {{mEditTargetSplit.updateTotalTime }}</div>
          </div>
        </div>
        <separator-line class="mt-2 mb-3"></separator-line>
        <div v-for="(split, index) in mEditTargetSplit.newSplits" :key="index" class="mt-1 ml-2 mr-2">
          <div class="d-flex flex-row justify-content-between">
            <div> {{split.getDistance() }}</div>
            <input
                type="text"
                class="form-control"
                v-mask="split.mask"
                style="max-width: 6rem"
                v-model="split.split"
                v-on:input="onNotifySplitTimeChange(split)"
            >
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3">
        <b-button class="my-button-confirm"
                  style="min-width: 4.3rem"
                  :disabled="!mEditTargetSplit.isUpdated"
                  v-on:click.stop="onUpdateSplit()">
          Update
        </b-button>
      </div>
    </b-modal>

    <div v-if="!restrictedAccess">
      <b-form-group label="Target distance & stroke:" label-for="goal-select" style="font-size: 0.85em">
        <b-form-select id="goal-select" v-model="mGoalOptions.selected" :options="mGoalOptions.options"></b-form-select>
      </b-form-group>

      <div class="d-flex flex-row justify-content-start">
        <b-form-group label="Target course:" label-for="course-select"
                      style="font-size: 0.85em; margin-top: 3px">
          <b-form-select id="course-select"
                         style="width: 8.3rem"
                         :disabled="mGoalOptions.selected === 'select'"
                         v-model="mGoalOptions.selectedCourse" :options="mGoalOptions.courseOptions"></b-form-select>
        </b-form-group>
        <div class="ml-2">
          <label
              style="font-size: 0.85em;">Target time:</label>
          <input
              type="text"
              class="form-control"
              v-mask="mGoalOptions.targetTimeMask"
              style="max-width: 8rem"
              :placeholder="'e.g. '+mGoalOptions.targetTimeHint"
              :disabled="mGoalOptions.selected === 'select'"
              v-model="mGoalOptions.targetTime">
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <b-button class="my-button-confirm"
                  style="min-width: 4.3rem"
                  :disabled="!mGoalOptions.isValid"
                  v-on:click.stop="onAddGoal()">
          Add
        </b-button>
      </div>
    </div>

    <div v-if="mGoalHistory.options.length > 0"
         class="mt-3" style="width: 100%; overflow-y: auto;">
      <div v-for="(target, index) in mGoalHistory.options" :key="index"
           class="my-box my-box-decoration-dark p-1 ml-1 mr-1 mb-1"
           :class="{
            'clickable': !restrictedAccess,
           }"
           style="min-width: 8rem; font-size: 0.75em"
          v-on:click.stop="onEditSplit(target)">


        <div class="d-flex flex-row justify-content-between mr-1">
          <div class="ml-1">
            <div style="font-size: 1em; font-weight: bold"> {{target.formattedEvent() }} ({{target.formattedCourse() }})</div>
            <div class="mt-1 ml-1" style="font-size: 0.85em; font-weight: bold;"> Splits:</div>
          </div>
          <div class="text-right">
            <font-awesome-icon
                icon="times-circle"
                size="sm"
                class="my-btn-warning"
                :class="{
                  'hidden': restrictedAccess,
                  'clickable': !restrictedAccess,
                }"
                v-on:click.stop="restrictedAccess ? null : onRemoveGoal(target)"
            />
            <div class="mt-1" style="font-weight: bold"> {{target.formattedTime() }}</div>
          </div>
        </div>
        <separator-line></separator-line>
        <div v-for="(split, index) in target.getSplits()" :key="index" class="mt-1 ml-2 mr-2">
          <div class="d-flex flex-row justify-content-between">
            <div> {{split.getDistance() }}</div>
            <div> {{split.getSplit() }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="min-height: 16rem"></div>
  </div>
</template>
<script>

import model from "@/model";
import SeparatorLine from "@/components/separator_line"
import MySquadBloc from "@/bloc/mysquad_bloc";
import datetimeHelper from "@/util/datetime_helper"
import displayHelper from "@/util/display_helper"
import logger from "@/util/logger"

const LOG_TAG = 'profile_update_goal_target_time';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    member: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    restrictedAccess: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data () {
    return {
      mSquadExt: model.squadResponseExt(),
      mMember: model.memberResponse(),
      mSwimmer: model.memberResponse(),
      mGoal: model.goalInfo(),
      mDistanceOptions: {
        options: [
          {text: '50', value: '50'},
          {text: '100', value: '100'},
          {text: '200', value: '200'},
          {text: '400', value: '400'},
          {text: '800', value: '800'},
          {text: '1500', value: '1500'},
        ],
      },
      mStrokeOptions: {
        options: [
          {text: displayHelper.STROKE_FREESTYLE, value: 'Freestyle'},
          {text: displayHelper.STROKE_BACKSTROKE, value: 'Backstroke'},
          {text: displayHelper.STROKE_BREASTSTROKE, value: 'Breaststroke'},
          {text: displayHelper.STROKE_BUTTERFLY, value: 'Butterfly'},
          {text: displayHelper.STROKE_IM, value: 'Individual Medley'},
        ],
      },
      mExcludeGoalTargets: {
        options: [
          {distance: '100', strokes: [
              'Individual Medley:lc',
            ]},
          {distance: '400', strokes: [
              'Backstroke:sc:lc',
              'Breaststroke:sc:lc',
              'Butterfly:sc:lc'
            ]},
          {distance: '800', strokes: [
              'Backstroke:sc:lc',
              'Breaststroke:sc:lc',
              'Butterfly:sc:lc',
              'Individual Medley:sc:lc'
            ]},
          {distance: '1500', strokes: [
              'Backstroke:sc:lc',
              'Breaststroke:sc:lc',
              'Butterfly:sc:lc',
              'Individual Medley:sc:lc'
            ]},
        ],
        getExclusions(distance) {
          return this.options.find((option) => option.distance === distance);
        }
      },
      mGoalOptions: {
        selected: 'select',
        options: [{
          text: 'Select',
          value: 'select',
        }],
        targetTime: '',
        targetTimeHint: '0:00.00',
        targetTimeMask: '#:##.##',

        // course
        selectedCourse: 'select',
        courseOptions: [
          {
            text: 'Select',
            value: 'select',
          },
          //{text: 'Short course', value: 25},
          //{text: 'Long course', value: 50},
        ],
        resetTargetOptions(targets) {
          this.options = targets;
          this.selected = 'select';
        },
        resetCourseOptions() {
          this.courseOptions = [{text: 'Select', value: 'select'}];
          this.selectedCourse = 'select';
        },
        validateCourseOptions(goal) {

          const splitTarget = this.selected.split(':');
          const distance = splitTarget[0];
          const stroke = splitTarget[1];
          const matches = goal.findMatchedTarget(distance, stroke);
          logger.d(LOG_TAG, 'validateCourseOptions', matches.length, this.selected);
          if (matches.length == 0) {
            if (distance === '100' && stroke === displayHelper.STROKE_IM) {
              this.courseOptions = [
                {text: 'Select', value: 'select'},
                {text: 'Short course', value: 25},
              ];
              this.selectedCourse = this.courseOptions[1].value;
            } else {
              this.courseOptions = [
                {text: 'Select', value: 'select'},
                {text: 'Short course', value: 25},
                {text: 'Long course', value: 50},
              ];
            }

          } else {
            const existingTarget = matches[0];
            this.courseOptions = [
              {text: 'Select', value: 'select'},
              {text: existingTarget.isSC() ? 'Long course' : 'Short course', value: existingTarget.isSC() ? 50 : 25},
            ];
            this.selectedCourse = this.courseOptions[1].value;
          }
        },
        validateTargetTimeFormat() {
          if (this.selected.includes('800') || this.selected.includes('1500')) {
            this.targetTimeHint = '00:00.00';
            this.targetTimeMask = '##:##.##';
          } else {
            this.targetTimeHint = '0:00.00';
            this.targetTimeMask = '#:##.##';
          }
        },
        validate() {
          logger.d(LOG_TAG, 'validate', this.targetTime);
          this.isValid =
              this.selected !== 'select' &&
              this.selectedCourse !== 'select' &&
              datetimeHelper.translateTimeToMilliseconds(this.targetTime) > 0;
        },
        isValid: false
      },
      mGoalHistory: {
        options: [],
      },
      mEditTargetSplit: {
        selected: model.targetInfo(),
        newSplits: [],
        isUpdated: false,
        updateTotalTime: '',

        setSelected(target) {
          logger.d(LOG_TAG, 'setSelected', 'target', target);
          const targetSplits = target.getSplits();

          // duplicate original splits
          this.newSplits = targetSplits.map((targetSplit) => model.splitInfo(targetSplit));
          this.isUpdated = false;
          this.updateTotalTime = target.formattedTime();

          this.newSplits.forEach((splitObj) => {
            const split = splitObj.split.toString();
            if (split.includes(':') && split.includes('.')) {
              const splitMin = split.split(':');
              const splitSec = splitMin[1].split('.');
              splitObj.mask = '';
              splitObj.mask += ''.padEnd(splitMin[0].length, '#').concat(':');
              splitObj.mask += ''.padEnd(splitSec[0].length, '#').concat('.');
              splitObj.mask += ''.padEnd(splitSec[1].length, '#');
            } else if (split.includes('.')) {
              const splitSec = split.split('.');
              splitObj.mask = '';
              splitObj.mask += ''.padEnd(splitSec[0].length, '#').concat('.');
              splitObj.mask += ''.padEnd(splitSec[1].length, '#');
            } else {
              splitObj.mask = '##.##';
            }
            logger.d(LOG_TAG, 'setSelected', splitObj.split, splitObj.mask);
          })
          this.selected = target;
        },
        validate() {
          this.isUpdated = false;
          const splits = this.selected.getSplits();


          for(let index = 0; splits.length > index; index++) {
            const origSplit = splits[index].split.toString();
            const newSplit = this.newSplits[index].split.toString();

            logger.d(LOG_TAG, 'validate', origSplit, newSplit);
            if (!newSplit || datetimeHelper.translateTimeToMilliseconds(newSplit) <= 0) {
              this.isUpdated = false;
              break;
            } else if (origSplit !== newSplit) {
              this.isUpdated = true;
              break;
            }
          }
          let updateTotalTimeInMilliseconds = this.newSplits.reduce(function(total, data) {
            return total += datetimeHelper.translateTimeToMilliseconds(data.split.toString());
          }, 0);
          this.updateTotalTime = datetimeHelper.translateMillisecondsToTime(updateTotalTimeInMilliseconds);
          logger.d(LOG_TAG, 'validate', updateTotalTimeInMilliseconds, this.updateTotalTime);
        },
        formatNewTarget() {
          let updatedSplitMS = 0;
          const updatedSplits = [];
          const splits = this.selected.getSplits();
          for(let index = 0; splits.length > index; index++) {
            const origSplitObj = splits[index];
            const origSplit = origSplitObj.getSplit().toString();
            const newSplit = this.newSplits[index].getSplit().toString();

            logger.d(LOG_TAG, 'formatNewTarget', origSplit, newSplit);
            if (origSplit !== newSplit) {
              //

              updatedSplits.push(origSplitObj.setCalibratedSplit(newSplit));
            } else {
              updatedSplits.push(origSplitObj);
            }
            // recalculate total ms
            const splitInSeconds = origSplitObj.formattedSplitInMilliseconds();
            updatedSplitMS += splitInSeconds;
            /*logger.d(LOG_TAG, 'formatNewTarget',
                'split',
                origSplitObj.getSplit(),
                splitInSeconds);*/
          }
          logger.d(LOG_TAG, 'formatNewTarget', 'total ms', updatedSplitMS);
          this.selected.setTime(updatedSplitMS);
          return this.selected;
        }
      }
    }
  },
  computed: {
  },
  watch: {
    squadExt: function onChange() {
      const vm = this;
      vm.refresh();
    },
    member: function onChange() {
      const vm = this;
      vm.refresh();
    },
    swimmer: function onChange() {
      const vm = this;
      vm.refresh();
    },
    'mGoalOptions.selected': function onChange() {
      const vm = this;
      vm.mGoalOptions.validateCourseOptions(vm.mGoal);
      vm.mGoalOptions.validateTargetTimeFormat();
      vm.mGoalOptions.validate();
    },
    'mGoalOptions.selectedCourse': function onChange() {
      const vm = this;
      vm.mGoalOptions.validate();
    },
    'mGoalOptions.targetTime': function onChange() {
      const vm = this;
      vm.mGoalOptions.validate();
    },
  },
  mounted() {
    const vm = this;
    vm.refresh();
  },
  methods: {
    refresh() {
      logger.d(LOG_TAG, 'refresh');
      const vm = this;
      vm.mSquadExt = vm.squadExt;
      vm.mMember = vm.member;
      vm.mSwimmer = vm.swimmer;

      MySquadBloc.syncMemberGoal(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          (goal) => {
            vm.onRefreshGoalTargets(goal);
          },
          () => {

          }
      )
    },
    onRefreshGoalTargets(goal) {
      const vm = this;
      if (goal) {
        //vm.mGoalHistory.options = goal ? goal.getTargets() : [];
        // reset available goal options
        const targets = goal.getTargets();
        logger.d(LOG_TAG, 'onRefreshGoalTargets', 'targets', targets.length);
        const options = [{
          text: 'Select goal',
          value: 'select',
        }];
        vm.mGoal = goal;
        vm.mDistanceOptions.options.forEach((distanceOption) => {
          const distance = distanceOption.text;
          const exclusions = vm.mExcludeGoalTargets.getExclusions(distance);

          //logger.d(LOG_TAG, 'syncMemberGoals', 'distanceOption', distance);
          vm.mStrokeOptions.options.forEach((strokeOption) => {
            const stroke = strokeOption.text;
            let splitExcludeStroke;
            if (exclusions) {
              const excludeStroke = exclusions.strokes.find((excludeStroke) => excludeStroke.includes(stroke));
              if (excludeStroke) {
                splitExcludeStroke = excludeStroke.split(':');
              }
            }
            //logger.d(LOG_TAG, 'syncMemberGoals', 'strokeOption', stroke);
            if (!splitExcludeStroke || splitExcludeStroke.length < 3) {
              const matches = goal.findMatchedTarget(distance, stroke);
              //logger.d(LOG_TAG, 'syncMemberGoals', index, distance.value, stroke.value);
              // 2 same targets implies both sc and lc already exist
              if (distance === '100' && stroke === displayHelper.STROKE_IM) {
                if (matches.length === 0) {
                  options.push({
                    text: distance + ' ' + stroke,
                    value: distance + ':' + stroke,
                  })
                }
              } else {
                if (matches.length < 2) {
                  options.push({
                    text: distance + ' ' + stroke,
                    value: distance + ':' + stroke,
                  })
                }
              }

            }

          })
        })
        // current history
        vm.mGoalHistory.options = targets;
        // reset options
        vm.mGoalOptions.resetTargetOptions(options);
        vm.mGoalOptions.resetCourseOptions();
        vm.mGoalOptions.targetTime = '';
      }

    },
    onAddGoal() {
      const vm = this;
      logger.d(LOG_TAG, 'onAddGoal',
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          vm.mGoalOptions.selected,
          vm.mGoalOptions.targetTime
      );
      const goalSplits = vm.mGoalOptions.selected.split(':');
      vm.$store.commit('setLoading', true);
      MySquadBloc.addMemberGoalTargetTime(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          [
              model.targetInfo()
              .setDistance(goalSplits[0])
              .setStroke(goalSplits[1])
              .setCourse(vm.mGoalOptions.selectedCourse)
              .setTime(datetimeHelper.translateTimeToMilliseconds(vm.mGoalOptions.targetTime))
          ],
          (goal) => {
            vm.onRefreshGoalTargets(goal);
            vm.$notify({ type: 'success', text: 'Settings updated successfully.' });
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$notify({ type: 'error', text: 'There was a problem updating goal settings, please try again later.' });
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onRemoveGoal(selected) {
      const vm = this;
      logger.d(LOG_TAG, 'onRemoveGoal',
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          selected
      );
      vm.$store.commit('setLoading', true);
      MySquadBloc.removeMemberGoalTargetTime(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          selected.getDistance(), selected.getStroke(), selected.getCourse(),
          (goal) => {
            vm.onRefreshGoalTargets(goal);
            vm.$notify({ type: 'success', text: 'Settings updated successfully.' });
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$notify({ type: 'error', text: 'There was a problem updating goal settings, please try again later.' });
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onEditSplit(selected) {
      const vm = this;
      if (!vm.restrictedAccess) {
        logger.d(LOG_TAG, 'onEditSplit', selected);
        vm.mEditTargetSplit.setSelected(model.targetInfo(selected));
        vm.$bvModal.show('edit-splits-modal');
      }
    },
    onNotifySplitTimeChange() {
      const vm = this;
      vm.mEditTargetSplit.validate();
    },
    onUpdateSplit() {
      const vm = this;
      const updatedTarget = vm.mEditTargetSplit.formatNewTarget();
      logger.d(LOG_TAG, 'onUpdateSplit', updatedTarget);
      vm.$bvModal.hide('edit-splits-modal');
      MySquadBloc.updateMemberGoalTargets(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          vm.mGoal.getTargets().map((target) => {
            return updatedTarget.isSame(target) ? updatedTarget : target
          }),
          (goal) => {
            vm.onRefreshGoalTargets(goal);
            vm.$notify({ type: 'success', text: 'Settings updated successfully.' });
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.$notify({ type: 'error', text: 'There was a problem updating goal settings, please try again later.' });
            vm.$store.commit('setLoading', false);
          }
      )
    }
  }
}
</script>

<style scoped>
</style>
