// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      lc_time_raw: 0,
      sc_time_raw: 0,
      lc_time: '',
      sc_time: ''
    };
  }
  object.getLcTimeRaw = function getLcTimeRaw() {
    return object.lc_time_raw ? object.lc_time_raw : 0;
  }
  object.getScTimeRaw = function getScTimeRaw() {
    return object.sc_time_raw ? object.sc_time_raw : 0;
  }
  object.getLcTime = function getLcTime() {
    return object.lc_time ? object.lc_time : '';
  }
  object.getScTime = function getScTime() {
    return object.sc_time ? object.sc_time : '';
  }
  return object;
}

