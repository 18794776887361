import { render, staticRenderFns } from "./profile_update_goal_memo.vue?vue&type=template&id=10a2719c&scoped=true&"
import script from "./profile_update_goal_memo.vue?vue&type=script&lang=js&"
export * from "./profile_update_goal_memo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a2719c",
  null
  
)

export default component.exports