<template>
  <content-layout>
    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <!-- modal start -->

        <div class="col-1 desktop"></div>
        <!-- content section-->
        <section
            class="col-10"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left;">
          <!-- create club section -->
          <section v-if="storeAuth.hasClub()">
            <loader v-if="!mInitialized" :embedded="true" style="margin-top: 4rem; text-align: center"></loader>
            <div v-else>
              <div class="d-flex flex-row justify-content-between">
                <label class="my-text-primary" style="font-size: 1.6em; font-weight: bold; text-transform: capitalize;">{{mClub.getClubName()}}</label>
              </div>

              <!-- my squads-->
              <div class="mt-2">
                <section v-if="windowWidth > 768">
                  <label class="my-text-primary" style="font-weight: bold;">Squads</label>
                  <div class="d-flex flex-row flex-wrap col-12 pl-0 pr-0">
                    <div v-for="(squad, index) in mSquads.options" v-bind:key="index"
                         class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-1
                            mr-1 mb-1"
                         :class="{
                          'my-highlight-item-selected': squad.isValid() && squad.getSquadId() === mSquads.selected.getSquadId()
                        }">
                      <div v-on:click.stop="onSelectSquad(squad)">
                        <span>{{ squad.getSquadName() }}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section v-else>
                  <b-form-group class="my-text-primary" label="Select squad" label-for="squad-select" style="font-size: 0.85em">
                    <b-form-select id="squad-select" v-model="mSquads.mobileSelected" :options="mSquads.mobileOptions"></b-form-select>
                  </b-form-group>
                </section>
              </div>
            </div>
          </section>
          <section v-else>
            <label class="my-text-primary">You do not currently belong to any club
              {{ isAdmin ? '' : ', please contact your club administrator.'}}</label>
          </section>

          <!-- squad details -->
          <section v-if="storeAuth.hasClub() && mInitialized && mSquads.selected.isValid()" class="mt-3">
            <!-- squad name -->
            <div class="my-text-primary d-flex flex-row justify-content-between pl-1 pr-1 pt-2">
              <span style="font-size: 1em; font-weight: bold; text-transform: capitalize;">{{ mSquads.selected.getSquadName() }}</span>
            </div>
            <!-- tabs -->
            <div class="d-flex flex-row flex-wrap col-12 mt-3 pl-0 pr-0">
              <div v-for="(option, index) in mSquadOptions.options" v-bind:key="index"
                   class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2
                    ml-1 mb-1"
                   :class="{
                          'my-highlight-item-selected': option.value === mSquadOptions.selected.value
                        }"
                   v-on:click.stop="onSelectSquadOption(option)">
                <span>{{ option.text }}</span>
              </div>
            </div>

            <!-- tab options -->
            <squad-attendance
                v-if="mSquadOptions.isAttendance()" :squad-ext="mSquads.selected">
            </squad-attendance>
          </section>

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>

import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import SquadAttendance from "@/views/content/components/squad_attendance"
import MySquadBloc from "@/bloc/mysquad_bloc";


import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'club_reports';

export default {
  name: LOG_TAG,
  display: 'Reports',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader,
    SquadAttendance
  },
  props: {
    placeHolder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return LOG_TAG;
      }
    }
  },
  data() {
    return {
      mInitialized: false,
      mClub: model.clubInfo(),
      mSquads: {
        selected: model.squadResponseExt(),
        options: [],

        mobileOptions: [],
        mobileSelected: '',
        setSquads(value) {
          this.options = value;
          this.selected = model.squadResponseExt();

          this.mobileOptions = [];
          value.forEach((data) => {
            this.mobileOptions.push({
              text: data.getSquadName(),
              value: data.getSquadId()
            })
          });
        },
        //
      },
      mCreateSquad: {
        name: '',
        isValid() {
          return this.name.length > 0;
        },
        clear() {
          this.name = '';
        }
      },
      mDeleteSquad: model.squadInfo(),
      mSquadOptions: {
        selected: {text: '', value: ''},
        options: [
          {text: 'Attendance', value: 'attendance'},
        ],
        isAttendance() {
          return this.selected.value === this.options[0].value;
        },
        init() {
          this.selected = this.options[0];
        }
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    storeClubSet() {
      return this.$store.state.clubsSet.set;
    },
    storeClubTimestamp() {
      return this.$store.state.clubsSet.timestamp;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
  },
  watch: {
    storeClubTimestamp: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:storeClubTimestamp', value);
      vm.onNotifyUpdateClub();
    },
    'mSquads.mobileSelected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:mobileSelected', value);
      vm.onSelectSquad(vm.mSquads.options.find((squad) => squad.getSquadId() === value));
    }
  },
  mounted() {
    const vm = this;
    if (vm.storeAuth.hasClub()) {
      vm.refresh();
    }
  },
  methods: {
    onNotifyUpdateClub() {
      const vm = this;
      const clubInfo = vm.storeClubSet[vm.storeAuth.getClubId()];
      if (clubInfo) {
        logger.d(LOG_TAG, 'onNotifyUpdateClub', clubInfo.getClubName());

        let clubSquads = [];
        // admin has access to all club squads
        clubInfo.getSquads().forEach((data) => {
          const squad = model.squadResponseExt(data)
              .setClubId(clubInfo.getClubId())
              .setClubName(clubInfo.getClubName())
              .setSquadSize(clubInfo.getSquadSize())
              .setSquadLimit(clubInfo.getSquadLimit());

          clubSquads.push(squad);
        });

        logger.d(LOG_TAG, 'onNotifyUpdateClub', 'squads', clubSquads.length);
        vm.mClub = clubInfo;
        vm.mSquads.setSquads(clubSquads);
        if (clubSquads.length > 0) {
          vm.onSelectSquad(clubSquads[0]);
        }
        vm.mInitialized = true;
        vm.mSquadOptions.init();
      }
    },
    refresh(selectedSquad) {
      logger.d(LOG_TAG, 'refresh', selectedSquad);
      const vm = this;
      const auth = vm.storeAuth;
      MySquadBloc.syncClub(
          auth.getClubId(),
          () => {
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            logger.d(LOG_TAG, 'syncClub', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onSelectSquad(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectSquad', value);
      vm.mSquads.selected = value;
      vm.mSquads.mobileSelected = value.getSquadId();
      vm.mSquadOptions.init();
    },
    onSelectSquadOption(value) {
      const vm = this;
      vm.mSquadOptions.selected = value;
    },
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
