<template>
  <content-layout>

    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <!-- add-admin-modal -->
        <b-modal centered
                 title="Add Club Admin"
                 id="add-admin-modal">

          <search-user-card
              :dark="true"
              :user="mClubAdmins.selected"
              :on-search-state="onSearchUserState">
          </search-user-card>

          <div class="d-flex flex-row justify-content-end mt-4">
            <b-button class="my-button-confirm"
                      :disabled="!mClubAdmins.selected.isValid()"
                      v-on:click.stop="onConfirmAddClubAdmin()">
              Add
            </b-button>
          </div>

          <template #modal-footer="{ ok }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-confirm" v-on:click.stop="ok()">
              Close
            </b-button>
          </template>
        </b-modal>

        <!-- delete-admin-modal -->
        <b-modal centered title="Remove Club Admin?" id="delete-admin-modal">
          <p class="my-4">
            Remove {{ mClubAdmins.selected.getValidatedName() }} from
            <span class="text-capitalize">{{mManagedClubs.selected.text}}</span>?
          </p>

          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-warning" v-on:click.stop="onConfirmDeleteClubAdmin">
              Remove
            </b-button>
            <b-button class="my-button-cancel" v-on:click.stop="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>

        <div class="col-1 desktop"></div>

        <!-- content section-->
        <div
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- create club section -->
          <section>
            <div class="d-flex flex-row justify-content-between my-text-primary my-highlight-item-light
             font-weight-bold clickable pl-2 pr-2 pt-1 pb-1"
            v-on:click.stop="onToggleSetupClub">
              <div>Create Club</div>
              <font-awesome-icon
                  :icon="mSetupClub.open ? 'chevron-up' : 'chevron-down'"
                  size="sm"
                  class="label mr-2 mt-auto mb-auto"/>
            </div>
            <separator-line></separator-line>

            <div v-if="mSetupClub.open">
              <b-form-select
                  class="mt-2"
                  style="text-transform: capitalize;"
                  v-model="mSetupClub.selectedClub"
                  :options="mUnclaimedClubs.options">
              </b-form-select>


              <div class=" mt-3">
                <label class="my-text-primary" style="font-size: 0.85em;">Max no. of squads per club:</label>
                <b-form-select
                    style="text-transform: capitalize;"
                    v-model="mSetupClub.squadLimit.selected"
                    :options="mSetupClub.squadLimit.options">
                </b-form-select>
              </div>
              <div class=" mt-3">
                <label class="my-text-primary" style="font-size: 0.85em;">Max no. of swimmers per squad:</label>
                <b-form-select
                    style="text-transform: capitalize;"
                    v-model="mSetupClub.squadSize.selected"
                    :options="mSetupClub.squadSize.options">
                </b-form-select>
              </div>


              <div class="d-flex flex-row justify-content-end mt-4">
                <button type="submit"
                        class="btn btn-outline-primary my-box-decoration ml-2"
                        style="width: 10rem"
                        :disabled="mSetupClub.disabled"
                        v-on:click.prevent="onCreateClub()">
                  Create
                </button>
              </div>
            </div>
          </section>

          <loader :embedded="true" v-if="mInit" style="margin-top: 4rem; text-align: center"></loader>
          <!-- managed club sections -->
          <section v-else class="mt-3">
            <div class="my-text-primary"
                 style="font-weight: bold;">Managed clubs:
            <span style="font-size: 0.85em">({{mManagedClubs.options.length}} / {{(
                mManagedClubs.options.length + mUnclaimedClubs.options.length
            )}})</span>
            </div>


            <!-- club selector -->
            <b-form-select
                class="mt-2 text-capitalize"
                v-model="mManagedClubs.selected">
              <b-form-select-option value="select">Select club</b-form-select-option>
              <b-form-select-option
                  v-for="(club, index) in mManagedClubs.options" :key="index"
                  :value="club">{{ club.text }}</b-form-select-option>
            </b-form-select>

            <div class="mt-4"></div>
            <!-- add admin -->
            <span
                v-if="mManagedClubs.selected !== 'select' && !mClubAdmins.loading && mClubAdmins.options.length > 0"
                class="my-text-primary font-weight-bold clickable" style="font-size: 0.85em"
                v-on:click.stop="onAddClubAdmin">
              <font-awesome-icon
                  icon="plus-circle"
                  size="sm"
                  class="label mr-2"/>
              <span>Add Club Admin</span>
            </span>

            <!-- admin list -->
            <div
                v-if="mManagedClubs.selected !== 'select'"
                class="my-box my-box-decoration mt-1 ml-1 mb-1 pl-2 pr-2 pb-2" style="min-height: 8rem">

              <div v-if="windowWidth > 768"
                   class="d-flex flex-row justify-content-between pl-1 pr-1"
                   style="height: 3rem; font-size: 0.85em">
                <div class="col-4 pl-1 align-self-center" style="text-align: left">ADMIN</div>
                <div class="col-4 align-self-center" style="text-align: center">EMAIL</div>
                <div class="col-4 pr-1 align-self-center" style="text-align: right"></div>
              </div>

              <separator-line class="pl-2 pr-2"></separator-line>

              <!-- swimmers -->
              <loader v-if="mClubAdmins.loading" :embedded="true" style="margin-top: 1rem; text-align: center"></loader>
              <div v-else>
                <div v-if="mClubAdmins.options.length == 0" style="margin-top: 1rem; text-align: center">
                  <span class="my-text-primary font-weight-bold mt-4 clickable" style="font-size: 0.85em"
                      v-on:click.stop="onAddClubAdmin">
                    <font-awesome-icon
                        icon="plus-circle"
                        size="sm"
                        class="label mr-2"/>
                    <span>Add Club Admin</span>
                  </span>
                </div>
                <div v-else>
                  <section v-if="windowWidth > 768">
                    <div v-for="(member, index) in mClubAdmins.options" :key="index"
                         class="my_text_md my-highlight-item d-flex flex-row justify-content-between pl-1 pr-1 pt-2"
                         style="height: 3.2rem">
                      <div class="col-4 pl-2 mt-2">
                        <div>
                          <div class="font-weight-bold text-capitalize">{{ member.getValidatedName() }}</div>
                        </div>
                      </div>
                      <div class="col-4 text-center mt-2">
                        <div class="d-flex flex-row justify-content-center">
                          <span>{{member.getEmail()}}</span>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <div class="my-hover-option mt-2 ml-2">
                          <font-awesome-icon
                              icon="times-circle"
                              size="lg"
                              class="clickable my-btn-warning"
                              v-on:click.stop="onDeleteClubAdmin(member)"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section v-else>
                    <div v-for="(member, index) in mClubAdmins.options" :key="index"
                         class="my_text_md my-highlight-item pl-1 pr-1"
                         style="height: 3.2rem">
                      <div class="d-flex flex-row justify-content-between pt-1 pb-1">
                        <div class="col-8">
                          <div class="mt-1">
                            <div class="font-weight-bold text-capitalize">{{ member.getValidatedName() }}</div>
                          </div>
                          <div>
                            <span>{{member.getEmail()}}</span>
                          </div>
                        </div>

                        <div class="col-4 text-right">
                          <div class="my-hover-option mt-2 ml-2">
                            <font-awesome-icon
                                icon="times-circle"
                                size="lg"
                                class="clickable my-btn-warning"
                                v-on:click.stop="onDeleteClubAdmin(member)"
                            />
                          </div>
                        </div>
                      </div>
                      <separator-line></separator-line>

                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>

        </div>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>

import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import SeparatorLine from "@/components/separator_line"
import SearchUserCard from "@/views/content/components/search_user_card"
import MySquadBloc from "@/bloc/mysquad_bloc";
import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'setup_club';

export default {
  name: LOG_TAG,
  display: 'Setup Club',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader,
    SearchUserCard,
    SeparatorLine
  },
  props: {
    placeHolder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return LOG_TAG;
      }
    }
  },
  data() {
    return {
      mInit: false,
      mUnclaimedClubs: {
        options: [],
        updateOptions(unclaimedClubs) {
          const newOptions = [
            {value: 'select', text: 'Select Club'}
          ]
          unclaimedClubs.forEach((club) => {
            newOptions.push({
              value: club,
              text: club.getName()
            })
          })
          this.options = newOptions;
        }
      },
      mSetupClub: {
        open: false,
        disabled: true,
        selectedClub: 'select',
        init() {
          this.open = false;
          this.selectedClub = 'select';
          this.squadLimit.init();
          this.squadSize.init();
        },
        squadLimit: {
          selected: 'select',
          options: [
            {text: 'Select max no. squad count', value: 'select'},
          ],
          init() {
            for (let index = 3; 5 >= index; index++) {
              this.options.push({
                text: index.toString(),
                value: index,
              });
            }
            this.selected = 3;
          }
        },
        squadSize: {
          selected: 'select',
          options: [
            {text: 'Select max single squad size', value: 'select'},
          ],
          init() {
            for (let index = 12; 30 >= index; index++) {
              this.options.push({
                text: index.toString(),
                value: index,
              });
            }
            this.selected = 12;
          }
        },
        validate() {
          const isValid = this.selectedClub !== 'select' &&
              this.squadLimit.selected !== 'select' &&
              this.squadSize.selected !== 'select';
          return isValid;
        }
      },
      mManagedClubs: {
        selected: 'select',
        options: [],
        updateOptions(claimedClubs, clubId) {
          const newOptions = []
          claimedClubs.forEach((club) => {
            newOptions.push({
              text: club.getName(),
              value: club,

            })
          })
          this.options = newOptions;
          if (clubId) {
            const selectedOption = newOptions.find((option) => option.value.getId() === clubId);
            if (selectedOption) {
              this.selected = selectedOption;
            }
          }
        }
      },
      mClubAdmins: {
        loading: false,
        selected: model.memberResponse(),
        options: [],
        setAdmins(value) {
          this.options = value;
        }
      }
    };
  },
  watch: {
    'mSetupClub.selectedClub': function (value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch', 'validate', value);
      vm.mSetupClub.disabled = !vm.mSetupClub.validate();
    },
    'mSetupClub.squadLimit.selected': function (value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch', 'validate', value);
      vm.mSetupClub.disabled = !vm.mSetupClub.validate();
    },
    'mSetupClub.squadSize.selected': function (value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch', 'validate', value);
      vm.mSetupClub.disabled = !vm.mSetupClub.validate();
    },
    'mManagedClubs.selected': function (value) {
      const vm = this;
      vm.onSelectClub(value);
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      MySquadBloc.syncAllClubs(
          false,
          (unclaimed, claimed) => {

            vm.mUnclaimedClubs.updateOptions(unclaimed);
            vm.mManagedClubs.updateOptions(claimed);
            vm.mSetupClub.init();
            vm.mInit = false;
            vm.$store.commit('setLoading', false);
          },
          () => {
            vm.mInit = false;
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onToggleSetupClub() {
      const vm = this;
      vm.mSetupClub.open = !vm.mSetupClub.open;
    },
    onSearchUserState(isSearching, isSuccess, user) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchUserState', isSearching, isSuccess, user);
      if (!isSearching && isSuccess) {
        vm.mClubAdmins.selected = user;
      }
    },
    onCreateClub() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      MySquadBloc.createClub(
        vm.mSetupClub.selectedClub.getId(),
        vm.mSetupClub.squadLimit.selected,
        vm.mSetupClub.squadSize.selected,
        (unclaimed, claimed) => {
          vm.mUnclaimedClubs.updateOptions(unclaimed);
          vm.mManagedClubs.updateOptions(claimed, vm.mSetupClub.selectedClub.getId());
          vm.mSetupClub.init();
          vm.mInit = false;
          vm.$notify({ type: 'success', text: 'Club created successfully' });
          vm.$store.commit('setLoading', false);
        },
        () => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later' });
          vm.$store.commit('setLoading', false);
        }
      )
    },
    onSelectClub(option) {
      const vm = this;
      if (option !== 'select') {
        vm.mClubAdmins.loading = true;
        const club = option.value;
        logger.d(LOG_TAG, 'onSelectClub', club.getName());
        MySquadBloc.syncClubAdmins(
            club.getId(),
            (clubAdmins) => {
              vm.mClubAdmins.setAdmins(clubAdmins);
              vm.mClubAdmins.loading = false;
            },
            () => {
              vm.mClubAdmins.loading = false;
            }
        )
      } else {
        vm.mClubAdmins.setAdmins([]);
      }
    },
    onAddClubAdmin() {
      const vm = this;
      vm.mClubAdmins.selected = model.memberResponse();
      vm.$bvModal.show('add-admin-modal');
    },
    onConfirmAddClubAdmin() {
      const vm = this;
      vm.mClubAdmins.loading = true;
      vm.$bvModal.hide('add-admin-modal');
      const payload = vm.mClubAdmins.selected.createClubAdminPayload();
      MySquadBloc.addClubAdmin(
          vm.mManagedClubs.selected.value.getId(),
          vm.mManagedClubs.selected.text,
          payload,
          (clubAdmins) => {
            vm.mClubAdmins.setAdmins(clubAdmins);
            vm.mClubAdmins.loading = false;
            vm.$notify({ type: 'success', text: 'Admin added successfully' });
          },
          () => {
            vm.mClubAdmins.loading = false;
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later' });
          }
      )
    },
    onDeleteClubAdmin(value) {
      const vm = this;
      vm.mClubAdmins.selected = value;
      vm.$bvModal.show('delete-admin-modal');
    },
    onConfirmDeleteClubAdmin() {
      const vm = this;
      vm.$bvModal.hide('delete-admin-modal');
      vm.mClubAdmins.loading = true;
      MySquadBloc.deleteClubAdmin(
          vm.mManagedClubs.selected.value.getId(),
          vm.mClubAdmins.selected.getId(),
          (clubAdmins) => {
            vm.mClubAdmins.setAdmins(clubAdmins);
            vm.mClubAdmins.loading = false;
            vm.$notify({ type: 'success', text: 'Admin removed successfully' });
          },
          () => {
            vm.mClubAdmins.loading = false;
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later' });
          }
      )
    }
  }
}
</script>

<style scoped>
input:disabled {
  background-color: transparent;
}
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-light {
}
.my-highlight-item-light:hover {
  background-color: var(--primary-highlight-background-p33);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
