// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    object.trainingSet = model.trainingSetInfo(object.trainingSet);
  } else {
    object = {
      dayOfWeek: 0,
      startTime: '00:00',
      duration: 0,
      trainingSetId: '',
      location: '',
      trainingSet: model.trainingSetInfo(),
    };
  }
  object.getDayOfWeek = function getDayOfWeek() {
    return object.dayOfWeek ? object.dayOfWeek : 0;
  },
  object.getDuration = function getDuration() {
    return object.duration ? object.duration : 0;
  },
  object.getTrainingSetId = function getTrainingSetId() {
    return object.trainingSetId ? object.trainingSetId :'';
  },
  object.getStartTime = function getStartTime() {
    return object.startTime ? object.startTime :'';
  },
  object.getLocation = function getLocation() {
    return object.location ? object.location :'';
  },
  object.getTrainingSet = function getTrainingSet() {
    return object.trainingSet ? object.trainingSet : model.trainingSetInfo();
  },
  object.getDurationInMinutes = function getDurationInMinutes() {
    return (this.getDuration() / 60 / 1000);
  },
  object.getStartTimeInFull = function getStartTimeInFull() {
    return (this.getStartTime()) + ':00';
  },
  object.formattedDuration = function formattedDuration() {
    return (this.getDurationInMinutes()) + ' mins';
  }
  object.setStartTime = function setStartTime(value) {
    object.startTime = value;
    return object;
  }
  object.setDuration = function setDuration(value) {
    object.duration = value;
    return object;
  }
  object.setLocation = function setLocation(value) {
    object.location = value;
    return object;
  }
  object.formattedDayOfWeek = function formattedDayOfWeek() {
    switch (object.getDayOfWeek()) {
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      case 7:
        return 'Sunday';

    }
    return '';
  }
  object.isSame = function isSame(other) {
    return object.getDayOfWeek() === other.getDayOfWeek() &&
      object.getStartTime() === other.getStartTime() &&
    object.getTrainingSetId() === other.getTrainingSetId();
  }
  return object;
}

