// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.squads) {
      object.squads = object.squads.map((squad) => model.squadResponse(squad));
    }
  } else {
    object = {
      clubId: '',
      club: '',
      squads: [],
      clubAdmin: [],
      squadLimit: 0, // max 5
      squadSize: 0 // max 30
    };
  }
  object.setClubId = function setClubId(value) {
    object.clubId = value;
    return this;
  }
  object.setClubAdmin = function setClubAdmin(value) {
    object.clubAdmin = [value];
    return this;
  }
  object.setSquadLimit = function setSquadLimit(value) {
    object.squadLimit = value;
    return this;
  }
  object.setSquadSize = function setSquadSize(value) {
    object.squadSize = value;
    return this;
  }
  object.getClubId = function getClubId() {
    return object.clubId ? this.clubId : '';
  }
  object.getClubName = function getClubName() {
    return object.club ? object.club : '';
  }
  object.getSquadLimit = function getSquadLimit() {
    return object.squadLimit ? object.squadLimit : 0;
  }
  object.getSquadSize = function getSquadSize() {
    return object.squadSize ? object.squadSize : 0;
  }
  object.getSquads = function getSquads() {
    return object.squads ? this.squads : [];
  }
  return object;
}

