<template>
  <div class="page_layout gradient_background">
    <div class="d-flex flex-row justify-content-center" style="height: 100%;">
      <div class="d-flex flex-column justify-content-between" style="min-width: 320px">
        <div></div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-self-center">
            <div class="brand p-2">
              <img alt="mytogs logo" src="../../assets/images/logo-mytogs-white.png">
            </div>
            <div class="brand p-2">
              <img alt="swimming new zealand logo" src="../../assets/images/logo-swimming-nz-white.png">
            </div>
          </div>
          <label class="label align-self-center mt-3">
            {{ isMySquad ? 'MYSQUAD PORTAL' : 'MYTOGS ADMIN PORTAL'}}
          </label>
        </div>
        <div></div>
        <div></div>
        <form class="form-group align-self-center" style="max-width: 280px;" v-on:submit.prevent="onSignIn()">
          <input v-if="isMySquad"
             type="email"
             class="form-control my-box-decoration"
             placeholder="Email"
             v-model="mUserName">
          <input v-else
              type="text"
              class="form-control my-box-decoration"
              placeholder="Username"
              v-model="mUserName">
          <input type="password"
                 class="form-control my-box-decoration mt-2"
                 placeholder="Password"
                 v-model="mPassword">
          <div
              v-if="isMySquad"
              class="clickable float-left ml-1 mt-1" v-on:click.stop="onToggleRememberMe()">
            <font-awesome-icon
                :icon="mRememberMe ? 'check-square' : 'square'"

                size="sm"
                class="label"/>
            <label class="form-check-label my-text-primary ml-2" style="font-size: 0.8em">
              Remember Me
            </label>
          </div>
          <button type="submit"
                  class="btn btn-outline-primary my-box-decoration mt-4"
                  style="width: 280px"
                  v-bind:disabled="!isValid">
            Sign In
          </button>
          <div class="my-text-primary mt-4 mb-2" style="font-size: 0.6em">
            Copyright © 2021 myTogs - All Rights Reserved.
          </div>
        </form>

      </div>


    </div>
  </div>
</template>

<script>
import webapi from "@/webapi"
import model from "@/model"
import storageHelper from "@/util/local_storage_helper"
import logger from "@/util/logger"

const LOG_TAG = 'sign_in';

export default {
  name: LOG_TAG,
  components: {
  },
  props: {
  },
  computed: {
    isMySquad() {
      return process.env.VUE_APP_TARGET === 'mysquad';
    },
    isValid() {
      const vm = this;
      return vm.mUserName.length > 0 && vm.mPassword.length > 0;
    },
  },
  data() {
    return {
      mUserName: '',
      mPassword: '',
      mAuthenticating: false,
      mRememberMe: false,
    };
  },
  mounted() {
    // const vm = this;
    const vm = this;
    vm.mRememberMe = storageHelper.isRememberMe();
    if (vm.mRememberMe) {
      const auth = storageHelper.getAuth();
      if (auth.isAuthenticated()) {
        vm.$store.commit('setAuth', auth);
      } else {
        vm.mUserName = auth.getEmail();
      }
    }
  },
  methods: {
    onSignIn() {
      const vm = this;
      logger.d(LOG_TAG, 'onSignIn', vm.mUserName, vm.mPassword);
      vm.$store.commit('setLoading', true);
      const userName = vm.mUserName;
      const isPortalAdmin =
          userName === 'aaronl@mytogs' ||
          userName === 'samc@snz';
      const userNameValidated =
          isPortalAdmin ? userName + '.co.nz' :
          userName;
      // special handling for portal admin access
      // requires signing into firebase auth first
      window.firebase.auth().signInWithEmailAndPassword(userNameValidated, vm.mPassword)
          .then((userCredential) => {
            if (isPortalAdmin) {
              vm.onSignInWithBackend(null, '');
            } else {
              const user = userCredential.user;
              logger.d(LOG_TAG, 'onSignIn', 'refreshToken', user.refreshToken);
              vm.onSignInWithBackend(user.refreshToken, user.uid);
            }

          })
          .catch((error) => {
            logger.w(LOG_TAG, 'onSignIn', 'error', error.message);
            vm.onSignInWithBackend(null, '');
          });
    },
    onSignInWithBackend(refreshToken, firebaseUid) {
      const vm = this;
      // logger.d(LOG_TAG, 'onSignIn', 'refreshToken', refreshToken);
      // with token => mysquad user (firebase signin)
      // without token => mytogs user

      webapi.apiService.signIn(
        vm.mUserName,
        (!refreshToken) ? vm.mPassword : null,
        refreshToken,
        (response) => {
          const data = model.authResponse(response);
          logger.d(LOG_TAG, 'onSignInWithBackend', 'accessLevel', data.getAccessLevel(), data.isSuperUser());
          if(data.isAdmin()) {
            data.setRegions([
              {
                'topic': 'news',
                'name': 'News (All)',
                'active': true
              },
              {
                'topic': 'hawkesbaypovertybay',
                'name': 'Hawkes Bay Poverty Bay',
                'active': true
              },
              {
                'topic': 'countiesmanukau',
                'name': 'Counties Manukau',
                'active': true
              },
              {
                'topic': 'otago',
                'name': 'Otago',
                'active': true
              },
              {
                'topic': 'manawatu',
                'name': 'Manawatu',
                'active': true
              },
              {
                'topic': 'wellington',
                'name': 'Wellington',
                'active': true
              },
              {
                'topic': 'waikato',
                'name': 'Waikato',
                'active': true
              },
              {
                'topic': 'taranaki',
                'name': 'Taranaki',
                'active': true
              },
              {
                'topic': 'nelsonmarlborough',
                'name': 'Nelson Marlborough',
                'active': true
              },
              {
                'topic': 'canterbury',
                'name': 'Canterbury',
                'active': true
              },
              {
                'topic': 'southland',
                'name': 'Southland',
                'active': true
              },
              {
                'topic': 'auckland',
                'name': 'Auckland',
                'active': true
              },
              {
                'topic': 'northland',
                'name': 'Northland',
                'active': true
              },
              {
                'topic': 'bayofplenty',
                'name': 'Bay of Plenty',
                'active': true
              }
            ]);
          }
          vm.$store.commit('setFirebaseSuperUser', data.isSuperUser());
          if (data.isSuperUser()) {
            vm.$store.commit('setFirebaseUid', firebaseUid);
          } else {
            vm.$store.commit('setFirebaseUid', '');
          }

          if (data.isMyTogsUser() && !vm.isMySquad) {
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'You are not authorized to use this portal' });
          } else {
            logger.d(LOG_TAG, 'onSignIn', response, data.isValid(), data.getToken());
            vm.$store.commit('setAuth', data);
            vm.$store.commit('setLoading', false);
            if (vm.mRememberMe) {
              storageHelper.setAuth(data);
            }
          }
        },
        (error) => {
          logger.w(LOG_TAG, 'onSignIn', error);
          vm.$store.commit('setLoading', false);
          vm.$notify({ type: 'error', text: error.data });
        }
      )
    },
    onToggleRememberMe() {
      const vm = this;
      vm.mRememberMe = !vm.mRememberMe;
      if (!vm.mRememberMe && storageHelper.isRememberMe()) {
        storageHelper.setAuth(null);
      }
    }
  }
}
</script>

<style scoped>
.page_layout {
  position: absolute;
  width: 100%;
  height: 100%;
}
.gradient_background {
  background-image: linear-gradient(var(--background-blue), var(--background-blue-light))
}
.brand {
  max-width: 8.6rem;
}
.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.btn-outline-primary {
  color: var(--primary-highlight);
}

</style>

