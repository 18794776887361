// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    object.sessions = [];
    if (object.sessionAttended) {
      object.sessionAttended.forEach((data) => {
        object.sessions.push(model.attendanceSessionResponse(data).togglePresent());
      })
    }
    if (object.sessionMissed) {
      object.sessionMissed.forEach((data) => {
        object.sessions.push(model.attendanceSessionResponse(data).toggleAbsent());
      })
    }
    object.sessions.sort((a, b) => b.getSessionTime() - a.getSessionTime());
  } else {
    object = {
      customName: '',
      memberId: '',
      userUuid: '',
      name: '',
      approved: false,
      squadMemberId: '',
      absence: 0,
      presence: 0,
      attendanceRating: 0,
      sessionAttended: [],
      sessionMissed: [],
      //
      sessions: [],
    };
  }
  object.getCustomName = function getCustomName() {
    return object.customName ? object.customName : '';
  }
  object.getName = function getName() {
    return object.name ? object.name : '';
  }
  object.getValidatedName = function getValidatedName() {
    return object.getCustomName() ? object.getCustomName() : object.getName();
  }
  object.getAbsence = function getAbsence() {
    return object.absence ? object.absence : 0;
  }
  object.getPresence = function getPresence() {
    return object.presence ? object.presence : 0;
  }
  object.getAttendanceRating = function getAttendanceRating() {
    return object.attendanceRating ? object.attendanceRating : 0;
  }
  object.formattedAttendanceRating = function formattedAttendanceRating() {
    return this.getAttendanceRating().toFixed(0);
  }
  object.getSessions = function getSessions() {
    return object.sessions ? object.sessions : [];
  }
  return object;
}

