import authResponse from "@/model/auth_response"
import datepickerInfo from "@/model/datepicker_info"
import bannerResponse from "@/model/banner_response"
import hy3FileResponse from "@/model/hy3file_response"
import hyvFileResponse from "@/model/hyvfile_response"
import notificationInfo from "@/model/notification_info"
import clubInfo from "@/model/club_info"
import clubResponse from "@/model/club_response"
import memberResponse from "@/model/member_response"
import squadInfo from "@/model/squad_info"
import squadResponse from "@/model/squad_response"
import squadResponseExt from "@/model/squad_response_ext"
import regionResponse from "@/model/region_response"
import trainingSetInfo from "@/model/training_set_info"
import trainingScheduleInfo from "@/model/training_schedule_info"
import trainingSessionInfo from "@/model/training_session_info"
import resultInfo from "@/model/result_info"
import conversionInfo from "@/model/conversion_info"
import inviteCodeResponse from "@/model/invite_code_response"
import attendanceInfo from "@/model/attendance_info"
import attendanceResponse from "@/model/attendance_response"
import attendanceSessionResponse from "@/model/attendance_session_response"
import attachmentInfo from "@/model/attachment_info"
import targetInfo from "@/model/target_info"
import splitInfo from "@/model/split_info"
import goalInfo from "@/model/goal_info"
import appConfig from "@/model/app_config"
import appUserProfile from "@/model/app_user_profile"
import appUserAction from "@/model/app_user_action"
import offDaysInfo from "@/model/off_days_info"
import profileAddons from "@/model/app_user_profile_addons";

export default {
  authResponse,
  datepickerInfo,
  bannerResponse,
  hy3FileResponse,
  hyvFileResponse,
  notificationInfo,
  clubInfo,
  clubResponse,
  memberResponse,
  squadInfo,
  squadResponse,
  squadResponseExt,
  regionResponse,
  trainingSetInfo,
  trainingScheduleInfo,
  trainingSessionInfo,
  resultInfo,
  conversionInfo,
  inviteCodeResponse,
  attendanceInfo,
  attendanceResponse,
  attendanceSessionResponse,
  attachmentInfo,
  targetInfo,
  splitInfo,
  goalInfo,
  appConfig,
  appUserProfile,
  appUserAction,
  offDaysInfo,
  profileAddons
};
