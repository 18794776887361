<template>
  <div v-if="mSwimmer.isValid()">
    <div v-if="mSwimmer.hasPersonalBest()">
      <b-form-group label="Select stroke" label-for="stroke-select" style="font-size: 0.85em">
        <b-form-select id="stroke-select" v-model="mStrokeOptions.selected" :options="mStrokeOptions.options"></b-form-select>
      </b-form-group>
      <div v-if="mSwimmer.getPersonalBest()" class="mt-2" style="width: 100%; overflow-y: auto;">
        <div v-for="(pb, index) in mSwimmer.getPersonalBest().filter((data) => data.getStroke() === mStrokeOptions.selected)" :key="index"
             class="my-box my-box-decoration-dark p-1 ml-1 mr-1 mb-2"
             style="min-width: 8rem; font-size: 0.75em">

          <div class="d-flex flex-row justify-content-between">
            <div> {{pb.formattedDate() }}</div>
            <div> {{pb.formattedCourse() }}</div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div style="font-weight: bold"> {{pb.getEvent() }}</div>
            <div style="font-size: 0.75em"> {{pb.formattedAge() }}</div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div> {{pb.getMeet() }}</div>
            <div style="font-size: 0.8em; text-align: right">
              <div style="font-weight: bold">{{pb.getResult()}}</div>
              <div>{{pb.formattedConversion()}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-else>
      No Records available.
    </div>
  </div>
</template>
<script>

import model from "@/model";
import logger from "@/util/logger"

const LOG_TAG = 'profile_pb';

export default {
  name: LOG_TAG,
  components: {
  },
  props: {
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
  },
  data () {
    return {
      mSwimmer: model.memberResponse(),
      mStrokeOptions: {
        selected: null,
        options: [
          {text: 'Freestyle', value: 'Freestyle'},
          {text: 'Backstroke', value: 'Backstroke'},
          {text: 'Breaststroke', value: 'Breaststroke'},
          {text: 'Butterfly', value: 'Butterfly'},
          {text: 'Individual Medley', value: 'Individual Medley'},
        ],
      }
    }
  },
  computed: {
  },
  watch: {
    swimmer: function onChange() {
      const vm = this;
      vm.refresh();
    }
  },
  mounted() {
    const vm = this;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      vm.mSwimmer = vm.swimmer;
      if (!vm.mStrokeOptions.selected) {
        vm.mStrokeOptions.selected = vm.mStrokeOptions.options[0].value;
      }
      logger.d(LOG_TAG, 'refresh', vm.mSwimmer.hasPersonalBest());
    }
  }
}
</script>

<style scoped>
</style>
