<template>
  <div class="line pl-2 pr-2" style="width: 100%;">
  </div>
</template>

<script>
const LOG_TAG = 'separator_line';

export default {
  name: LOG_TAG,
  props: {},
  data() {
    return {};
  },
}
</script>

<style scoped>
.line {
  height: 1px;
  background-color: lightgrey;
  opacity: 0.3;
}
</style>
