import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      eventName: '',
      ageRange: [],
      eventStartMS: 0,
      eventEndMS: 0,
      eventId: '',
      openAge: false,
      qualifyEnd: 0,
      qualifyStart: 0
    };
  }
  object.getEventName = function getEventName() {
    return object.eventName ? object.eventName : '';
  }
  object.getAgeRange = function getAgeRange() {
    return object.ageRange ? object.ageRange : [];
  }
  object.getEventStartAt = function getEventStartAt() {
    return object.eventStartMS ? object.eventStartMS : 0;
  }
  object.getEventEndAt = function getEventEndAt() {
    return object.eventEndMS ? object.eventEndMS : 0;
  }
  object.formattedEventPeriod = function formattedEventPeriod() {
    return datetimeHelper.formattedDateTime(object.getEventStartAt()) +
      ' ~ ' +
      datetimeHelper.formattedDateTime(object.getEventEndAt())
      ;
  }
  object.formattedAgeRange = function formattedAgeRange() {
    let range = '';
    if (object.getAgeRange().length > 1) {
      range = object.getAgeRange()[0] + ' ~ ' + object.getAgeRange()[object.getAgeRange().length - 1];
    } else if (object.getAgeRange().length > 0) {
      range = object.getAgeRange()[0];
    }
    if (object.isOpenAge()) {
      range += ' / over';
    }
    return range;
  }
  object.isOpenAge = function isOpenAge() {
    return object.openAge ? object.openAge : false;
  }
  object.getQualifyStart = function getQualifyStart() {
    return object.qualifyStart ? object.qualifyStart : 0;
  }
  object.getQualifyEnd = function getQualifyEnd() {
    return object.qualifyEnd ? object.qualifyEnd : 0;
  }
  object.hasQualifyPeriod = function hasQualifyPeriod() {
    return object.getQualifyStart() > 0 && object.getQualifyEnd() > 0;
  }
  object.formattedQualifyPeriod = function formattedQualifyPeriod() {
    return datetimeHelper.formattedDateTime(object.getQualifyStart(), true) +
      ' ~ ' +
      datetimeHelper.formattedDateTime(object.getQualifyEnd(), true)
      ;
  }
  return object;
}

