// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      key: '',
      name: '',
      email: '',
      userUuid: '',
      memberId: '', // for invite info
      club: '', // for invite info
      pending: false, // for invite state
      approved: false, // for invite state
      xbranch: '', // for member search
      squadMemberId: '',
      personalBest: [],

      // profile update
      customName: '',
      mobile: '',
      goals: {
        time: [
          /*{
            distance: "100",
            stroke: "Freestyle",
            time: 0
          }*/
        ],
        notes: ''
      },
      reconcileId: '',

      // firebase profile
      premium: false,
      subscription_premium: false,
      subscription: '',
      platform: '',
    };
  }
  object.createClubAdminPayload = function createClubAdminPayload() {
    return {
      name: object.name,
      email: object.email,
      userUuid: object.userUuid,
    }
  }
  object.getId = function getId() {
    return object.userUuid ? object.userUuid : '';
  }
  object.getMemberId = function getMemberId() {
    return object.memberId ? object.memberId : '';
  }
  object.getPending = function getPending() {
    return object.pending ? object.pending : false;
  }
  object.getName = function getName(placeholder) {
    return object.name ? object.name : placeholder ? placeholder : '';
  }
  object.getEmail = function getEmail(placeholder) {
    return object.email ? object.email : placeholder ? placeholder : '';
  }
  object.getBranch = function getBranch() {
    return object.xbranch ? object.xbranch : '';
  }
  object.getCustomName = function getCustomName() {
    return object.customName ? object.customName : '';
  }
  object.getMobile = function getMobile() {
    return object.mobile ? object.mobile : '';
  }
  object.getValidatedName = function getValidatedName() {
    return object.getCustomName().length > 0 ? object.getCustomName() : object.getName();
  }
  object.getValidatedNameKey = function getValidatedNameKey() {
    let nameKey = object.getCustomName().length > 0 ? object.getCustomName() : object.getName();
    return nameKey + object.getSquadMemberId();
  }
  object.getSquadMemberId = function getSquadMemberId() {
    return object.squadMemberId ? object.squadMemberId : '';
  }
  object.getReconcileId = function getReconcileId() {
    return object.reconcileId ? object.reconcileId : '';
  }
  object.setClub = function setClub(value) {
    object.club = value;
    return this;
  }
  object.setName = function setName(value) {
    object.name = value;
    return this;
  }
  object.setCustomName = function setCustomName(value) {
    object.customName = value;
    return this;
  }
  object.setMobile = function setMobile(value) {
    object.mobile = value;
    return this;
  }
  object.setMemberId = function setMemberId(value) {
    object.memberId = value;
    return this;
  }
  object.setUserUuid = function setUserUuid(value) {
    object.userUuid = value;
    return this;
  }
  object.setKey = function setKey(value) {
    object.key = object.getId() + value.toString();
    return this;
  }
  object.setEmail = function setEmail(value) {
    object.email = value;
    return this;
  }
  object.hasMemberId = function hasMemberId() {
    return object.getMemberId().length > 0;
  }
  object.isValid = function isValid() {
    return object.getId().length > 0 ||
      object.getMemberId().length > 0 ||
      object.getEmail().length > 0 ||
      object.getValidatedName().length > 0;
  }
  object.isPending = function isPending() {
    return object.getPending();
  }
  object.getPersonalBest = function getPersonalBest() {
    return object.personalBest ? object.personalBest : [];
  }
  object.hasPersonalBest = function hasPersonalBest() {
    return object.getPersonalBest().length > 0;
  }
  object.hasApproved = function hasApproved() {
    return object.approved ? object.approved : false;
  }
  object.isNewSignUp = function isNewSignUp() {
    return object.getId() === 'signup';
  }
  object.isLegacyPremium = function isLegacyPremium() {
    return object.premium;
  }
  object.isPremiumSubscription = function isPremiumSubscription() {
    return object.subscription_premium;
  }
  object.isPremiumUser = function isPremiumUser() {
    return object.isLegacyPremium() || object.isPremiumSubscription();
  }
  object.formattedPremiumState = function formattedPremiumState() {
    return object.isPremiumUser() ? 'Premium user' : 'Free user';
  }
  object.getSubscription = function getSubscription() {
    return object.subscription ? object.subscription : '';
  }
  object.setPremium = function setPremium(value) {
    object.premium = value;
    return this;
  }
  object.setPremiumSubscription = function setPremiumSubscription(value) {
    object.subscription_premium = value;
    return this;
  }
  object.setSubscription = function setSubscription(value) {
    object.subscription = value;
    return this;
  }
  object.setPlatform = function setPlatform(value) {
    object.platform = value;
    return this;
  }
  return object;
}

