<template>
  <form class="form-group" v-on:submit.prevent="onSearchUser()">
    <div
        v-if="linkMyTogs"
        class="font-weight-bold"
        :class="{
        'my-text-primary': !dark
      }"
        style="font-size: 0.85em;">
      <font-awesome-icon
          :icon="'link'"
          size="sm"
          class="label"/>
      {{(mData.user.isValid()) ? 'Linked MyTogs account' : 'Link swimmer to MyTogs'}}:
    </div>
    <div
        v-else
        class="font-weight-bold"
        :class="{
        'my-text-primary': !dark
      }"
        style="font-size: 0.85em;">
      Search user by Email:
    </div>
    <separator-line></separator-line>
    <div class="d-flex flex-row justify-content-between mt-2">
      <div v-if="mData.user.isValid()"
           :class="{'my-text-primary': !dark}"
           style="padding: 0.35rem 0; font-size: 0.75em;">
        <div class="font-weight-bold text-capitalize"
             :class="{'my-text-warning': mData.user.isNewSignUp()}">{{
            mData.user.isNewSignUp() ? '( *New user )' : mData.user.getName()
          }}</div>
        <div>{{ mData.user.getEmail() }}</div>
      </div>
      <input
          v-else
          type="email"
          class="form-control"
          :class="{'my-box-decoration': !dark}"
          placeholder="Enter email"
          v-model="mData.email"
          :disabled="mData.isSearching">
      <button v-if="modifiable"
              type="submit"
              class="btn btn-outline-primary ml-2"
              :class="{'my-box-decoration': !dark}"
              style="width: 3rem; max-height: 2.4rem"
              v-bind:disabled="mData.isSearching || mData.user.isValid() ? false : !isValidEmail">

        <div v-if="mData.isSearching"
             class="spinner-border"
             :class="{'text-light': !dark}"
             style="width: 1rem; height: 1rem; border-width: 1px" role="status"></div>
        <font-awesome-icon
            v-else
            :icon="mData.user.isValid() ? 'times' : 'search'"
            size="sm"
            class="label clickable warning"/>
      </button>
    </div>
  </form>
</template>
<script>

import SeparatorLine from "@/components/separator_line"
import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger";

const LOG_TAG = 'search_user_by_email_card';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    supportSignUp: {
      type: Boolean,
      default: false,
    },
    invite: {
      type: Boolean,
      default: false,
    },
    linkMyTogs: {
      type: Boolean,
      default: false,
    },
    clubId: null,
    squadId: null,
    user: {
      type: Object,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return model.memberResponse();
      }
    },
    onSearchState: {
      type: Function,
    },
    modifiable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mData: {
        email: '',
        isSearching: false,
        user: model.memberResponse(),
        errorText: '',
        reset() {
          this.email = '';
          this.isSearching = false;
          this.user = model.memberResponse();
        },
      }
    };
  },
  computed: {
    isValidEmail() {
      const vm = this;
      return vm.mData.email.length > 0;
    },
  },
  watch: {
    user: function(value) {
      logger.d(LOG_TAG, 'watch.user', 'reset', value);
      const vm = this;
      if (!value.isValid()) {
        vm.mData.reset();
      } else {
        vm.mData.user = value;
      }
    },
  },
  mounted() {
    //
    const vm = this;
    logger.d(LOG_TAG, 'mounted', vm.isSearchByEmail, vm.user);
    vm.mData.user = vm.user;
  },
  methods: {
    onSearchUser() {
      const vm = this;
      vm.onSearchUserByEmail(vm.mData.email);
    },
    onSearchUserByEmail(email) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchUserByEmail', email);
      if (vm.mData.isSearching) {
        return;
      }
      if (vm.mData.user.isValid()) {
        logger.d(LOG_TAG, 'onSearchUserByEmail', 'reset');
        vm.mData.reset();
        vm.onSearchState(false, true, model.memberResponse());
      } else {
        vm.onSearchState(true, false);
        vm.mData.isSearching = true;
        webapi.apiService.searchMember(
            email,
            (response) => {
              const user = model.memberResponse(response);
              vm.mData.user = user;
              vm.mData.isSearching = false;
              vm.onSearchState(false, true, user);
              logger.d(LOG_TAG, 'onSearchUserByEmail', vm.mData.user.getName());
            },
            (error) => {
              if (vm.supportSignUp) {
                const user = model.memberResponse().setUserUuid('signup').setEmail(email);
                vm.mData.user = user;
                vm.mData.isSearching = false;
                vm.onSearchState(false, true, user);
              } else {
                vm.mData.isSearching = false;
                vm.onSearchState(false, false);
                vm.$bvModal.msgBoxOk('email provided does not match an existing mytogs user', {
                  title: 'Error',
                  size: 'sm',
                  okVariant: 'danger',
                  okTitle: 'Close',
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                });
              }

              logger.w(LOG_TAG, 'onSearchUserByEmail', error);
            }
        )
      }
    },
  }
}
</script>

<style scoped>
</style>
