// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model"
import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    object.trainingSet = model.trainingSetInfo(object.trainingSet);
    if (object.attachments) {
      object.attachments = object.attachments.map((attachment) => model.attachmentInfo(JSON.parse(attachment)));
    }
  } else {
    object = {
      sessionTime: 0,
      dayOfWeek: 0,
      startTime: '00:00',
      duration: 0,
      trainingSetId: '',
      location: '',
      trainingSet: model.trainingSetInfo(),
      attendance: {
        presence: [],
        presenceName: [],
        absence: [],
        absenceName: [],
        attendanceRating: 0
      },
      notes: '',
      attachments: [],
      lastUpdated: 0,
      submittedBy: '',
    };
  }
  object.getSessionTime = function getSessionTime() {
    return object.sessionTime ? object.sessionTime : 0;
  },
  object.formattedSessionTime = function formattedSessionTime() {
    return datetimeHelper.formattedDateTime(this.getSessionTime(), true);
  },
  object.getDayOfWeek = function getDayOfWeek() {
    return object.dayOfWeek ? object.dayOfWeek : 0;
  },
  object.getDuration = function getDuration() {
    return object.duration ? object.duration : 0;
  },
  object.getTrainingSetId = function getTrainingSetId() {
    return object.trainingSetId ? object.trainingSetId :'';
  },
  object.getStartTime = function getStartTime() {
    return object.startTime ? object.startTime :'';
  },
  object.getLocation = function getLocation() {
    return object.location ? object.location :'';
  },
  object.getTrainingSet = function getTrainingSet() {
    return object.trainingSet ? object.trainingSet : model.trainingSetInfo();
  },
  object.formattedDuration = function formattedDuration() {
    return (this.getDuration() / 60 / 1000) + ' mins';
  }
  object.formattedDayOfWeek = function formattedDayOfWeek(short) {
    switch (object.getDayOfWeek()) {
      case 1:
        return short ? 'Mon' : 'Monday';
      case 2:
        return short ? 'Tue' : 'Tuesday';
      case 3:
        return short ? 'Wed' : 'Wednesday';
      case 4:
        return short ? 'Thu' : 'Thursday';
      case 5:
        return short ? 'Fri' : 'Friday';
      case 6:
        return short ? 'Sat' : 'Saturday';
      case 7:
        return short ? 'Sun' : 'Sunday';

    }
    return '';
  }
  object.getNotes = function getNotes() {
    return object.notes ? object.notes: '';
  }
  object.hasNotes = function hasNotes() {
    return object.getNotes().length > 0;
  }
  object.getAttachments = function getAttachments() {
    return object.attachments ? object.attachments: [];
  }
  object.hasAttachments = function hastAttachments() {
    return object.getAttachments().length > 0;
  }
  object.getAttendance = function getAttendance() {
    return object.attendance ? object.attendance: {};
  }
  object.getSubmittedBy = function getSubmittedBy() {
    return object.submittedBy ? object.submittedBy : '---';
  }
  object.getLastUpdated = function getLastUpdated() {
    return object.lastUpdated ? object.lastUpdated : 0;
  }
  object.hasLastUpdated = function hasLastUpdated() {
    return object.getLastUpdated() > 0;
  }
  object.getPresence = function getPresence() {
    return object.getAttendance().presence ? object.getAttendance().presence: [];
  }
  object.getPresenceName = function getPresenceName() {
    return object.getAttendance().presenceName ? object.getAttendance().presenceName: [];
  }
  object.getAbsence = function getAbsence() {
    return object.getAttendance().absence ? object.getAttendance().absence: [];
  }
  object.getAbsenceName = function getAbsenceName() {
    return object.getAttendance().absenceName ? object.getAttendance().absenceName: [];
  }
  object.getAttendanceRating = function getAttendanceRating() {
    return object.getAttendance().attendanceRating ? object.getAttendance().attendanceRating : 0;
  }
  object.formattedAttendanceRating = function formattedAttendanceRating() {
    return object.getAttendanceRating().toFixed(0) + '%';
  }
  object.getTotalAttendance = function getTotalAttendance() {
    return object.getPresence().length + object.getAbsence().length;
  }
  object.hasAttendance = function hasAttendance() {
    return object.getAbsenceName().length > 0 || object.getAbsenceName().length > 0;
  }
  object.formattedAttendance = function formattedAttendance() {
    return object.hasAttendance() ?
      (object.getPresence().length.toString() + ' / ' + object.getTotalAttendance().toString()) :
      '---'
  }
  object.formattedLastUpdated = function formattedLastUpdated() {
    return object.hasLastUpdated() ? datetimeHelper.formattedTimeDate(this.getLastUpdated()) : '---';
  }
  return object;
}

