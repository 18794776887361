import Vue from 'vue'
import Vuex from 'vuex'
import model from "@/model"
import logger from "@/util/logger"
import datetimeHelper from "@/util/datetime_helper";

const LOG_TAG = 'store';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firebaseUser: {
      superUser: false,
      uid: '',
    },
    auth: model.authResponse(),
    appVersion: process.env.VUE_APP_VERSION,
    windowWidth: window.innerWidth,
    selectedPage: {
      text: '',
      value: '',
    },
    searchBy: {
      providerAccountId: '',
      houseNumber: '',
      accountNumber: '',
    },
    loading: false,
    datePicker: model.datepickerInfo(),
    trainingSets: [],

    allClubs: {
      unclaimed: [],
      claimed: [],
    },
    clubsSet: {
      set: {},
      timestamp: '',
    },
    squadsSet: {
      set: {},
      timestamp: '',
    },
    squadMessageSet: {
      set: {},
      timestamp: '',
    },
    swimmerPerfSet: {
      set: {},
      timestamp: '',
    }
  },
  mutations: {
    setWindowWidth(state) {
      state.windowWidth = window.innerWidth;
    },
    setSelectedPage(state, data) {
      state.selectedPage = data;
    },
    setSearchBy(state, data) {
      state.searchBy = data;
    },
    setFirebaseSuperUser(state, data) {
      state.firebaseUser.superUser = data;
      logger.d(LOG_TAG, 'setFirebaseSuperUser', data);
    },
    setFirebaseUid(state, data) {
      state.firebaseUser.uid = data;
      logger.d(LOG_TAG, 'setFirebaseUid', data);
    },
    setAuth(state, data) {
      state.auth = data;
      logger.d(LOG_TAG, 'setAuth', data);
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setDatePicker(state, data) {
      state.datePicker = data;
    },
    setTrainingSets(state, data) {
      state.trainingSets = data;
    },
    setAllClubs(state, data) {
      state.allClubs = data;
      logger.d(LOG_TAG, 'setAllClubs', data);
    },
    // club
    updateClub(state, data) {
      state.clubsSet.set[data.getClubId()] = data;
      state.clubsSet.timestamp = datetimeHelper.currentTimeInSecond().toString() + ':' + data.getClubId();
      logger.d(LOG_TAG, 'updateClub', data);
    },
    notifyClubChange(state, data /*true*/) {
      if (data) {
        state.clubsSet.timestamp = datetimeHelper.currentTimeInSecond().toString();
        logger.d(LOG_TAG, 'notifyClubChange', 'cache');
      }
    },
    // squad
    updateSquad(state, data) {
      state.squadsSet.set[data.getSquadId()] = data;
      state.squadsSet.timestamp = datetimeHelper.currentTimeInSecond().toString() + ':' + data.getSquadId();
      logger.d(LOG_TAG, 'updateSquad', data);
    },
    notifySquadChange(state, data /*true*/) {
      if (data) {
        state.squadsSet.timestamp = datetimeHelper.currentTimeInSecond().toString();
        logger.d(LOG_TAG, 'notifySquadChange', 'cache');
      }
    },
    // squad message
    updateSquadMessage(state, data) {
      state.squadMessageSet.set[data.squadId] = data.data;
      state.squadMessageSet.timestamp = datetimeHelper.currentTimeInSecond().toString() + ':' + data.squadId;
      logger.d(LOG_TAG, 'updateSquadMessage', data);
    },
    notifySquadMessageChange(state, data /*true*/) {
      if (data) {
        state.squadMessageSet.timestamp = datetimeHelper.currentTimeInSecond().toString();
        logger.d(LOG_TAG, 'notifySquadMessageChange', 'cache');
      }
    },
    // perf
    updateSwimmerPerf(state, data) {
      state.swimmerPerfSet.set[data.key] = data.data;
      state.swimmerPerfSet.timestamp = datetimeHelper.currentTimeInSecond().toString() + ':' + data.memberId;
      logger.d(LOG_TAG, 'updateSwimmerPerf', data);
    },
    notifySwimmerPerfChange(state, data /*true*/) {
      if (data) {
        state.swimmerPerfSet.timestamp = datetimeHelper.currentTimeInSecond().toString();
        logger.d(LOG_TAG, 'notifySwimmerPerfChange', 'cache');
      }
    },
    logout(state, data) {
      if (data) {
        state.allClubs = {
          unclaimed: [],
          claimed: [],
        }
        state.clubsSet.set = {};
        state.squadsSet.set = {};
        state.squadMessageSet.set = {};
        state.swimmerPerfSet.set = {};
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
