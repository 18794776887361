<template>
  <div class="container-fluid footer_bar pl-2 pr-2">
    <div style="width: 100%; height: 1px; background-color: white; opacity: 0.2"></div>
    <!-- desktop mode -->
    <div class="desktop d-flex flex-row justify-content-end">
      <div class="brand pt-2 pb-2 pl-5 mb-1 clickable" v-on:click.prevent="onClickMyTogs">
        <img alt="mytogs logo" src="../assets/images/logo-mytogs-white.png">
      </div>
      <div class="brand pt-1 pb-1 mb-1 clickable" v-on:click.prevent="onClickSwimmingNZ">
        <img alt="swimming new zealand logo" src="../assets/images/logo-swimming-nz-white.png">
      </div>
    </div>
  </div>
</template>

<script>


const LOG_TAG = 'footer_bar';

export default {
  name: LOG_TAG,
  props: {},
  data() {
    return {};
  },
  computed: {
    appVersion() {
      return this.$store.state.appVersion;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
  },
  mounted() {
  },
  methods: {
    onClickMyTogs() {
      window.open("https://mytogs.co.nz/");
    },
    onClickSwimmingNZ() {
      window.open("https://www.swimming.org.nz/");
    }
  }
}
</script>
<style scoped>
.footer_bar {
  position: fixed;
  height: 2.8rem;
  width: 98%;
  bottom: 0.2rem;
  left: 0.33rem;
  border-bottom: 0.1rem solid lightgrey;
  background-color: black;
  opacity: 0.3;
  border-radius: 12px;
}
.footer_bar .desktop {
  display: flex;
  height: 100%;
}
.footer_bar .mobile label {
  font-weight: bold;
  text-transform: uppercase;
}
.brand {
  max-width: 6rem;
  height: 100%;
}
.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .footer_bar {
    display: none;
  }
  .brand {
    max-width: 3rem;
    height: 100%;
  }
}
</style>
