// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      squadName: '',
      clubName: '',
      members: {
        reconcileId: '',
        memberId: '',
        name: '',
        customName: ''
      }
    };
  }
  object.getSquadName = function getSquadName() {
    return object.squadName ? object.squadName : '';
  }
  object.getClubName = function getClubName() {
    return object.clubName ? object.clubName : '';
  }
  object.isValid = function isValid() {
    return this.getClubName().length > 0 && this.getSquadName().length > 0;
  }
  return object;
}

