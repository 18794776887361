// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      topic: '',
      name: '',
      active: false
    };
  }
  object.getTopic = function getTopic() {
    return object.topic ? object.topic : '';
  }
  object.getName = function getName() {
    return object.name ? object.name : '';
  }
  object.isActive = function isActive() {
    return object.active ? object.active : false;
  }
  object.isTopicNews = function isTopicNews() {
    return object.getTopic() === 'news';
  }
  return object;
}

