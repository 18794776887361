
export default {
  NETWORK_TIMEOUT: (40) * 1000,
  POLL_DURATION: 10 * 1000, // poll every x sec after timeout

  SCAN_TIMEOUT_DEFAULT: (40 + 10) * 1000,
  SCAN_TIMEOUT_1: (40 + 20 + 20 + 20) * 1000,
  SCAN_TIMEOUT_MAX: (40 + 20 + 20 + 20 + 10) * 1000,

  POLL_EXTEND_DEFAULT: 10 * 1000,
  POLL_EXTEND_1: (20 + 20 + 20) * 1000,
  POLL_EXTEND_MAX: (20 + 20 + 20 + 10) * 1000,
  isValidUrlLink(value) {
    let url;

    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
  isValidEmail(value) {
    //https://www.w3resource.com/javascript/form/email-validation.php
    //eslint-disable-next-line
    const mailPattern = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return (mailPattern.test(value) );
  },
  validateTextWithUrlLink(text, color) {

    //https://www.cluemediator.com/find-urls-in-string-and-make-a-link-using-javascript
    //eslint-disable-next-line
    const urlPattern = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.replace(urlPattern, function (url) {
      let hyperlink = url;
      return '<a' +
        ' href="' + hyperlink + '"' +
        ' target="_blank"' +
        ' rel="noopener noreferrer"' +
        ' style="color: '+ color +'; text-decoration: underline;">' + url + '' +
        '</a>'
    });
  },
  STROKE_FREESTYLE: 'Freestyle',
  STROKE_BACKSTROKE: 'Backstroke',
  STROKE_BREASTSTROKE: 'Breaststroke',
  STROKE_BUTTERFLY: 'Butterfly',
  STROKE_IM: 'Individual Medley',

};
