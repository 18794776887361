<template>
  <div>
    <!-- search by inputs forms -->
    <div>
      <form class="form-group" v-on:submit.prevent="onClickReset()">
        <input type="email"
               class="form-control my-box-decoration"
               placeholder="Enter your email"
              v-model="mEmail">
        <div class="d-flex flex-row justify-content-end mt-4">
          <button type="submit"
                  class="btn btn-outline-primary my-box-decoration ml-2"
                  style="width: 10rem"
                  v-bind:disabled="!isValidEmail">
            Reset
          </button>
        </div>

      </form>

    </div>
  </div>
</template>
<script>

import logger from "@/util/logger";

const LOG_TAG = 'email_card';

export default {
  name: LOG_TAG,
  props: {
    _disabled: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    _onClickCb: {
      type: Function,
    }
  },
  data() {
    return {
      mEmail: ''
    };
  },
  watch: {
    _disabled: function onChange(value) {
      logger.d(LOG_TAG, 'watch', '_disabled', value);
      // const vm = this;
    },
  },
  computed: {
    isValidEmail() {
      const vm = this;
      return vm.mEmail.length > 0;
    }
  },
  mounted() {
    //
    // const vm = this;

  },
  methods: {
    onClickReset() {
      const vm = this;
      logger.d(LOG_TAG, 'onClickReset', vm.mEmail);
      vm._onClickCb(vm.mEmail);
      vm.mEmail = '';
    }
  }
}
</script>

<style scoped>
.label {
  color: var(--primary-text);
  font-size: 1em;
}
</style>
