// import logger from "@/util/logger"
import axios from "axios"

// const LOG_TAG = 'api_helper';

export default {
  ERR_API_RESP: 'Error response from API',
  ERR_API_COMM: 'Error communicating with API',

  storeStates() {
    return this.$store.state;
  },
  apiBaseHeader() {
    const headers = {
      'Authorization': 'd42acc9b-0853-4404-86f9-813abe201890'
    };
    return headers;
  },
  createUrl(...args) {
    let url = process.env.VUE_APP_TARGET_ENDPOINT;
    // logger.d(LOG_TAG, 'createUrl', url);
    args.forEach((arg) => {
      url = `${url}/${arg}`
    })
    return url;
  },
  createParams(...args) {
    let params = '';
    if (args.length > 0 && 0 === args.length % 2) {
      // logger.d(LOG_TAG, 'createParams', args);
      for (let index = 0; index < args.length; index += 2) {
        const key = args[index];
        const value = args[index + 1];
        params  = `${index === 0 ? '?' : ''}${params}${key}=${value}${(args.length > index + 2) ? '&' : ''}`;
      }
    }
    // logger.d(LOG_TAG, 'createParams', params);
    return params;
  },
  apiGet(url, onResponseCb, onErrorCb) {
    // const vm = this;

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + window.store.state.auth.getToken()
      }
    }).then(onResponse).catch(onError);
  },
  apiPost(url, payload, onResponseCb, onErrorCb) {
    // const vm = this;

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.post(url, payload, {
      headers: {
        'Authorization': 'Bearer ' + window.store.state.auth.getToken()
      }
    }).then(onResponse).catch(onError);
  },
  apiPost2(url, payload, onResponseCb, onErrorCb) {
    // const vm = this;

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }).then(onResponse).catch(onError);
  },
  apiPut(url, payload, onResponseCb, onErrorCb) {
    // const vm = this;

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.put(url, payload, {
      headers: {
        'Authorization': 'Bearer ' + window.store.state.auth.getToken()
      }
    }).then(onResponse).catch(onError);
  },
  apiUpload(url, payload, onResponseCb, onErrorCb) {
    // const vm = this;

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + window.store.state.auth.getToken()
      }
    }).then(onResponse).catch(onError);
  },
  apiDelete(url, onResponseCb, onErrorCb) {

    //
    // validate result
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error)
    };
    axios.delete(url, {
      headers: {
        'Authorization': 'Bearer ' + window.store.state.auth.getToken()
      }
    }).then(onResponse).catch(onError);
  },
};

