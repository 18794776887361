import apiHelper from "@/webapi/api_helper"
import logger from "@/util/logger"

// apis
const TARGET_API = 'api/admin';

const API_SWIMMER = 'swimmer';
const API_REFRESH = 'refresh';

const PARAM_NAME = 'name';


const LOG_TAG = 'admin_service';
export default {
  searchSwimmer(name, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_SWIMMER);

    url = url.concat('?', PARAM_NAME, '=', name);

    logger.d(LOG_TAG, 'searchSwimmer', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  triggerDatabaseReSync(uId, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_SWIMMER, uId, API_REFRESH);

    logger.d(LOG_TAG, 'triggerDatabaseReSync', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },

}