<template>
  <div
      v-if="dayOfWeek > 0"
      class="mt-2 mr-2"
      style="min-width: 6rem; min-height: 3rem;">
    <div style="font-size: 0.85em; text-align: center; color: lightgray; font-weight: bold">{{formattedDayOfWeek}}</div>
    <div class="mt-2"></div>
    <div v-for="(schedule, index) in mSchedules" :key=index
         class="my-box my-box-decoration my-box-light-border my-highlight-item clickable p-1 mb-1" style="min-height: 3rem; font-size: 0.75em"
         v-on:click.stop="onClickSchedule(schedule)"
    >

      <div class="mt-1 d-flex flex-row justify-content-between">
        <div style="font-size: 0.85em; font-weight: bold">{{schedule.getStartTime()}}</div>
        <div v-if="!disableEdit" v-on:click.stop="onDeleteSchedule(schedule)">
          <font-awesome-icon
              icon="times-circle"
              size="sm"
              class="clickable my-hover-option"
              :class="{
                    'hide': !storeAuth.hasMemberControl()
                  }"
          />
        </div>
      </div>
      <div class="mt-1" style="font-size: 0.85em">{{ schedule.getTrainingSet().getDescription() }}</div>
      <div style="font-size: 0.75em">{{ schedule.getLocation() }}</div>
      <separator-line></separator-line>
      <div class="mt-1" style="font-size: 0.8em; font-weight: bold; text-align: right">{{ schedule.formattedDuration() }}</div>
    </div >
  </div>
</template>
<script>


import SeparatorLine from "@/components/separator_line"
import model from "@/model";
//import logger from "@/util/logger"

const LOG_TAG = 'day_training_set_card';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
  },
  props: {
    dayOfWeek: {
      type: Number,
      default: function () {
        return 0;
      }
    },
    schedules: {
      type: Array,
      default: function () {
        return [];
      }
    },
    _onClickCb: {
      type: Function,
    },
    _onDeleteCb: {
      type: Function,
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      mSchedules: [],
    };
  },
  computed: {
    storeAuth() {
      return this.$store.state.auth;
    },
    storeTrainingSets() {
      return this.$store.state.trainingSets;
    },
    formattedDayOfWeek() {
      const vm = this;
      switch (vm.dayOfWeek) {
        case 1:
          return 'Monday';
        case 2:
          return 'Tuesday';
        case 3:
          return 'Wednesday';
        case 4:
          return 'Thursday';
        case 5:
          return 'Friday';
        case 6:
          return 'Saturday';
        case 7:
          return 'Sunday';
        default:
          return '';
      }
    }
  },
  watch: {
    schedules: function onChange(value) {
      const vm = this;
      vm.refresh(vm.dayOfWeek, value);
    },
  },
  mounted() {
    const vm = this;
    vm.refresh(vm.dayOfWeek, vm.schedules);
  },
  methods: {
    refresh(dayOfWeek, newSchedules) {
      //logger.d(LOG_TAG, 'refresh', dayOfWeek, newSchedules.length);
      const vm = this;
      if (dayOfWeek > 0) {
        vm.mSchedules = newSchedules.filter((schedule) => dayOfWeek == schedule.getDayOfWeek());
        vm.mSchedules.sort((a, b) => a.getStartTime().localeCompare(b.getStartTime()));
      }
      // logger.d(LOG_TAG, 'refresh', vm._dayOfWeek, squadInfo.getSchedule().length, vm.mSchedules.length);
    },
    getTrainingSet(trainingSetId) {
      const vm = this;
      let selected = vm.storeTrainingSets.find((trainingSet) => trainingSetId == trainingSet.getTrainingSetId());
      // logger.d(LOG_TAG, 'getTrainingSet', trainingSetId, selected);
      return null!=selected ? selected : model.trainingSetInfo();
    },
    onClickSchedule(selected) {
      const vm = this;
      if (vm._onClickCb) {
        vm._onClickCb(selected);
      }
    },
    onDeleteSchedule(selected, event) {
      const vm = this;
      if (event) {
        event.preventDefault();
      }
      if (vm._onDeleteCb && vm.storeAuth.hasMemberControl()) {
        vm._onDeleteCb(selected);
      }
    }
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
