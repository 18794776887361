// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      squadId: '',
      squadName: '',
      club: '',
      clubName: '',
      coach: false,
    };
  }
  object.setSquadId = function setSquadId(value) {
    object.squadId = value;
    return this;
  }
  object.setSquadName = function setSquadName(value) {
    object.squadName = value;
    return this;
  }
  object.setClubName = function setClubName(value) {
    object.clubName = value;
    return this;
  }
  object.getSquadId = function getSquadId() {
    return object.squadId ? object.squadId : '';
  }
  object.getSquadName = function getSquadName() {
    return object.squadName ? object.squadName : 'Add new squad';
  }
  object.isCoach = function isCoach() {
    return object.coach ? object.coach : false;
  }
  object.isValid = function isValid() {
    return this.getSquadId().length > 0;
  }
  return object;
}

