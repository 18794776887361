// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      fileName: '', // present, absent, unknown
      downloadUrl: '',
      timestamp: 0,
    };
  }
  object.setFileName = function setFileName(value) {
    object.fileName = value;
    return this;
  }
  object.setDownloadUrl = function setDownloadUrl(value) {
    object.downloadUrl = value;
    return this;
  }
  object.setTimestamp = function setTimestamp(value) {
    object.timestamp = value;
    return this;
  }
  object.getFileName = function getFileName() {
    return object.fileName ? object.fileName : '';
  }
  object.getDownloadUrl = function getDownloadUrl() {
    return object.downloadUrl ? object.downloadUrl : '';
  }
  object.getTimestamp = function getTimestamp() {
    return object.timestamp ? object.timestamp : 0;
  }
  object.formattedTimestamp = function formattedTimestamp() {
    return datetimeHelper.formattedTimeDate(this.getTimestamp());
  }
  return object;
}

