// import displayHelper from "@/util/display_helper"
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      version: '', // present, absent, unknown
      description: '',
      link_url: '',
    };
  }

  object.getVersion = function getVersion() {
    return object.version ? object.version : '';
  }
  object.getDescription = function getDescription() {
    return object.description ? object.description : '';
  }
  object.getLinkUrl = function getLinkUrl() {
    return object.link_url ? object.link_url : '';
  }
  return object;
}

