<template>
  <div class="page_layout">
    <img alt="certificate" :src="mImage" class="img_layout">
    <div class="style-name">
      {{mName}}
    </div>
    <div class="style-meet">
      {{mMeet}}
    </div>
    <div class="style-result">
      {{mEntries}}
    </div>
  </div>
</template>

<script>

const LOG_TAG = 'sign_in';

export default {
  name: LOG_TAG,
  components: {
  },
  props: {
  },
  computed: {
  },
  data() {
    return {
      mImage: '',
      mName: '',
      mMeet: '',
      mEntries: ''

    };
  },
  mounted() {
    const vm = this;
    vm.mImage = vm.$route.query.image;
    vm.mName = vm.$route.query.name;
    vm.mMeet = vm.$route.query.meet;
    vm.mEntries = vm.$route.query.entries;
  },
  methods: {
  }
}
</script>

<style scoped>
.page_layout {
  position: relative;
  width: 842px;
}
.img_layout {
  width: 100%;
}
.style-name {
  position: absolute;
  top: 300px;
  left: 50px;

  color: black;
  font-weight: bold;
  font-size: 40px;
}
.style-meet {
  position: absolute;
  top: 350px;
  left: 55px;

  color: #4B4B4B;
  font-weight: bold;
  font-size: 14px;
}
.style-result {
  position: absolute;
  top: 350px;
  left: 480px;

  color: #4B4B4B;
  font-weight: bold;
  font-size: 13px;

  white-space: pre-line;
  text-align: left;
}


</style>

