// import displayHelper from "@/util/display_helper"
//import model from "@/model"
import datetimeHelper from "@/util/datetime_helper"
// import logger from "@/util/logger";

// const LOG_TAG = 'split_info';

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));

  } else {
    object = {
      distance: '',
      split: ''
    };
  }
  object.getDistance = function getDistance() {
    return object.distance ? object.distance : '';
  }
  object.getSplit = function getSplit() {
    return object.split ? object.split : '';
  }
  object.setSplit = function setSplit(value) {
    object.split = value;
    return object;
  }
  object.setCalibratedSplit = function setCalibratedSplit(value) {
    const toMilliseconds = datetimeHelper.translateTimeToMilliseconds(value);
    object.split = datetimeHelper.translateMillisecondsToTime(toMilliseconds);
    /*logger.d(LOG_TAG, 'formatNewTarget',
      'setCalibratedSplit',
      value,
      toMilliseconds,
      object.split);*/
    return object;
  }
  object.formattedSplitInMilliseconds = function formattedSplitInMilliseconds() {
    return datetimeHelper.translateTimeToMilliseconds(object.getSplit());
  }
  return object;
}

