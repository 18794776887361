import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      enabled: false,
      viewOnly: false,
      byDateTime: false,
      byMonth: false,
      byMonth2: false,
      byWeek: false,
      byDays: false,
      byDay: false,
      byDayNoLimit: false,
      selected: 0,
      minDate: new Date(),
      selectedDates: [],

      // result
      date: '',
      dateStart: '',
      dateEnd: ''
    };
  }
  object.getSelected = function getSelected() {
    return object.selected ? object.selected : 0;
  }
  object.isReset = function isReset() {
    return this.getSelected() === 0;
  }
  object.enableDefaultMinDate = function enableDefaultMinDate() {
    this.minDate = new Date(2021, 8, 1);
    return object;
  }
  object.setEnabled = function setEnabled(value) {
    object.enabled = value;
    return object;
  }
  object.setViewOnly = function setViewOnly(value) {
    object.viewOnly = value;
    return object;
  }
  object.setMinDate = function setMinDate(value) {
    object.minDate = value;
    return object;
  }
  object.setByDateTime = function setByDateTime(value) {
    object.byDateTime = value;
    return object;
  }
  object.setByMonth = function setByMonth(value) {
    object.byMonth = value;
    return object;
  }
  object.setByMonth2 = function setByMonth2(value) {
    object.byMonth2 = value;
    return object;
  }
  object.setByWeek = function setByWeek(value) {
    object.byWeek = value;
    return object;
  }
  object.setByDays = function setByDays(value) {
    object.byDays = value;
    return object;
  }
  object.setByDay = function setByDay(value) {
    object.byDay = value;
    return object;
  }
  object.setDate = function setDate(value) {
    object.date = value;
    return object;
  }
  object.setStartDate = function setStartDate(value) {
    object.dateStart = value;
    return object;
  }
  object.setEndDate = function setEndDate(value) {
    object.dateEnd = value;
    return object;
  }
  object.formattedDateTime = function formattedDateTime() {
    return datetimeHelper.formattedDateTime(object.date);
  }
  return object;
}

