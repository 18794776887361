// import displayHelper from "@/util/display_helper"
import model from "@/model"
import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.splits) {
      object.splits = object.splits.map((split) => model.splitInfo(split));
    }
  } else {
    object = {
      distance : '',
      stroke: '',
      course: 0,
      time: 0,
      splits: [],
    };
  }
  object.getDistance = function getDistance() {
    return object.distance ? object.distance : '';
  }
  object.getStroke = function getStroke() {
    return object.stroke ? object.stroke : '';
  }
  object.getTime = function getTime() {
    return object.time ? object.time : 0;
  }
  object.getCourse = function getCourse() {
    return object.course ? object.course : 0;
  }
  object.getText = function getText() {
    return object.text ? object.text : '';
  }
  object.getSplits = function getSplits() {
    return object.splits ? object.splits : [];
  }
  object.setDistance = function setDistance(value) {
    object.distance = value;
    return object;
  }
  object.setStroke = function setStroke(value) {
    object.stroke = value;
    return object;
  }
  object.setTime = function setTime(value) {
    object.time = value;
    return object;
  }
  object.setSplits = function setSplits(value) {
    object.splits = value;
    return object;
  },
  object.setCourse = function setCourse(value) {
    object.course = value;
    return object;
  }
  object.isSC = function isSC() {
    return object.getCourse() === 25;
  },
  object.isLC = function isLC() {
    return object.getCourse() === 50;
  },
  object.setText = function setText(value) {
    object.text = value;
    return object;
  }
  object.formattedEvent = function formattedEvent() {
    return object.getDistance() + ' ' + object.getStroke();
  }
  object.formattedCourse = function formattedCourse() {
    return object.isSC() ? 'SC' : object.isLC() ? 'LC' : '';
  }
  object.formattedTime = function formattedTime() {
    return datetimeHelper.translateMillisecondsToTime(object.getTime());
  }
  object.isSame = function isSame(other) {
    return object.getDistance() === other.getDistance() &&
      object.getStroke() === other.getStroke() &&
      object.getCourse() === other.getCourse();
  }
  return object;
}

