<template>
  <div v-if="mSquadExt.isValid()" class="mt-1">
    <b-modal centered title="Send squad announcement?" id="broadcast-modal">
      <h6>{{mMessageData.title}}</h6>
      <p class="my-4">{{ mMessageData.body }}</p>


      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="my-button-warning" v-on:click.stop="onConfirmSquadNotification()">
          Send
        </b-button>
        <b-button class="my-button-cancel" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>


    <div class="mt-4">
      <div class="my-text-primary" style="font-size: 0.9em; font-weight: bold">Send an announcement to the squad</div>
      <message-card
          :confirm-text="'Send'"
          :disable-date-picker="true"
          :disable-region-picker="true"
          :limit-body="0"
          :on-click-cb="onSendSquadNotification"
          :timestamp="mMessageDataTimestamp">
      </message-card>
    </div>
    <!-- message history-->
    <section style="min-height: 4rem;">
      <div class="my-text-primary" style="font-size: 0.9em; font-weight: bold">History ({{mMessageHistory.options.length}})</div>
      <loader :embedded="true" v-if="mLoading" style="margin-top: 4rem; text-align: center"></loader>
      <div v-else-if="mMessageHistory.options.length > 0" class="pl-1 pr-1" style="max-height: 24rem; overflow-y: auto">
        <div v-for="(message, index) in mMessageHistory.options" v-bind:key="index"
             class="my-text-primary my-box my-box-decoration mt-2 pt-1 pl-2 pr-2 pb-1
                    d-flex flex-column justify-content-sm-around"
             style="padding-top: 3px">

          <div class="d-flex flex-row justify-content-between" style="font-size: 0.8em">
            <div>
              {{message.formattedCreateAtDate()}}
            </div>
            <div>
              {{message.formattedCreateAtTime()}}
            </div>
          </div>
          <div style="font-size: 0.8em">
            <span style="font-weight: bold;">{{message.title}}</span>
          </div>
          <div class="mt-1" style="font-size: 0.8em">
            <span>{{message.body}}</span>
          </div>
        </div>
      </div>
      <div v-else>
      </div>
    </section>

  </div>
</template>

<script>

import MessageCard from "@/views/content/components/message_card"
import Loader from "@/components/loader"
import model from "@/model"
import webapi from "@/webapi"
import MySquadBloc from "@/bloc/mysquad_bloc";
import logger from "@/util/logger"
import DateTimeHelper from "@/util/datetime_helper"

const LOG_TAG = 'squad_notifications';

export default {
  name: LOG_TAG,
  components: {
    MessageCard,
    Loader
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      mLoading: true,
      mSquadExt: model.squadResponseExt(),
      mMessageData: model.notificationInfo(),
      mMessageDataTimestamp: DateTimeHelper.currentTimeInSecond(),
      mMessageHistory: {
        options: []
      },

    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    storeSquadMessageSet() {
      return this.$store.state.squadMessageSet.set;
    },
    storeSquadMessageTimestamp() {
      return this.$store.state.squadMessageSet.timestamp;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
  },
  watch: {
    squadExt: function onChange(value) {
      const vm = this;
      vm.refresh(value);
    },
    storeSquadMessageTimestamp: function onChange(value) {
      //logger.d(LOG_TAG, 'storeSquadMessageTimestamp', value);
      const vm = this;
      vm.onNotifyUpdateSquadMessage(vm.squadExt, value);
    }
  },
  mounted() {
    logger.d(LOG_TAG, 'mounted');
    const vm = this;
    vm.refresh(vm.squadExt);
  },
  methods: {
    onNotifyUpdateSquadMessage(selectedSquad) {
      const vm = this;
      const squadMessage = vm.storeSquadMessageSet[selectedSquad.getSquadId()];
      if (squadMessage) {
        //logger.d(LOG_TAG, 'onNotifyUpdateSquadMessage', squadMessage.length, selectedSquad.getSquadId());
        vm.mMessageHistory.options = squadMessage;
        vm.mLoading = false;
      }
    },
    refresh(selectedSquad, force) {
      const vm = this;
      const update = vm.mSquadExt.getSquadId() !== selectedSquad.getSquadId();
      logger.d(LOG_TAG, 'refresh',
          'force', force,
          'update', update,
          vm.mSquadExt.getSquadId(),
          selectedSquad.getSquadId()
      );
      if (update || force) {
        vm.mLoading = true;
        vm.mSquadExt = selectedSquad;
        vm.mMessageHistory.options = [];
        MySquadBloc.syncMessages(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
            force,
          (messages) => {
            logger.d(LOG_TAG, 'syncMessages', messages.length);
            if (force) {
              vm.$store.commit('setLoading', false);
            }
          },
          (error) => {
            vm.mLoading = false;
            logger.w(LOG_TAG, 'syncMessages', error);
            if (force) {
              vm.$store.commit('setLoading', false);
            }
          }
        );
      }
    },
    onSendSquadNotification(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSendSquadNotification', value);
      vm.mMessageData = value;
      vm.$bvModal.show('broadcast-modal');
    },
    onConfirmSquadNotification() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmSquadNotification');
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('broadcast-modal');
      webapi.apiService.broadcastMessage(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMessageData,
          (response) => {
            logger.d(LOG_TAG, 'broadcastMessage', response);
            vm.mMessageDataTimestamp = DateTimeHelper.currentTimeInSecond();
            vm.refresh(vm.mSquadExt, true);
          },
          (error) => {
            logger.w(LOG_TAG, 'broadcastMessage', error);
            vm.$store.commit('setLoading', false);
            vm.$notify({ type: 'error', text: 'There was a problem sending your message, please try again later.' });
          }
      )
    }
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
