<template>
  <div class="page_layout gradient_background">
    <div class="d-flex flex-row justify-content-center" style="height: 100%;">
      <div class="d-flex flex-column justify-content-between" style="min-width: 320px">
        <div></div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-self-center">
            <div class="brand p-2">
              <img alt="mytogs logo" src="../../assets/images/logo-mytogs-white.png">
            </div>
            <div class="brand p-2">
              <img alt="swimming new zealand logo" src="../../assets/images/logo-swimming-nz-white.png">
            </div>
          </div>
          <label class="label align-self-center mt-3">
            {{ 'MYSQUAD PORTAL'}}
          </label>
        </div>
        <div></div>
        <div></div>
        <div class="form-group align-self-center" style="max-width: 280px;">
          <div class="my-text-primary mt-4 mb-2" style="font-size: 0.6em">
            Copyright © 2021 myTogs - All Rights Reserved.
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import webapi from "@/webapi"
import model from "@/model"
import storageHelper from "@/util/local_storage_helper"
import logger from "@/util/logger"

const LOG_TAG = 'auto_login';

export default {
  name: LOG_TAG,
  components: {
  },
  props: {
  },
  computed: {
    isMySquad() {
      return process.env.VUE_APP_TARGET === 'mysquad';
    },
    isValid() {
      const vm = this;
      return vm.mUserName.length > 0 && vm.mPassword.length > 0;
    },
  },
  data() {
    return {
      mUserName: '',
      mPassword: '',
      mAuthenticating: false,
      mRememberMe: false,
    };
  },
  mounted() {
    const vm = this;
    logger.d(LOG_TAG, 'mounted', vm.$route.path);
    const userUuid = vm.$route.query.userUuid;
    const lastMyTogsLogin = vm.$route.query.lastMyTogsLogin;
    // auto login only supported by mysquad
    if (vm.isMySquad && userUuid && lastMyTogsLogin) {
      logger.d(LOG_TAG, 'mounted', userUuid, lastMyTogsLogin);
      vm.$store.commit('setLoading', true);
      webapi.apiService.autoLogin(
          userUuid, lastMyTogsLogin,
          (response) => {
            const data = model.authResponse(response);
            logger.d(LOG_TAG, 'mounted', response, data.isValid(), data.getToken());
            vm.$store.commit('setAuth', data);
            vm.$store.commit('setLoading', false);
            if (vm.mRememberMe) {
              storageHelper.setAuth(data);
            }
          },
          (error) => {
            logger.w(LOG_TAG, 'mounted', error);
            vm.$store.commit('setLoading', false);
            vm.$router.replace('/');
          }
      )
    } else {
      vm.$store.commit('setLoading', false);
      vm.$router.replace('/');
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.page_layout {
  position: absolute;
  width: 100%;
  height: 100%;
}
.gradient_background {
  background-image: linear-gradient(var(--background-blue), var(--background-blue-light))
}
.brand {
  max-width: 8.6rem;
}
.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.btn-outline-primary {
  color: var(--primary-highlight);
}

</style>

