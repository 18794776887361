//import displayHelper from "@/util/display_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      swimmer_10: false,
      swimmer_performance: false,
      link_fastlane: false,
      meet_result: false,
      xlr8_points: false,
      pysch_sheet: false,
    };
  }
  object.createDefault = function createDefault() {
    object.swimmer_10 = true;
    object.swimmer_performance = true;
    object.link_fastlane = false;
    object.meet_result = false;
    object.xlr8_points = false;
    object.pysch_sheet = false;
    return object;
  }
  object.isSupportLinkFastlane = function isSupportLinkFastlane() {
    return object.link_fastlane ? true : false;
  }
  object.isSupportMeetResult = function isSupportMeetResult() {
    return object.meet_result ? true : false;
  }
  object.isSupportSwimmer10 = function isSupportSwimmer10() {
    return object.swimmer_10 ? true : false;
  }
  object.isSupportSwimmerPerformance = function isSupportSwimmerPerformance() {
    return object.swimmer_performance ? true : false;
  }
  object.isSupportXlr8Points = function isSupportXlr8Points() {
    return object.xlr8_points ? true : false;
  }
  object.isSupportPyschSheet = function isSupportPyschSheet() {
    return object.pysch_sheet ? true : false;
  }
  return object;
}

