import { render, staticRenderFns } from "./place_holder.vue?vue&type=template&id=14a9aa28&scoped=true&"
import script from "./place_holder.vue?vue&type=script&lang=js&"
export * from "./place_holder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a9aa28",
  null
  
)

export default component.exports