import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlusCircle, faTimesCircle, faCalendarAlt, faTrashAlt,
  faDownload, faSearch, faTimes, faClock, faLink,
  faEdit, faCopy, faCheckCircle, faChevronDown, faChevronUp,
  faStickyNote, faPaperclip, faExternalLinkAlt,
  faSquare, faCheckSquare,
  faSortUp, faSortDown, faRedo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Chart, RadarController, RadialLinearScale, PointElement, LineElement, Tooltip, Title, Filler } from 'chart.js';

import Axios from 'axios'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import VueMask from 'v-mask'

import AppBloc from "@/bloc/app_bloc";
import logger from '@/util/logger'


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC955q8xS3IQ_9e-1gnrU0XvymK6UuaqHo",
  authDomain: "togs-3e106.firebaseapp.com",
  projectId: "togs-3e106",
  storageBucket: "togs-3e106.appspot.com",
  measurementId: "G-TEMRMLET8S"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const database = firebase.database();

window.firebase = firebaseApp;
window.firebaseStorageRef = storage.ref();
window.firebaseDatabaseRef = database;


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Notifications from 'vue-notification'
import VCalendar from 'v-calendar';

library.add(faPlusCircle)
library.add(faTimesCircle)
library.add(faCalendarAlt)
library.add(faTrashAlt)
library.add(faDownload)
library.add(faSearch)
library.add(faTimes)
library.add(faClock)
library.add(faLink)
library.add(faEdit)
library.add(faCopy)
library.add(faCheckCircle)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faStickyNote)
library.add(faPaperclip)
library.add(faExternalLinkAlt)
library.add(faSquare)
library.add(faCheckSquare)
library.add(faSortUp)
library.add(faSortDown)
library.add(faRedo)
Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, Tooltip, Title, Filler);

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueMask);
// Axios.defaults.baseURL = process.env.TARGET_ENDPOINT;
// Axios.defaults.withCredentials = true;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  const response = error.response
  const status = response.status
  logger.w('main', 'axios', status, response.data)
  if (status === 401) {
    //
    AppBloc.logout();
  }
  return Promise.reject(response)
})
//
import './assets/css/global.css'

Vue.config.productionTip = false

window.store = store;
window.router = router;

import webapi from "@/webapi"
window.webapi = webapi;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
