<template>
  <content-layout>

    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search by -->
          <label class="my-text-primary" style="font-size: 0.85em;">
            {{ mDescriptions[0]}}
          </label>
          <email-card
            :_on-click-cb="onResetPassword">
          </email-card>
          <!-- search result -->

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import EmailCard from "@/views/content/components/email_card"
import webapi from "@/webapi"
import logger from "@/util/logger"

const LOG_TAG = 'resetPassword';

export default {
  name: LOG_TAG,
  display: 'Reset Password',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    EmailCard,
  },
  data() {
    return {
      mFilePath: '',
      mState: '',
      mStateDescription: '',
      mDescriptions: [
        'Enter your registered email account and an email with your new password will be sent to you.',
      ]
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },

  },
  mounted() {
  },
  methods: {
    onResetPassword(email) {
      const vm = this;
      logger.d(LOG_TAG, 'onResetPassword', email);
      vm.$store.commit('setLoading', true);
      webapi.apiService.resetPassword(
        email,
        (response) => {
          logger.d(LOG_TAG, 'onResetPassword', response);
          vm.$notify({
            type: 'success',
            title: 'Success',
            text: response,
            duration: 3000});
          vm.$store.commit('setLoading', false);
        },
        (error) => {
          logger.w(LOG_TAG, 'onResetPassword', error);
          vm.$notify({
            type: 'error',
            title: 'Oops, something went wrong.',
            text: error.data,
            duration: 3000
          });
          vm.$store.commit('setLoading', false);
        }
      );
    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
