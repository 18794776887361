import model from "@/model"
import webapi from "@/webapi"
// import dateTimeHelper from "@/util/datetime_helper"
import logger from "@/util/logger"

const LOG_TAG = 'mytogs_bloc';
export default {
  syncScheduledMessages(onSuccess, onError) {
    logger.d(LOG_TAG, 'syncScheduledMessages');
    webapi.apiService.getScheduledPushList(
      (response) => {
        logger.d(LOG_TAG, 'syncScheduledMessages', response.length);
        const dataList = response.map((data) => model.notificationInfo(data));
        dataList.sort((a, b) => a.getScheduledDate() - b.getScheduledDate())
        onSuccess(dataList);
      },
      (error) => {
        logger.w(LOG_TAG, 'syncScheduledMessages', error);
        onError(error);
      }
    )
  },
  addScheduledMessage(data, onSuccess, onError) {
    logger.d(LOG_TAG, 'addScheduledMessage', data);
    webapi.apiService.createScheduledPush(
      data,
      (response) => {
        logger.d(LOG_TAG, 'addScheduledMessage', response);
        this.syncScheduledMessages(
          (dataList) => {
            onSuccess(dataList);
          },
          (error) => {
            onError(error);
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'addScheduledMessage', error);
        onError(error);
      }
    )
  },
  removeScheduledMessage(messageId, onSuccess, onError) {
    logger.d(LOG_TAG, 'removeScheduledMessage', messageId);
    webapi.apiService.deleteScheduledPush(
      messageId,
      (response) => {
        logger.d(LOG_TAG, 'removeScheduledMessage', response);
        this.syncScheduledMessages(
          (dataList) => {
            onSuccess(dataList);
          },
          (error) => {
            onError(error);
          }
        )
      },
      (error) => {
        logger.w(LOG_TAG, 'removeScheduledMessage', error);
        onError(error);
      }
    )
  },
  searchUser(email, onSuccess, onError) {
    logger.d(LOG_TAG, 'searchUser', email);
    webapi.apiService.searchMember(email,
      (response) => {
        onSuccess(model.memberResponse(response));
      },
      (error) => {
        logger.w(LOG_TAG, 'searchUser', error);
        onError(error);
      }
    )
  },
  searchUserByUuid(member, onSuccess, onError) {
    const uuid = member.getId();
    logger.d(LOG_TAG, 'searchUserByUuid', uuid);

    webapi.apiService.getUser(uuid,
      (response) => {
        onSuccess(model.memberResponse()
          .setName(response.userName)
          .setEmail(response.userEmail)
          .setUserUuid(uuid)
          .setPremium(member.isLegacyPremium())
          .setPremiumSubscription(member.isPremiumSubscription())
          .setSubscription(member.getSubscription())
          .setPlatform(member.platform)
          .setKey(Date.now())
        );
      },
      (error) => {
        logger.w(LOG_TAG, 'searchUserByUuid', error);
        onError(error);
      }
    )
  },
  searchSwimmer(name, onSuccess, onError) {
    logger.d(LOG_TAG, 'searchSwimmer', name);
    webapi.adminService.searchSwimmer(name,
      (response) => {
        const dataList = response.map((data) => model.memberResponse(data));
        onSuccess(dataList);
      },
      (error) => {
        logger.w(LOG_TAG, 'searchSwimmer', error);
        onError(error);
      }
    )
  },
  forceDatabaseReSync(uId, onSuccess, onError) {
    logger.d(LOG_TAG, 'forceDatabaseReSync', name);
    webapi.adminService.triggerDatabaseReSync(uId,
      (response) => {
        logger.d(LOG_TAG, 'forceDatabaseReSync', response);
        onSuccess(response);
      },
      (error) => {
        logger.w(LOG_TAG, 'forceDatabaseReSync', error);
        onError(error);
      }
    )
  },
};
