// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import displayHelper from "@/util/display_helper";
import datetimeHelper from "@/util/datetime_helper";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      description: '',
      details: '',

      clubId: '',
      createdAt: 0,
      createdBy: '',
      trainingSetId: ''
    };
  }
  object.getTrainingSetId = function getTrainingSetId() {
    return object.trainingSetId ? object.trainingSetId : '';
  },
  object.getClubId = function getClubId() {
    return object.clubId ? object.clubId : '';
  },
  object.getCreatedAt = function getCreatedAt() {
    return object.createdAt ? object.createdAt : 0;
  },
  object.getCreatedBy = function getCreatedBy() {
    return object.createdBy ? object.createdBy : 0;
  },
  object.getDescription = function getDescription() {
    return object.description ? object.description : '---';
  },
  object.getDetails = function getDetails() {
    return object.details ? object.details : '';
  },
  object.getDetailsLinkify = function getDetailsLinkify(color) {
    return displayHelper.validateTextWithUrlLink(object.getDetails(), null!= color ? color : 'white');
  },
  object.formattedCreateAt = function formattedCreateAt() {
    return datetimeHelper.formattedDateTime(this.getCreatedAt());
  },
  object.setDescription = function setDescription(value) {
    object.description = value;
    return this;
  },
  object.setDetails = function setDetails(value) {
    object.details = value;
    return this;
  }

  return object;
}

