<template>
  <div>
    <h1>This is a Place Holder Page</h1>
  </div>
</template>

<script>

const LOG_TAG = 'place_holder';
export default {
  name: LOG_TAG,
  display: 'PlaceHolder',
  route: `dashboard.${LOG_TAG}`,
  props: {
    placeHolder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return LOG_TAG;
      }
    }
  },
}
</script>

<style scoped>

</style>
