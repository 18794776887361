<template>
  <content-layout>
    <div slot="content_container">
      <div class="row mt-2 mb-5">
        <!-- modal start -->
        <!-- broadcast-modal -->


        <!-- create-squad-modal -->
        <b-modal centered title="Create Squad" id="create-squad-modal">
          <form ref="form" @submit.stop.prevent="onConfirmAddNewSquad()">
            <b-form-group label="Squad name" label-for="title-input">
              <b-form-input id="title-input" v-model="mCreateSquad.name" maxlength="25"></b-form-input>
              <label style="font-size: 0.6em">* max 25 characters</label>
            </b-form-group>
          </form>
          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-confirm" @click="onConfirmAddNewSquad()" :disabled="!mCreateSquad.isValid()">
              Create
            </b-button>
            <b-button class="my-button-cancel" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>
        <!-- delete-squad-modal -->
        <b-modal centered title="Delete squad?" id="delete-squad-modal">
          <p class="my-4">
            {{ mDeleteSquad.getSquadName() }}
          </p>

          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="my-button-warning" @click="onConfirmDeleteSquad()">
              Delete
            </b-button>
            <b-button class="my-button-cancel" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>
        <!-- modal end -->

        <div class="col-1 desktop"></div>
        <!-- content section-->
        <section
            class="col-10"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left;">
          <!-- create club section -->
          <section v-if="storeAuth.hasClub()">
            <loader v-if="!mInitialized" :embedded="true" style="margin-top: 4rem; text-align: center"></loader>
            <div v-else>
              <div class="d-flex flex-row justify-content-between">
                <label class="my-text-primary" style="font-size: 1.6em; font-weight: bold; text-transform: capitalize;">{{mClub.getClubName()}}</label>
                <label class="my-text-primary" style="font-size: 0.8em">{{ storeAuth.formattedRole() }}</label>
              </div>

              <!-- my squads-->
              <div class="mt-2">
                <section v-if="windowWidth > 768">
                  <label class="my-text-primary" style="font-weight: bold;">Squads</label>
                  <div class="d-flex flex-row flex-wrap col-12 pl-0 pr-0">
                    <div v-for="(squad, index) in mSquads.options" v-bind:key="index"
                         class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-1
                            mr-1 mb-1"
                         :class="{
                          'my-highlight-item-selected': squad.isValid() && squad.getSquadId() === mSquads.selected.getSquadId()
                        }">
                      <div v-if="squad.isValid()" v-on:click.stop="onSelectSquad(squad)">
                        <span>{{ squad.getSquadName() }}</span>
                      </div>
                      <div v-else v-on:click.prevent="onAddNewSquad">
                        <font-awesome-icon
                            icon="plus-circle"
                            size="sm"
                            class="label mr-2"/>
                        <span style="font-weight: bold; text-transform: capitalize;">Add new squad</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section v-else>
                  <div
                      v-if="mSquads.supportNewSquad"
                      class="clickable d-flex justify-content-end my-text-primary" style="font-size: 0.85em" v-on:click.prevent="onAddNewSquad">
                    <font-awesome-icon
                        icon="plus-circle"
                        size="sm"
                        class="label mt-1 mr-1"/>
                    <span style="font-weight: bold; text-transform: capitalize;">Add new squad</span>
                  </div>
                  <b-form-group class="my-text-primary" label="Select squad" label-for="squad-select" style="font-size: 0.85em">
                    <b-form-select id="squad-select" v-model="mSquads.mobileSelected" :options="mSquads.mobileOptions"></b-form-select>
                  </b-form-group>
                </section>
<!--                <section v-else-if="!storeAuth.hasSquads()">
                  <label class="my-text-primary">You do not currently belong to any squad,
                    please contact your club administrator or check your email inbox for pending squad invitation.</label>
                </section>-->
              </div>
            </div>
          </section>
          <section v-else>
            <label class="my-text-primary">You do not currently belong to any club
            {{ isAdmin ? '' : ', please contact your club administrator.'}}</label>
          </section>

          <!-- squad details -->
          <section v-if="storeAuth.hasClub() && mInitialized && mSquads.selected.isValid()" class="mt-3">
            <!-- squad name -->
            <div class="my-text-primary d-flex flex-row justify-content-between pl-1 pr-1 pt-2">
              <span style="font-size: 1em; font-weight: bold; text-transform: capitalize;">{{ mSquads.selected.getSquadName() }}</span>
              <font-awesome-icon
                  v-if="isAdmin"
                  icon="trash-alt"
                  size="sm"
                  class="label clickable warning mr-1"
                  v-on:click.prevent="onDeleteSquad(mSquads.selected)"
              />
            </div>
            <!-- tabs -->
            <div class="d-flex flex-row flex-wrap col-12 mt-3 pl-0 pr-0">
              <div v-for="(option, index) in mSquadOptions.options" v-bind:key="index"
                   class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2
                    ml-1 mb-1"
                   :class="{
                          'my-highlight-item-selected': option.value === mSquadOptions.selected.value
                        }"
                   v-on:click.stop="onSelectSquadOption(option)">
                <span>{{ option.text }}</span>
              </div>
            </div>

            <!-- tab options -->
            <squad-members
                v-if="mSquadOptions.isMembers()" :squad-ext="mSquads.selected" :disable-edit="!(mCoachAccess)">
            </squad-members>
            <squad-schedules
                v-else-if="mSquadOptions.isSchedules()" :squad-ext="mSquads.selected" :disable-edit="!(mCoachAccess)">
            </squad-schedules>
            <squad-training-logs
                v-else-if="mSquadOptions.isTrainingLogs()" :squad-ext="mSquads.selected">
            </squad-training-logs>
            <squad-notifications
                v-else-if="mSquadOptions.isNotifications()" :squad-ext="mSquads.selected">
            </squad-notifications>
          </section>

        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>

import ContentLayout from "@/components/content_layout"
import Loader from "@/components/loader"
import SquadMembers from "@/views/content/components/squad_members"
import SquadSchedules from "@/views/content/components/squad_schedules"
import SquadTrainingLogs from "@/views/content/components/squad_training_logs"
import SquadNotifications from "@/views/content/components/squad_notifications"
import MySquadBloc from "@/bloc/mysquad_bloc";


import model from "@/model"
import webapi from "@/webapi"
import logger from "@/util/logger"

const LOG_TAG = 'my_club';

export default {
  name: LOG_TAG,
  display: 'My Club',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    Loader,
    SquadMembers,
    SquadSchedules,
    SquadTrainingLogs,
    SquadNotifications
  },
  props: {
    placeHolder: {
      type: String,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return LOG_TAG;
      }
    }
  },
  data() {
    return {
      mInitialized: false,
      mCoachAccess: false,
      mClub: model.clubInfo(),
      mSquads: {
        selected: model.squadResponseExt(),
        options: [],

        mobileOptions: [],
        mobileSelected: '',

        supportNewSquad: false,
        setSquads(value) {
          this.options = value;
          this.selected = model.squadResponseExt();

          this.mobileOptions = [];

          this.supportNewSquad = false;
          value.forEach((data) => {
            if (data.isValid()) {
              this.mobileOptions.push({
                text: data.getSquadName(),
                value: data.getSquadId()
              })
            } else {
              this.supportNewSquad = true;
            }
          });
        },

        clear() {
          this.selected = model.squadResponseExt();
          this.options = [];
        },
        //
      },
      mCreateSquad: {
        name: '',
        isValid() {
          return this.name.length > 0;
        },
        clear() {
          this.name = '';
        }
      },
      mDeleteSquad: model.squadInfo(),
      mSquadOptions: {
        selected: {text: '', value: ''},
        options: [
          {text: 'Members', value: 'members'},
          {text: 'Schedules', value: 'schedules'},
          {text: 'Training logs', value: 'training_logs'},
          {text: 'Notifications', value: 'notifications'},
        ],
        isMembers() {
          return this.selected.value === this.options[0].value;
        },
        isSchedules() {
          return this.selected.value === this.options[1].value;
        },
        isTrainingLogs() {
          return this.selected.value === this.options[2].value;
        },
        isNotifications() {
          return this.selected.value === this.options[3].value;
        },
        init(coachAccess) {
          this.options = [
            {text: 'Members', value: 'members'},
            {text: 'Schedules', value: 'schedules'},
            {text: 'Training logs', value: 'training_logs'},
          ]
          if (coachAccess) {
            this.options.push({text: 'Announcements', value: 'notifications'});
          }
          this.selected = this.options[0];
        }
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    storeClubSet() {
      return this.$store.state.clubsSet.set;
    },
    storeClubTimestamp() {
      return this.$store.state.clubsSet.timestamp;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
  },
  watch: {
    storeClubTimestamp: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:storeClubTimestamp', value);
      vm.onNotifyUpdateClub();
    },
    'mSquads.mobileSelected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:mobileSelected', value);
      vm.onSelectSquad(vm.mSquads.options.find((squad) => squad.getSquadId() === value));
    }
  },
  mounted() {
    const vm = this;
    if (vm.storeAuth.hasClub()) {
      vm.refresh();
    }
  },
  methods: {
    onNotifyUpdateClub(selectedSquad) {
      const vm = this;
      const clubInfo = vm.storeClubSet[vm.storeAuth.getClubId()];
      if (clubInfo) {
        logger.d(LOG_TAG, 'onNotifyUpdateClub', clubInfo.getClubName());

        let clubSquads = [];
        if (vm.isAdmin) {
          // admin has access to all club squads
          for (let index = 0; clubInfo.getSquadLimit() > index; index++) {
            if (clubInfo.getSquads().length > index) {
              const squad = model.squadResponseExt(clubInfo.getSquads()[index])
                .setClubId(clubInfo.getClubId())
                .setClubName(clubInfo.getClubName())
                .setSquadSize(clubInfo.getSquadSize())
                .setSquadLimit(clubInfo.getSquadLimit());

              clubSquads.push(squad);
            } else {
              clubSquads.push(model.squadResponse());
            }
          }
        } else {
          // coaches only sees their own squad
          clubInfo.getSquads().forEach((data) => {
            const squad = model.squadResponseExt(data);
            if (vm.storeAuth.isSquadCoach(squad.getSquadId())) {
              clubSquads.push(squad
                .setClubId(clubInfo.getClubId())
                .setClubName(clubInfo.getClubName())
                .setSquadSize(clubInfo.getSquadSize())
                .setSquadLimit(clubInfo.getSquadLimit())
              );
            }
          });
        }

        logger.d(LOG_TAG, 'onNotifyUpdateClub', 'squads', clubSquads.length);
        vm.mClub = clubInfo;
        vm.mSquads.setSquads(clubSquads);

        if (null!=selectedSquad) {
          vm.onSelectSquad(selectedSquad);
        } else if (clubSquads.length > 0) {
          vm.onSelectSquad(clubSquads[0]);
        }
        vm.mInitialized = true;
      }
    },
    refresh(selectedSquad) {
      logger.d(LOG_TAG, 'refresh', selectedSquad);
      const vm = this;
      const auth = vm.storeAuth;
      MySquadBloc.syncClub(
          auth.getClubId(),
          () => {
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            logger.d(LOG_TAG, 'syncClub', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onSelectSquad(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onSelectSquad', value);
      vm.mSquads.selected = value;
      vm.mSquads.mobileSelected = value.getSquadId();
      vm.mCoachAccess = vm.isAdmin || vm.storeAuth.isSquadCoach(value.getSquadId());
      vm.mSquadOptions.init(vm.mCoachAccess);
    },
    onAddNewSquad() {
      const vm = this;
      logger.d(LOG_TAG, 'onAddNewSquad');
      vm.mCreateSquad.clear();
      vm.$bvModal.show('create-squad-modal');
    },
    onConfirmAddNewSquad() {
      const vm = this;
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('create-squad-modal');
      webapi.apiService.createSquad(
        vm.mClub.getClubId(),
        model.squadInfo()
          .setSquadName(vm.mCreateSquad.name)
          .setClubName(vm.mClub.getClubName()),
        (response) => {
          logger.d(LOG_TAG, 'createSquad', response);
          vm.mCreateSquad.clear();

          vm.refresh(model.squadResponse(response));
        },
        (error) => {
          logger.w(LOG_TAG, 'createSquad', error);
          vm.mCreateSquad.clear();
          vm.$store.commit('setLoading', false);
        }
      )
    },
    onDeleteSquad(value) {
      const vm = this;
      vm.mDeleteSquad = value;
      logger.d(LOG_TAG, 'onDeleteSquad');
      vm.$bvModal.show('delete-squad-modal');
    },
    onConfirmDeleteSquad() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmDeleteSquad', vm.mDeleteSquad);
      vm.$store.commit('setLoading', true);

      vm.$bvModal.hide('delete-squad-modal');
      webapi.apiService.deleteSquad(
          vm.mClub.getClubId(),
          vm.mDeleteSquad.getSquadId(),
        (response) => {
            logger.d(LOG_TAG, 'deleteSquad', response);
            vm.refresh();
          },
          (error) => {
            logger.w(LOG_TAG, 'deleteSquad', error);
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onSelectSquadOption(value) {
      const vm = this;
      vm.mSquadOptions.selected = value;
    },
  }
}
</script>

<style scoped>
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
