<template>
  <div class="my-overlay-host">
    <div class="my-overlay-loader-background-darker d-flex flex-column justify-content-center">
    </div>
    <div class="my-overlay-loader-background d-flex flex-column justify-content-center">
      <div>
        <vc-calendar
            v-if="storeDatePicker.viewOnly"
            show-weeknumbers
            :min-date='new Date()'
            :attributes="mCalendarAttributes"/>
        <!-- by month -->
        <div v-else-if="storeDatePicker.byMonth"
             class="ml-auto mr-auto"
             style="width: 20rem">

          <!-- year -->
          <label class="my-text-primary font-weight-bold">Year:</label>
          <div class="d-flex flex-row justify-content-center">
            <div
                v-for="year in mMonthPicker.year.options" :key="year"
                class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2 mr-1"
                style="width: 5rem; font-size: 0.8em"
                :class="{
                          'my-highlight-item-selected': year === mMonthPicker.year.selected
                        }"
                v-on:click.stop="onSelectYear(year)">
              <label>{{year}}</label>
            </div>
          </div>

          <!-- Month from -->
          <div class="mt-3"></div>
          <label class="my-text-primary font-weight-bold">Month from:</label>
          <div class="d-flex flex-row justify-content-center flex-wrap">
            <div
                v-for="month in mMonthPicker.monthFrom.options" :key="month.value + 'from'"
                class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2 mr-1 mt-1"
                style="width: 4rem; font-size: 0.8em"
                :class="{
                  'my-highlight-item-disabled': month.disabled,
                  'my-highlight-item-selected': month.value === mMonthPicker.monthFrom.selected
                        }"
                v-on:click.stop="onSelectMonthFrom(month)">
              {{ month.text }}
            </div>
          </div>

          <!-- Month to -->
          <div class="mt-3"></div>
          <label class="my-text-primary font-weight-bold">Month to:</label>
          <div class="d-flex flex-row justify-content-center flex-wrap">
            <div
                v-for="month in mMonthPicker.monthTo.options" :key="month.value + 'to'"
                class="clickable my-highlight-item my-text-primary my-box my-box-decoration my-button-tab-decoration-2 mr-1 mt-1"
                style="width: 4rem; font-size: 0.8em"
                :class="{
                  'my-highlight-item-disabled': month.disabled,
                  'my-highlight-item-selected': month.value === mMonthPicker.monthTo.selected
                        }"
                v-on:click.stop="onSelectMonthTo(month)">
              {{ month.text }}
            </div>
          </div>
          <div class="mt-3 mb-2">
            <label class="my-text-primary" style="font-size: 0.85em">* max {{ mMonthPicker.maxMonth }} months</label>
          </div>

        </div>
        <!-- by month 2-->
        <div v-else-if="storeDatePicker.byMonth2"
             class="ml-auto mr-auto"
             style="width: 20rem;">
          <div>
            <label class="my-text-primary font-weight-bold">{{ formattedSelectedDateRange }}</label>
          </div>

          <vc-date-picker
              v-model='mSelectedDate'
              show-weeknumbers
              is-range
              :min-date='new Date(2021, 8, 1)'
              :attributes="mPickerAttributes"/>
          <div class="mt-3 mb-2">
            <label class="my-text-primary" style="font-size: 0.85em">* max {{ mMaxDaysRange }} days</label>
          </div>
        </div>
        <!-- week -->
        <vc-date-picker
            v-else-if="storeDatePicker.byWeek"
            v-model='mSelectedDate'
            show-weeknumbers
            :min-date='new Date(2021, 8, 1)'
            :max-date='new Date()'
            :attributes="mPickerAttributes"/>
        <!-- day(s) -->
        <vc-date-picker
            v-else-if="storeDatePicker.byDays"
            v-model='mSelectedDate'
            show-weeknumbers
            is-range
            :min-date='new Date()'
            :attributes="mPickerAttributes"/>
        <!-- day -->
        <vc-date-picker
            v-else-if="storeDatePicker.byDay"
            v-model='mSelectedDate'
            show-weeknumbers
            :min-date='storeDatePicker.minDate ? storeDatePicker.minDate : new Date()'
            :attributes="mPickerAttributes"/>
        <vc-date-picker
            v-else-if="storeDatePicker.byDayNoLimit"
            v-model='mSelectedDate'
            show-weeknumbers
            :min-date='storeDatePicker.minDate ? storeDatePicker.minDate : null'
            :attributes="mPickerAttributes"/>
        <!-- datetime -->
        <vc-date-picker
            v-else-if="storeDatePicker.byDateTime"
            v-model='mSelectedDate'
            show-weeknumbers
            mode='dateTime'
            is24hr
            :min-date='storeDatePicker.minDate ? storeDatePicker.minDate : new Date()'
            :attributes="mPickerAttributes"/>
        <vc-date-picker
            v-else
            :min-date='new Date()'
            v-model='mSelectedDate'
            mode='dateTime'/>
        <div v-if="storeDatePicker.viewOnly" class="mt-2">
          <button
              class="btn btn-outline-primary my-box-decoration"
              style="width: 15.6rem;"
              v-on:click.prevent="onClickCancel">
            Close
          </button>
        </div>
        <div v-else class="mt-2">
          <button
              class="btn btn-outline-primary my-box-decoration"
              style="width: 7.6rem;"
              v-on:click.prevent="onClickCancel">
            Cancel
          </button>
          <button
              class="btn btn-outline-primary my-box-decoration ml-1"
              style="width: 7.6rem"
              v-on:click.prevent="onClickConfirm">
            Done
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateTimeHelper from "@/util/datetime_helper"
import logger from "@/util/logger";

const LOG_TAG = 'date_picker';

export default {
  name: LOG_TAG,
  props: {
    embedded: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
  },
  data() {
    return {
      mSelectedDate: null,
      mHighlight: {
        default: {
          start: { fillMode: 'outline' },
          base: { fillMode: 'light' },
          end: { fillMode: 'outline' },
        }
      },
      mPickerAttributes: [
        {
          highlight: {
            start: { fillMode: 'outline' },
            base: { fillMode: 'light' },
            end: { fillMode: 'outline' },
          },
          //dates: { start: new Date(2019, 0, 14), end: new Date(2019, 0, 18) },
        },
      ],
      mMaxDaysRange: 90,
      mCalendarAttributes: [],
      mMonthPicker: {
        maxMonth: 2,
        firstYear: 2021,
        init(data) {
          // init year options
          let yearNow = dateTimeHelper.epocYearNow();
          this.year.options = [];
          for (let year = this.firstYear; year <= yearNow; year++ ) {
            this.year.options.push(year);
          }

          // set default year
          let yearCurrent = dateTimeHelper.epocYearNow(data.dateStart);
          let yearIndex = this.year.options.findIndex((year) => year === yearCurrent);
          this.setYear(this.year.options[yearIndex]);

          // set default month From/To
          this.setMonthFromTo(data.dateStart, data.dateEnd);
        },
        setYear(value) {
          this.year.selected = value;
          let yearNow = dateTimeHelper.epocYearNow();
          if (value === yearNow) {
            let monthNow = dateTimeHelper.epocMonthNow();
            // disable years currently not selectable
            this.monthFrom.options.forEach((option) => {
              if (option.value > monthNow) {
                option.disabled = true;
              } else {
                option.disabled = false;
              }
            });
            this.monthTo.options.forEach((option) => {
              if (option.value > monthNow) {
                option.disabled = true;
              } else {
                option.disabled = false;
              }
            });
            this.monthFrom.selected = monthNow;
            this.monthTo.selected = monthNow;
          } else {
            this.monthFrom.options.forEach((option) => {
              option.disabled = false;
            });
            this.monthTo.options.forEach((option) => {
              option.disabled = false;
            });
          }
          //logger.d(LOG_TAG, 'setYear', value, monthNow);
        },
        setMonthFromTo(dateFrom, dateTo) {
          this.monthFrom.selected = dateTimeHelper.epocMonthNow(dateFrom);
          this.monthTo.selected = dateTimeHelper.epocMonthNow(dateTo);
        },
        year: {
          selected: null,
          options: [],
        },
        monthFrom: {
          selected: null,
          options: [
            {text: 'Jan', value: 1, disabled: false},
            {text: 'Feb', value: 2, disabled: false},
            {text: 'Mar', value: 3, disabled: false},
            {text: 'Apr', value: 4, disabled: false},
            {text: 'May', value: 5, disabled: false},
            {text: 'Jun', value: 6, disabled: false},
            {text: 'Jul', value: 7, disabled: false},
            {text: 'Aug', value: 8, disabled: false},
            {text: 'Sep', value: 9, disabled: false},
            {text: 'Oct', value: 10, disabled: false},
            {text: 'Nov', value: 11, disabled: false},
            {text: 'Dec', value: 12, disabled: false},
          ],
        },
        monthTo: {
          selected: null,
          options: [
            {text: 'Jan', value: 1, disabled: false},
            {text: 'Feb', value: 2, disabled: false},
            {text: 'Mar', value: 3, disabled: false},
            {text: 'Apr', value: 4, disabled: false},
            {text: 'May', value: 5, disabled: false},
            {text: 'Jun', value: 6, disabled: false},
            {text: 'Jul', value: 7, disabled: false},
            {text: 'Aug', value: 8, disabled: false},
            {text: 'Sep', value: 9, disabled: false},
            {text: 'Oct', value: 10, disabled: false},
            {text: 'Nov', value: 11, disabled: false},
            {text: 'Dec', value: 12, disabled: false},
          ],
        }
      }
    };
  },
  computed: {
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
    formattedSelectedDateRange() {
      const vm = this;
      if (vm.mSelectedDate) {
        let days = dateTimeHelper.formattedDayDifference(vm.mSelectedDate.start, vm.mSelectedDate.end) + 1;
        return dateTimeHelper.formattedDateTime(vm.mSelectedDate.start, true) +
            ' ~ ' +
            dateTimeHelper.formattedDateTime(vm.mSelectedDate.end, true) +
            ' ('+days+')';
      } else {
        return ' ~ ';
      }
    },
  },
  watch: {
    mSelectedDate: function onChange(value) {
      const vm = this;
      if (!vm.storeDatePicker.viewOnly) {
        logger.d(LOG_TAG, 'watch', 'mSelectedDate', value);
        if (vm.storeDatePicker.byMonth2) {
          vm.updateSelectedDateRange(value.start, value.end, true);
        } else {
          vm.updateSelectedDateRange(value, null, true);
        }
      }
    },
    storeDatePicker(value) {
      logger.d(LOG_TAG, 'watch', 'storeDatePicker', value/*, datetimeHelper.convertDateTimeToMilliseconds(value)*/);
      //const vm = this;
    },
  },
  mounted() {
    const vm = this;
    logger.d(LOG_TAG, 'mounted');
    if (vm.storeDatePicker.byMonth) {
      vm.mMonthPicker.init(vm.storeDatePicker);
    } else if (vm.storeDatePicker.byMonth2) {
      let startDate = dateTimeHelper.convertDateFromMilliseconds(vm.storeDatePicker.dateStart);
      let endDate = dateTimeHelper.convertDateFromMilliseconds(vm.storeDatePicker.dateEnd);
      vm.updateSelectedDateRange(startDate, endDate);
    } else {
      let date = dateTimeHelper.convertDateFromMilliseconds(vm.storeDatePicker.date);
      vm.updateSelectedDateRange(date, null);
    }
  },
  methods: {
    updateSelectedDateRange(date1, date2, ignoreUpdate) {
      const vm = this;
      if (vm.storeDatePicker.viewOnly) {
        logger.d(LOG_TAG, 'updateSelectedDateRange', 'viewOnly');
        vm.mCalendarAttributes = vm.storeDatePicker.selectedDates.map(dayInfo => ({
          highlight: true,
          dates: {
            start: dayInfo.formattedCalendarStartDate(),
            end: dayInfo.formattedCalendarEndDate(),
          },
          popover: {
            label: dayInfo.getDescription(),
          }

        }))
      } else if (vm.storeDatePicker.byMonth) {
        logger.d(LOG_TAG, 'updateSelectedDateRange', 'byMonth');
      } else if (vm.storeDatePicker.byMonth2) {
        logger.d(LOG_TAG, 'updateSelectedDateRange', 'byMonth2');
        if (!ignoreUpdate) {
          vm.mSelectedDate = {
            start: date1,
            end: date2
          };
        }

      } else if (vm.storeDatePicker.byWeek) {
        logger.d(LOG_TAG, 'updateSelectedDateRange', 'byWeek');
        const selectedDateInMilliseconds = dateTimeHelper.convertDateTimeToMilliseconds(date1);
        const startOfWeek = dateTimeHelper.epocStartOfWeek(selectedDateInMilliseconds);
        let endOfWeek = dateTimeHelper.epocEndOfWeek(selectedDateInMilliseconds);
        // endOfWeek does not go beyond current time
        const currentDateInMilliseconds = dateTimeHelper.epocTimeNow();
        if (endOfWeek > currentDateInMilliseconds) {
          endOfWeek = currentDateInMilliseconds;
        }

        if (!ignoreUpdate) {
          vm.mSelectedDate = date1;
        }
        // range
        vm.mPickerAttributes[0].dates = {
          start: dateTimeHelper.convertDateFromMilliseconds(startOfWeek),
          end: dateTimeHelper.convertDateFromMilliseconds(endOfWeek),
        }
      } else if (vm.storeDatePicker.byDays) {
        logger.d(LOG_TAG, 'updateSelectedDateRange', 'byDays');
        if (!ignoreUpdate) {
          vm.mSelectedDate = date1;
        }
      }
      logger.d(LOG_TAG, 'updateSelectedDateRange', date1, date2, vm.mSelectedDate);
    },
    onClickCancel() {
      const vm = this;
      const update = {
        enabled: false,
        date: vm.storeDatePicker.date,
        dateStart: vm.storeDatePicker.dateStart,
        dateEnd: vm.storeDatePicker.dateEnd,
      }
      // logger.d(LOG_TAG, 'onClickCancel', update);
      vm.$store.commit('setDatePicker', update);
    },
    onClickConfirm() {
      const vm = this;
      const update = {
        'enabled': false
      };
      if (vm.storeDatePicker.byMonth) {
        update.dateStart = dateTimeHelper.epocStartOfMonth(
            new Date(
                vm.mMonthPicker.year.selected,
                vm.mMonthPicker.monthFrom.selected - 1)
        );
        update.dateEnd = dateTimeHelper.epocEndOfMonth(
            new Date(
                vm.mMonthPicker.year.selected,
                vm.mMonthPicker.monthTo.selected - 1)
        );
      } else if (vm.storeDatePicker.byMonth2) {
        let days = dateTimeHelper.formattedDayDifference(vm.mSelectedDate.start, vm.mSelectedDate.end);
        if (days <= vm.mMaxDaysRange) {
          update.dateStart = dateTimeHelper.convertDateTimeToMilliseconds(vm.mSelectedDate.start);
          update.dateEnd = dateTimeHelper.convertDateTimeToMilliseconds(vm.mSelectedDate.end);
        } else {
          return;
        }
      } else {
        update.date = vm.mSelectedDate;
      }

      logger.d(LOG_TAG, 'onClickConfirm', update);
      vm.$store.commit('setDatePicker', update);
    },
    onSelectYear(value) {
      logger.d(LOG_TAG, 'onSelectYear', value);
      const vm = this;
      vm.mMonthPicker.setYear(value);
    },
    onSelectMonthFrom(value) {
      const vm = this;
      if (!value.disabled) {
        logger.d(LOG_TAG, 'onSelectMonthFrom', value.value);
        vm.mMonthPicker.monthFrom.selected = value.value;

        if (value.value > vm.mMonthPicker.monthTo.selected) {
          vm.onSelectMonthTo(value);
        }

        // validate max month
        let maxMonth = vm.mMonthPicker.monthTo.selected - vm.mMonthPicker.monthFrom.selected;
        if (maxMonth >= vm.mMonthPicker.maxMonth) {
          vm.mMonthPicker.monthTo.selected = vm.mMonthPicker.monthFrom.selected + (vm.mMonthPicker.maxMonth - 1);
        }
      }
    },
    onSelectMonthTo(value) {
      const vm = this;
      if (!value.disabled) {
        logger.d(LOG_TAG, 'onSelectMonthTo', value.value);
        vm.mMonthPicker.monthTo.selected = value.value;

        if (value.value < vm.mMonthPicker.monthFrom.selected) {
          vm.onSelectMonthFrom(value);
        }

        // validate max month
        let maxMonth = vm.mMonthPicker.monthTo.selected - vm.mMonthPicker.monthFrom.selected;
        if (maxMonth >= vm.mMonthPicker.maxMonth) {
          vm.mMonthPicker.monthFrom.selected = vm.mMonthPicker.monthTo.selected - (vm.mMonthPicker.maxMonth - 1);
        }
      }
    }
  }
}
</script>

<style scoped>
.month-picker {
  width: 24rem;
  height: 24rem;
  border-width: 1px;
  background-color: yellow;
}
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-disabled {
  opacity: 0.5;
}
</style>
