<template>
  <div class="container-fluid p-3">
    <slot name="content_container"></slot>
  </div>
</template>

<script>
const LOG_TAG = 'content_layout';

export default {
  name: LOG_TAG,
}
</script>

<style scoped>
</style>
