<template>
  <div v-if="mMember.isValid()">


    <search-user-card
        mode="name"
        :club-id="mSquadExt.getClubId()"
        :squad-id="mSquadExt.getSquadId()"
        :dark="true"
        :modifiable="false"
        :user="mMember"
        :swimmer="mSwimmer"
        :on-search-state="onSearchSwimmerState">
    </search-user-card>

    <search-user-by-email-card
      v-if="mMember.getId()"
      :dark="true"
      :modifiable="false"
      :link-my-togs="true"
      :user="mMember">

    </search-user-by-email-card>

    <!-- custom swimmer name -->
    <label
        class="mt-2 primary"
        style="font-size: 0.85em;">Display name:</label>
    <input
        type="text"
        class="form-control"
        placeholder="Enter swimmer name"
        v-model="mEditOptions.customName">

    <!-- swimmer mobile -->
    <label
        class="mt-2 primary"
        style="font-size: 0.85em;">Mobile number:</label>
    <input
        type="text"
        class="form-control"
        placeholder="Enter mobile number"
        v-model="mEditOptions.mobile">
    <div class="d-flex flex-row justify-content-end mt-4">
      <b-button class="my-button-confirm"
                :disabled="!mEditOptions.updated"
                v-on:click.stop="onConfirmUpdateMember()">
        Update
      </b-button>
    </div>
  </div>
</template>
<script>

import SearchUserCard from "@/views/content/components/search_user_card";
import SearchUserByEmailCard from "@/views/content/components/search_user_by_email_card";
import MySquadBloc from "@/bloc/mysquad_bloc";
import model from "@/model";
import logger from "@/util/logger"

const LOG_TAG = 'profile_update_profile';

export default {
  name: LOG_TAG,
  components: {
    SearchUserCard,
    SearchUserByEmailCard
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    member: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
    swimmer: {
      type: Object,
      default: function () {
        return model.memberResponse();
      }
    },
  },
  data () {
    return {
      mSquadExt: model.squadResponseExt(),
      mMember: model.memberResponse(),
      mSwimmer: model.memberResponse(),
      mEditOptions: {
        customName: '',
        mobile: '',
        updated: false,
      },
      mUser: model.memberResponse(),
    }
  },
  computed: {
  },
  watch: {
    squadExt: function onChange() {
      const vm = this;
      vm.refresh();
    },
    member: function onChange() {
      const vm = this;
      vm.refresh();
    },
    swimmer: function onChange() {
      const vm = this;
      vm.refresh();
    },
    'mEditOptions.customName': function onChange() {
      const vm = this;
      vm.mEditOptions.updated = true;
      logger.d(LOG_TAG, 'watch:name');
    },
    'mEditOptions.mobile': function onChange() {
      const vm = this;
      vm.mEditOptions.updated = true;
      logger.d(LOG_TAG, 'watch:mobile');
    }
  },
  mounted() {
    const vm = this;
    vm.refresh();
  },
  methods: {
    refresh() {
      logger.d(LOG_TAG, 'refresh');
      const vm = this;
      vm.mSquadExt = vm.squadExt;
      vm.mMember = vm.member;
      vm.mSwimmer = vm.swimmer;
      vm.mEditOptions.customName = vm.member.getValidatedName();
      vm.mEditOptions.mobile = vm.member.getMobile();
      setTimeout(function(){
        vm.mEditOptions.updated = false;
      }, 300);
    },
    onSearchSwimmerState(isSearching, isSuccess, swimmer) {
      const vm = this;
      if (!isSearching && isSuccess) {
        vm.mSwimmer = swimmer;
        // use swimmer official name as default
        if (swimmer.getName().length > 0) {
          vm.mEditOptions.customName = swimmer.getName();
        }
        logger.d(LOG_TAG, 'onSearchSwimmerState', isSearching, isSuccess, swimmer.getMemberId());
      }
    },
    onSearchUserState(isSearching, isSuccess, user) {
      const vm = this;
      logger.d(LOG_TAG, 'onSearchUserState', isSearching, isSuccess, user);
      vm.mUser = user;
    },
    onConfirmUpdateMember() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      MySquadBloc.updateMemberProfile(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mMember.getSquadMemberId(),
          vm.mEditOptions.customName, vm.mEditOptions.mobile, vm.mSwimmer.getMemberId(),
          () => {
            vm.$notify({ type: 'success', text: 'Profile updated successfully.' });
            vm.$store.commit('setLoading', false);
            vm.mEditOptions.updated = false;
          },
          (error) => {
            vm.$notify({ type: 'error', text: 'There was a problem updating your profile, please try again later.' });
            vm.$store.commit('setLoading', false);
            logger.w(LOG_TAG, 'updateMember', error);
          }
      )
    }
  }
}
</script>

<style scoped>
</style>
