<template>
  <page-layout>
    <side-bar
        slot="page_side_bar"
        :side-options="mSideOption.options"
        v-on:click="onClickNav">
    </side-bar>
    <!-- router view is page content -->
    <router-view slot="page_content">
    </router-view>
  </page-layout>
</template>

<script>

import PageLayout from "@/components/page_layout"
import SideBar from "@/components/side_bar"
import UpdateBanner from "@/views/content/update_banner"
import UpdateResult from "@/views/content/update_result"
import UpdateEvent from "@/views/content/update_event"
import UpdatePyschSheet from "@/views/content/update_pysch_sheet"
import BroadcastMessage from "@/views/content/broadcast_message"
import Report from "@/views/content/report"
import ResetPassword from "@/views/content/reset_password"
import SuperUser from "@/views/content/super_user"
import PremiumUsers from "@/views/content/premium_users"
import SubscriptionPremiumUsers from "@/views/content/subscription_premium_users"
import SyncUserRecords from '@/views/content/sync_user_records.vue'
// mysquad
import SetupClub from "@/views/content/setup_club"
import MyClub from "@/views/content/my_club"
import TrainingSets from "@/views/content/training_sets"
import ClubReports from "@/views/content/club_reports"
import MySquads from "@/views/content/my_squads"

import PlaceHolder from "@/views/content/place_holder"

import webapi from "@/webapi"
import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'dashboard';

export default {
  name: LOG_TAG,
  components: {
    PageLayout,
    SideBar,
  },
  props: {
  },
  computed: {
    isMySquad() {
      return process.env.VUE_APP_TARGET === 'mysquad';
    },
    storeState() {
      return this.$store.state;
    },
    isSuperUser() {
      return this.storeState.firebaseUser.superUser;
    },
    storeAuth() {
      return this.storeState.auth;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeSquadsExcludeCoached() {
      return this.storeAuth.getSquadsExcludeCoached();
    },
  },
  data() {
    return {
      mSideOption: {
        options: [],
      }
    };
  },
  mounted() {
    const vm = this;
    // logger.d(LOG_TAG, 'mounted', vm.$route.name);
    const auth = vm.storeAuth;
    if (vm.isMySquad) {
      const options = [];
      logger.d(LOG_TAG, 'mounted', vm.isAdmin, vm.isCoach, vm.storeAuth.hasSquads());

      if (vm.storeAuth.isAdmin()) {
        options.push({text: SetupClub.display, value: SetupClub.route});
      }
      if (vm.isAdmin || vm.isCoach) {
        options.push({text: MyClub.display, value: MyClub.route});
        if (vm.storeAuth.hasClub()) {
          options.push({text: TrainingSets.display, value: TrainingSets.route});
          options.push({text: ClubReports.display, value: ClubReports.route});
        }
      }

      // generic options (only displays non coach squads)
      if (vm.storeSquadsExcludeCoached.length > 0) {
        options.push({text: MySquads.display, value: MySquads.route});
      }

      vm.mSideOption.options = options;
      vm.onClickNav(options[0].value);

      vm.syncMySquadData();
    } else {
      if (auth.isAdmin()) {
        vm.mSideOption.options = [
          {text: UpdateResult.display, value: UpdateResult.route},
          {text: UpdateEvent.display, value: UpdateEvent.route},
          {text: UpdatePyschSheet.display, value: UpdatePyschSheet.route},
          {text: UpdateBanner.display, value: UpdateBanner.route},
        ];
        if (vm.storeAuth.hasRegions()) {
          vm.mSideOption.options.push({text: BroadcastMessage.display, value: BroadcastMessage.route});
        }
        vm.mSideOption.options.push({text: Report.display, value: Report.route});
        vm.mSideOption.options.push({text: ResetPassword.display, value: ResetPassword.route});
        if (vm.isSuperUser) {
          vm.mSideOption.options.push({text: SuperUser.display, value: SuperUser.route});
          vm.mSideOption.options.push({text: SubscriptionPremiumUsers.display, value: SubscriptionPremiumUsers.route});
          vm.mSideOption.options.push({text: PremiumUsers.display, value: PremiumUsers.route});
          vm.mSideOption.options.push({text: SyncUserRecords.display, value: SyncUserRecords.route});
        }
      } else {
        vm.mSideOption.options = [
          {text: UpdateResult.display, value: UpdateResult.route},
          {text: UpdateEvent.display, value: UpdateEvent.route},
          {text: UpdatePyschSheet.display, value: UpdatePyschSheet.route},
        ];
      }
      vm.onClickNav(UpdateResult.route);
    }

    logger.d(LOG_TAG, 'mounted', vm.$route.name, auth.isAdmin(), vm.mSideOption.options.length);

  },
  methods: {
    onClickNav(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickNav', value);
      switch (value) {
        case UpdateResult.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case UpdateEvent.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case UpdatePyschSheet.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case UpdateBanner.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case BroadcastMessage.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case Report.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case ResetPassword.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case PlaceHolder.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case SetupClub.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case MyClub.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case TrainingSets.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case ClubReports.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case MySquads.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case SuperUser.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case PremiumUsers.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case SubscriptionPremiumUsers.route:
          vm.$router.replace({name: value}, () => {});
          break;
        case SyncUserRecords.route:
          vm.$router.replace({name: value}, () => {});
          break;
        default:
          //vm.$router.replace({name: UpdateResult.route}, () => {});
      }
    },
    syncMySquadData() {
      const vm = this;
      if (vm.storeAuth.hasClub()) {
        // sync club training sets
        webapi.apiService.getTrainingSets(
            vm.storeAuth.getClubId(),
            (response) => {
              logger.d(LOG_TAG, 'getTrainingSets', response.length);
              const dataList = response.map((data) => {
                return model.trainingSetInfo(data);
              });
              vm.$store.commit('setTrainingSets', dataList);
            },
            (error) => {
              logger.w(LOG_TAG, 'getTrainingSets', error);
            }
        )
      }

    }
  }
}
</script>

<style scoped>
</style>
