// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      state: 'unknown', // present, absent, unknown
      name: '',
      squadMemberId: ''
    };
  }
  object.getNameKey = function getNameKey() {
    return object.getName() + object.getSquadMemberId();
  }
  object.setAbsent = function setAbsent() {
    object.state = 'absent';
    return object;
  }
  object.setPresent = function setPresent() {
    object.state = 'present';
    return object;
  }
  object.setName = function setName(value) {
    object.name = value;
    return object;
  }
  object.setSquadMemberId = function setSquadMemberId(value) {
    object.squadMemberId = value;
    return object;
  }
  object.getState = function getState() {
    return object.state ? object.state : 'unknown';
  }
  object.isAbsent = function isAbsent() {
    return object.getState() === 'absent' || object.getState() === 'unknown';
  }
  object.isPresent = function isPresent() {
    return object.getState() === 'present';
  }
  object.isUnknown = function isUnknown() {
    return object.getState() === 'unknown';
  }
  object.getName = function getName() {
    return object.name ? object.name : '';
  }
  object.getSquadMemberId = function getSquadMemberId() {
    return object.squadMemberId ? object.squadMemberId : '';
  }
  object.getAbsentKey = function getAbsentKey() {
    return object.getSquadMemberId() + '-absent';
  }
  object.getPresentKey = function getPresentKey() {
    return object.getSquadMemberId() + '-present';
  }
  object.formattedState = function formattedState() {
    let loweredCaseState = object.getState().toLowerCase();
    return object.isUnknown() ? '---' : loweredCaseState.charAt(0).toUpperCase() + loweredCaseState.slice(1);
  }
  return object;
}

