// import displayHelper from "@/util/display_helper"
import model from "@/model"
//import logger from "@/util/logger"
//import datetimeHelper from "@/util/datetime_helper"

//const LOG_TAG = 'goal_info';

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.targets) {
      object.targets = object.targets.map((target) => model.targetInfo(target));
    }

  } else {
    object = {
      squadMemberId: '',
      targets: [],
      text: '',
    };
  }
  object.getTargets = function getTargets() {
    return object.targets ? object.targets : [];
  }
  object.findMatchedTarget = function findMatchedTarget(distance, stroke) {
    const matches = this.getTargets().filter((target) => target.getDistance() === distance && target.getStroke() === stroke);
    return matches;
  }
  object.getText = function getText() {
    return object.text ? object.text : '';
  }
  return object;
}

