<template>
  <content-layout>

    <div slot="content_container">
      <!--broadcast warning -->
      <b-modal centered title="Create new message?" id="broadcast-modal">
        <h6>{{mMessageData.title}}</h6>
        <p class="my-4">{{ mMessageData.body }}</p>

        <div class="mt-1" style="font-size: 0.85em">
          <span style="font-weight: bold">Regions:</span>
          <div class="d-flex flex-row flex-wrap">
            <div class="mr-1" v-for="(region, index) in mMessageData.getSubRegion()" :key=index>
              {{region['name']}}{{mMessageData.getSubRegion().length > 1 && (mMessageData.getSubRegion().length - 1) > index ? ',' : ''}}
            </div>
          </div>
        </div>
        <div class="mt-1" style="font-size: 0.85em">
          <span style="font-weight: bold">Scheduled for:</span>
          <div>{{ mMessageData.formattedScheduledDate() }}</div>
        </div>

        <div
            v-if="mMessageData.linkText && mMessageData.link"
            class="mt-1" style="font-size: 0.85em">
          <span style="font-weight: bold">With action:
            <span class="ml-1">{{mMessageData.linkText}}</span>
          </span>
          <p>{{mMessageData.link}}</p>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="my-button-warning" @click="onConfirmBroadcastMessage()">
            Create
          </b-button>
          <b-button class="my-button-cancel" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>

      <b-modal centered title="Delete message?" id="delete-broadcast-modal">
        <h5>{{mMessageData.title}}</h5>
        <p class="my-4">
          {{ mMessageData.body }}
        </p>

        <span>
            <span style="font-weight: bold">Scheduled for:</span>
            {{ mMessageData.formattedScheduledDate() }}
          </span>


        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="my-button-warning" @click="onConfirmDeleteBroadcastMessage()">
            Delete
          </b-button>
          <b-button class="my-button-cancel" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>

      <div class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>
        <!-- create section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">
          <!-- search by -->
          <label class="my-text-primary my-input-label">
            {{ mDescriptions[0]}}
          </label>
          <message-card
            :on-click-cb="onOpenBroadcastMessage"
            :timestamp="mClearTimestamp">
          </message-card>

          <!-- history section-->
          <div class="my-text-primary mt-2"
               style="font-weight: bold;">Scheduled messages: {{mScheduledMessages.length}}</div>
          <loader :embedded="true" v-if="mInit" style="margin-top: 4rem; text-align: center"></loader>
          <section v-if="mScheduledMessages.length > 0">
            <div v-for="(message) in mScheduledMessages" v-bind:key="message.selected"
              class="my-text-primary my-box my-box-decoration mt-2 pt-1 pl-2 pr-2 pb-1
                    d-flex flex-column justify-content-sm-around"
                 style="padding-top: 3px">

              <div class="d-flex flex-row justify-content-between">
                <span style="font-size: 0.8em">
                {{message.formattedScheduledDate()}}
                </span>

                <div>
                  <font-awesome-icon
                      icon="trash-alt"
                      size="sm"
                      class="label clickable warning"
                      v-on:click.prevent="onOpenDeleteMessage(message)"
                  />
                </div>
              </div>
              <div style="font-size: 0.8em">
                <span style="font-weight: bold;">{{message.title}}</span>
              </div>
              <div class="mt-1" style="font-size: 0.8em">
                <span>{{message.body}}</span>
              </div>
              <span v-if="message.getSubRegion().length > 0" class="font-weight-bold mt-1" style="font-size: 0.8em">Regions: </span>
              <div class="mt-1 d-flex flex-row flex-wrap" style="font-size: 0.8em">
                <span class="mr-1" v-for="(region, index) in message.getSubRegion()" :key="index">
                  {{region['name']}}{{message.getSubRegion().length > 1 && (message.getSubRegion().length - 1) > index ? ',' : ''}}
                </span>
              </div>
              <div v-if="message.hasAction()">
                <span class="font-weight-bold mt-1" style="font-size: 0.8em">Action:
                  <span>{{message.getLinkText()}}</span>
                </span>
                <p style="font-size: 0.8em">{{message.getLink()}}</p>
              </div>
            </div>
          </section>
          <section v-else-if="!mInit && mScheduledMessages.length == 0">
            <div class="my-text-primary mt-3" style="font-size: 0.75em">No message scheduled.</div>
          </section>
        </section>
        <div class="col-1 desktop"></div>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import MessageCard from "@/views/content/components/message_card"
import Loader from "@/components/loader"
import model from "@/model"
import MyTogsBloc from "@/bloc/mytogs_bloc";
import datetimeHelper from "@/util/datetime_helper"
import logger from "@/util/logger"

const LOG_TAG = 'broadcastMessage';

export default {
  name: LOG_TAG,
  display: 'Broadcast',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    MessageCard,
    Loader
  },
  data() {
    return {
      mInit: false,
      mMessageData: model.notificationInfo(),
      mDescriptions: [
        'Broadcast message to all mytogs APP users.',
      ],
      mScheduledMessages: [],
      mClearTimestamp: 0,
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },

  },
  mounted() {
    const vm = this;
    vm.mInit = true;
    vm.refresh();
  },
  methods: {
    refresh() {
      const vm = this;
      MyTogsBloc.syncScheduledMessages(
        (dataList) => {
          vm.mScheduledMessages = dataList;
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        },
        () => {
          vm.$store.commit('setLoading', false);
          vm.mInit = false;
        }
      )
    },
    onOpenBroadcastMessage(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onOpenBroadcastMessage', data);
      vm.mMessageData = data;
      vm.$bvModal.show('broadcast-modal');

    },
    onConfirmBroadcastMessage() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmBroadcastMessage');
      vm.$bvModal.hide('broadcast-modal');
      vm.$store.commit('setLoading', true);
      MyTogsBloc.addScheduledMessage(
          vm.mMessageData,
          (dataList) => {
            vm.$notify({
              type: 'success',
              title: 'Success',
              text: 'Message created successfully',
              duration: 3000});

            // clear
            vm.$store.commit('setDatePicker', model.datepickerInfo());
            vm.mClearTimestamp = datetimeHelper.currentTimeInSecond();
            vm.mScheduledMessages = dataList;
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            vm.$notify({
              type: 'error',
              title: 'Oops, something went wrong.',
              text: error.data,
              duration: 3000
            });
            vm.$store.commit('setLoading', false);
          }
      )
    },
    onOpenDeleteMessage(data) {
      const vm = this;
      logger.d(LOG_TAG, 'onOpenDeleteMessage', data);
      vm.mMessageData = data;
      vm.$bvModal.show('delete-broadcast-modal');

    },
    onConfirmDeleteBroadcastMessage() {
      const vm = this;
      logger.d(LOG_TAG, 'onConfirmDeleteBroadcastMessage');
      vm.$bvModal.hide('delete-broadcast-modal');
      vm.$store.commit('setLoading', true);
      MyTogsBloc.removeScheduledMessage(
          vm.mMessageData.getMessageId(),
          (dataList) => {
            vm.$notify({
              type: 'success',
              title: 'Success',
              text: 'Message deleted successfully',
              duration: 3000});

            vm.mScheduledMessages = dataList;
            vm.$store.commit('setLoading', false);
          },
          (error) => {
            vm.$notify({
              type: 'error',
              title: 'Oops, something went wrong.',
              text: error.data,
              duration: 3000
            });
            vm.$store.commit('setLoading', false);
          }
      )
    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
