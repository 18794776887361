<template>
  <div v-if="mSquadExt.isValid()" class="mt-1">
    <b-modal centered
             :title="mAttendance.selected.getValidatedName()"
             id="sessions-modal" :hide-footer="true">

      <section>
        <div style="font-size: 0.85em">History ({{mAttendance.selected.getSessions().length}})</div>
        <div class="mt-1" style="width: 100%;">
          <div v-for="(session, index) in mAttendance.selected.getSessions()" :key="index"
               class="
               d-flex flex-column justify-content-center clickable
               mt-1 mb-1 pl-2 pr-2 pt-1 pb-1 my-box-no-border my-box-decoration-dark"
               style="min-height: 2.8rem; font-size: 0.75em">

            <div class="d-flex flex-row justify-content-between align-items-center">
              <div> {{session.formattedSessionTime() }} <span class="font-weight-bold">({{session.formattedDayOfWeek(true)}})</span></div>
              <div class="pt-2">
                {{session.getStartTime() }}
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <div class="font-weight-bold"> {{session.getDescription() }}</div>
              <div class="pt-2"
                :class="{
                'warning font-weight-bold': session.isAbsent(),
                }"
              >
                {{session.getType() }}
              </div>
            </div>


          </div>
        </div>
      </section>
    </b-modal>

    <!-- calendar picker -->
    <section>
      <div class="my-text-primary d-flex flex-row justify-content-end mr-4">
        <input class="mt-auto mb-auto" type="radio" id="by_week" name="by_week" :value="mCalendar.calendarType.options[0].value" v-model="mCalendar.calendarType.selected">
        <label class="ml-2 mt-auto mb-auto" style="font-size: 0.85em" for="by_week">{{mCalendar.calendarType.options[0].text}}</label>
        <input class="ml-3 mt-auto mb-auto" type="radio" id="by_month" name="by_month" :value="mCalendar.calendarType.options[1].value" v-model="mCalendar.calendarType.selected">
        <label class="ml-2 mt-auto mb-auto" style="font-size: 0.85em" for="by_month">{{ mCalendar.calendarType.options[1].text }}</label>
      </div>
    </section>
    <section>
      <div class="my-highlight-item clickable my-text-primary mt-1 mb-1 pl-3 pr-3"
           style="padding-top: 2px;" v-on:click.stop="onOpenDatePicker()">
        <div class="text-capitalize text-center d-flex flex-row justify-content-end" style="height: 2rem">
          <font-awesome-icon
              icon="calendar-alt"
              size="sm"
              class="label clickable mr-2 mt-auto mb-auto"
          />
          <div class="mt-auto mb-auto" style="margin-top: 3px; font-size: 0.9em; font-weight: bold">
            {{ mCalendar.calendarType.formattedSelected() }}: {{mCalendar.formattedStartDate()}}  -  {{mCalendar.formattedEndDate()}}
          </div>
          <font-awesome-icon
              icon="chevron-down"
              size="sm"
              class="label clickable ml-3 mt-auto mb-auto"
          />
        </div>
      </div>
    </section>

    <separator-line></separator-line>

    <!-- attendance history-->
    <div class="my-box my-box-decoration ml-1 mb-1 pl-2 pr-2 pb-2" style="min-height: 8rem">
      <div>
        <div class="d-flex flex-row justify-content-between pl-1 pr-1"
             style="height: 3rem; font-size: 0.85em">
          <div class="col-3 pl-1 align-self-center"
               style="text-align: left">
            NAME
          </div>
          <div
              class="clickable col-6 align-self-center"
              :class="{
                  'text-center': windowWidth > 768,
                  'col-9': windowWidth <= 768,
                  'text-right': windowWidth <= 768
                }"
              v-on:click.stop="onToggleRatingSort()">
            <span>ATTENDANCE RATING %
            <font-awesome-icon
                :icon="mAttendance.isSortUp() ? 'sort-up' : 'sort-down'"
                size="sm"/>
            </span>
          </div>
          <div class="col-3 pr-1 align-self-center" style="text-align: right"
               :class="{
                  'hide': windowWidth <= 768
                }">

          </div>
        </div>

        <div class="pl-2 pr-2">
          <separator-line></separator-line>
          <loader v-if="mLoading" :embedded="true" style="margin-top: 1rem; text-align: center"></loader>
          <div v-if="!mLoading && mAttendance.options.length == 0" style="margin-top: 1rem; text-align: center">
            No members
          </div>
        </div>

        <!-- swimmers -->
        <div v-for="(member, index) in mAttendance.options" :key="index"
             class="my_text_md my-highlight-item d-flex flex-row justify-content-between pl-1 pr-1 pt-2"
             style="height: 3.2rem"
            v-on:click.stop="onClickMember(member)"
        >
          <div class="col-3 pl-2 mt-2"
               :class="{
                  'col-6': windowWidth <= 768
                }">
            <div class="font-weight-bold">{{ member.getValidatedName() }}</div>
          </div>
          <div class="col-6 align-self-center"
               :class="{
                  'text-center': windowWidth > 768,
                  'col-4': windowWidth <= 768,
                  'text-right': windowWidth <= 768
                }">
            <div class="mb-1">{{member.formattedAttendanceRating()}}</div>
          </div>
          <div class="col-3 text-right"
               :class="{
                  'hide': windowWidth <= 768
                }">


          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import SeparatorLine from "@/components/separator_line"
import Loader from "@/components/loader"
import model from "@/model"
import MySquadBloc from "@/bloc/mysquad_bloc";
import dateTimeHelper from "@/util/datetime_helper";
import logger from "@/util/logger"

const LOG_TAG = 'squad_attendance';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
    Loader
  },
  props: {
    squadExt: {
      type: Object,
      default: function () {
        return model.squadResponseExt();
      }
    },
    disableEdit: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      mLoading: true,
      mSquadExt: model.squadResponseExt(),
      mCalendar: {
        loading: false,
        calendarType: {
          selected: 1,
          options: [
            {text: 'By Week', value: 1},
            {text: 'By Days', value: 2},
          ],
          isByWeek() {
            return this.selected == 1;
          },
          isByMonth() {
            return this.selected == 2;
          },
          formattedSelected() {
            return this.selected == 2 ? 'Days' : 'Week';
          },
        },
        dayOfWeekOptions: {
          options: [
            {text: 'Monday', value: 1},
            {text: 'Tuesday', value: 2},
            {text: 'Wednesday', value: 3},
            {text: 'Thursday', value: 4},
            {text: 'Friday', value: 5},
            {text: 'Saturday', value: 6},
            {text: 'Sunday', value: 7},
          ],
        },
        selectedDate: null,

        startDate: 0,
        endDate: 0,
        schedules: [],
        init() {
          this.setDate(dateTimeHelper.currentDate());
        },
        setStartEndDate(start, end) {
          logger.d(LOG_TAG, 'setStartEndDate', start, end);

          if (start && end) {
            this.startDate = start;
            this.endDate = end;

            logger.d(LOG_TAG, 'setStartEndDate',
                dateTimeHelper.convertDateFromMilliseconds(this.startDate),
                dateTimeHelper.convertDateFromMilliseconds(this.endDate));
          }
        },
        setDate(value) {
          this.selectedDate = value;
          const dateInMilliseconds = dateTimeHelper.convertDateTimeToMilliseconds(value);

          if (this.calendarType.isByMonth() || this.calendarType.isByWeek()) {
            if (this.calendarType.isByMonth()) {
              this.startDate = dateTimeHelper.epocStartOfMonth(dateInMilliseconds);
              this.endDate = dateTimeHelper.epocEndOfMonth(dateInMilliseconds);
            } else {
              this.startDate = dateTimeHelper.epocStartOfWeek(dateInMilliseconds);
              this.endDate = dateTimeHelper.epocEndOfWeek(dateInMilliseconds);
            }
            logger.d(LOG_TAG, 'setDate',
                this.selectedDate,
                dateTimeHelper.convertDateFromMilliseconds(this.startDate),
                dateTimeHelper.convertDateFromMilliseconds(this.endDate));
          } else {
            logger.d(LOG_TAG, 'setDate', this.selectedDate);
          }
        },
        setSchedules(value) {
          this.schedules = value;
        },
        formattedStartDate() {
          return dateTimeHelper.formattedDateTime(this.startDate, true);
        },
        formattedEndDate() {
          return dateTimeHelper.formattedDateTime(this.endDate, true);
        }
      },
      mAttendance: {
        selected: model.attendanceResponse(),
        options: [],
        sort: 'up', // or 'down'
        init(attendance) {
          this.options = attendance;
          this.sort = 'up';
          this.toggleSort(true);
        },
        isSortUp() {
          return this.sort === 'up';
        },
        toggleSort(init) {
          if (!init) {
            this.sort = this.sort === 'up' ? 'down' : 'up';
          }

          if (this.isSortUp()) {
            this.options.sort((a, b) => a.getAttendanceRating() - b.getAttendanceRating());
          } else {
            this.options.sort((a, b) => b.getAttendanceRating() - a.getAttendanceRating());
          }
        }
      }

    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeAuth() {
      return this.$store.state.auth;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeDatePicker() {
      return this.$store.state.datePicker;
    },
  },
  watch: {
    squadExt: function onChange(value) {
      const vm = this;
      vm.refresh(value, true);
    },
    storeDatePicker: function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:storeDatePicker', value);
      if (vm.mCalendar.calendarType.isByMonth()) {
        vm.mCalendar.setStartEndDate(value.dateStart, value.dateEnd);
      } else {
        vm.mCalendar.setDate(value.date);
      }
      vm.refresh(vm.squadExt, true);
    },
    'mCalendar.calendarType.selected': function onChange(value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch:mCalendar.calendarType.selected', value);
      vm.mCalendar.init();
      vm.refresh(vm.squadExt, true);
    }
  },
  mounted() {
    logger.d(LOG_TAG, 'mounted');
    const vm = this;
    vm.mCalendar.init();
    vm.refresh(vm.squadExt);
  },
  methods: {
    refresh(selectedSquad, force) {
      const vm = this;
      const update = (vm.mSquadExt.getSquadId() !== selectedSquad.getSquadId()) || force;
      logger.d(LOG_TAG, 'refresh', 'update', update, force);
      if (update) {
        vm.mLoading = true;
        vm.mSquadExt = selectedSquad;
        vm.mAttendance.options = [];
        MySquadBloc.syncSquadAttendance(
          vm.mSquadExt.getClubId(),
          vm.mSquadExt.getSquadId(),
          vm.mCalendar.startDate,
          vm.mCalendar.endDate,
          (attendance) => {
            vm.mAttendance.init(attendance);
            vm.mLoading = false;
            logger.d(LOG_TAG, 'syncSquadAttendance', attendance.length);
          },
          (error) => {
            vm.mLoading = false;
            logger.w(LOG_TAG, 'syncSquadAttendance', error);

          }
        );
      }
    },
    onOpenDatePicker() {
      const vm = this;
      if (vm.mCalendar.calendarType.isByMonth()) {
        vm.$store.commit('setDatePicker',
            model.datepickerInfo().enableDefaultMinDate()
                .setDate(vm.mCalendar.selectedDate)
                .setStartDate(vm.mCalendar.startDate)
                .setEndDate(vm.mCalendar.endDate)
                .setEnabled(true)
                .setByMonth2(true));

      } else {
        vm.$store.commit('setDatePicker',
            model.datepickerInfo().enableDefaultMinDate()
                .setDate(vm.mCalendar.selectedDate)
                .setEnabled(true)
                .setByWeek(true));
      }
    },
    onToggleRatingSort() {
      const vm = this;
      vm.mAttendance.toggleSort();
    },
    onClickMember(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickMember', value.getSessions().length);
      vm.mAttendance.selected = value;
      vm.$bvModal.show('sessions-modal');
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.my-highlight-item {
}
.my-highlight-item:hover {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-highlight-item-selected {
  color: var(--primary-dark);
  background-color: var(--primary-highlight);
}
.my-hover-option {
  display: none;
}
.my-highlight-item:hover .my-hover-option {
  display: block;
}
</style>
